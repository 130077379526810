// @flow

import { rem } from 'polished';
import { animated } from 'react-spring';
import styled from 'styled-components';

export default styled(animated.nav)`
  display: flex;

  & > :not(:first-child) {
    margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  }
`;
