import { styled } from '@a1s/ui';

export const Button = styled('button', {
  appearance: 'none',
  background: 'none',
  border: 'none',
  color: '$gray600',

  '&:hover': {
    color: '$gray700',
  },
});
