import { Dropdown } from '@a1s/ui';
import React, { ComponentProps } from 'react';

import { useTranslation } from 'react-i18next';

type DurationDropdownProps = ComponentProps<typeof Dropdown>;

export function DurationDropdown({ disabled, onChange, value }: DurationDropdownProps) {
  const { t } = useTranslation('dashboardHome');

  return (
    <Dropdown disabled={disabled} onChange={onChange} value={value}>
      <Dropdown.Option value="90">{t('last90Days')}</Dropdown.Option>
      <Dropdown.Option value="30">{t('last30Days')}</Dropdown.Option>
      <Dropdown.Option value="7">{t('last7Days')}</Dropdown.Option>
    </Dropdown>
  );
}
