// @flow

// $FlowFixMe
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DaysBackContext } from 'screens/App';
import Dropdown from 'ui/organisms/Dropdown';

// $FlowFixMe
import { useDurationContext } from 'utils/duration';

type Props = {
  onChange?: (selected: number) => {},
};

export default function DaysBackDropdown({ onChange }: Props) {
  const { currentInterval, setDaysBackContext } = useContext(DaysBackContext);
  const [duration, setDuration] = useDurationContext();
  const { t } = useTranslation('dates');

  useEffect(() => {
    setDaysBackContext(duration);
  }, [duration, setDaysBackContext]);

  function handleOnOptionSelected(selected: number) {
    if (onChange) onChange(selected);

    setDuration(String(selected));
    setDaysBackContext(selected);
  }

  return (
    <Dropdown
      expanded={false}
      options={[90, 30, 7]}
      onOptionSelected={(selected) => handleOnOptionSelected(selected)}
      renderAsOption={(option) => t('daysLast', { key: option })}
    >
      {t('lastDays_plural', { count: currentInterval })}
    </Dropdown>
  );
}
