import { styled, Box, Button, Stack, Table } from '@a1s/ui';
import hash from 'object-hash';
import React, { useEffect, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { canMarkAsFalsePositive } from '../../../lib';
import { Dispositions, SearchResultRow } from '../../../types';
import {
  MetaCell,
  ModalHeader,
  ReasonCell,
  ReleaseButton,
  RenderNoData,
  ReportLinkButton,
  RetractButton,
  RowWrapper,
  SectionHeader,
  SimilarMessages,
  StatusCell,
  ThreatCell,
} from '../../../ui';

import { useSearchContext } from 'screens/Search/lib/searchContext';
import { getUniqueRecipientsAsString } from 'screens/shared/dataTypeAndUtils';
import { useUnisearchAllMailData } from 'screens/shared/hooks/useUnisearchAllMailData';
import { Scrollable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

export interface ResultsProps {
  checked?: Array<SearchResultRow['id']>;

  collapsed: boolean;

  /**
   * Sets the selected row in state to be shared with the <Detail /> component
   */
  onPressViewButton?: RowProps['onPressViewButton'];

  // eslint-disable-next-line  no-unused-vars
  onRowCheck?(checked: Array<SearchResultRow['id']>): void;

  // eslint-disable-next-line no-unused-vars
  onSearchFinished?(counts: ComponentProps<typeof SectionHeader>['searchCounts']): void;

  // eslint-disable-next-line no-unused-vars
  onSearchStart?(): void;

  /**
   * The string to run the search query on.
   */
  search: ComponentProps<typeof ModalHeader>['params'];

  /**
   * The ID of the message details currently selected
   */
  selectedId?: string;
}

export function Results({
  checked = [],
  collapsed,
  onPressViewButton,
  onRowCheck,
  onSearchFinished,
  onSearchStart,
  search,
  selectedId,
}: ResultsProps) {
  const { clawbackEnabled } = useSearchContext();
  const { data, error, loading, fetchMore } = useUnisearchAllMailData(search);

  const effectDependency = hash({ data });
  useEffect(() => {
    if (!onSearchFinished) return;
    if (!data?.length) {
      onSearchFinished({ total: 0, detections: 0 });

      return;
    }

    const detections = data.filter((d) => d.finalDisposition?.toLowerCase() !== 'none');
    onSearchFinished({ total: data.length, detections: detections.length });
  }, [effectDependency]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onSearchStart && loading === true) onSearchStart();
  }, [loading, onSearchStart]);

  function handleCheck(id: string, isChecked: boolean) {
    if (!onRowCheck) return;

    if (isChecked) {
      onRowCheck([...checked, id]);
    } else {
      onRowCheck(checked.filter((i) => i !== id));
    }
  }

  function handleEnter() {
    if (data?.length && fetchMore) fetchMore();
  }

  const hasData = data && data.length > 0 && !loading && !error;

  return (
    <Box css={{ flexGrow: 1, height: '100%', minWidth: 470, position: 'relative' }}>
      <Scrollable>
        <Table css={{ minWidth: collapsed ? '100%' : 1280 }}>
          <tbody>
            {hasData && !loading ? (
              <>
                {data.map((row) => (
                  <Row
                    checked={checked.includes(row.id)}
                    clawbackFeatureEnabled={clawbackEnabled}
                    collapsed={collapsed}
                    data={row}
                    key={row.id}
                    onCheck={handleCheck}
                    onPressViewButton={onPressViewButton}
                    selectedId={selectedId}
                  />
                ))}
                <tr>
                  <td colSpan={5}>
                    <Waypoint onEnter={handleEnter} />
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={5}>
                  <RenderNoData loading={loading} term={search.searchTerm} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Scrollable>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface BlankCellProps {
  hidden?: boolean;
}

function BlankCell({ hidden }: BlankCellProps) {
  return <Table.Cell border="none" css={{ display: hidden === true ? 'none' : undefined }} />;
}

interface RowProps {
  checked: ComponentProps<typeof MetaCell>['checked'];
  clawbackFeatureEnabled?: boolean;
  collapsed: ResultsProps['collapsed'];
  data: SearchResultRow;
  onCheck?: ComponentProps<typeof MetaCell>['onCheck'];
  onPressViewButton?: (details: SearchResultRow) => void; // eslint-disable-line no-unused-vars
  selectedId?: string;
}

function Row({ checked, clawbackFeatureEnabled, collapsed, data, onCheck, onPressViewButton, selectedId }: RowProps) {
  const { retractEnabled, userPermitted } = useSearchContext();
  const { t } = useTranslation('unisearch');

  const {
    bccRecipient,
    cc,
    clientRecipients,
    edfHash,
    envelopeTo,
    findings,
    finalDisposition,
    from,
    id,
    isQuarantined,
    messageId,
    phishSubmission,
    redressedActions,
    storedAt,
    subject,
    threatCategories = [],
    to,
    ts,
    validation,
  } = data;

  const isBenign = finalDisposition?.toLowerCase() === 'none';

  const onPressView = () => {
    if (onPressViewButton) onPressViewButton(data);
  };

  const onRowClick = (e: any) => {
    e.stopPropagation();
    if (collapsed) onPressView();
  };

  const recipients = getUniqueRecipientsAsString(bccRecipient, cc, envelopeTo, to);

  return (
    <RowWrapper collapsed={collapsed} onClick={onRowClick} selected={selectedId === id}>
      <MetaCell
        checked={checked}
        from={from}
        onCheck={onCheck}
        subject={subject}
        timestamp={ts}
        to={recipients}
        uniqueMessageId={id}
      />
      <StatusCell
        disposition={finalDisposition}
        isQuarantined={isQuarantined}
        redressedActions={redressedActions}
        truncated={collapsed}
        validation={validation}
      />
      <RenderThreatCell collapsed={collapsed} dispositionType={finalDisposition} findings={threatCategories} />
      <RenderReasonCell collapsed={collapsed} detectionReasons={findings} />
      <Table.Cell
        border="none"
        css={{ display: collapsed === true ? 'none' : undefined, maxWidth: 266, minWidth: '10%' }}
      >
        <Stack align="end" css={{ height: '100%' }} gap={2}>
          <ButtonWrapper>
            {userPermitted && (
              <>
                {isQuarantined && <ReleaseButton releaseParams={[{ clientRecipients, storedAt }]} />}
                {!isQuarantined && !phishSubmission && retractEnabled && (
                  <RetractButton
                    clawbackFeatureEnabled={clawbackFeatureEnabled}
                    retractParams={[{ clientRecipients, messageId }]}
                  />
                )}
                <Button onPress={onPressView}>{t(isBenign ? 'viewDetails' : 'view')}</Button>
              </>
            )}
          </ButtonWrapper>
          <StackWithWaypointBuster align="end" css={{ flexGrow: 1 }} justify="space-between">
            {edfHash && <SimilarMessages edfHash={edfHash} searchType="all-mail" />}
            <Stack align="end" css={{ flexGrow: 1 }} justify="end">
              {data && userPermitted && (
                <ReportLinkButton
                  data={data}
                  kind={canMarkAsFalsePositive(finalDisposition) ? 'false-positive' : 'false-negative'}
                />
              )}
            </Stack>
          </StackWithWaypointBuster>
        </Stack>
      </Table.Cell>
    </RowWrapper>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface ThreatCellProps {
  collapsed: ResultsProps['collapsed'];
  dispositionType: Dispositions;
  findings: string[];
}

function RenderThreatCell({ collapsed, dispositionType, findings }: ThreatCellProps) {
  if (dispositionType !== 'NONE') {
    return <ThreatCell findings={findings} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

interface ReasonCellProps {
  collapsed: ResultsProps['collapsed'];
  detectionReasons: SearchResultRow['findings'];
}

function RenderReasonCell({ collapsed, detectionReasons = [] }: ReasonCellProps) {
  if (detectionReasons?.length > 0) {
    return <ReasonCell data={detectionReasons} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const ButtonWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  float: 'right',
  gap: '$2',
  justifyContent: 'end',
  maxWidth: 195,
  position: 'relative',
  width: '100%',
});

const StackWithWaypointBuster = styled(Stack, {
  // This little bit of CSS removes the `Waypoint` component inside of the `SimilarMessages`
  // from the flexbox layout while still letting it be considered a visible element bvy the
  // browser in a way that adding `display: none` or `visibility: hiddern` would not. The
  // reason for this is that while it was still in the flexbox layout, it was adding extra
  // padding on the element because flexbox as still rendering a gap inbetween the 0px
  // element that the `Waypoint` component renders.
  '& span[style]': {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap;',
    width: '1px',
  },
});
