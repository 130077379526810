// @flow

import { rem, rgba } from 'polished';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';

import Modal from './Modal';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import MaxHeight from 'ui/atoms/MaxHeight';
import PaddedContent from 'ui/atoms/PaddedContent';
import Section from 'ui/atoms/Section';
import TextStyle from 'ui/atoms/TextStyle';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Centered = styled.div`
  position: relative;
  text-align: center;
`;

export const Container = styled(animated.div).attrs(({ open }) => ({ 'aria-hidden': !open, role: 'alert' }))`
  background-color: ${({ dark, theme }) => (dark ? theme.colors.ghostWhite : 'transparent')};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  left: 50%;
  min-height: ${rem(150)};
  margin: ${({ theme }) => rem(theme.spacing.lg)};
  opacity: 0;
  padding: ${({ theme }) => rem(theme.spacing.lg)};
  padding-top: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: ${rem(320)};
  max-width: ${({ expanded }) => (expanded ? rem(820) : rem(480))};
  z-index: 999;

  ${({ expanded }) =>
    expanded &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type Props = {
  children?: React$Node,
  dark?: boolean,
  dataTestId?: string,
  expanded?: boolean,
  onDismiss?: Function,
  message?: string,
  open?: boolean,
  title?: React$Node,
  zebraStripe?: boolean,
};

export default function Alert(props: Props) {
  const {
    dark = true,
    dataTestId = 'molecule-alert',
    expanded = false,
    message = 'dialogs:dismiss',
    open = false,
    title,
    zebraStripe = false,
  } = props;

  const transitions = useTransition(open, null, {
    config: { tension: 500, friction: 35, mass: 1 },
    from: { opacity: 0, transform: 'translate(-50%, -100%)' },
    enter: { opacity: 1, transform: 'translate(-50%, -50%)' },
    leave: { opacity: 0, transform: 'translate(-50%, -100%)' },
  });

  const { t } = useTranslation('common');

  return transitions.map(
    ({ item, key, props: style }) =>
      item && (
        <Container
          dark={dark ? 'true' : undefined}
          data-testid={dataTestId}
          expanded={expanded ? 'true' : undefined}
          key={key}
          open={open}
          style={style}
        >
          <>
            <Section key={key} style={props} zebraStripe={zebraStripe}>
              <Centered>
                <ConditionalRender
                  condition={!!title}
                  fallback={
                    <MaxHeight height={250}>
                      <TextStyle fontSize="sm">{props.children || t(message)}</TextStyle>
                    </MaxHeight>
                  }
                >
                  <>
                    {title}
                    <PaddedContent pushTop="md">
                      <MaxHeight height={450}>
                        <TextStyle fontSize="sm">{props.children || t(message)}</TextStyle>
                      </MaxHeight>
                    </PaddedContent>
                  </>
                </ConditionalRender>
              </Centered>
            </Section>
            <Centered>
              <Button onClick={props.onDismiss}>{t('buttons.dismiss')}</Button>
            </Centered>
          </>
        </Container>
      )
  );
}

Alert.Modal = Modal;
