// @flow

import styled from 'styled-components';

const Split = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
Split.displayName = 'Split';

export default Split;
