import { Box, Center } from '@a1s/ui';
import { range } from 'lodash';
import React from 'react';
import Masonry from 'react-masonry-component';

import {
  DetectionStatsPanel,
  DomainProximityPanel,
  EmailLinkIsolation,
  MaliciousThreatTypePanel,
  OrgSpoofPanel,
  PhishSubmissionsPanel,
  RetractionsPanel,
  SystemStatsPanel,
  ThreatOriginsPanel,
  TopBECTargetsPanel,
} from './panels';

import { useSearchContext } from 'screens/Search/lib/searchContext';

//
// Settings
// -------------------------------------------------------------------------------------------------

const OPTIONS = {
  columnWidth: '[data-sizer]',
  itemSelector: '[data-column]',
  percentPosition: true,
  transitionDuration: 0,
};

const STYLES = {
  '[data-column], [data-sizer]': { padding: '$space$5', width: '25%' },
  '[data-column="2"]': { width: '50%' },

  // This bit of code programmatically creates a z-index hierarchy for the panels/chiclets below
  // so that the tooltips at the top of the grid will always render at a higher z-index
  // than ones lower on the grid. This code assumes there are no more than 10 panels being rendered
  // so we're not adding unneeded CSS. The number 10, however, can be increased safely in the future.
  ...range(1, 10).reduce(
    (acc, i, _, arr) => ({ ...acc, [`[data-column]:nth-child(${i}) [data-title]`]: { zIndex: arr.length + 99 - i } }),
    {}
  ),
};

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function DashboardScreen() {
  const { retractEnabled } = useSearchContext();

  return (
    <Center max="7xl">
      <Box css={STYLES} px="10" py="12">
        <Masonry enableResizableChildren options={OPTIONS}>
          <div data-sizer />
          <div data-column="2">
            <SystemStatsPanel />
          </div>

          <div data-column="2">
            <DetectionStatsPanel />
          </div>

          {retractEnabled && (
            <div data-column="2">
              <RetractionsPanel />
            </div>
          )}

          <div data-column="2">
            <ThreatOriginsPanel />
          </div>

          <div data-column="2">
            <PhishSubmissionsPanel />
          </div>

          <div data-column="1">
            <MaliciousThreatTypePanel />
          </div>

          <div data-column="1">
            <EmailLinkIsolation />
          </div>

          <div data-column="1">
            <TopBECTargetsPanel />
          </div>

          <div data-column="1">
            <OrgSpoofPanel />
          </div>

          <div data-column="1">
            <DomainProximityPanel />
          </div>
        </Masonry>
      </Box>
    </Center>
  );
}
