// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(2)};
  padding: 0 ${({ theme }) => rem(theme.spacing.lg)};
  transition: all 0.2s ${({ theme }) => theme.timing.easeInOut};

  background-image: ${({ percent, theme }) => `
    linear-gradient(
      to right,
      ${theme.colors.antiFlashWhite} ${percent * 100}%,
      transparent ${percent * 100}%,
      transparent
    );
  `};
`;

export default Row;
