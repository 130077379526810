// @flow

import { rem } from 'polished';

import styled, { css } from 'styled-components';

const Blip = styled.div`
  align-items: center;
  background-color: ${(p) => p.theme.colors.neonCarrot};
  border-radius: ${rem(16)};
  color: ${(p) => p.theme.colors.white};
  display: flex;
  font-size: ${rem(10)};
  font-weight: bold;
  height: ${rem(16)};
  justify-content: center;
  width: ${rem(16)};

  ${({ success }) =>
    success &&
    css`
      background-color: ${(p) => p.theme.colors.green};
    `}
`;

Blip.displayName = 'Blip';

export default Blip;
