import React from 'react';

import { useTranslation } from 'react-i18next';

import Loading from 'ui/atoms/Loading';
import NoData from 'ui/molecules/NoData';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface NoDataProps {
  loading: boolean;
  term?: string;
}

export function RenderNoData({ loading, term }: NoDataProps) {
  const { t } = useTranslation('unisearch');

  if (loading) {
    return <Loading />;
  }

  if (!loading && (!term || term === '')) {
    return <NoData message="noResults" />;
  }

  return <NoData>{t('noResultsSearch', { term })}</NoData>;
}
