// @flow

import {
  sankey as d3sankey,
  sankeyLinkHorizontal,
  sankeyRight,
  type SankeyExtraProperties,
} from '@joshnabbott/d3-sankey';
import hash from 'hash-sum';
import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import ResizeObserver from 'resize-observer-polyfill';
import { withTheme } from 'styled-components';

import colors from './colors';
import Path from './Path';
import Rect from './Rect';
import Text from './Text';
import { HEIGHT, NODE_PADDING, NODE_WIDTH } from './util';

import type { Data } from './util';

import type { Theme } from 'config/theme';

type Props = { data: Data, theme: Theme, t: TFunction };
type State = { width: number };

class SankeyGraph extends PureComponent<Props, State> {
  container: ?HTMLDivElement = null;

  resizeObserver = null;

  state = { width: 400 };

  componentDidMount() {
    if (!this.container) return;

    this.setState({ width: this.container.offsetWidth });

    this.resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      entries.forEach((entry) => this.setState({ width: entry.contentRect.width }));
    });

    // $FlowFixMe
    this.resizeObserver.observe(this.container);
  }

  componentWillUnmount() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  assignRef = (ref: any) => {
    this.container = ref;
  };

  render() {
    const { data, theme, t } = this.props;
    const { width } = this.state;

    const sankey = d3sankey()
      .nodeWidth(NODE_WIDTH)
      .nodePadding(NODE_PADDING)
      .extent([
        [1, 1],
        [width, HEIGHT],
      ]);

    sankey.nodeAlign(sankeyRight);

    sankey.nodeId((d) => d.id);

    sankey(data);

    const sankeyedData: SankeyExtraProperties = (data: SankeyExtraProperties);

    // A real sankey graph wouldn't implement colors in this way, but it was a design requirement
    let linksIndex = 0;
    let nodesIndex = 0;
    const calculateFillForRect = (key) => {
      let color;
      if (sankeyedData.links.map((l) => l.source.id).includes(key)) {
        color = colors.source[linksIndex];
        if (linksIndex < colors.source.length - 1) linksIndex += 1;
        return color;
      }

      color = colors.target[nodesIndex];
      if (nodesIndex < colors.source.length - 1) nodesIndex += 1;
      return color;
    };

    const calculateFillForText = (d) =>
      d.y1 - d.y0 > NODE_PADDING - 5 ? theme.colors.white : theme.colors.deepSpaceSparkle;

    const links = sankeyedData.links.map((d) => {
      const linkPath = sankeyLinkHorizontal()(d);

      return (
        <g key={linkPath}>
          <Path d={linkPath} strokeWidth={Math.max(1, d.width)} stroke={theme.colors.azureishWhite} />
          <Path
            d={linkPath}
            strokeWidth={1}
            stroke={theme.colors.pastelBlue}
            transform={`translate(0, -${d.width / 2})`}
          />
          <Path
            d={linkPath}
            strokeWidth={1}
            stroke={theme.colors.pastelBlue}
            transform={`translate(0, ${d.width / 2})`}
          />
        </g>
      );
    });

    const nodes = sankeyedData.nodes.map((d, i) => (
      <g key={hash(d)}>
        <Rect
          x={d.x0}
          y={d.y0}
          rx={2}
          ry={2}
          height={d.y1 - d.y0}
          width={d.x1 - d.x0}
          fill={calculateFillForRect(d.id)}
        />
        <g transform="translate(15, 25)">
          <clipPath id={`clip${i}`}>
            <rect x={d.x0} y={d.y0 - 10} width={d.x1 - d.x0 - 80} height={18} />
          </clipPath>
          <Text
            clipPath={`url(#clip${i})`}
            fill={calculateFillForText(d)}
            x={d.x0}
            y={d.y0}
            dy="0.35em"
            textAnchor="start"
          >
            {d.id}
          </Text>
          <Text fill={calculateFillForText(d)} x={d.x1 - 25} y={d.y0} dy="0.35em" textAnchor="end">
            {t('Statistic.number', { value: d.value })}
          </Text>
        </g>
      </g>
    ));

    return (
      <div ref={this.assignRef} data-testid="organisms-sankey-graph">
        <svg height={HEIGHT + 50} width={width}>
          <g className="links" fill="none">
            {links}
          </g>
          <g className="nodes">{nodes}</g>
        </svg>
      </div>
    );
  }
}

export default withTranslation('components')(withTheme(SankeyGraph));
