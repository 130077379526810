import { Text } from '@a1s/ui';
import React, { ReactNode } from 'react';

export const P = ({ children }: { children: ReactNode }) => {
  return (
    <Text color="$gray800" css={{ maxWidth: '65ch' }} font="sans" size="sm" stretch="ultraCondensed" weight="regular">
      {children}
    </Text>
  );
};

export const Title = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      color="$orange500"
      css={{ flexGrow: 1 }}
      font="sans"
      size="sm"
      stretch="ultraCondensed"
      transform="uppercase"
      weight="semibold"
    >
      {children}
    </Text>
  );
};
