// @flow

// $FlowFixMe
import { Box, HorizontalBars, Loadable, Text } from '@a1s/ui';
import { startCase } from 'lodash';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { dataForThreatTypeGraphTable, placeholderDataArray } from '../dataTypesAndUtils';
import { LabeledValue } from '../ui/LabeledValue';

import { type ThreatType } from './dataTypesAndUtils';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';

type Props = {
  data: Array<ThreatType>,
  error: boolean,
  loading: boolean,
};

export default function TopThreatTypes({ data, error, loading }: Props) {
  const { t } = useTranslation('email');
  const total = data.reduce((acc, { count }) => {
    return acc + count;
  }, 0);

  return (
    <ContentBlock
      dataTestId="top-threat-types"
      top={
        <>
          <BlockHeader title={t('suspiciousThreatTypes')} />
          <ConditionalRender
            condition={!error && (loading || dataForThreatTypeGraphTable(data, total).length > 0)}
            fallback={
              <Box css={{ textAlign: 'center' }} pb>
                <Text color="$gray400" size="sm" stretch="ultraCondensed">
                  {t('components:NoData.noDataObserved')}
                </Text>
              </Box>
            }
          >
            <HorizontalBars gap="2">
              {(loading ? placeholderDataArray(10) : dataForThreatTypeGraphTable(data, total)).map((row) => {
                return (
                  <HorizontalBars.Bar color="$gray100" key={row[0]} value={row[1]}>
                    <Loadable loading={loading}>
                      <LabeledValue
                        childrenColor="$gray600"
                        gap="5"
                        params={{
                          disposition: 'suspicious',
                          metric: `threat_cats_suspicious:${row[0]}`,
                        }}
                        value={row[1]}
                      >
                        {t(`threatTypes:${startCase(row[0])}`)}
                      </LabeledValue>
                    </Loadable>
                  </HorizontalBars.Bar>
                );
              })}
            </HorizontalBars>
          </ConditionalRender>
        </>
      }
    />
  );
}
