// @flow

import styled, { css, type ReactComponentStyled } from 'styled-components';

type Props = { side: 'left' | 'right' };

const Section: ReactComponentStyled<Props> = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;

  ${({ side }) =>
    side === 'right' &&
    css`
      justify-content: flex-end;
      max-width: 50%;
    `};
`;

export default Section;
