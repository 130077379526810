import { styled } from '@a1s/ui';

//
// Styled components
// -------------------------------------------------------------------------------------------------

export const StyledDiv = styled('div', {
  maxWidth: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
});
