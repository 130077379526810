import { rem } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useCurrentUser } from 'utils/hooks/useCurrentUser';

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: inline;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(12)};
  line-height: 1;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.dimGray};
  }
`;

export default function OneTrustButton() {
  const { user: currentUser } = useCurrentUser();
  const { t } = useTranslation();

  // Start with text that comes out of our own i18n translations.
  // Ultimately, we will use the text provided by OneTrust since it
  // needs to change for different locations and OneTrust will
  // translate that text for us.
  const [cookieText, setCookieText] = useState(t('cookieSettings'));

  const { langPref } = currentUser || {};
  const initializeOneTrust = useCallback(() => {
    // Make sure that OneTrust has initialized - it usually has but we
    // can't rely on it being there.
    if (typeof window.OneTrust?.GetDomainData === 'function') {
      // See if the user has set its language on our end and, if so,
      // pass that info along to OneTrust.
      if (langPref && typeof window.OneTrust?.changeLanguage === 'function') {
        window.OneTrust?.changeLanguage(langPref);
      }
      const data = window?.OneTrust?.GetDomainData();
      setCookieText(data?.CookieSettingButtonText || t('cookieSettings'));
    } else {
      window.setTimeout(initializeOneTrust, 500);
    }
  }, [langPref, t]);

  useEffect(initializeOneTrust, [initializeOneTrust]);

  function handleClick() {
    window.OneTrust?.ToggleInfoDisplay();
  }

  return (
    <Button id="ot-sdk-btn" onClick={handleClick} type="button">
      {cookieText}
    </Button>
  );
}
