/**
 *
 * All of the paths used when communicating with the API
 *
 */

const baseUrl = '/api';
const mailstreamUrl = `${baseUrl}/mailstream`;

const apiPaths = {
  mailstream: {
    // belong to BEC Legacy
    displayNames: `${mailstreamUrl}/display_names/limit/-/offset/-/conditions/-/columns/-`,
    displayNameCreate: `${mailstreamUrl}/display_names_create`,
    displayNamesCreateBatch: `${mailstreamUrl}/display_names_create_batch`,
    displayNameDelete: `${mailstreamUrl}/display_names_delete`,
    displayNameUpdate: `${mailstreamUrl}/display_names_update`,
  },
  users: `${baseUrl}/users/`,
};

export default apiPaths;
