import React from 'react';

import { Container } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

/**
 * Displays a masked, blurred background over the entire screen. Meant to be used as the brackgrop
 * for the `Modal` component.
 */
export function Backdrop() {
  return <Container role="presentation" />;
}
