import { Text } from '@a1s/ui';
import React, { ComponentProps } from 'react';

import { AbbreviatedNumber } from '..';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface LargeNumberProps {
  /**
   * Escape-hatch for being able to have more control over how the large number is rendered
   */
  children?: ComponentProps<typeof AbbreviatedNumber>['children'];

  /**
   * Optionally set the color for the large number
   */
  color?: ComponentProps<typeof Text>['color'];

  /**
   * The number to display, largely
   */
  value?: ComponentProps<typeof AbbreviatedNumber>['value'];
}

/**
 * @deprecated Use a text style instead
 */
export function LargeNumber({ children, color = '$gray700', value }: LargeNumberProps) {
  return (
    <Text as="div" color={color} font="lato" lineHeight="1" size="6xl" stretch="expanded" weight="light">
      <AbbreviatedNumber value={value}>{children}</AbbreviatedNumber>
    </Text>
  );
}
