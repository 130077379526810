// @flow

// $FlowFixMe
import React, { createElement, useEffect, useState, Fragment } from 'react';
import rehypeReact from 'rehype-react';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

import Content from './Content';

type Props = { dataTestId?: string, children?: string };

const MarkedDown = ({ dataTestId = 'molecule-marked-down', children = '' }: Props) => {
  const markdown = useMarkdown(children.toString());
  return <Content data-testid={dataTestId}>{markdown}</Content>;
};

export default MarkedDown;

function useMarkdown(text: string) {
  const [content, setContent] = useState(Fragment);

  useEffect(() => {
    unified()
      .use(remarkParse, { fragment: true })
      .use(remarkGfm)
      .use(remarkRehype)
      .use(rehypeReact, { createElement, Fragment })
      .process(text)
      .then((file) => {
        setContent(file.result);
      });
  }, [text]);

  return content;
}
