// @flow

import { rgba, rem } from 'polished';
import styled, { css } from 'styled-components';

const Cell = styled.div`
  background-color: none;
  color: ${({ theme }) => rgba(theme.colors.raisinBlack, 0.5)};
  font-size: ${rem(14)};
  padding: ${({ theme }) => rem(theme.spacing.sm)} 0;
  word-wrap: break-word;

  ${({ hideValue }) =>
    hideValue &&
    css`
      display: none;
    `};

  ${({ width }) => {
    switch (typeof width) {
      case 'number':
        return css`
          width: ${rem(width)};
        `;
      case 'string':
        return css`
          width: ${width};
        `;
      default:
        return css`
          flex: 1;
        `;
    }
  }};
`;

export default Cell;
