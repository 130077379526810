import React from 'react';

import { DetailMetadata } from '..';

import { HighlightDataType as HighlightData, SearchResultRow } from 'screens/Search/types';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface EmailDetailsProps {
  canReport?: boolean;
  isBenign: boolean;
  loading?: boolean;
  mailviewHighlightData?: HighlightData;
  searchInfo?: SearchResultRow;
}

export function EmailDetails({
  canReport = true,
  isBenign,
  loading = false,
  mailviewHighlightData,
  searchInfo,
}: EmailDetailsProps) {
  return (
    <DetailMetadata
      canReport={canReport}
      isBenign={isBenign}
      loading={!!loading}
      mailviewData={mailviewHighlightData}
      searchInfo={searchInfo}
    />
  );
}
