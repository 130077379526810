// @flow

import { localPoint } from '@vx/event';
import { Group } from '@vx/group';
import { bisector, max } from 'd3-array';
import hash from 'hash-sum';
import { rem } from 'polished';
import React, { PureComponent } from 'react';
import { Translation } from 'react-i18next';
import { Spring, config } from 'react-spring/renderprops';
import ResizeObserver from 'resize-observer-polyfill';

import Container from './Container';
import CurrentLine from './CurrentLine';
import PreviousLine from './PreviousLine';
import Rows from './Rows';
import Tooltip from './Tooltip';
import TooltipContent from './Tooltip/Content';
import TooltipHeader from './Tooltip/Header';
import TooltipOverlay from './Tooltip/Overlay';
import { findPathYAtX, HEIGHT, PADDING, X_OFFSET, Y_OFFSET, type Data, type LineProps, type Tint } from './util';
import XAxis from './XAxis';
import YAxis from './YAxis';

import theme from 'config/theme';
import { getTextWidth } from 'utils/util';

const bisectDate = bisector((d) => new Date(d.date)).left;

type Props = {
  closed?: boolean,
  currentLineProps?: LineProps,
  data: Data[],
  dataTestId?: string,
  daysBack?: number,
  isComparing?: boolean,
  placeholder?: boolean,
  placeholderText?: string,
  previousData?: Data[],
  previousLineProps?: LineProps,
  showTime?: boolean,
  tint?: Tint,
  titleMap?: string[],
};

type State = {
  tooltipLeft: number,
  tooltipData: Data[],
  tooltipOpen: boolean,
  tooltipTop: number,
  vertLineLeft: number,
  width: number,
};

class LineGraph extends PureComponent<Props, State> {
  container: ?HTMLDivElement = null;

  svg: any = null;

  resizeObserver = null;

  tooltip: ?HTMLDivElement = null;

  state = {
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: [],
    vertLineLeft: 0,
    width: 100,
  };

  lineTints: any = {};

  pathRefs: { [index: number]: Element | null } = {};

  componentDidMount() {
    if (!this.container) return;

    this.setState({ width: this.container.offsetWidth });

    this.resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      entries.forEach((entry) => this.setState({ width: entry.contentRect.width }));
    });

    // $FlowFixMe - null check is on line 75
    this.resizeObserver.observe(this.container);
  }

  componentWillUnmount() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  assignRef = (ref: Element | null) => {
    this.container = ((ref: any): HTMLDivElement);
  };

  assignSvgRef = (ref: Element | null) => {
    this.svg = ((ref: any): HTMLElement);
  };

  assignTooltipRef = (ref: Element | null) => {
    this.tooltip = ((ref: any): HTMLDivElement);
  };

  setPathRef = (ref: Element | null) => {
    if (!ref) {
      this.pathRefs = {};
      return;
    }

    this.pathRefs[Number.parseInt(ref.getAttribute('data-index') || '0', 10)] = ((ref: any): HTMLElement);
  };

  mouseLeave = () => {
    this.closeTooltip();
  };

  mouseMove = (event: SyntheticEvent<any>, tooltipData: any) => {
    const { x, y } = localPoint(this.svg, event);
    this.showTooltipAt(x, y, tooltipData);
  };

  showTooltipAt = (x: number, y: number, { series, xMax, xScale }: any) => {
    const positionX = x - X_OFFSET;
    const positionY = y - (Y_OFFSET - 18);

    if (positionX < 0 || positionY < 0) {
      this.closeTooltip();
      return;
    }

    const tooltipWidth = this.tooltip ? this.tooltip.getBoundingClientRect().width : 0;

    const dataPoints = series
      .filter((data) => data.length > 0)
      .map((d) => {
        const xDomain = xScale.invert(x - X_OFFSET);

        const index = bisectDate(d, xDomain, 1);

        const dLeft = d[index - 1];
        const dRight = d[index];

        const isRightCloser = dRight && xDomain - new Date(dLeft.date) > new Date(dRight.date) - xDomain;

        return isRightCloser ? dRight : dLeft;
      });

    const xOffset = 75;
    const yOffset = 75;

    const positionXWithOffset = positionX + xOffset;
    const pastRightSide = positionXWithOffset + tooltipWidth > xMax;
    const tooltipLeft = pastRightSide ? positionX - tooltipWidth - xOffset : positionXWithOffset + xOffset;

    const tooltipTop = positionY - yOffset;

    const vertLineLeft = dataPoints[0] ? xScale(new Date(dataPoints[0].date)) : 0;

    this.setState({
      tooltipOpen: true,
      tooltipData: dataPoints,
      tooltipLeft,
      tooltipTop,
      vertLineLeft,
    });
  };

  closeTooltip = () => {
    this.setState({
      tooltipOpen: false,
    });
  };

  getPathYFromX = (index: number, x: number) => {
    const path = this.pathRefs[index];
    return findPathYAtX(x, path, index);
  };

  addLineTint = (index: number, tint: string) => {
    this.lineTints[index] = tint;
  };

  render() {
    const { dataTestId = 'organism-line-graph', titleMap: propsTitleMap } = this.props;
    const titleMap = propsTitleMap || ['current', 'previous'];
    const {
      closed = false,
      currentLineProps,
      daysBack = 6,
      isComparing = true,
      placeholder = false,
      placeholderText = 'sampleData',
      previousLineProps,
      showTime = false,
      tint = 'dark',
    } = this.props;
    let { data, previousData = [] } = this.props;
    data = data.sort((a, b) => a.date - b.date);

    // In order for the tooltip to work properly, we need for previousData to have the same xScale (dates, essentially)
    // But we only want to muck with previousData if it's not an empty array
    if (previousData.length > 0) {
      previousData = data
        .map((row, i) => ({ date: row.date, value: previousData[i] ? previousData[i].value : 0 }))
        .sort((a, b) => a.date - b.date);
    }

    const { tooltipOpen, tooltipLeft, tooltipData, tooltipTop, vertLineLeft, width } = this.state;
    const allData = [...data, ...previousData];
    const offsetLeft = getTextWidth(String(max(allData, (d) => d.value)), `bold ${rem(10)} ${theme.fonts.roboto}`);

    return (
      <Translation ns="components">
        {(t) => (
          <Container
            data-testid={dataTestId}
            ref={this.assignRef}
            placeholder={placeholder ? 'true' : undefined}
            placeholderText={t(placeholderText)}
          >
            <>
              <svg height={HEIGHT + PADDING} ref={this.assignSvgRef} width={width}>
                <Group left={offsetLeft} top={10}>
                  <YAxis data={data} previousData={previousData} />
                  <Rows data={data} previousData={previousData} width={width} />
                </Group>

                <Group left={X_OFFSET}>
                  <Group left={offsetLeft} top={28}>
                    {previousData.length > 0 && (
                      <PreviousLine
                        data={data}
                        dataIndex={1}
                        ref={this.setPathRef}
                        previousData={previousData}
                        width={width - offsetLeft - 4}
                        {...previousLineProps}
                      >
                        {({ lineTint }) => this.addLineTint(1, lineTint)}
                      </PreviousLine>
                    )}
                    <CurrentLine
                      closed={closed}
                      dataIndex={0}
                      data={data}
                      ref={this.setPathRef}
                      previousData={previousData}
                      tint={tint}
                      width={width - offsetLeft - 4}
                      {...currentLineProps}
                    >
                      {({ series, lineTint, xMax, xScale, yMax }) => {
                        this.addLineTint(0, lineTint);
                        return (
                          <TooltipOverlay
                            getPathYFromX={this.getPathYFromX}
                            lineTints={this.lineTints}
                            onMouseLeave={this.mouseLeave}
                            onMouseMove={this.mouseMove}
                            series={series}
                            tooltipData={tooltipData}
                            tooltipOpen={tooltipOpen}
                            vertLineLeft={vertLineLeft}
                            xMax={xMax}
                            xScale={xScale}
                            yMax={yMax}
                          />
                        );
                      }}
                    </CurrentLine>
                  </Group>
                  <Group top={20}>
                    <XAxis data={allData} width={width} />
                  </Group>
                </Group>
              </svg>
              <Spring
                config={config.stiff}
                from={{ left: tooltipLeft || 0, opacity: 0, top: tooltipTop || 0 }}
                to={{ left: tooltipLeft || 0, opacity: tooltipOpen ? 0.9 : 0, top: tooltipTop || 0 }}
              >
                {({ left, opacity, top }) => (
                  <Tooltip
                    ref={this.assignTooltipRef}
                    style={{
                      left,
                      top,
                      opacity,
                      zIndex: 1,
                    }}
                  >
                    {tooltipData && tooltipData.length && (
                      <>
                        <TooltipHeader
                          isComparing={isComparing}
                          daysBack={daysBack}
                          showTime={showTime}
                          startDate={new Date(tooltipData[0].date)}
                        />
                        {tooltipData.map((d, i) => (
                          <TooltipContent
                            color={this.lineTints[i]}
                            dashedLine={i > 0}
                            key={hash(`${titleMap[i]}-${i}`)}
                            title={t(titleMap[i])}
                          >
                            {t('Statistic.number', { value: d.value })}
                          </TooltipContent>
                        ))}
                      </>
                    )}
                  </Tooltip>
                )}
              </Spring>
            </>
          </Container>
        )}
      </Translation>
    );
  }
}

export default LineGraph;
