// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { type ContextRouter, Redirect, Route, Switch } from 'react-router-dom';

import {
  defaultDetections,
  defaultDetectionsTimeline,
  fetchInsightsDetectionStatsQuery,
  allowListRecord,
  blockListRecord,
  fetchInsightsAllowList,
  fetchInsightsBlockList,
} from './dataTypesAndUtils';

import AllowList from './screens/AllowList';
import Attachments from './screens/Attachments';
import BlockList from './screens/BlockList';
import MaliciousEmailLinks from './screens/MaliciousEmailLinks';
import MaliciousEmailNewDomains from './screens/MaliciousEmailNewDomains';
import MaliciousEmailTargets from './screens/MaliciousEmailTargets';
import MaliciousGeoOrigins from './screens/MaliciousGeoOrigins';
import MaliciousThreatTypes from './screens/MaliciousThreatTypes';
import NameSpoof from './screens/NameSpoof';
import SenderMismatch from './screens/SenderMismatch';
import SimilarDomains from './screens/SimilarDomains';
import SuspiciousEmailLinks from './screens/SuspiciousEmailLinks';
import SuspiciousEmailNewDomains from './screens/SuspiciousEmailNewDomains';
import SuspiciousEmailTargets from './screens/SuspiciousEmailTargets';
import SuspiciousThreatTypes from './screens/SuspiciousThreatTypes';
import Sidebar from './Sidebar';

import PageNotFound from 'ui/organisms/PageNotFound';
import SettingsTemplate from 'ui/templates/Settings';

// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';

const Detections = ({ match }: ContextRouter) => {
  const { t } = useTranslation(['common', 'components']);
  const duration = useDuration();

  const {
    data: detectionStatsData,
    error,
    loading,
  } = useQuery(fetchInsightsDetectionStatsQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: setInsightsDateRangeFromDuration(duration),
  });

  const totalDetections = get(detectionStatsData, 'insightsDetectionStats.data', [
    { totalEmailsProcessedPrevious: 0 },
    { totalEmailsProcessed: 0 },
    { breakdown: { current: defaultDetections, previous: defaultDetections } },
    { timeline: { current: [defaultDetectionsTimeline], previous: [defaultDetectionsTimeline] } },
  ]);

  const breakdown = (totalDetections && totalDetections.find((d) => 'breakdown' in d)?.breakdown) || {
    current: defaultDetections,
    previous: defaultDetections,
  };

  const totalEmailsProcessedData = totalDetections.find((el) => 'totalEmailsProcessed' in el)?.totalEmailsProcessed;

  const {
    data: allowData,
    error: allowError,
    loading: allowLoading,
  } = useQuery(fetchInsightsAllowList, { variables: setInsightsDateRangeFromDuration(duration) });

  const allowListData = get(allowData, 'allowList.data', allowListRecord);

  const {
    data: blockData,
    error: blockError,
    loading: blockLoading,
  } = useQuery(fetchInsightsBlockList, { variables: setInsightsDateRangeFromDuration(duration) });

  const blockListData = get(blockData, 'blockList.data', blockListRecord);

  const currentTotals =
    get(allowData, 'allowList.data.totals.current', 0) + get(blockData, 'lockList.data.totals.current', 0);

  const previousTotals =
    get(allowData, 'allowList.data.totals.previous', 0) + get(blockData, 'lockList.data.totals.previous', 0);

  return (
    <>
      <Helmet title={`${t('detections')} :: ${t('email')} - ${t('const:names:area1security')}`} />
      <SettingsTemplate sidebar={<Sidebar breakdown={breakdown} current={currentTotals} previous={previousTotals} />}>
        <Switch>
          <Redirect exact from={`${match.path}`} to={`${match.path}/malicious-email`} />

          <Redirect exact from={`${match.path}/malicious-email`} to={`${match.path}/malicious-email/attachments`} />
          <Route
            component={() => (
              <Attachments
                detectionCount={totalEmailsProcessedData}
                detectionError={error}
                detectionLoading={loading}
              />
            )}
            exact
            path={`${match.path}/malicious-email/attachments`}
          />
          <Route component={MaliciousGeoOrigins} exact path={`${match.path}/malicious-email/origins`} />
          <Route component={MaliciousEmailTargets} exact path={`${match.path}/malicious-email/targets`} />
          <Route
            component={() => (
              <MaliciousEmailNewDomains
                detectionCount={totalEmailsProcessedData}
                detectionError={error}
                detectionLoading={loading}
              />
            )}
            exact
            path={`${match.path}/malicious-email/new-domains`}
          />
          <Route
            component={() => (
              <MaliciousEmailLinks
                detectionCount={totalEmailsProcessedData}
                detectionError={error}
                detectionLoading={loading}
              />
            )}
            exact
            path={`${match.path}/malicious-email/links`}
          />
          <Route component={MaliciousThreatTypes} exact path={`${match.path}/malicious-email/threat-types`} />

          <Redirect exact from={`${match.path}/suspicious-email`} to={`${match.path}/suspicious-email/targets`} />
          <Route component={SuspiciousEmailTargets} exact path={`${match.path}/suspicious-email/targets`} />
          <Route
            component={() => (
              <SuspiciousEmailNewDomains
                detectionCount={totalEmailsProcessedData}
                detectionError={error}
                detectionLoading={loading}
              />
            )}
            exact
            path={`${match.path}/suspicious-email/new-domains`}
          />
          <Route
            component={() => (
              <SuspiciousEmailLinks
                detectionCount={totalEmailsProcessedData}
                detectionError={error}
                detectionLoading={loading}
              />
            )}
            exact
            path={`${match.path}/suspicious-email/links`}
          />
          <Route component={SuspiciousThreatTypes} exact path={`${match.path}/suspicious-email/threat-types`} />

          <Redirect exact from={`${match.path}/spoofs`} to={`${match.path}/spoofs/sender-mismatches`} />
          <Route
            component={() => (
              <SenderMismatch
                detectionCount={totalEmailsProcessedData}
                detectionError={error}
                detectionLoading={loading}
              />
            )}
            exact
            path={`${match.path}/spoofs/sender-mismatches`}
          />
          <Route
            component={() => (
              <NameSpoof detectionCount={totalEmailsProcessedData} detectionError={error} detectionLoading={loading} />
            )}
            exact
            path={`${match.path}/spoofs/name-spoof`}
          />
          <Route component={SimilarDomains} exact path={`${match.path}/spoofs/similar-domains`} />

          <Redirect exact from={`${match.path}/policy-detection`} to={`${match.path}/policy-detection/allow-list`} />
          <Route
            component={() => <AllowList allowListData={allowListData} error={allowError} loading={allowLoading} />}
            exact
            path={`${match.path}/policy-detection/allow-list`}
          />
          <Route
            component={() => <BlockList blockListData={blockListData} error={blockError} loading={blockLoading} />}
            exact
            path={`${match.path}/policy-detection/block-list`}
          />
          <Route component={PageNotFound} path="*" status={404} />
        </Switch>
      </SettingsTemplate>
    </>
  );
};

export default Detections;
