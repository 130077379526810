// @flow

import { Record, type RecordFactory } from 'immutable';

import getPermissionLevel from 'utils/permissions';

const ENTERPRISE_ACCOUNT_TYPES = ['Enterprise', 'Internal', 'Partner', 'POV'];

const isParent = (data) => data.is_parent || data.account_type === 'Parent';

/*
  This is coming back from the API
*/
export type FetchUserResponse = {
  account_type: string,
  api_enabled: boolean,
  bettermail_enabled: boolean,
  bettermail_only: boolean,
  block_subdomains: boolean,
  bot_code: string,
  brs_enabled: boolean,
  created_at: number,
  customer_created_at: number,
  customer_id: string,
  customer_name: string,
  customer_permissions: Array<string | mixed>,
  directories_enabled: boolean,
  dns_rpz_enabled: boolean,
  email: string,
  first_name: string,
  is_parent: boolean,
  is_phishguard_enabled: boolean,
  last_modified: number,
  last_name: string,
  lockout_ends: number,
  malicious_bec_enabled: boolean,
  mailsearch_enabled: boolean,
  mailstream_enabled: boolean,
  mailtrace_enabled: boolean,
  password_last_modified: number,
  parent_user_id: number,
  recursive_dns_enabled: boolean,
  role: string,
  search_enabled: boolean,
  show_eula: boolean,
  show_onboarding: boolean,
  user_id: number,
  user_permissions: string,
};

/*
  This is what the data will look like after we munge it
*/
export type UserType = {
  apiEnabled: boolean,
  bettermailEnabled: boolean,
  bettermailOnly: boolean,
  blockSubdomains: boolean,
  botCode: string,
  brsEnabled: boolean,
  childPermission: Array<string> | string,
  createdAt: number,
  customerCreatedAt: number,
  customerId: string,
  customerName: string,
  delegatedAccountsEnabled: boolean,
  delegatedAccountsReadOnly: boolean,
  delegatedRolesEnabled: boolean,
  demoEnabled: boolean,
  directoriesEnabled: boolean,
  email: string,
  enterpriseEnabled: boolean,
  firstName: string,
  lastModified: number,
  lastName: string,
  lockoutEnds: number,
  maliciousBecEnabled: boolean,
  mailsearchEnabled: boolean,
  mailstreamEnabled: boolean,
  mailtraceEnabled: boolean,
  parentPermission: Array<string> | string,
  parentUserId: number,
  passwordLastModified: number,
  phishguardEnabled: boolean,
  role: string,
  searchEnabled: boolean,
  settingsReadOnly: boolean,
  showEula: boolean,
  showOnboarding: boolean,
  userId: number,
};

const defaultRecord: UserType = {
  apiEnabled: false,
  bettermailEnabled: false,
  bettermailOnly: false,
  blockSubdomains: false,
  botCode: '',
  brsEnabled: false,
  childPermission: [],
  createdAt: new Date(),
  customerCreatedAt: new Date(),
  customerId: '',
  customerName: '',
  delegatedAccountsEnabled: false,
  delegatedAccountsReadOnly: false,
  delegatedRolesEnabled: false,
  demoEnabled: false,
  directoriesEnabled: false,
  email: '',
  enterpriseEnabled: false,
  firstName: '',
  inviteSent: false,
  isAuthenticated: false,
  lastModified: new Date(),
  lastName: '',
  lockoutEnds: 0,
  maliciousBecEnabled: false,
  mailsearchEnabled: false,
  mailstreamEnabled: false,
  mailtraceEnabled: false,
  parentPermission: [],
  parentUserId: 0,
  passwordLastModified: new Date(),
  phishguardEnabled: false,
  requestComplete: false,
  role: 'read only',
  searchEnabled: false,
  settingsReadOnly: false,
  showEula: false,
  showOnboarding: false,
  userId: 0,
};

export const UserRecord: RecordFactory<UserType> = Record(defaultRecord);

export function transformData(data: FetchUserResponse): UserType {
  return {
    apiEnabled: data.api_enabled,
    bettermailEnabled: data.bettermail_enabled,
    bettermailOnly: data.bettermail_only,
    blockSubdomains: data.block_subdomains,
    botCode: data.bot_code,
    brsEnabled: data.brs_enabled,
    createdAt: new Date(data.created_at),
    customerCreatedAt: new Date(data.customer_created_at),
    customerId: data.customer_id,
    customerName: data.customer_name,
    delegatedAccountsEnabled:
      (data.user_permissions.includes('child read only') || data.user_permissions.includes('child admin')) &&
      isParent(data),
    delegatedAccountsReadOnly: data.user_permissions.includes('child read only') && data.is_parent,
    delegatedRolesEnabled: isParent(data),
    demoEnabled: data.account_type.toLowerCase() === 'demo',
    directoriesEnabled: data.directories_enabled,
    email: data.email,
    enterpriseEnabled: ENTERPRISE_ACCOUNT_TYPES.includes(data.account_type),
    firstName: data.first_name,
    lastModified: data.last_modified,
    lastName: data.last_name,
    lockoutEnds: data.lockout_ends,
    maliciousBecEnabled: data.malicious_bec_enabled,
    mailsearchEnabled: data.mailsearch_enabled,
    mailstreamEnabled: data.mailstream_enabled,
    mailtraceEnabled: data.mailtrace_enabled,
    parentUserId: data.parent_user_id,
    passwordLastModified: new Date(data.password_last_modified),
    phishguardEnabled: data.is_phishguard_enabled,
    role: data.role,
    searchEnabled: data.search_enabled,
    settingsReadOnly: !!(data.parent_user_id && data.role === 'read only'),
    showEula: data.show_eula,
    showOnboarding: data.show_onboarding,
    userId: data.user_id,
    childPermission: getPermissionLevel(data.user_permissions, 'child'),
    parentPermission: getPermissionLevel(data.customer_permissions, 'parent'),
  };
}
