import { styled } from '@a1s/ui';
import React, { ComponentProps, ReactElement } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface MainProps extends Omit<ComponentProps<typeof StyledContainer>, 'children'> {
  children: [ReactElement<ComponentProps<typeof StyledHeader>>, ReactElement<ComponentProps<typeof StyledBody>>];
}

/**
 * Displays the "main" part of a search section. Had sub-components for rendering the header body sections.
 *
 *     <Main>
 *       <Main.Header> header </Main.Header>
 *       <Main.Body> content </Main.Body>
 *     </Main>
 */
export function Main({ children, ...props }: MainProps) {
  return <StyledContainer {...props}>{children}</StyledContainer>;
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledBody = styled('div', {
  backgroundColor: '$white',
  flexGrow: 1,
  maxHeight: '100%',
  overflow: 'hidden',
  p: '$2',
  r: '$2',
});

const StyledContainer = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  height: '100%',
  px: '$9',
  py: '$6',
});

const StyledHeader = styled('header', {
  maxHeight: 'min-content',
});

//
// Dot-notation subcomponents
// -------------------------------------------------------------------------------------------------

Main.Body = StyledBody;
Main.Header = StyledHeader;
