import { Stack, Table, Text } from '@a1s/ui';
import { endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import React, { MouseEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { APIData } from './SubmissionTable';

import { matchColorAndDisposition } from 'common';
import { useSearchContext } from 'screens/Search/lib/searchContext';
import { RetractButton } from 'screens/Search/ui';
import { Badge, InlineTable, Menu } from 'ui-new';
import { Duration } from 'utils/duration';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

export interface SubmissionRowProps {
  data: APIData;
  duration: Duration;
  // eslint-disable-next-line no-unused-vars
  onViewEmail(id: string): void;
}

export function SubmissionRow({ data, duration, onViewEmail }: SubmissionRowProps) {
  const { t } = useTranslation('submission');
  const searchDetections = useGlobalSearch('detectionSearch');
  const { retractEnabled } = useSearchContext();
  const retractButtonRef = useRef<HTMLButtonElement>(null);
  const {
    originalDisposition,
    originalTs = '',
    outcomeDisposition,
    requestedBy,
    requestedDisposition,
    requestedTs = '',
    type,
  } = data;

  const handleRetractClick: MouseEventHandler = (e) => {
    e.preventDefault();
    // The reason behind this kind of hacky implementation is that <RetractButton /> is a component that returns a button, but on this screen
    // we're displaying "Retract Item" as text in a meatball menu, and a button doesn't look good in a menu.
    // If we find ourselves in more situations where we're displaying the Retract Item option in a menu, we can extend the <RetractButton /> component
    // to render as text (or a menu item).
    retractButtonRef.current?.click();
  };

  return (
    <Table.Row>
      <Table.Cell>
        <Stack>
          <Text as="p" color="$gray500" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {format(parseISO(requestedTs), t('const:formats.dateLongWithTimezone'))}
          </Text>
          <InlineTable css={{ whiteSpace: 'nowrap' }} gap="$1">
            <InlineTable.DataRow label={t('id')} value={data?.submissionId} />
            <InlineTable.DataRow label={t('type')} value={t(data?.type || 'unknownMalwareType')} />
            <InlineTable.DataRow label={t('by')} nowrap={false} value={t(requestedBy)} />
            <InlineTable.DataRow
              label={null}
              nowrap={false}
              value={
                <Text color="$gray500" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
                  [{t(type)}]
                </Text>
              }
            />
          </InlineTable>
        </Stack>
      </Table.Cell>
      <Table.Cell>
        {!!originalDisposition && (
          <Badge color={matchColorAndDisposition(originalDisposition)}>{t(originalDisposition)}</Badge>
        )}
      </Table.Cell>
      <Table.Cell>
        <Badge color={matchColorAndDisposition(requestedDisposition)}>{t(requestedDisposition)}</Badge>
      </Table.Cell>
      <Table.Cell>
        <Badge color={matchColorAndDisposition(outcomeDisposition)}>{t(outcomeDisposition)}</Badge>
      </Table.Cell>
      <Table.Cell>
        <Stack>
          <Text as="p" color="$gray500" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {!!originalTs && <>{format(parseISO(originalTs), t('const:formats.dateLongWithTimezone'))}</>}
          </Text>
          <InlineTable gap="$1">
            <InlineTable.DataRow label={t('from')} value={data?.originalSender} />
            <InlineTable.DataRow label={t('to')} value={data?.recipient} />
            <InlineTable.DataRow label={t('subj')} nowrap={false} value={data?.subject} />
          </InlineTable>
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Menu>
          {data?.submissionId && (
            <Menu.Item onMouseDown={() => onViewEmail(data.submissionId)}>{t('viewMessage')}</Menu.Item>
          )}
          {data?.edfHash && (
            <Menu.Item
              onMouseDown={() => {
                const range = [startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), endOfDay(new Date())];
                searchDetections({
                  finalDisposition: '',
                  dateRange: [range[0], range[1]],
                  query: data?.edfHash,
                });
              }}
            >
              {t('findSimilarMessages')}
            </Menu.Item>
          )}
          {retractEnabled && data?.recipient && (
            <Menu.Item onMouseDown={handleRetractClick}>{t('retractMessage')}</Menu.Item>
          )}
        </Menu>
        {/* // The reason behind this kind of hacky implementation is that <RetractButton /> is a component that returns a button, but on this screen
        we're displaying "Retract Item" as text in a meatball menu, and a button doesn't look good in a menu.
        If we find ourselves in more situations where we're displaying the Retract Item option in a menu, we can extend the <RetractButton /> component
        to render as text (or a menu item). */}
        {retractEnabled && !!data?.recipient && data?.originalMessageId && (
          <div style={{ visibility: 'hidden' }}>
            <RetractButton
              clawbackFeatureEnabled
              ref={retractButtonRef}
              retractParams={[{ clientRecipients: [data?.recipient], messageId: data?.originalMessageId }]}
            />
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
