// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import { type AllowListCountsByDate } from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import PlaceholderStackedBarGraph from 'ui/organisms/PlaceholderStackedBarGraph';
import SectionOverview from 'ui/organisms/SectionOverview';
import StackedBarGraph from 'ui/organisms/StackedBarGraph';
import withDataHandler from 'utils/withDataHandler';

type Data = {
  stackedBarGraph: AllowListCountsByDate[],
  sectionOverview: {
    current: number,
    previous: number,
  },
};

type Props = {
  data: Data,
  error: boolean,
  loading: boolean,
};

const StackedBarGraphWithDataHandler = withDataHandler({
  empty: <PlaceholderStackedBarGraph />,
})(StackedBarGraph);

export default function AllowedPatterns({ data, error, loading }: Props) {
  const { stackedBarGraph, sectionOverview } = data;
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader title={t('allowedPatterns')} />
          <SectionOverview
            formatValue="number"
            currentValue={sectionOverview.current}
            dimmed={loading}
            previousValue={sectionOverview.previous}
          />
          <Content>
            <PaddedContent pushTop="xl">
              <StackedBarGraphWithDataHandler
                colorScheme="graphite"
                data={stackedBarGraph}
                isEmpty={() => loading || error || stackedBarGraph.length === 0}
                isLoading={loading}
              />
            </PaddedContent>
          </Content>
        </>
      }
    />
  );
}
