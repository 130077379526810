import { styled } from '@a1s/ui';

export const ConfigRuleLink = styled('a', {
  color: '$blue400',
  cursor: 'pointer',
  textDecoration: 'underline',

  [`&:hover `]: {
    color: '$black',
  },
});

export const Container = styled('div', {
  color: 'inherit',
  cursor: 'copy',
  display: 'inherit',
  fontSize: 'small',
  marginLeft: '0.2em',
  position: 'relative',
  verticalAlign: 'textTop',
});

export const CopyButton = styled('button', {
  background: 'none',
  borderWidth: 0,
  color: 'inherit',
  display: 'inline-block',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  marginLeft: '0.2em',
  textAlign: 'inherit',
  wordBreak: 'break-all',
  wordWrap: 'break-word',
});

export const StyledLink = styled('a', {
  color: 'red',
  cursor: 'pointer',
  textDecoration: 'none',

  [`&:hover span`]: {
    color: '$black',
  },
});

export const StyledList = styled('ul', {
  maxHeight: '100%',
  overflowX: 'auto',
  overflowY: 'auto',
  paddingLeft: '1.15em',
  userSelect: 'none',
});

export const StyledSpan = styled('span', {
  variants: {
    color: {
      green: {
        color: '$green500',
      },
      red: {
        color: '$red500',
      },
    },
  },
});

export const ThreatTypeLink = styled('a', {
  cursor: 'pointer',
  textDecoration: 'none',

  [`&:hover  span`]: {
    color: '$black',
  },
});
