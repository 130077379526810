// @flow

import React, { type Node } from 'react';

import Container from './Container';
import Grid from './Grid';
import Section from './Section';

type Props = { dataTestId?: string, left?: Node, right?: Node };

const SectionHeader = ({ dataTestId = 'molecule-section-header', left, right }: Props) => (
  <Container data-testid={dataTestId}>
    <Grid>
      {left && <Section side="left">{left}</Section>}
      {right && <Section side="right">{right}</Section>}
    </Grid>
  </Container>
);

export default SectionHeader;
