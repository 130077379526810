// @flow

import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import Icon from './Icon';

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mystic};
  border-radius: 50%;
  display: flex;
  height: ${rem(48)};
  justify-content: center;
  margin-right: ${({ theme }) => rem(theme.spacing.sm)};
  width: ${rem(48)};

  ${({ size }) => {
    if (size === 'medium')
      return css`
        height: ${rem(32)};
        width: ${rem(32)};
      `;

    if (size === 'small')
      return css`
        height: ${rem(24)};
        margin-right: 0;
        width: ${rem(24)};
      `;

    if (size === 'tiny')
      return css`
        height: ${rem(24)};
        margin-right: 0;
        width: ${rem(24)};
      `;

    return null;
  }};
`;

type Props = { negative?: boolean, size: 'tiny' | 'small' | 'medium' | 'large' };

const Direction = ({ negative = false, size = 'default' }: Props) => (
  <Container size={size}>
    <Icon negative={negative} size={size} />
  </Container>
);

export default Direction;
