// @flow

import React, { type ComponentType } from 'react';
import { Redirect, Route, type RouteProps } from 'react-router-dom';

type Props<T> = { authenticated?: boolean, component: ComponentType<T>, redirectTo?: string };

const HOME = '/';
const LOGOUT = '/users/login';

/**
 * Protect route. If authenicated it will be render the the given component. If not, it will redirect.
 */
export function AuthRoute<T>({ authenticated = false, component: Component, redirectTo = LOGOUT, ...rest }: Props<T>) {
  return <Route {...rest} render={renderer(Component, authenticated, redirectTo)} />;
}

/**
 * Opposite of `AuthRouter` It will on render the given component if **not** authenticated.
 */
export function UnauthRoute<T>({ authenticated = false, component: Component, redirectTo = HOME, ...rest }: Props<T>) {
  return <Route {...rest} render={renderer(Component, !authenticated, redirectTo)} />;
}

//
// Private Functions
// -------------------------------------------------------------------------------------------------

/**
 * Returns a component or a redirect depending on whether authenticated or not.
 */
function renderer<T>(Component: ComponentType<T>, authenticated: boolean, redirectTo: string) {
  return (props: RouteProps) => {
    const { location } = props;
    const to = { pathname: redirectTo, state: { from: location } };
    return authenticated ? <Component {...props} /> : <Redirect to={to} />;
  };
}
