import { map, sum } from 'lodash';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from '../../molecules/DataTable';
import PercentChange from '../../molecules/PercentChange';
import PlaceholderLineGraph from '../../molecules/PlaceholderLineGraph';
import TabView from '../../molecules/TabView';
import Stack from '../../templates/Stack';
import LineGraph from '../LineGraph';
import SectionOverview from '../SectionOverview';
import SectionSelect, { SectionOption } from '../SectionSelect';

import type { Tint } from 'ui/organisms/LineGraph/util';

import { calculatePercentDifference } from 'utils/util';

//
// Styled components
// -------------------------------------------------------------------------------------------------

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Data {
  date: number;
  value: number;
}

interface Props {
  data: { [string]: { current: Array<Data>, previous: Array<Data> } };
  dataTestId?: string;
  renderGroupName?: (string) => Node;
}

export default function DataView({ dataTestId = 'organisms-data-view', ...props }: Props) {
  const { t } = useTranslation('components');

  function renderTab(index) {
    return index === 0 ? <Graph {...props} /> : <Table {...props} />;
  }

  return (
    <TabView
      dataTestId={dataTestId}
      renderTab={renderTab}
      tabs={[t('DataView.graph'), t('DataView.table')]}
      tabsLocation="top"
    />
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Graph({ data, renderGroupName, ...props }: Props) {
  const groups = Object.keys(data);

  return (
    <SectionSelect initialSelected={groups[0]}>
      {({ selected }, handleSelect) => {
        const tint = deriveTintFromGroupSelected(selected);

        return (
          <>
            <Stack spaced>
              {groups.map((group) => (
                <GraphSelector
                  data={data}
                  group={group}
                  key={group || 'defaultKey'}
                  onSelect={handleSelect}
                  selected={selected === group}
                  renderGroupName={renderGroupName}
                />
              ))}
            </Stack>
            <GraphDisplay data={data} group={selected} tint={tint} {...props} />
          </>
        );
      }}
    </SectionSelect>
  );
}

interface GraphDisplayProps extends Props {
  group: string;
  placeholderText?: string;
}

function GraphDisplay({ data, group, placeholderText, ...props }: GraphDisplayProps) {
  const { current, previous } = data[group];

  if (!current.reduce((acc, val) => acc + val.value, 0) && !previous.reduce((acc, val) => acc + val.value, 0))
    return <PlaceholderLineGraph placeholderText={placeholderText} />;

  return <LineGraph data={current} previousData={previous} {...props} />;
}

interface GraphSelectorProps extends Props {
  group: string;
  onSelect: Function;
  selected: boolean;
}

function GraphSelector({ data, group, onSelect, renderGroupName, selected }: GraphSelectorProps) {
  const currentValue = sum(map(data[group].current, 'value'));
  const previousValue = sum(map(data[group].previous, 'value'));

  const percent = calculatePercentDifference(currentValue, previousValue);

  if (typeof renderGroupName === 'undefined') return null;

  return (
    <SectionOption name={group} onSelect={onSelect} selected={selected}>
      <SectionOverview currentValue={currentValue} formatValue="number" size="small" title={renderGroupName(group)}>
        <PercentChange boxed>{Number.isNaN(percent) ? 0 : percent}</PercentChange>
      </SectionOverview>
    </SectionOption>
  );
}

function Table({ data, renderGroupName }: Props) {
  // Combine the previous and current data together to be displayed on the table as a list of dates.
  function reduce(acc, [group, { current, previous }]) {
    return {
      ...acc,
      [group]: [...current, ...previous].sort(sort),
    };
  }

  function sort(a, b) {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    return bDate - aDate;
  }

  const combinedData = Object.entries(data).reduce(reduce, {});

  return <DataTable data={combinedData} renderHeader={renderGroupName} />;
}

function deriveTintFromGroupSelected(selected: string): Tint {
  switch (selected) {
    case 'mailBulk':
      return 'bulk';
    case 'mailMalicious':
      return 'malicious';
    case 'mailBec':
      return 'malicious-bec';
    case 'mailSuspicious':
      return 'suspicious';
    case 'mailSpam':
      return 'spam';
    case 'mailSpoof':
      return 'spoof';
    default:
      return 'none';
  }
}
