import { Box, Button, Cluster, Stack, Text } from '@a1s/ui';
import React, { lazy, useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdatesDisplay } from '../../../ui';

import { Duration } from './useRemoteData';

import { ButtonLink, CloseButton, Dialog, DurationDropdown } from 'ui-new';
import { useDuration } from 'utils/duration';

const Details = lazy(() => import('./Details'));

export default function Bottom() {
  const globalDuration = useDuration();
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [duration, setDuration] = useState<Duration>(globalDuration);
  const { t } = useTranslation('dashboardHome');

  useEffect(() => {
    setDuration(globalDuration);
  }, [globalDuration]);

  function handleClose() {
    setDetailsVisible(false);
  }

  function handlePress() {
    setDetailsVisible(true);
  }

  function onDurationChange(newDuration: Duration) {
    setDuration(newDuration);
  }

  return (
    <>
      <Box pb px testId="detection-stats-bottom">
        <Cluster align="end" css={{ '& :first-child': { flexGrow: '1' } }} gap justify="space-between">
          <UpdatesDisplay duration="PT15M" />
          <ButtonLink to="/settings/email/policies/url-actions">{t('configure')}</ButtonLink>
          <Button onPress={handlePress}>{t('viewDetails')}</Button>
        </Cluster>
      </Box>

      {/* TODO: Ability to pass css to limit max-height and add scrollbars */}
      <Dialog maxWidth onClose={handleClose} visible={detailsVisible}>
        <Box p>
          <Stack gap="4">
            <Cluster gap justify="end">
              <Text
                color="$orange500"
                css={{ flexGrow: 1 }}
                font="sans"
                size="sm"
                stretch="ultraCondensed"
                transform="uppercase"
                weight="semibold"
              >
                {t('eliDetails')}
              </Text>
              <DurationDropdown onChange={onDurationChange} value={duration} />
              <CloseButton onPress={handleClose} />
            </Cluster>
            {detailsVisible ? (
              <Suspense fallback="Loading">
                <Details duration={duration} />
              </Suspense>
            ) : null}
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}
