"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "borderStyles", {
  enumerable: true,
  get: function () {
    return _borderStyles.borderStyles;
  }
});
Object.defineProperty(exports, "borderWidths", {
  enumerable: true,
  get: function () {
    return _borderWidths.borderWidths;
  }
});
Object.defineProperty(exports, "colors", {
  enumerable: true,
  get: function () {
    return _colors.colors;
  }
});
Object.defineProperty(exports, "fontSizes", {
  enumerable: true,
  get: function () {
    return _fontSizes.fontSizes;
  }
});
Object.defineProperty(exports, "fontWeights", {
  enumerable: true,
  get: function () {
    return _fontWeights.fontWeights;
  }
});
Object.defineProperty(exports, "fonts", {
  enumerable: true,
  get: function () {
    return _fonts.fonts;
  }
});
Object.defineProperty(exports, "letterSpacings", {
  enumerable: true,
  get: function () {
    return _letterSpacings.letterSpacings;
  }
});
Object.defineProperty(exports, "radii", {
  enumerable: true,
  get: function () {
    return _radii.radii;
  }
});
Object.defineProperty(exports, "shadows", {
  enumerable: true,
  get: function () {
    return _shadows.shadows;
  }
});
Object.defineProperty(exports, "sizes", {
  enumerable: true,
  get: function () {
    return _sizes.sizes;
  }
});
Object.defineProperty(exports, "space", {
  enumerable: true,
  get: function () {
    return _space.space;
  }
});
Object.defineProperty(exports, "transitions", {
  enumerable: true,
  get: function () {
    return _transitions.transitions;
  }
});
var _borderStyles = require("./borderStyles");
var _borderWidths = require("./borderWidths");
var _colors = require("./colors");
var _fonts = require("./fonts");
var _fontSizes = require("./fontSizes");
var _fontWeights = require("./fontWeights");
var _letterSpacings = require("./letterSpacings");
var _radii = require("./radii");
var _shadows = require("./shadows");
var _sizes = require("./sizes");
var _space = require("./space");
var _transitions = require("./transitions");