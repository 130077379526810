// @flow

type CurrentIntervalValue = 7 | 30 | 90;

export type EmailReportEnvelopeAttachmentFormats = {
  count: number,
  filetype: string,
};

export type EmailReportEnvelopeAttachmentTypes = {
  count: number,
  filetype: string,
};

type EmailReportEnvelopeAttachmentTypeToExtensions = {
  [string]: any,
};

export type EmailReportEnvelopeMismatches = {
  actuallyFrom: string,
  appearedFrom: string,
  count: number,
};

export type EmailReportTopRecipients = {
  numMalicious: number,
  email: string,
};

export type EmailReportTopSenders = {
  numMalicious: number,
  email: string,
};

type EmailReportType = {
  attachmentFormats: Array<EmailReportEnvelopeAttachmentFormats>,
  attachmentTypes: Array<EmailReportEnvelopeAttachmentTypes>,
  attachmentTypeToExtensions: EmailReportEnvelopeAttachmentTypeToExtensions,
  envelopeMismatches: Array<EmailReportEnvelopeMismatches>,
  topRecipients: Array<EmailReportTopRecipients>,
  topSenders: Array<EmailReportTopSenders>,
};

type MixType = string | number;

export const userActivityRecord: EmailReportType = {
  attachmentFormats: [],
  attachmentTypes: [],
  attachmentTypeToExtensions: {},
  envelopeMismatches: [],
  topRecipients: [],
  topSenders: [],
};

export const calculatePercent = (data: Array<Array<MixType>>, row: Array<MixType>) =>
  Number(row[2]) / data.reduce((acc, current) => Number(acc) + Number(current[2]), 0);

// eslint-disable-next-line no-unused-vars
export const calculatePercentForProgressTable = (data: Array<Array<MixType>>, [_, number]: Array<MixType>): number =>
  Number(number) / data.reduce((acc, current) => Number(acc) + Number(current[1]), 0);

export const countMismatchTotal = (data: Array<EmailReportEnvelopeMismatches>) =>
  data.reduce((acc, curr) => acc + curr.count, 0);

export const dataForProgressTable = (data: Array<EmailReportEnvelopeMismatches>): Array<Array<MixType>> =>
  data
    ? data
        .sort((a, b) => b.count - a.count)
        .slice(0, 15)
        .map((row) => [row.appearedFrom, row.actuallyFrom, row.count])
    : [];

export const dataForTopMessageProgressTable = (data: Array<EmailReportTopRecipients>): Array<Array<MixType>> =>
  data
    .sort((a, b) => b.numMalicious - a.numMalicious)
    .slice(0, 15)
    .map((row) => [row.email, row.numMalicious]);

export const determineReportInterval = (currentInterval: CurrentIntervalValue) => {
  switch (Number(currentInterval)) {
    case 7:
      return 'weekly';
    case 90:
      return 'quarterly';
    default:
      return 'monthly';
  }
};

export const dataForSankeyGraph = (
  attachmentFormats: Array<EmailReportEnvelopeAttachmentFormats>,
  attachmentTypes: Array<EmailReportEnvelopeAttachmentTypes>,
  attachmentTypeToExtensions: EmailReportEnvelopeAttachmentTypeToExtensions
) => {
  if (attachmentFormats) {
    const allFormats = attachmentFormats.map((row) => row.filetype);
    const allExtensions = attachmentTypes.map((row) => row.filetype);

    const nodes = [
      ...allFormats.map((format) => ({ id: format })),
      ...allExtensions.map((extension) => ({ id: extension })),
    ];

    const links = [];

    /* eslint-disable consistent-return */
    Object.keys(attachmentTypeToExtensions).forEach((format) => {
      if (!allFormats.includes(format)) return null;
      // eslint-disable-next-line
      const _extensions = attachmentTypeToExtensions[format];
      const extensions = typeof _extensions === 'string' ? [_extensions] : _extensions;
      extensions.forEach((extension) => {
        if (allExtensions.includes(extension)) {
          const valueRow: EmailReportEnvelopeAttachmentTypes = attachmentTypes.find(
            (row) => row.filetype === extension
          ) || { count: 0, filetype: '' };

          return links.push({
            source: format,
            target: extension,
            value: valueRow.count,
          });
        }
        return null;
      });
    });
    return {
      nodes,
      links,
    };
  }
  return null;
};
