// @flow

import { rem } from 'polished';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Checkbox from 'ui/atoms/Checkbox';
import Link from 'ui/atoms/Link';
import PaddedContent from 'ui/atoms/PaddedContent';
import Stack from 'ui/templates/Stack';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  a {
    font-size: ${rem(12)};
  }
  font-size: ${rem(12)};
  padding-top: ${({ theme }) => rem(theme.spacing.md)};
`;

interface Props {
  checked: boolean;
  setEulaAccepted: (eulaAccepted: boolean) => void;
}

export default function Eula({ checked = false, setEulaAccepted }: Props) {
  const handleCheckboxClick = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (setEulaAccepted) setEulaAccepted(e.target.checked);
  };

  return (
    <Container>
      <PaddedContent pushTop="sm">
        <Stack>
          <PaddedContent pushRight="sm">
            <Checkbox checked={!!checked} onClick={handleCheckboxClick} required />
          </PaddedContent>
          <div>
            <Trans i18nKey="useOfService" ns="loginPage">
              By checking the box to the left, I agree to Cloudflare&apos;s{' '}
              <Link external rel="nofollow" target="_blank" to="https://www.cloudflare.com/terms/">
                terms
              </Link>
              {', '}
              <Link external rel="nofollow" target="_blank" to="https://www.cloudflare.com/privacypolicy/">
                privacy policy
              </Link>
              {', '}
              and{' '}
              <Link external rel="nofollow" target="_blank" to="https://www.cloudflare.com/cookie-policy/">
                cookie policy
              </Link>
              .
            </Trans>
          </div>
        </Stack>
      </PaddedContent>
    </Container>
  );
}
