// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { ReactComponent } from './zoom-in.svg';

export default styled(ReactComponent)`
  height: ${rem(16)};
  margin-right: ${({ theme }) => rem(theme.spacing.sm)};
  width: ${rem(16)};

  ${({ focused, theme }) =>
    focused &&
    css`
      path {
        fill: ${theme.colors.independence};
        stroke: ${theme.colors.independence};
      }
    `};
`;
