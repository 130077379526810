// @flow

import axios from 'axios';

import { FETCH_USER_FAILED, FETCH_USER_REQUESTED, FETCH_USER_SUCCEEDED } from '../actionTypes/user';

import apiPaths from 'config/apiPaths';

export const fetchUser = () => async (dispatch: any) => {
  try {
    dispatch({ payload: { status: 'fetching' }, type: FETCH_USER_REQUESTED });

    const result = await axios.get(apiPaths.users);
    dispatch({ payload: result.data, type: FETCH_USER_SUCCEEDED });
  } catch (error) {
    dispatch({ error: true, payload: error.message, type: FETCH_USER_FAILED });
  }
};
