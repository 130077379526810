// @flow

// $FlowFixMe
import React, { forwardRef } from 'react';

import Container from './Container';
import Icon, { type IconName } from './Icon';
import Text from './Text';

type Props = {
  children?: string,
  clickableText?: ?'blue',
  danger?: boolean,
  dataTestId?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  icon?: IconName,
  muted?: boolean,
  noMargin?: boolean,
  onClick?: Function,
  small?: boolean,
  tiny?: boolean,
};

const Button = (
  {
    children,
    clickableText,
    danger = false,
    dataTestId = 'atom-button',
    disabled = false,
    icon,
    muted = false,
    noMargin = false,
    onClick,
    small = false,
    tiny = false,
    ...props
  }: Props,
  ref
) => (
  <Container
    clickableText={clickableText}
    data-testid={dataTestId}
    danger={danger}
    disabled={disabled}
    muted={muted}
    noMargin={noMargin}
    onClick={onClick}
    ref={ref}
    small={small}
    spaced={!!children}
    tiny={tiny}
    {...props}
  >
    {icon && <Icon {...props} muted={muted} name={icon} />}
    {children && <Text>{children}</Text>}
  </Container>
);

export default forwardRef(Button);
