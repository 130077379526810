// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from 'ui/atoms/Button';
import Link from 'ui/atoms/Link';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import PercentChange from 'ui/molecules/PercentChange';
import SectionOverview from 'ui/organisms/SectionOverview';
import Split from 'ui/templates/Split';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';
import { calculatePercentDifference } from 'utils/util';

const StyledSplit = styled(Split)`
  align-items: flex-start;
`;

export const insightsQuarantineStatsQuery = loader('../AdminQuarantine/queries/InsightsQuarantineStats.graphql');
export const insightsUrlActionsQuery = loader('./queries/InsightsUrlActions.graphql');

export default function MailConfiguration() {
  const duration = useDuration();
  const { t } = useTranslation('email');

  const {
    data: quarantineData,
    error: quarantineError,
    loading: quarantineLoading,
  } = useQuery(insightsQuarantineStatsQuery, { variables: setInsightsDateRangeFromDuration(duration) });
  const insightsQuarantineData = get(quarantineData, 'quarantineCount.data.totals.current', 0);
  const quarantinePrevious = get(quarantineData, 'quarantineCount.data.totals.previous', 0);

  const quarantinesPercentChange = calculatePercentDifference(insightsQuarantineData, quarantinePrevious);

  const { data, error, loading } = useQuery(insightsUrlActionsQuery, {
    variables: setInsightsDateRangeFromDuration(duration),
  });
  const defangCount = get(data, 'urlActions.data.breakdown.current.defang', 0);
  const previousDefangCount = get(data, 'urlActions.data.breakdown.previous.defang', 0);
  const urlRewriteCount = get(data, 'urlActions.data.breakdown.current.rewrite', 0);
  const previousUrlRewriteCount = get(data, 'urlActions.data.breakdown.previous.rewrite', 0);

  const emailUrlActions = defangCount + urlRewriteCount;

  const urlActionsTotalPercentChanged = calculatePercentDifference(
    defangCount + urlRewriteCount,
    previousDefangCount + previousUrlRewriteCount
  );

  const defangedUrlsPercentChanged = calculatePercentDifference(defangCount, previousDefangCount);

  const rewrittenUrlsPercentChanged = calculatePercentDifference(urlRewriteCount, previousUrlRewriteCount);

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader
            title={t('emailConfiguration')}
            action={
              <Link to="/settings/email/routing/domains">
                <Button>{t('emailSettings')}</Button>
              </Link>
            }
          />

          <StyledSplit>
            <SectionOverview
              currentValue={emailUrlActions}
              dataTestId="email-url-actions"
              dimmed={error || loading}
              formatValue="number"
              size="small"
              title={t('emailUrlActions')}
            >
              <PercentChange boxed>{urlActionsTotalPercentChanged}</PercentChange>
            </SectionOverview>

            <SectionOverview
              currentValue={urlRewriteCount}
              dataTestId="rewritten"
              dimmed={error || loading}
              formatValue="number"
              size="small"
              title={t('linksIsolated')}
            >
              <PercentChange boxed>{rewrittenUrlsPercentChanged}</PercentChange>
            </SectionOverview>

            <SectionOverview
              currentValue={defangCount}
              dataTestId="defanged"
              dimmed={error || loading}
              formatValue="number"
              size="small"
              title={t('defanged')}
            >
              <PercentChange boxed>{defangedUrlsPercentChanged}</PercentChange>
            </SectionOverview>

            <SectionOverview
              currentValue={insightsQuarantineData}
              dataTestId="quarantined"
              dimmed={quarantineError || quarantineLoading}
              formatValue="number"
              size="small"
              title={t('quarantined')}
            >
              <PercentChange boxed>{quarantinesPercentChange}</PercentChange>
            </SectionOverview>
          </StyledSplit>
        </>
      }
      dataTestId="email-configuration-card"
    />
  );
}
