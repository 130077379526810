// @flow

import React, { type Node } from 'react';

import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';

type Props = {
  children: Node,
  dataTestId?: string,
  external?: boolean, // Set to true if you want to use a regular <a>
  target?: string,
  to: string | Object,
};

const Link = ({ children, dataTestId = 'atom-link', external = false, target, to, ...rest }: Props) =>
  external ? (
    <ExternalLink data-testid={dataTestId} href={to} target={target} {...rest}>
      {children}
    </ExternalLink>
  ) : (
    <InternalLink data-testid={dataTestId} to={to} {...rest}>
      {children}
    </InternalLink>
  );

export default Link;
