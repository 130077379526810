// @flow

import hash from 'hash-sum';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from './error.svg';

type FailuresType = {
  recipient: string,
  status: string,
};

type FailedReleasesType = {
  failures: Array<FailuresType>,
  message: string,
};

type Props = {
  failedReleases: FailedReleasesType,
};

const StyledErrorIcon = styled(ErrorIcon)`
  height: ${rem(12)};
  position: relative;
  width: ${rem(12)};
`;

export default function ReleaseFailures({ failedReleases }: Props) {
  const { failures } = failedReleases;

  return (
    <>
      {failures.map((failure, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={hash(failure) + i}>
          <StyledErrorIcon /> <strong>{failure.recipient}</strong>
          <br />
          <em>- {failure.status}</em>
        </p>
      ))}
    </>
  );
}
