"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledBase = exports.StyledAccessibilityLabel = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
const StyledBase = (0, _styled.styled)('button', {
  $$borderWidth: '$borderWidths$1',
  // Use `em` units here so the control adjusts with the text size. Also with `width`.
  $$height: '1.5em',
  $$width: '3em',
  backgroundColor: 'transparent',
  borderColor: 'currentColor',
  borderRadius: '$full',
  borderStyle: 'solid',
  borderWidth: '$$borderWidth',
  height: '$$height',
  position: 'relative',
  width: '$$width',
  '&:disabled': {
    opacity: 0.75
  },
  '&::before': {
    $$size: 'calc(1.5em - ($$borderWidth * 4))',
    backgroundColor: 'currentColor',
    borderRadius: '$full',
    content: '',
    display: 'block',
    height: '$$size',
    left: 'calc($$borderWidth * 2)',
    position: 'absolute',
    top: '$$borderWidth',
    transition: 'all 250ms $quick',
    width: '$$size'
  },
  '&[aria-checked=true]': {
    backgroundColor: 'currentColor',
    '&::before': {
      backgroundColor: '$white',
      transform: 'translateX(calc($$width - $$size - ($$borderWidth * 6)))'
    }
  },
  variants: {
    /**
     * Sets the color of the swtich.
     * @type $colors
     */
    color: (0, _utils.tokenVariant)('color', _styled.theme.colors)
  }
});
exports.StyledBase = StyledBase;
const StyledAccessibilityLabel = (0, _styled.styled)('span', {
  display: 'none'
});
exports.StyledAccessibilityLabel = StyledAccessibilityLabel;