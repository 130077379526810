import { isNil, omitBy } from 'lodash';
import qs from 'qs';

/**
 * A path builder for `apollo-link-rest` to remove variables that are null or empty strings since the server does not like those.
 */
export function pathBuilder({ args }: { args: {} }) {
  const options = omitBy(args, (val) => {
    if (isNil(val)) return true;
    if (val.length === 0) return true;
    return false;
  });
  return `/?${qs.stringify(options)}`;
}
