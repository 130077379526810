// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  calculatePercent,
  countMismatchTotal,
  dataForProgressTable,
  type EmailReportEnvelopeMismatches,
} from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import TruncateText from 'ui/atoms/TruncateText';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import NoData from 'ui/molecules/NoData';
import ProgressTable from 'ui/molecules/ProgressTable';
import TextEllipsisTooltip from 'ui/molecules/TextEllipsisTooltip';
import withDataHandler from 'utils/withDataHandler';

type Props = {
  data: EmailReportEnvelopeMismatches[],
  error: boolean,
  loading: boolean,
};

const ProgressTableWithDataHandler = withDataHandler({
  empty: <NoData />,
})(ProgressTable);

export default function EnvelopeVsHeaderFromMismatches({ data, error, loading }: Props) {
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader title={t('envelopeVsHeaderFromMismatches')} />
          <Content>
            <p>{t('envelopeVsHeaderFromMismatchesFound', { count: countMismatchTotal(data) })}</p>
            <p>{t('envelopeVsHeaderFromMismatchesCopy')}</p>
          </Content>
        </>
      }
      dataTestId="envelope-vs-header-card"
    >
      <PaddedContent pushBottom="lg">
        <>
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            calculatePercent={(data, row) => calculatePercent(data, row)}
            cellWidths={['30%', '60%', '10%']}
            data={dataForProgressTable(data)}
            dataTestId="envelope-vs-header-table"
            headings={[t('lookedLike'), t('wasActuallyFrom'), t('count')]}
            isEmpty={() => loading || error || dataForProgressTable(data).length === 0}
            isLoading={loading}
            /* eslint-disable no-shadow */
            renderCell={(data, _rowIndex, index) => renderCell(data, _rowIndex, index, t)}
          />
        </>
      </PaddedContent>
    </ContentBlock>
  );
}

function renderCell(data, _rowIndex, index, t) {
  if (index === 0) return <TruncateText width={300}>{data}</TruncateText>;
  if (index === 1)
    return (
      <TextEllipsisTooltip tooltipPosition="top" width={500}>
        {data}
      </TextEllipsisTooltip>
    );
  return t('components:Statistic.number', { value: data });
}
