// @flow

import React from 'react';

type Props = { color?: string, percent?: number, size?: number };

const Chart = ({ color = '#FF4F4F', percent = 0.4, size = 128 }: Props) => {
  const circumference = Math.PI * size;
  const dashPercent = circumference * percent;

  const style = {
    transform: 'rotate(-90deg) scaleX(-1)',
    transformOrigin: 'center center',
  };

  return (
    <svg height={size + 8} style={style} version="1.1" width={size + 8} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4 4)" strokeWidth="8" stroke="none" fill="none" fillRule="evenodd">
        <circle cx={size / 2} cy={size / 2} r={size / 2} stroke="#F1F4FA" />
        <path
          d={`M0,${size / 2}a${size / 2},${size / 2} 0 1,0 ${size},0a${size / 2},${size / 2} 0 1,0 -${size},0`}
          stroke={color}
          strokeDasharray={`${dashPercent}, ${circumference}`}
          strokeLinecap="round"
          style={{ transition: 'stroke-dasharray 150ms ease-out' }}
        />
      </g>
    </svg>
  );
};

export default Chart;
