// @flow

import styled from 'styled-components';

import Content from 'ui/molecules/Content';

const Value = styled(Content)`
  flex: 1;
`;

export default Value;
