import React, { createContext, useContext, useState, Dispatch, PropsWithChildren, SetStateAction } from 'react';

//
// Settings & Types
// -------------------------------------------------------------------------------------------------

const DEFAULT_DURATION = '30';

// Duration value 365 is being used for SecOps component
export type Duration = '7' | '30' | '90' | '365';
type DurationContextType = [Duration, Dispatch<SetStateAction<Duration>>];

//
// Context & Components
// -------------------------------------------------------------------------------------------------

const DurationContext = createContext<DurationContextType>([DEFAULT_DURATION, () => {}]);

export function DurationProvider({ children }: PropsWithChildren<{}>) {
  const durationState = useState<Duration>(DEFAULT_DURATION);
  return <DurationContext.Provider value={durationState}>{children}</DurationContext.Provider>;
}

//
// Hooks
// -------------------------------------------------------------------------------------------------

export function useDurationContext() {
  return useContext(DurationContext);
}

export function useDuration() {
  return useDurationContext()[0];
}
