import { styled } from '@a1s/ui';

export const StyledTable = styled('table', {
  borderCollapse: 'collapse',

  variants: {
    stretch: {
      true: {
        minHeight: '100%',
      },
    },
  },
});

export const StyledTD = styled('td', {
  verticalAlign: 'top',
  '& + &': { pl: '$2' },

  variants: {
    allowBreaks: {
      true: {
        wordBreak: 'break-all',
      },
    },

    preventBreaks: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
  },
});

export const StyledTR = styled('tr', {});
