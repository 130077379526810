import { styled } from '@a1s/ui';

export const Scrollable = styled('div', {
  bottom: 0,
  left: 0,
  overflowY: 'scroll',
  pr: '$2',
  position: 'absolute',
  right: 0,
  top: 0,
  scrollbarColor: '$gray50 $gray200',

  '&::-webkit-scrollbar': {
    height: '$sizes$3xs',
    width: '$sizes$3xs',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '$gray200',
    r: '$full',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '$gray50',
    r: '$full',
  },

  variants: {
    dark: {
      true: {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '$gray400',
        },

        '&::-webkit-scrollbar-track': {
          backgroundColor: '$gray150',
        },
      },
    },
    thin: {
      true: {
        '&::-webkit-scrollbar': {
          height: '$sizes$4xs',
          width: '$sizes$4xs',
        },
      },
    },
  },
});
