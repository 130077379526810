import { keyframes, styled } from '@a1s/ui';

export const Button = styled('button', {
  appearance: 'none',
  alignItems: 'center',
  aspectRatio: '1 / 1',
  backgroundColor: '$gray100',
  border: 'none',
  borderRadius: '$2',
  color: '$gray600',
  display: 'flex',
  justifyContent: 'center',
  minHeight: 24,
  minWidth: 24,
  px: '$2',

  '&:hover': {
    color: '$gray700',
    backgroundColor: '$gray50',
  },

  '&:disabled': {
    color: '$gray400',

    '&:hover': {
      color: '$gray400',
      backgroundColor: '$gray100',
    },
  },
});

//
// SVG animation
// -------------------------------------------------------------------------------------------------

const color = keyframes({
  '0%': { stroke: '$colors$orange500' },
  '40%': { stroke: '$colors$orange500' },
  '66%': { stroke: '$colors$orange500' },
  '80%': { stroke: '$colors$orange500' },
  '90%': { stroke: '$colors$orange500' },
});

const dash = keyframes({
  '0%': {
    strokeDasharray: '1, 200',
    strokeDashoffset: 0,
  },
  '50%': {
    strokeDasharray: '89, 200',
    strokeDashoffset: -35,
  },
  '100%': {
    strokeDasharray: '89, 200',
    strokeDashoffset: -124,
  },
});

const rotate = keyframes({
  '100%': { transform: 'rotate(360deg)' },
});

export const WaitingSVG = styled('svg', {
  animation: `${rotate} 2s linear infinite`,
  transformOrigin: 'center center',

  '& circle': {
    animation: `${dash} 1.5s ease-in-out infinite, ${color} 6s ease-in-out infinite`,
    fill: 'none',
    stroke: '$colors$orange500',
    strokeDasharray: '150, 200',
    strokeDashoffset: -10,
    strokeLinecap: 'round',
    strokeWidth: '3',
  },
});
