import { Box, Cluster, Cover, Stack, TabList } from '@a1s/ui';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UpdatesDisplay } from '../../ui';

import Middle, { Tabs } from './Middle';

import { ButtonFooter, PanelContainer, InfoTooltip } from 'ui-new';
import { useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

export function ThreatOriginsPanel() {
  const duration = useDuration();
  const [currentTab, setCurrentTab] = useState<Tabs>('worldwide');
  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('threatOrigins')} titleDecoration={<Tooltip />}>
      <Cover>
        <Stack gap="7">
          <Stack>
            <Top currentTab={currentTab} onTabChange={setCurrentTab} />
            <Middle currentTab={currentTab} duration={duration} />
          </Stack>
          <Bottom />
        </Stack>
      </Cover>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Bottom() {
  return (
    <Box pb px testId="threat-origins-bottom-box">
      <ButtonFooter to="/email/detections/malicious-email/origins">
        <UpdatesDisplay duration="PT15M" />
      </ButtonFooter>
    </Box>
  );
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.threatOrigins', { postProcess: 'markdown' })}</InfoTooltip>;
}

interface TopProps {
  /**
   * Which tab is currently selected
   */
  currentTab: Tabs;

  /**
   * Callback that is fired when the tab type is changed
   */
  onTabChange: (tabName: Tabs) => void; // eslint-disable-line no-unused-vars
}

function Top({ currentTab, onTabChange }: TopProps) {
  const { t } = useTranslation('dashboardHome');

  return (
    <Box pt px testId="threat-origins-top-box">
      <Cluster justify="stretch">
        <TabList appearance="arrow-down">
          <TabList.Tab onClick={() => onTabChange('worldwide')} selected={currentTab === 'worldwide'}>
            {t('worldWide')}
          </TabList.Tab>
          <TabList.Tab
            onClick={() => onTabChange('sameIndustry')}
            selected={currentTab === 'sameIndustry'}
            testId="same-industry-button"
          >
            {t('sameIndustry')}
          </TabList.Tab>
        </TabList>
      </Cluster>
    </Box>
  );
}
