// @flow

import theme from '../../../config/theme';

export default {
  // This one is an object because the disposition must match a specific color
  detections: {
    mailBec: theme.colors.maliciousBec,
    mailBulk: theme.colors.bulk,
    mailMalicious: theme.colors.malicious,
    mailSpam: theme.colors.spam,
    mailSpoof: theme.colors.spoof,
    mailSuspicious: theme.colors.suspicious,
  },
  graphite: [
    theme.colors.deepSpaceSparkle,
    theme.colors.steelTeal,
    theme.colors.cadetGrey,
    theme.colors.ashGrey,
    theme.colors.gainsboro,
  ],
  inferno: [
    theme.colors.pumpkin,
    theme.colors.neonCarrot,
    theme.colors.sunglow,
    theme.colors.mellowYellow,
    theme.colors.peach,
    theme.colors.papayaWhip,
  ],
  lavender: [
    theme.colors.maximumPurple,
    theme.colors.purpureus,
    theme.colors.orchid,
    theme.colors.brightLilac,
    theme.colors.mauve,
  ],
  sea: [
    theme.colors.illuminatingEmerald,
    theme.colors.polishedPine,
    theme.colors.mediumAquamarine,
    theme.colors.middleBlueGreen,
    theme.colors.magicMint,
  ],
};
