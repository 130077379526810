import { Box } from '@a1s/ui';
import React, { useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCfRedirectUrl } from './hooks/useGetCfRedirectUrl';
import { CfAccountType } from './shared/types';

import Button from 'ui/atoms/Button';

interface ButtonType {
  accounts: CfAccountType[];
  handleClick: () => void;
}

export const LinkButton = ({ accounts, handleClick }: ButtonType) => {
  const [getRedirectUrl, { data, error, loading }] = useGetCfRedirectUrl();
  const { t } = useTranslation('linkCfAccount');

  const handleInitiateCfSso = (event: SyntheticEvent) => {
    event.preventDefault();
    getRedirectUrl();
  };

  useEffect(() => {
    if (!error && !loading && !!data && 'getRedirectUrlResponse' in data) {
      const { redirectUrl } = data.getRedirectUrlResponse;
      window.location.href = redirectUrl;
    }
  }, [data, error, loading]);

  if (accounts?.length === 0)
    return (
      <Box pl="2">
        <Button disabled={!!error || loading} onClick={handleInitiateCfSso}>
          {t('linkAccountBannerText')}
        </Button>
      </Box>
    );

  return (
    <Box pl="2">
      <Button onClick={handleClick}>{t('linkAccountBannerText')}</Button>
    </Box>
  );
};
