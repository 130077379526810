"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.borderWidths = void 0;
const borderWidths = {
  0: '0px',
  1: '1px',
  2: '2px'
};
exports.borderWidths = borderWidths;