import { Cluster } from '@a1s/ui';
import React, { useEffect, useState, ComponentProps } from 'react';

import { SearchResultRow } from '../../types';
import { Main, SectionHeader } from '../../ui';
import { getIdsForCheckboxes } from '../../ui/MetaCell';

import { Details, Results } from './ui';

//
// Main component
// -------------------------------------------------------------------------------------------------

type CheckedRows = Exclude<ComponentProps<typeof Results>['checked'], undefined>;

interface DetectionSearchProps {
  params: ComponentProps<typeof Results>['search'];
}

export function DetectionSearch({ params }: DetectionSearchProps) {
  const [checked, setChecked] = useState<CheckedRows>([]);
  const [detail, setDetail] = useState<SearchResultRow | null>(null);
  const [counts, setCounts] = useState<ComponentProps<typeof SectionHeader>['searchCounts'] | undefined>(undefined);
  const [countsLoading, setCountsLoading] = useState<boolean>(false);

  // Uncheck all rows when a new search is run
  const deps = JSON.stringify(params);
  useEffect(() => {
    setChecked([]);
  }, [deps]);

  function handleSelectAll(all: boolean) {
    if (all) {
      const ids = getIdsForCheckboxes();
      setChecked(ids);
    } else {
      setChecked([]);
    }
  }

  function handleSearchFinished(searchCounts: ComponentProps<typeof SectionHeader>['searchCounts']) {
    setCountsLoading(false);
    setCounts(searchCounts);
  }

  function handleSearchStart() {
    setCountsLoading(true);
  }

  return (
    <Main>
      <Main.Header>
        <SectionHeader
          countsLoading={countsLoading}
          dateRange={[params.start, params.end]}
          messageIds={checked}
          onSelectAll={handleSelectAll}
          params={params}
          searchCounts={counts}
          selectedIds={checked}
        />
      </Main.Header>

      <Main.Body>
        <Cluster css={{ height: '100%' }} gap="5">
          <Results
            checked={checked}
            collapsed={!!detail}
            onRowCheck={setChecked}
            onPressViewButton={setDetail}
            onSearchFinished={handleSearchFinished}
            onSearchStart={handleSearchStart}
            search={params}
            selectedId={detail?.id}
          />
          {!!detail && <Details onPressBackButton={() => setDetail(null)} row={detail} />}
        </Cluster>
      </Main.Body>
    </Main>
  );
}
