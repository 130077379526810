"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledInput = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
//
// Shared styles
// -------------------------------------------------------------------------------------------------
const thumbStyles = {
  appearance: 'none',
  backgroundColor: 'currentColor',
  borderColor: '$white',
  borderRadius: '$$size',
  borderStyle: 'solid',
  borderWidth: '2px',
  boxSizing: 'border-box',
  height: '$$size',
  width: '$$size'
};
const trackStyles = {
  appearance: 'none',
  backgroundColor: 'currentColor',
  height: '1px',
  width: '100%'
};

//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledInput = (0, _styled.styled)('input', {
  $$size: '16px',
  appearance: 'none',
  backgroundColor: 'transparent',
  height: '$$size',
  // Firefox psuedo elements
  '&::-moz-range-thumb': {
    ...thumbStyles
  },
  '&::-moz-range-track': {
    ...trackStyles
  },
  // Safari, Brave, Chrome, etc. psuedo elements
  '&::-webkit-slider-runnable-track': {
    ...trackStyles
  },
  '&::-webkit-slider-thumb': {
    ...thumbStyles,
    marginTop: 'calc(($$size / 2) * -1)'
  },
  variants: {
    color: (0, _utils.tokenVariant)('color', _styled.theme.colors)
  }
});
exports.StyledInput = StyledInput;