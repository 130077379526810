// @flow

// $FlowFixMe
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import Drawer from '.';

import Backdrop from 'ui/atoms/Backdrop';

type Props = {
  onClose?: (SyntheticEvent<HTMLDivElement>) => void,
  visible?: boolean,
};

export default function Modal({ onClose, visible, ...props }: Props) {
  const elRef = useRef(document.createElement('div'));
  const modalRef = useRef(document.getElementById('modal'));

  useEffect(() => {
    const el = elRef.current;
    const modal = modalRef.current;

    if (el && modal) modal.appendChild(el);
    return () => {
      if (el && modal) modal.removeChild(el);
    };
  });

  function handleClick(event: SyntheticEvent<HTMLDivElement>) {
    if (onClose) onClose(event);
  }

  return createPortal(
    <>
      <Drawer closed={!visible} onClose={onClose} {...props} />
      <Backdrop invisible={!visible} onClick={handleClick} />
    </>,
    elRef.current
  );
}
