"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.radii = void 0;
const radii = {
  full: '9999px',
  none: '0px',
  1: '0.125rem',
  2: '0.25rem',
  3: '0.375rem',
  4: '0.5rem',
  5: '0.75rem',
  6: '1rem',
  7: '1.5rem'
};
exports.radii = radii;