"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledStack = void 0;
var _styled = require("../../styled");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledStack = (0, _styled.styled)('div', {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    /**
     * Aligns the children of the `Stack` in a horizontal direction within itself.
     * @type "baseline" | "center" | "end" | "start" | "stretch"
     */
    align: {
      baseline: {
        alignItems: 'baseline'
      },
      center: {
        alignItems: 'center'
      },
      end: {
        alignItems: 'flex-end'
      },
      start: {
        alignItems: 'flex-start'
      },
      stretch: {
        alignItems: 'stretch'
      }
    },
    /**
     * The amount of vertical space to insert between the child components.
     * @type $space
     */
    gap: {
      true: {
        rowGap: '$3'
      },
      none: {
        rowGap: '$none'
      },
      px: {
        rowGap: '$px'
      },
      0.5: {
        rowGap: '$0.5'
      },
      1: {
        rowGap: '$1'
      },
      1.5: {
        rowGap: '$1.5'
      },
      2: {
        rowGap: '$2'
      },
      2.5: {
        rowGap: '$2.5'
      },
      3: {
        rowGap: '$3'
      },
      3.5: {
        rowGap: '$3.5'
      },
      4: {
        rowGap: '$4'
      },
      5: {
        rowGap: '$5'
      },
      6: {
        rowGap: '$6'
      },
      7: {
        rowGap: '$7'
      },
      8: {
        rowGap: '$8'
      },
      9: {
        rowGap: '$9'
      },
      10: {
        rowGap: '$10'
      },
      11: {
        rowGap: '$11'
      },
      12: {
        rowGap: '$12'
      }
    },
    /**
     * Aligns the children vertically, and the space/gap will be honored regardless of wrapping.
     * @type "center" | "end" | "space-around" | "space-between" | "space-evenly" | "start" | "stretch"
     */
    justify: {
      center: {
        justifyContent: 'center'
      },
      end: {
        justifyContent: 'flex-end'
      },
      'space-around': {
        justifyContent: 'space-around'
      },
      'space-between': {
        justifyContent: 'space-between'
      },
      'space-evenly': {
        justifyContent: 'space-evenly'
      },
      start: {
        justifyContent: 'flex-start'
      },
      stretch: {
        justifyContent: 'stretch',
        '& > *': {
          flexGrow: 1
        }
      }
    }
  }
});
exports.StyledStack = StyledStack;