// @flow

import { motion } from 'framer-motion';

import { rem } from 'polished';
import styled, { css } from 'styled-components';

export default styled(motion.div)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};
  display: flex;
  height: ${rem(38)};
  padding: 0 ${({ theme }) => rem(theme.spacing.md)};
  width: 100%;

  ${({ small }) =>
    small &&
    css`
      height: ${rem(32)};
    `};

  ${({ focused }) =>
    focused &&
    css`
      /* Figure out some position trickery */
    `};
`;
