"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMutations = useMutations;
var _react = require("react");
/**
 * Represents the mutations of an HTML element.
 */

/**
 * Returns the mutation events of the element within the current value of the given `ref` as a stateful value.
 * The stateful value is updated when the configured element mutation events are fired.
 */

function useMutations(options) {
  const ref = (0, _react.useRef)(null);
  const [mutation, setMutation] = (0, _react.useState)(null);
  const observer = (0, _react.useRef)(new MutationObserver(entries => {
    if (entries[0]) {
      window.requestAnimationFrame(() => {
        setMutation(entries[0]);
      });
    }
  }));
  const setRef = (0, _react.useCallback)(node => {
    const {
      current: obs
    } = observer;
    if (ref.current) obs.disconnect();
    if (node) {
      ref.current = node;
      observer.current.observe(node, options);
    }
  }, [options]);
  return [mutation, setRef, ref];
}