// @flow

import React from 'react';

type Scale = {
  (arg0: number): number,
  ticks: (arg0: number) => number[],
};

type CommonGridProps = {
  left?: number,
  numTicks: number,
  scale: Scale,
  stroke?: string,
  width: number,
};

export const Grid = (props: CommonGridProps) => {
  return <GridRows {...props} />;
};

export const GridRows = ({ left = 0, numTicks, scale, stroke, width }: CommonGridProps) => {
  return (
    <g transform={`translate(${left}, 0)`}>
      {scale.ticks(numTicks).map((tick, index) => (
        /* eslint-disable react/no-array-index-key */
        <line key={index} stroke={stroke} strokeWidth={1} x1={0} x2={width} y1={scale(tick)} y2={scale(tick)} />
      ))}
    </g>
  );
};
