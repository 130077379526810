import { Box, Table, Text } from '@a1s/ui';
import { ApolloError, useQuery } from '@apollo/client';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { loader } from 'graphql.macro';
import React, { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { DISPOSITION_FILTER_DEFAULT_OPTION } from '..';

import { SubmissionRow } from './SubmissionRow';

import { Dispositions } from 'common';

import { RenderNoData } from 'screens/Search/ui/NoData';
import { Scrollable } from 'ui-new';
import Toast from 'ui/molecules/Toast';
import { useDuration, Duration } from 'utils/duration';

export interface SubmissionTableProps {
  dispositionType: typeof DISPOSITION_FILTER_DEFAULT_OPTION | Dispositions;
  offset: number;
  submissionId?: string;
  submissionType: 'all' | 'team' | 'user';
  onViewEmail: ComponentProps<typeof SubmissionRow>['onViewEmail'];
}

export function SubmissionTable({
  dispositionType,
  offset,
  onViewEmail,
  submissionId,
  submissionType,
}: SubmissionTableProps) {
  const { t } = useTranslation('submission');
  const duration = useDuration();
  const { data, fetchMore, loading, loadingMore } = useRemoteData({ duration, offset, submissionId, submissionType });

  const filteredFnSubmissionData = data?.filter((row: APIData) => {
    return (
      (dispositionType.toLowerCase() === DISPOSITION_FILTER_DEFAULT_OPTION ||
        row?.originalDisposition?.toLowerCase() === dispositionType.toLowerCase()) &&
      (!submissionId || row.submissionId.toLowerCase().includes(submissionId.toLowerCase()))
    );
  });

  const hasFilteredData = filteredFnSubmissionData && filteredFnSubmissionData.length > 0;

  function handleWaypointEnter() {
    // We don't want to use fetchMore if submissionId is present
    if (!submissionId) fetchMore?.({ offset: data?.length || 0 });
  }

  return (
    <>
      <Box
        css={{
          flexGrow: 1,
          minHeight: 'calc(100vh - 370px)',
          minWidth: 800,
          position: 'relative',
        }}
      >
        <Scrollable css={{ overflowX: 'hidden' }}>
          <Table css={{ '& th': { backgroundColor: '$gray150' } }}>
            <Table.Header>
              <Table.HeaderCell>
                <Text.Title color="$gray700">{t('submissionInfo')}</Text.Title>
              </Table.HeaderCell>
              <Table.HeaderCell css={{ width: 175 }}>
                <Text.Title color="$gray700">{t('origDisposition')}</Text.Title>
              </Table.HeaderCell>
              <Table.HeaderCell css={{ width: 175 }}>
                <Text.Title color="$gray700">{t('submittedAs')}</Text.Title>
              </Table.HeaderCell>
              <Table.HeaderCell css={{ width: 175 }}>
                <Text.Title color="$gray700">{t('outcomeDisposition')}</Text.Title>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Text.Title color="$gray700">{t('messageInfo')}</Text.Title>
              </Table.HeaderCell>
              <Table.HeaderCell css={{ width: 75 }}>
                <Text.Title color="$gray700">{t('actions')}</Text.Title>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Main>
              {hasFilteredData && !loading ? (
                <>
                  {filteredFnSubmissionData.map((row) => (
                    <SubmissionRow data={row} duration={duration} onViewEmail={onViewEmail} />
                  ))}
                  <Waypoint onEnter={handleWaypointEnter} />
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <RenderNoData loading={loading} />
                  </td>
                </tr>
              )}
            </Table.Main>
          </Table>
        </Scrollable>
      </Box>
      {/* The positioning is fairly arbitrary. I just eyeballed it and this looked best. */}
      <Box css={{ bottom: -10, position: 'absolute', width: '100%' }}>
        {/* @ts-ignore - Toast is an old flow component */}
        <Toast open={loadingMore} variant="success">
          {t('Loading more...')}
        </Toast>
      </Box>
    </>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

export interface APIData {
  clientUuid: string;
  comments: string;
  edfHash: string;
  type: string;
  originalDisposition?: Dispositions;
  originalMessageId?: string;
  originalPostfixId?: string;
  originalRecipients?: string;
  originalSender?: string;
  originalTs?: string;
  outcome: string;
  outcomeDisposition: Dispositions;
  recipient: string;
  submissionId: string;
  requestedBy: string;
  requestedDisposition: Dispositions;
  requestedTs: string;
  storedAt: string;
  subject: string;
  updatedBy: string;
  updatedTs: string;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData[] | null;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error?: ApolloError | null;

  /**
   * Returns useQuery's fetchMore function
   */
  fetchMore?: Function | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;

  /**
   * Returns true if more data is being loaded from the API
   */
  loadingMore?: boolean;
}

const query = loader('../queries/load.graphql');

type SubmissionType = 'all' | 'team' | 'user';

interface UseRemoteDataType {
  duration: Duration;
  limit?: number;
  offset: number;
  submissionId?: string;
  submissionType: SubmissionType;
}

function useRemoteData({
  duration,
  limit = 10,
  offset = 0,
  submissionId,
  submissionType,
}: UseRemoteDataType): HookResult {
  const { data, error, fetchMore, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...setDateRangeFromDuration(duration),
      limit,
      offset,
      submissionId,
      submissionType: determineSubmissionType(submissionType),
    },
    skip: !!submissionId && submissionId.length < 3,
  });
  const [loadingMore, setLoadingMore] = useState(false);

  // eslint-disable-next-line no-shadow
  async function loadMore({ offset = 0 }) {
    setLoadingMore(true);
    try {
      await fetchMore({
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          setLoadingMore(false);

          return {
            submissionMetrics: {
              error: null,
              data: [
                ...(previousResult.submissionMetrics.data || []),
                ...(fetchMoreResult.submissionMetrics.data || []),
              ],
              __typename: 'SubmissionMetrics',
            },
          };
        },
        variables: {
          limit,
          offset,
        },
      });
    } catch (e) {
      // check Sentry for potential errors
    }
  }

  if (loading) return { data: undefined, error: null, loading: true, loadingMore };
  if (error) return { data: undefined, error, loading: false, loadingMore };
  if (!data?.submissionMetrics?.data) return { data: undefined, error: null, loading: false, loadingMore };

  return { data: data.submissionMetrics.data, error, fetchMore: loadMore, loading: false, loadingMore };
}

function determineSubmissionType(type: SubmissionType) {
  if (type === 'all') return '';
  return type;
}

interface SetDateRangeFromDurationType {
  start: string; // String representation of ISO date
  end: string; // String representation of ISO date
}

export function setDateRangeFromDuration(duration: Duration): SetDateRangeFromDurationType {
  return {
    start: format(startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), 'yyyy-MM-dd'),
    end: format(endOfDay(new Date()), 'yyyy-MM-dd'),
  };
}
