import { styled } from '@a1s/ui';

export const Content = styled('div', {
  fontFamily: '$roboto',
  '& h2': {
    color: '$black',
    marginBottom: '$5',
    marginTop: '$5',
    fontFamily: 'lato',
    fontWeight: 'bold',
  },
  '& h3': {
    color: '$gray700',
    fontWeight: 'bold',
  },
  '& p': {
    color: '$gray700',
    fontSize: '$md',
    textAlign: 'justify',
  },
  '& ul': {
    color: '$gray700',
    fontSize: '$md',
    marginBottom: '$5',
    marginLeft: '$6',
    textAlign: 'justify',
  },
});
