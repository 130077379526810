// @flow

class RingChartPercentageError extends Error {
  constructor(...params) {
    super(...params);
    this.name = 'RingChartPercentageError';
  }
}

const checkPercent = (percent: number) => {
  if (percent > 1) {
    try {
      throw new RingChartPercentageError('RingChart has rendered a percentage greater than 100%');
    } catch (err) {
      if (window.Sentry) {
        // $FlowFixMe
        Sentry.configureScope((scope) => {
          scope.setTag('percent_value', percent);
        });
        Sentry.captureException(err);
      }
    }
  }
  return percent;
};

export default checkPercent;
