import { styled } from '@a1s/ui';

export const StyledArea = styled('div', {
  variants: {
    grow: {
      true: {
        alignItems: 'stretch',
        display: 'flex',
        '& > *': {
          flexGrow: '1',
        },
      },
    },
  },
});

export const StyledGrid = styled('div', {
  display: 'grid',
});
