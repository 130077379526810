import { Box, Button, Cluster, Stack, Text } from '@a1s/ui';
import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { CfAccountType } from './shared/types';
import { P, Title } from './shared/typography';

import { CloseButton, Dialog } from 'ui-new';

export const ErrorDialog = ({ account, error }: { account?: CfAccountType; error?: ApolloError }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation('linkCfAccount');

  useEffect(() => {
    if (!!account && !!error) setShowModal(true);
  }, [account, error]);

  return (
    <Dialog maxWidth onClose={() => setShowModal(false)} visible={showModal}>
      <Box p>
        <Stack gap="4">
          <Cluster gap>
            <Title>{t('linkAccountErrorTitle')}</Title>
            <CloseButton onPress={() => setShowModal(false)} />
          </Cluster>
          <Trans i18nKey="linkAccountErrorText">
            <P>
              There was an error while linking Cloudflare account{' '}
              <Text weight="bold">
                {account?.name} ({account?.id})
              </Text>{' '}
              to your Area 1 account. This could mean that you&apos;ve already linked this Cloudflare account to another
              Area 1 account.
            </P>
            <P>You may try linking a different Cloudflare account, or reach out to customer support for help.</P>
          </Trans>
          <Cluster justify="center">
            <Button onPress={() => setShowModal(false)}>{t('close')}</Button>
          </Cluster>
        </Stack>
      </Box>
    </Dialog>
  );
};
