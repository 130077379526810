// @flow

import React, { type Node } from 'react';

import Container from './Container';

interface Props {
  children?: Node;
  dataTestId?: string;
  style?: Object;
}

const HeaderOptions = ({ children, dataTestId = 'molecule-header-options', style }: Props, ref) => (
  <Container data-testid={dataTestId} ref={ref} style={style}>
    {children}
  </Container>
);

// $FlowFixMe
export default React.forwardRef(HeaderOptions);
