"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.space = void 0;
const space = {
  none: '0px',
  px: '1px',
  1: '0.125rem',
  '1-5': '0.25rem',
  2: '0.5rem',
  '25': '0.625rem',
  3: '0.75rem',
  '35': '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem'
};
exports.space = space;