// @flow

import React from 'react';

import StackedGraphTable from 'ui/molecules/StackedGraphTable';

export const defaultData = [
  { label: 'Example 3', value: 11 },
  { label: 'Example 1', value: 17 },
  { label: 'Example 5', value: 2 },
  { label: 'Example 2', value: 15 },
  { label: 'Example 4', value: 5 },
];

type DataType = { label: string, value: number };

type Props = { data?: Array<DataType> };

const PlaceholderStackedGraphTable = ({ data = defaultData }: Props) => (
  <StackedGraphTable data={data} hideValue colorScheme="graphite" placeholder placeholderText="NoData.noDataObserved" />
);

export default PlaceholderStackedGraphTable;
