// @flow

import theme from 'config/theme';

export default {
  source: [
    theme.colors.deepSpaceSparkle,
    theme.colors.steelTeal,
    theme.colors.cadetGrey,
    theme.colors.ashGrey,
    theme.colors.gainsboro,
  ],
  target: [
    theme.colors.illuminatingEmerald,
    theme.colors.polishedPine,
    theme.colors.mediumAquamarine,
    theme.colors.middleBlueGreen,
    theme.colors.magicMint,
  ],
};
