// @flow

import React from 'react';
import styled, { css } from 'styled-components';

export type IconName =
  | 'add'
  | 'check'
  | 'close'
  | 'download'
  | 'ellipsis'
  | 'file-graph'
  | 'mail-retract'
  | 'hamburger'
  | 'reload'
  | 'spinner'
  | 'viewDetails';

type Props = { name: IconName };

const Icon = ({ name, ...props }: Props) => {
  switch (name) {
    case 'add':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" {...props}>
          <title>Plus icon</title>
          <path
            d="M5 1v8M1 5h8"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      );
    case 'download':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" {...props}>
          <title>Download</title>
          <path d="M10.398 4v7.2H7.2L12 16l4.8-4.8h-3.198V4zM4 18.398V20h16v-1.602zm0 0" fill="currentColor" />
        </svg>
      );
    case 'ellipsis':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" {...props}>
          <title>Ellipsis</title>
          <path
            fill="currentColor"
            d="M2 0C.893 0 0 .893 0 2s.893 2 2 2 2-.893 2-2-.893-2-2-2zm12 0c-1.107 0-2 .893-2 2s.893 2 2 2 2-.893 2-2-.893-2-2-2zM8 0C6.893 0 6 .893 6 2s.893 2 2 2 2-.893 2-2-.893-2-2-2z"
          />
        </svg>
      );
    case 'hamburger':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" {...props}>
          <title>Menu</title>
          <path
            d="M1 1h13M1 6.133h13M1 11.267h13"
            fill="none"
            fillRule="evenodd"
            stroke="#B8C1CF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      );
    case 'close':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" {...props}>
          <title>Close</title>
          <path
            fill="#F2F3F5"
            stroke="#797979"
            d="M1.66 1.66l11.31 11.31m-11.31 0L12.97 1.66"
            fillRule="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      );
    case 'check':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <title>Check</title>
          <path
            d="M8.042 0C3.6236 0 0.0419998 3.5816 0.0419998 8C0.0419998 12.4184 3.6236 16 8.042 16C12.4604 16 16.042 12.4184 16.042 8C16.042 3.5816 12.4596 0 8.042 0ZM7.442 12.6L3.442 9.6L4.642 8L7.042 9.8L11.242 4.2L12.842 5.4L7.442 12.6Z"
            fill="#fff"
          />
        </svg>
      );
    case 'mail-retract':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" {...props}>
          <path fill="none" d="M0-.001h24v24H0z" />
          <g fill="currentColor">
            <path d="M18 0c-3.309 0-6 2.691-6 6h2c0-2.205 1.795-4 4-4s4 1.795 4 4c0 2.206-1.795 4-4 4-1.07 0-2.074-.416-2.779-1.119l-.047-.054L17 7h-5v5l1.758-1.757A5.954 5.954 0 0018 12c3.309 0 6-2.691 6-6s-2.691-6-6-6zM8 16.771l3.95-2.821a10.074 10.074 0 01-1.937-1.949H2c-.194 0-.378.037-.556.088L8 16.771z" />
            <path d="M13.816 15.074l-5.235 3.739a.995.995 0 01-1.162 0l-7.364-5.26A1.996 1.996 0 000 14v8c0 1.103.896 2 2 2h12c1.104 0 2-.897 2-2v-6.201a9.862 9.862 0 01-2.184-.725z" />
          </g>
        </svg>
      );
    case 'reload':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" {...props}>
          <title>Loading arrow</title>
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M8 0a7.942 7.942 0 0 0-5.65 2.35L.8.8v4.8h4.8L3.48 3.48A6.351 6.351 0 0 1 8 1.6c3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4A6.407 6.407 0 0 1 1.6 8H0c0 4.412 3.589 8 8 8s8-3.588 8-8-3.589-8-8-8z"
          />
        </svg>
      );
    case 'spinner':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 50 50" {...props}>
          <title>Spinner</title>
          <path
            fill="currentColor"
            d="M6.347 21.74C4.65 31.918 11.522 41.545 21.7 43.244c10.177 1.698 19.805-5.176 21.503-15.353l-4.012-.67c-1.329 7.961-8.86 13.339-16.821 12.01-7.962-1.328-13.339-8.86-12.01-16.821l-4.013-.67z"
          >
            <animateTransform
              attributeName="transform"
              attributeType="xml"
              dur="0.6s"
              from="0 25 25"
              repeatCount="indefinite"
              to="360 25 25"
              type="rotate"
            />
          </path>
        </svg>
      );
    case 'viewDetails':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" {...props}>
          <title>View Details</title>
          <path
            d="M4 4.8v6.4h6.4V4.8zm2.4 2.4H8v1.6H6.4zm5.6 0v1.6h4V7.2zm5.6 0v1.6H20V7.2zM4 12.8v6.4h6.4v-6.4zm3.2 2.4a.8.8 0 110 1.6.8.8 0 010-1.6zm4.8 0v1.6h4v-1.6zm5.6 0v1.6H20v-1.6zm0 0"
            fill="currentColor"
          />
        </svg>
      );
    case 'file-graph':
      return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="14" height="14" {...props}>
          <title>File Graph</title>
          <g fill="currentColor">
            <path d="M5 19L5 3 3 3 3 21l18 0 0-2Z" />
            <path d="M16 6h4v11.99h-4Z" />
            <path d="M11 12h4v6h-4Z" />
            <path d="M6 10h4v8H6Z" />
          </g>
        </svg>
      );
    default:
      return null;
  }
};

export default styled(Icon)`
  path {
    color: ${({ theme }) => theme.colors.white} !important;
  }

  ${({ muted, theme }) =>
    muted &&
    css`
      path {
        color: ${theme.colors.grayBlue} !important;
      }

      &:hover {
        path {
          color: ${theme.colors.independence} !important;
        }
      }
    `};
`;
