// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  calculatePercentForProgressTable,
  dataForTopMessageProgressTable,
  type EmailReportTopRecipients,
  type EmailReportTopSenders,
} from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import NoData from 'ui/molecules/NoData';
import ProgressTable from 'ui/molecules/ProgressTable';
import TwoColumn from 'ui/templates/TwoColumn';
import withDataHandler from 'utils/withDataHandler';

type EmailReportData = {
  recipients: EmailReportTopRecipients[],
  senders: EmailReportTopSenders[],
};

type Props = {
  data: EmailReportData,
  error: boolean,
  loading: boolean,
};

const ProgressTableWithDataHandler = withDataHandler({
  empty: <NoData />,
})(ProgressTable);

export default function TopMessageSendersAndRecipients({ data, error, loading }: Props) {
  const { recipients, senders } = data;
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader title={t('topMessageSendersAndRecipients')} />
        </>
      }
      dataTestId="top-message-card"
    >
      <PaddedContent pushBottom="lg" pushTop="lg">
        <TwoColumn bordered unspaced>
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            calculatePercent={(senders, [_, number]) => calculatePercentForProgressTable(senders, [_, number])}
            cellWidths={['80%', '20%']}
            data={dataForTopMessageProgressTable(senders)}
            dataTestId="top-email-senders-table"
            headings={[t('topEmailSenders'), t('weeklyAverage')]}
            isEmpty={() => loading || error || senders.length === 0}
            isLoading={loading}
            /* eslint-disable no-shadow */
            renderCell={(senders, _rowIndex, index) => renderSenderCell(senders, _rowIndex, index, t)}
          />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            calculatePercent={(recipients, [_, number]) => calculatePercentForProgressTable(recipients, [_, number])}
            cellWidths={['80%', '20%']}
            data={dataForTopMessageProgressTable(recipients)}
            dataTestId="top-email-recipients-table"
            headings={[t('topEmailRecipients'), t('weeklyAverage')]}
            isEmpty={() => loading || error || recipients.length === 0}
            isLoading={loading}
            /* eslint-disable no-shadow */
            renderCell={(recipients, _rowIndex, index) => renderRecipientCell(recipients, _rowIndex, index, t)}
          />
        </TwoColumn>
      </PaddedContent>
    </ContentBlock>
  );
}

function renderRecipientCell(data, _rowIndex, index, t) {
  if (index !== 1) return data;
  return t('components:Statistic.number', { value: data });
}

function renderSenderCell(data, _rowIndex, index, t) {
  if (index !== 1) return data;
  return t('components:Statistic.number', { value: data });
}
