// @flow

// $FlowFixMe
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useTransition } from 'react-spring';
import styled from 'styled-components';

import { Container } from '../Alert';

import Modal from './Modal';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Section from 'ui/atoms/Section';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Centered = styled.div`
  position: relative;
  text-align: center;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  busy?: boolean;
  buttonText?: ReactNode;
  dark?: boolean;
  dataTestId?: string;
  message?: ReactNode;
  onActionCancel?: Function;
  onActionConfirm?: Function;
  open?: boolean;
  zebraStripe?: boolean;
}

function AlertDialog(props: Props) {
  const {
    busy = false,
    buttonText = 'buttons.delete',
    dark = true,
    dataTestId = 'molecule-alert-dialog',
    message = 'dialogs.confirmDelete',
    open = false,
    zebraStripe = true,
  } = props;

  const transitions = useTransition(open, null, {
    config: { tension: 500, friction: 35, mass: 1 },
    from: { opacity: 0, transform: 'translate(-50%, -100%)' },
    enter: { opacity: 1, transform: 'translate(-50%, -50%)' },
    leave: { opacity: 0, transform: 'translate(-50%, -100%)' },
  });

  const { t } = useTranslation('common');

  return transitions.map(
    ({ item, key, props: style }) =>
      item && (
        <Container dark={dark ? 'true' : undefined} data-testid={dataTestId} key={key} style={style}>
          <>
            <Section zebraStripe={zebraStripe}>
              <Centered>{typeof message === 'string' ? t(message) : message}</Centered>
            </Section>
            <Centered>
              <Button dataTestId="button-cancel" value={false} onClick={props.onActionCancel} muted>
                {t('buttons.cancel')}
              </Button>
              <ConditionalRender condition={!!buttonText}>
                <Button
                  dataTestId="button-confirm"
                  disabled={busy}
                  icon={busy && 'spinner'}
                  value
                  onClick={props.onActionConfirm}
                >
                  {typeof buttonText === 'string' ? t(buttonText) : buttonText}
                </Button>
              </ConditionalRender>
            </Centered>
          </>
        </Container>
      )
  );
}

AlertDialog.Modal = Modal;

export default AlertDialog;
