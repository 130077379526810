// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import GeoOrigins from './GeoOrigins';

// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';

export const threatOriginsMapQuery = loader('./queries/InsightsThreatOriginsMap.graphql');

export default function MaliciousOriginsOverview() {
  const duration = useDuration();
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(threatOriginsMapQuery, {
    variables: setInsightsDateRangeFromDuration(duration),
  });
  const locations = get(data, 'insightsThreatOriginsMap.data[0].byLocationCounts', [
    { count: 0, geohash: '', lat: 0, lng: 0, location: '' },
  ]);

  return (
    <>
      <Helmet title={`${t('targets')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <GeoOrigins data={locations} error={error} loading={loading} />
    </>
  );
}
