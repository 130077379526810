// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Change from './Change';
import Children from './Children';
import Container from './Container';
import Values from './Values';

import Statistic, { type Size } from 'ui/atoms/Statistic';
import Subtitle from 'ui/atoms/Subtitle';
import PercentChange from 'ui/molecules/PercentChange';
import { calculatePercentDifference } from 'utils/util';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Decoration = styled.div`
  flex-shrink: 1;
  margin-left: ${(p) => rem(p.theme.spacing.sm)};
`;

const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type Format = 'number' | 'percent' | 'percentWithDecimal';

// eslint-disable-next-line no-use-before-define
type Props = ChangeProps & {
  animate?: boolean,
  cappedValue?: number,
  children?: Node,
  colorize?: boolean,
  dataTestId?: string,
  dimmed?: boolean,
  formatValue?: Format,
  title?: Node,
  titleDecoration?: Node,
};

export default function SectionOverview(props: Props) {
  const { animate = false, colorize = false, dimmed = false, size = 'medium' } = props;
  const {
    cappedValue,
    children,
    currentValue,
    dataTestId = 'organisms-section-overview',
    formatValue,
    previousValue,
    title,
    titleDecoration,
  } = props;

  return (
    <Container data-testid={dataTestId} dimmed={dimmed} large={size === 'large'}>
      <Title title={title} titleDecoration={titleDecoration} />
      <Values>
        <Statistic animate={animate} cappedValue={cappedValue} colorize={colorize} format={formatValue} size={size}>
          {currentValue}
        </Statistic>
        <Changed currentValue={currentValue} previousValue={previousValue} size={size} />
      </Values>
      {children && <Children>{children}</Children>}
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

type ChangeProps = {
  currentValue: number | string | Node,
  previousValue?: number,
  size?: Size,
};

function Changed({ currentValue, previousValue, size }: ChangeProps) {
  const { t } = useTranslation('components');

  if (typeof currentValue !== 'number' || typeof previousValue !== 'number') return null;

  const percent = calculatePercentDifference(currentValue, previousValue);
  return (
    <Change>
      <PercentChange shrunk={size !== 'large'}>{percent}</PercentChange>
      <Subtitle>{t(`SectionOverview.previously`, { value: previousValue })}</Subtitle>
    </Change>
  );
}

type TitleProps = {
  title?: Node,
  titleDecoration?: Node,
};

function Title({ title, titleDecoration }: TitleProps) {
  if (!title && !titleDecoration) return null;

  return (
    <TitleContainer>
      <Subtitle capitalized>{title}</Subtitle>
      {titleDecoration && <Decoration>{titleDecoration}</Decoration>}
    </TitleContainer>
  );
}
