// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Head, Header, Row } from 'ui/molecules/Table';

type AlignType = 'left' | 'center' | 'right';
type TextAlignFunction = (heading: string) => AlignType;
type TextAlignType = AlignType | TextAlignFunction;

type Props = {
  className?: string,
  colWidths?: any[],
  dataTestId?: string,
  headings?: Array<string>,
  ns?: string,
  padCell?: (heading: string) => boolean,
  /**
   * Pass a string to use the same alignment on all headings
   * or you can pass a function that checks the value of a heading to determine
   * alignment.
   */
  textAlign?: TextAlignType,
};

export default function TableHeader({
  className,
  colWidths = [],
  dataTestId = 'molecule-table-header',
  headings = [],
  ns = 'mailConfig',
  padCell = () => false,
  textAlign,
}: Props) {
  const alignHeading = (heading: string): AlignType => {
    if (!textAlign) return 'left';

    if (typeof textAlign === 'function') {
      return textAlign(heading);
    }
    return textAlign;
  };

  const renderCols = (widths) =>
    widths.length ? (
      // eslint-disable-next-line react/no-array-index-key
      widths.map((width, i) => <Col key={width + i} width={width} />)
    ) : (
      <>
        {
          // eslint-disable-next-line no-unused-vars, react/no-array-index-key
          [...new Array(headings.length).map((col, i) => <Col key={i} />)]
        }
      </>
    );

  const renderHeadings = () =>
    headings.map((heading, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Header textAlign={alignHeading(heading)} key={heading + i} padLeft={padCell(heading)}>
        {t(heading)}
      </Header>
    ));

  const { t } = useTranslation(ns);

  return (
    <>
      <colgroup>{renderCols(colWidths)}</colgroup>
      <Head className={className} data-testid={dataTestId}>
        <Row>{renderHeadings()}</Row>
      </Head>
    </>
  );
}
