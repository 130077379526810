// @flow

import { lighten, rem } from 'polished';
import styled, { css } from 'styled-components';

const Button = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.deepSkyBlue};
  border: ${({ theme }) => theme.colors.deepSkyBlue} solid ${rem(2)};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer !important')};
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(12)};
  line-height: 1;
  outline: 0;
  padding: ${({ theme }) => rem(theme.spacing.sm)}
    ${({ spaced, theme }) => rem(spaced ? theme.spacing.md : theme.spacing.sm)};
  transition: background-color 150ms ${({ theme }) => theme.timing.easeOutQuart},
    border-color 150ms ${({ theme }) => theme.timing.easeOutQuart},
    transform 250ms ${({ theme }) => theme.timing.easeOutBack};
  white-space: nowrap;

  /*  stylelint-disable-next-line */
  &:not(:first-child) {
    margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.starCommandBlue};
    border-color: ${({ theme }) => theme.colors.starCommandBlue};
    transform: translateY(${rem(1)});
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.diamond};
    border-color: ${({ theme }) => theme.colors.diamond};

    &:hover {
      background-color: ${({ theme }) => theme.colors.diamond};
      border-color: ${({ theme }) => theme.colors.diamond};
    }

    &:active {
      transform: none;
    }
  }

  ${({ clickableText }) =>
    clickableText &&
    css`
      background-color: transparent !important;
      border-width: 0;
      color: ${({ theme }) => (clickableText === 'blue' ? theme.colors.deepSkyBlue : theme.colors.grayBlue)};
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin: 0;
      outline: inherit;
      padding: 0;

      &:hover {
        color: ${({ theme }) => theme.colors.onyx};
      }
    `};

  ${({ danger }) =>
    danger &&
    css`
      background-color: #e24d47;
      border-color: #e24d47;
      height: ${rem(33)};

      &:active {
        background-color: #b12d29;
        border-color: #b12d29;
      }

      &:disabled {
        background-color: #f1ced1;
        border-color: #f1ced1;
        color: ${({ theme }) => theme.colors.white};

        &:hover {
          background-color: #f1ced1 !important;
          border-color: #f1ced1 !important;
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-left: 0px !important;
    `};

  ${({ muted }) =>
    muted &&
    css`
      background-color: ${({ theme }) => theme.colors.antiFlashWhite};
      border-color: ${({ theme }) => theme.colors.antiFlashWhite};
      color: ${({ theme }) => theme.colors.grayBlue};

      &:hover {
        background-color: ${({ theme }) => lighten(0.025, theme.colors.azureishWhite)};
        border-color: ${({ theme }) => lighten(0.025, theme.colors.azureishWhite)};
        color: ${({ theme }) => theme.colors.dimGray};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.pastelBlue};
        border-color: ${({ theme }) => theme.colors.pastelBlue};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.antiFlashWhite};
        border-color: ${({ theme }) => theme.colors.antiFlashWhite};
        color: ${({ theme }) => theme.colors.lightGray};

        &:hover {
          background-color: ${({ theme }) => theme.colors.antiFlashWhite};
          border-color: ${({ theme }) => theme.colors.antiFlashWhite};
          color: ${({ theme }) => theme.colors.lightGray};
        }
      }
    `};

  ${({ small }) =>
    small &&
    css`
      padding: ${({ theme }) => rem(theme.spacing.xs)} ${({ theme }) => rem(theme.spacing.sm)};
    `};

  ${({ tiny }) =>
    tiny &&
    css`
      padding: 0;
    `};

  ${({ warning }) =>
    warning &&
    css`
      background-color: ${({ theme }) => theme.colors.pastelRed};
      border-color: ${({ theme }) => theme.colors.pastelRed};

      &:hover {
        background-color: ${({ theme }) => lighten(0.1, theme.colors.pastelRed)};
        border-color: ${({ theme }) => lighten(0.1, theme.colors.pastelRed)};
      }
    `};
`;

Button.displayName = 'Button';

export default Button;
