"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledBase = void 0;
var _styled = require("../../styled");
const StyledBase = (0, _styled.styled)('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  // A janky way to edit out some of the land-masses that we don't want visible,
  // namely Antatica
  '& > svg': {
    marginBottom: -64,
    marginLeft: -16
  }
});
exports.StyledBase = StyledBase;