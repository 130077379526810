import { Cluster } from '@a1s/ui';
import React, { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../ButtonLink';

//
// Types
// -------------------------------------------------------------------------------------------------
interface ButtonFooterProps {
  /**
   * Control the vertucak alignment of the contents of the footer.
   */
  align?: ComponentProps<typeof Cluster>['align'];

  /**
   * Additional information can be displayed by passing it into the component as children.
   */
  children?: ReactNode;

  css?: ComponentProps<typeof Cluster>['css'];

  /**
   * The i18nKey to use to for displaying the text of the button.
   */
  i18nKey?: string;

  /**
   * Control the horizontal justification of the contents of the footer.
   */
  justify?: ComponentProps<typeof Cluster>['justify'];

  testId?: string;

  /**
   * The URI path for the button to go to when clicked
   */
  to?: ComponentProps<typeof ButtonLink>['to'];
}

//
// Main component
// -------------------------------------------------------------------------------------------------

export function ButtonFooter({ children, css, testId, to, ...props }: ButtonFooterProps) {
  // Extract the props with defaults...
  const { align = 'end', i18nKey = 'common:viewAll', justify = 'space-between' } = props;

  const { t } = useTranslation();

  return (
    <Cluster align={align} as="footer" css={css} data-testid={testId} gap justify={justify}>
      {children}
      <ButtonLink to={to}>{t(i18nKey)}</ButtonLink>
    </Cluster>
  );
}
