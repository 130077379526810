// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Subtitle from 'ui/atoms/Subtitle';

const Container = styled.div`
  padding: ${({ theme }) => rem(theme.spacing.md)} ${({ theme }) => rem(theme.spacing.md)}
    ${({ theme }) => rem(theme.spacing.md - 6)};
`;

const ExtraContainer = styled.div`
  padding-top: ${({ theme }) => rem(theme.spacing.xs)};
`;

const Link = styled(NavLink)`
  display: block;
  text-decoration: none;
`;

const Title = styled(Subtitle)`
  [aria-current='page'] > & {
    color: ${({ theme }) => theme.colors.black};
  }
`;

type Props = { children: Node, extra?: Node, to: string };
const Header = ({ children, extra, to }: Props) => (
  <Container>
    <Link to={to}>
      <Title capitalized>{children}</Title>
      {extra && <ExtraContainer>{extra}</ExtraContainer>}
    </Link>
  </Container>
);

export default Header;
