import { Text } from '@a1s/ui';
import React from 'react';

export function Divider() {
  return (
    <Text as="span" color="$gray400" weight="regular">
      <> | </>
    </Text>
  );
}
