// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';

// $FlowFixMe
import React, { useState } from 'react';

import { type InsightsNameSpoofType } from './dataTypesAndUtils';

import TopSpoofedTargets from './TopSpoofedTargets';
import TopSpoofedUsers from './TopSpoofedUsers';

import TwoColumn from 'ui/templates/TwoColumn';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';

export const fetchInsightsOrgSpoofs = loader('./queries/FetchInsightsOrgSpoofs.graphql');

type Props = {
  data: InsightsNameSpoofType,
  error: boolean,
  loading: boolean,
};

export default function MostCommonNameSpoofs({ data, error, loading }: Props) {
  const duration = useDuration();
  const [fetchData, setFetchData] = useState(false);

  const {
    data: insightsData,
    loading: insightsLoading,
    error: insightsError,
  } = useQuery(fetchInsightsOrgSpoofs, {
    skip: !fetchData,
    variables: {
      limit: 50,
      ...setInsightsDateRangeFromDuration(duration),
    },
  });

  const { topNames, topTargets } = get(insightsData, 'insightsOrgSpoofs.data', {
    classicSpoofCount: 0,
    nameSpoofCount: 0,
    topNames: [],
    topTargets: [],
  });

  return (
    <>
      <TwoColumn>
        <TopSpoofedUsers
          data={data.topNames}
          error={error}
          insightsError={insightsError}
          insightsLoading={insightsLoading}
          loading={loading}
          setFetchData={setFetchData}
          topNames={topNames}
        />
        <TopSpoofedTargets
          data={data.topTargets}
          error={error}
          insightsError={insightsError}
          insightsLoading={insightsLoading}
          loading={loading}
          setFetchData={setFetchData}
          topTargets={topTargets}
        />
      </TwoColumn>
    </>
  );
}
