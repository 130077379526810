// Shared utility and helper functions for ./Dashboard

export const FIFTEEN_MINUTE_POLL_INTERVAL: number = 900000; // 15 minutes in milliseconds

export function placeholderData(length: number) {
  return Array.from({ length }, () => ({} as any));
}

export function percentOf(value: number, total: number): string {
  if (!value || !total) return (0).toFixed(0);
  return ((value / total) * 100).toFixed(0);
}
