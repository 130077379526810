import { styled } from '@a1s/ui';
import React, { ComponentProps } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface BackButtonProps extends Omit<ComponentProps<typeof Button>, 'onClick' | 'type'> {
  onPress?: ComponentProps<typeof Button>['onClick'];
}

/**
 * Display a button with a _close_ "X" icon on it.
 */
export function BackButton({ onPress, ...props }: BackButtonProps) {
  return (
    <Button onClick={onPress} {...props}>
      <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16z" fill="currentColor" fillRule="nonzero" />
      </svg>
    </Button>
  );
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

export const Button = styled('button', {
  appearance: 'none',
  background: 'none',
  border: 'none',
  color: '$gray600',

  '&:hover': {
    color: '$gray700',
  },
});
