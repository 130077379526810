"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTable = exports.StyledTR = exports.StyledTHead = exports.StyledTH = exports.StyledTD = exports.StyledTBody = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
const StyledTD = (0, _styled.styled)('td', {
  backgroundColor: '$gray50',
  py: '$2',
  verticalAlign: 'top',
  '&:first-of-type': {
    pl: '$$xPadding',
    rl: '$2'
  },
  '&:last-of-type': {
    pr: '$$xPadding',
    rr: '$2'
  },
  '& > div': {
    height: '100%',
    px: '$2'
  },
  '&:first-of-type > div': {
    pl: '0 !important'
  },
  '& + & > div': {
    borderLeftColor: '$gray300',
    borderLeftStyle: 'solid',
    borderLeftWidth: 1
  },
  variants: {
    border: {
      /**
       * Removes the border separator from the cell.
       */
      none: {
        '& > div': {
          borderLeft: 'none !important',
          px: '0 !important'
        }
      }
    }
  }
});
exports.StyledTD = StyledTD;
const StyledTH = (0, _styled.styled)('th', {
  bg: '$gray100',
  py: '$2',
  textAlign: 'left',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    pl: '$$xPadding',
    rl: '$2'
  },
  '&:last-of-type': {
    pr: '$$xPadding',
    rr: '$2'
  }
});
exports.StyledTH = StyledTH;
const StyledTR = (0, _styled.styled)('tr', {
  height: '100%',
  variants: {
    bg: (0, _utils.tokenVariant)('bg', _styled.theme.colors, ' & > td')
  }
});
exports.StyledTR = StyledTR;
const StyledTBody = (0, _styled.styled)('tbody', {
  height: '100%'
});
exports.StyledTBody = StyledTBody;
const StyledTHead = (0, _styled.styled)('thead', {});
exports.StyledTHead = StyledTHead;
const StyledTable = (0, _styled.styled)('table', {
  $$xPadding: '$space$3',
  borderSpacing: '0px $space$1-5',
  tableLayout: 'fixed',
  width: '100%',
  // This is a really strange behavior of tables. In order to get *anything* inside of the tables
  // to accept a height of `100%`, some sort of height needs to be set on the table. Once the
  // height is set to `1px`, the divs inside each of the table cells are then able to accept the
  // a height 100%. There's some interesting back-and-forth in this StackOverflow thread:
  // https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell
  height: 1
});
exports.StyledTable = StyledTable;