// @flow

import React from 'react';

import PercentChange from '../PercentChange';

import Container from './Container';

import Statistic from 'ui/atoms/Statistic';

type Props = {
  dataTestId?: string,
  format?: 'abbreviatedNumber' | 'number' | 'percent',
  percentChange: number,
  statistic: number,
};
const SidebarOverview = ({
  dataTestId = 'molecule-sidebar-overview',
  format = 'number',
  percentChange,
  statistic,
}: Props) => (
  <Container data-testid={dataTestId}>
    <Statistic format={format} size="x-small">
      {statistic}
    </Statistic>
    <PercentChange boxed>{percentChange}</PercentChange>
  </Container>
);

export default SidebarOverview;
