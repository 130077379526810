// @flow

import styled, { css } from 'styled-components';

const Centered = styled.div.attrs({ 'data-testid': 'atom-centered' })`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  ${({ stacked }) =>
    stacked &&
    css`
      display: block;
      text-align: center;
      position: relative;

      & > * {
        display: block;
      }
    `};
`;

Centered.displayName = 'Centered';

export default Centered;
