// @flow

import React, { Fragment, type Node } from 'react';

import Footer from './Footer';
import Header from './Header';
import Main from './Main';

import ConditionalRender from 'ui/atoms/ConditionalRender';

type Props = { footer?: Node, header: Node, main: Node };

const App = ({ footer, header, main }: Props) => (
  <Fragment>
    <Header>{header}</Header>
    <Main>{main}</Main>
    <ConditionalRender condition={Boolean(footer)}>
      <Footer>{footer}</Footer>
    </ConditionalRender>
  </Fragment>
);

export default App;
