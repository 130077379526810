"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledContent = exports.StyledContainer = exports.StyledArrow = void 0;
var _css = require("../../shared/css");
var _styled = require("../../styled");
const StyledArrow = (0, _styled.styled)('div', {
  height: '$$size',
  left: '50%',
  position: 'absolute',
  overflow: 'hidden',
  transform: 'translate(-50%, -1px)',
  width: '$$size',
  '&::after': {
    backgroundColor: '$$backgroundColor',
    borderColor: '$$borderColor',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    content: ' ',
    display: 'block',
    height: '$$size',
    transform: 'rotate(45deg) translate(calc(($$size * 0.5) * -1), calc(($$size * 0.5) * -1))',
    width: '$$size'
  }
});
exports.StyledArrow = StyledArrow;
const StyledContainer = (0, _styled.styled)('div', {
  $$backgroundColor: '$colors$yellow50',
  $$borderColor: '$colors$gray200',
  $$size: '$sizes$4',
  ..._css.toggle.show,
  position: 'relative',
  '&[hidden]': {
    ..._css.toggle.hide,
    display: 'block' // Overrides browser's default behavior so the element doesn't disappear right away
  }
});
exports.StyledContainer = StyledContainer;
const StyledContent = (0, _styled.styled)('div', {
  backgroundColor: '$$backgroundColor',
  borderColor: '$$borderColor',
  borderRadius: '$2',
  borderStyle: 'solid',
  borderWidth: '0.5px',
  px: '$2',
  py: '$1',
  whiteSpace: 'nowrap'
});
exports.StyledContent = StyledContent;