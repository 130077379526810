// @flow

import React, { Fragment, type Node } from 'react';

import Content from './Content';
import Header from './Header';
import Main from './Main';

type Props = { header: Node, main: Node };

const Section = ({ header, main }: Props) => (
  <Fragment>
    <Header>{header}</Header>
    <Main>
      <Content>{main}</Content>
    </Main>
  </Fragment>
);

export default Section;
