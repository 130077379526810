// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Values = styled.div`
  align-items: center;
  display: flex;

  & > :first-child {
    margin-right: ${({ theme }) => rem(theme.spacing.md)};
  }
`;

export default Values;
