import { styled } from '@a1s/ui';

import { ReactComponent as AlertSVG } from './alert.svg';

export const Alert = styled(AlertSVG, {
  color: '$red700',
  height: '3rem',
  display: 'block',
});

/**
 * Main wrapper for the <Widget> component. It's main function is set a relative positioning
 * context so the private `<Title>` component can use absolute postioning.
 */
export const BaseContainer = styled('section', {
  alignContent: 'stretch',
  display: 'flex',
  position: 'relative',

  '& > *': {
    flexGrow: 1,
  },
});

/**
 * Wrapper for the content.
 */
export const ContentContainer = styled('div', {
  alignItems: 'stretch',
  backgroundColor: '$white',
  borderRadius: '$2',
  boxShadow: '$1',
  display: 'flex',
  position: 'relative',
  zIndex: 1,

  '& > *': {
    flexGrow: '1',
  },
});

/**
 * Wrapper component for displaying and error message when an error is thrown from within
 * children of the component
 */
export const ErrorDisplay = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minHeight: 400,
});

/**
 * Wrapper for the private `<Title>` component. It uses absolute positioning to left itself
 * out of the main wrapper component, so it can live above the main content box.
 */
export const TitleContainer = styled('div', {
  left: 0,
  position: 'absolute',
  transform: 'translateY(-100%)',
  top: 0,
  zIndex: 2,
});
