"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSVG = exports.StyledRect = exports.StyledCaptionsContainerHorizontal = exports.StyledCaptionsContainer = exports.StyledCaptionLine = exports.StyledCaption = exports.StyledBaseHorizontal = exports.StyledBase = exports.StyledBackground = exports.LineHandle = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
//
// Styled components
// -------------------------------------------------------------------------------------------------
const StyledBackground = (0, _styled.styled)('rect', {
  fill: 'transparent',
  strokeWidth: 0
});
exports.StyledBackground = StyledBackground;
const StyledBase = (0, _styled.styled)('figure', {
  alignItems: 'stretch',
  display: 'flex',
  columnGap: '$2'
});
exports.StyledBase = StyledBase;
const StyledBaseHorizontal = (0, _styled.styled)('figure', {
  flexDirection: 'row'
});
exports.StyledBaseHorizontal = StyledBaseHorizontal;
const StyledCaption = (0, _styled.styled)('p', {});
exports.StyledCaption = StyledCaption;
const StyledCaptionsContainer = (0, _styled.styled)('figcaption', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  rowGap: '$5'
});
exports.StyledCaptionsContainer = StyledCaptionsContainer;
const StyledCaptionsContainerHorizontal = (0, _styled.styled)(StyledCaptionsContainer, {
  flexDirection: 'row',
  gap: 'unset',
  justifyContent: 'space-evenly'
});
exports.StyledCaptionsContainerHorizontal = StyledCaptionsContainerHorizontal;
const StyledCaptionLine = (0, _styled.styled)('path', {
  fill: 'transparent',
  stroke: '$gray400',
  strokeWidth: 1
});
exports.StyledCaptionLine = StyledCaptionLine;
const StyledSVG = (0, _styled.styled)('svg', {
  display: 'block'
});
exports.StyledSVG = StyledSVG;
const StyledRect = (0, _styled.styled)('rect', {
  variants: {
    /**
     * Sets the fill of the rect
     * @type $colors
     */
    fill: (0, _utils.tokenVariant)('fill', _styled.theme.colors)
  }
});
exports.StyledRect = StyledRect;
const LineHandle = (0, _styled.styled)('span');
exports.LineHandle = LineHandle;