"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootStyles = exports.fontImports = void 0;
var _styled = require("../styled");
var _LatoBold = _interopRequireDefault(require("../assets/fonts/Lato-Bold.woff2"));
var _LatoBoldItalic = _interopRequireDefault(require("../assets/fonts/Lato-BoldItalic.woff2"));
var _LatoBlack = _interopRequireDefault(require("../assets/fonts/Lato-Black.woff2"));
var _LatoBlackItalic = _interopRequireDefault(require("../assets/fonts/Lato-BlackItalic.woff2"));
var _LatoLight = _interopRequireDefault(require("../assets/fonts/Lato-Light.woff2"));
var _LatoLightItalic = _interopRequireDefault(require("../assets/fonts/Lato-LightItalic.woff2"));
var _LatoRegular = _interopRequireDefault(require("../assets/fonts/Lato-Regular.woff2"));
var _LatoItalic = _interopRequireDefault(require("../assets/fonts/Lato-Italic.woff2"));
var _LatoThin = _interopRequireDefault(require("../assets/fonts/Lato-Thin.woff2"));
var _LatoThinItalic = _interopRequireDefault(require("../assets/fonts/Lato-ThinItalic.woff2"));
var _RobotoVariableFont = _interopRequireDefault(require("../assets/fonts/Roboto-VariableFont.woff2"));
var _RobotoMonoVariableFont = _interopRequireDefault(require("../assets/fonts/RobotoMono-VariableFont.woff2"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const fontImports = (0, _styled.globalCss)({
  '@font-face': [{
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '100',
    fontStyle: 'normal',
    src: `url('${_LatoThin.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '100',
    fontStyle: 'italic',
    src: `url('${_LatoThinItalic.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontStyle: 'normal',
    src: `url('${_LatoLight.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontStyle: 'italic',
    src: `url('${_LatoLightItalic.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '400',
    fontStyle: 'normal',
    src: `url('${_LatoRegular.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '400',
    fontStyle: 'italic',
    src: `url('${_LatoItalic.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontStyle: 'normal',
    src: `url('${_LatoBold.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontStyle: 'italic',
    src: `url('${_LatoBoldItalic.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '900',
    fontStyle: 'normal',
    src: `url('${_LatoBlack.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Lato',
    fontWeight: '900',
    fontStyle: 'italic',
    src: `url('${_LatoBlackItalic.default}') format('woff2')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Roboto',
    fontWeight: '100 1000',
    fontStretch: '25% 151%',
    fontStyle: 'normal italic',
    src: `url('${_RobotoVariableFont.default}') format('woff2-variations')`
  }, {
    fontDisplay: 'swap',
    fontFamily: 'Roboto Mono',
    fontWeight: '100 700',
    fontStyle: 'normal',
    src: `url('${_RobotoMonoVariableFont.default}') format('woff2-variations')`
  }]
});
exports.fontImports = fontImports;
const rootStyles = (0, _styled.globalCss)({
  ':root': {
    fontFamily: '$roboto'
  },
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0
  },
  'body[data-close-path]': {
    minWidth: '100vw',
    position: 'fixed'
  }
});
exports.rootStyles = rootStyles;