"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledRestrainer = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledRestrainer = (0, _styled.styled)('div', {
  '& > *': {
    height: '100%',
    width: '100%'
  },
  variants: {
    //
    // Dimensional props
    // -------------------------------------------------------------------------------------------------

    /**
     * Sets minimum height of the box.
     * @type $sizes
     */
    height: (0, _utils.tokenVariant)('maxHeight', _styled.theme.sizes, '& > *'),
    /**
     * Sets min width of the box.
     * @type $sizes
     */
    width: (0, _utils.tokenVariant)('maxWidth', _styled.theme.sizes, '& > *')
  }
});
exports.StyledRestrainer = StyledRestrainer;