import { Button } from '@a1s/ui';
import React, { MouseEvent, ReactNode } from 'react';
import { useHistory } from 'react-router';

//
// Types
// -------------------------------------------------------------------------------------------------
interface ButtonLinkProps {
  /**
   * The contents of the button, usually text.
   */
  children: ReactNode;

  /**
   * The URI path for the button to link to.
   */
  to?: string;
}

//
// Main component
// -------------------------------------------------------------------------------------------------

export function ButtonLink({ children, to }: ButtonLinkProps) {
  const history = useHistory();

  const handlePress = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (to) history.push(to);
  };

  if (!to) return null;

  return <Button onPress={handlePress}>{children}</Button>;
}
