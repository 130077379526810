// @flow

// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderData } from '../dataTypesAndUtils';

import { dataForGraphTableTargets, type InsightsNameSpoofList } from './dataTypesAndUtils';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import PlaceholderStackedGraphTable from 'ui/molecules/PlaceholderStackedGraphTable';
import StackedGraphTable from 'ui/molecules/StackedGraphTable';
import withDataHandler from 'utils/withDataHandler';

const TopSpoofedTargetsDetailsTable = lazy(() => import('./TopSpoofedTargetsDetailsTable'));

type Props = {
  data: InsightsNameSpoofList[],
  error: boolean,
  insightsError: boolean,
  insightsLoading: boolean,
  loading: boolean,
  setFetchData: Function,
  topTargets: Object,
};

const GraphTableWithDataHandler = withDataHandler({
  empty: <PlaceholderStackedGraphTable data={placeholderData} />,
})(StackedGraphTable);

export default function TopSpoofedTargets({
  data,
  error,
  insightsError,
  insightsLoading,
  loading,
  setFetchData,
  topTargets,
}: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  const handleClick = () => {
    if (data.length > 0) setOpenModal(true);
    setFetchData(true);
  };

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader
            title={t('topSpoofedTargets')}
            action={<Button disabled={data.length === 0} icon="hamburger" muted onClick={handleClick} />}
          />
          <GraphTableWithDataHandler
            data={dataForGraphTableTargets(data)}
            dataTestId="graph-table-spoofed-targets"
            isEmpty={() => loading || error || data.length === 0}
            loading={loading}
            sortOtherLast
            tint="aquamarine"
          />
          <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
            <Suspense fallback={<Loading />}>
              {openModal && (
                <TopSpoofedTargetsDetailsTable
                  data={topTargets}
                  insightsError={insightsError}
                  insightsLoading={insightsLoading}
                />
              )}
            </Suspense>
          </Drawer.Modal>
        </>
      }
    />
  );
}
