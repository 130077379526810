// @flow

import React, { type Node } from 'react';

import Container from './Container';

import Section from 'ui/atoms/Section';

type Props = {
  alt?: boolean,
  bordered?: boolean,
  bottom?: Node,
  children?: Node,
  dataTestId?: string,
  top?: Node,
  zebraStripe?: boolean,
};

const ContentBlock = ({
  alt = false,
  bordered = false,
  bottom,
  children,
  dataTestId = 'molecule-content-block',
  top,
  zebraStripe = true,
}: Props) => (
  <Container data-testid={dataTestId}>
    {top && (
      <Section alternate={alt} bordered={bordered} zebraStripe={zebraStripe}>
        {top}
      </Section>
    )}

    {children}

    {bottom && (
      <Section alternate={!alt} zebraStripe={zebraStripe}>
        {bottom}
      </Section>
    )}
  </Container>
);

export default ContentBlock;
