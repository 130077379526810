// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const TwoColumn = styled.div`
  display: flex;
  flex-direction: row;

  & > :first-child {
    margin-right: ${({ spaced, theme }) => rem(spaced ? theme.spacing.lg : theme.spacing.md)};
    width: 50%;
  }

  & > :last-child {
    margin-left: ${({ spaced, theme }) => rem(spaced ? theme.spacing.lg : theme.spacing.md)};
    width: 50%;
  }

  & + & {
    margin-top: ${({ theme }) => rem(theme.spacing.xl)};
  }

  ${({ bordered, spaced, theme }) =>
    bordered &&
    css`
      & > :first-child {
        border-right: ${theme.colors.lightGray} solid ${rem(1)};
        margin-right: 0;
        padding-right: ${rem(spaced ? theme.spacing.lg : theme.spacing.md)};
        width: 50%;
      }

      & > :last-child {
        margin-left: 0;
        padding-left: ${rem(spaced ? theme.spacing.lg : theme.spacing.md)};
        width: 50%;
      }
    `};

  ${({ unspaced }) =>
    unspaced &&
    css`
      & > :first-child,
      & > :last-child {
        margin: 0;
        padding: 0;
      }
    `};
`;

TwoColumn.displayName = 'TwoColumn';

export default TwoColumn;
