"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledComponent = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledComponent = (0, _styled.styled)('div', {
  backgroundColor: 'transparent',
  scrollBehavior: 'smooth',
  scrollbarWidth: 'thin',
  variants: {
    /**
     * Sets maximum height of the box.
     * @type $sizes
     */
    height: (0, _utils.tokenVariant)('height', _styled.theme.sizes),
    scrollbarColor: (0, _utils.tokenVariant)('gray400', _styled.theme.colors),
    scrollable: {
      true: {
        overflowY: 'auto'
      },
      false: {
        overflowY: 'hidden'
      }
    }
  }
});
exports.StyledComponent = StyledComponent;