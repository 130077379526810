// @flow

import hash from 'hash-sum';
// $FlowFixMe
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import colors from './colorSchemes';
import Row from './Row';
import Table from './Table';

type ColorSchemes = 'graphite' | 'lavender' | 'sea';
type Data = { label: string, value: number };
type Props = {
  colorScheme?: ColorSchemes,
  data: Data[],
  dataTestId?: string,
  displayPercentage?: boolean,
  hideValue?: boolean,
  placeholder?: boolean,
  placeholderText?: string,
  sortOtherLast?: boolean,
};

const GraphTable = ({
  colorScheme = 'graphite',
  data,
  dataTestId = 'molecule-graph-table',
  displayPercentage = false,
  hideValue = false,
  placeholder = false,
  placeholderText = 'noDataObserved',
  sortOtherLast = false,
}: Props) => {
  const { t } = useTranslation('common');

  const rows = useMemo(() => {
    if (sortOtherLast) {
      const other = data.filter((o) => o.label === 'other');
      const sorted = data.filter((d) => d.label !== 'other').sort((a, b) => b.value - a.value);

      return [...sorted, ...other.filter(Boolean)];
    }

    return data.sort((a, b) => b.value - a.value);
  }, [data, sortOtherLast]);

  const sum = rows.reduce((acc, { value }) => {
    return acc + value;
  }, 0);

  // eslint-disable-next-line no-unused-vars
  const [first, ..._] = rows;

  return (
    <Table data-testid={dataTestId} placeholder={placeholder ? 'true' : undefined} placeholderText={t(placeholderText)}>
      <tbody>
        {rows.map((row) => {
          const percent = Math.max(row.value / first.value, 0.1);
          const label = displayPercentage ? formatPercent(row.value, sum) : row.value;

          return (
            <Row
              hideValue={hideValue}
              key={hash(row)}
              label={t(row.label)}
              percent={percent}
              value={label}
              colorScheme={colors[colorScheme]}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default GraphTable;

const formatPercent = (value, total) => {
  return `${((value / total) * 100).toFixed(1)}%`;
};
