// @flow

import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import Container from './Container';

const H1 = styled.h1`
  font-size: ${rem(96)};
  font-weight: 500;
  position: absolute;
  right: 0;
`;

const H3 = styled.h3`
  font-size: ${rem(32)};
  font-weight: 300;
  letter-spacing: ${rem(-0.8)};
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
`;

const PageNotFound = () => (
  <Container>
    <H1>404</H1>
    <H3>Page not found</H3>
  </Container>
);

export default PageNotFound;
