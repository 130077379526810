import { DonutChart, Stack, Text } from '@a1s/ui';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledValue } from '../../ui';

import { APIData } from '.';

import { useDuration } from 'utils/duration';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface BreakdownChartProps {
  data?: APIData;
}

export default function BreakdownChart({ data }: BreakdownChartProps) {
  const duration = useDuration();
  const searchDetections = useGlobalSearch('detectionSearch');
  const { t } = useTranslation('dashboardHome');

  const phishTotal: number =
    (data?.breakdown?.current?.malicious || 0) +
    (data?.breakdown?.current?.maliciousBec || 0) +
    (data?.breakdown?.current?.spoof || 0) +
    (data?.breakdown?.current?.suspicious || 0);

  const detectionsTotal: number =
    phishTotal + (data?.breakdown?.current?.spam || 0) + (data?.breakdown?.current?.bulk || 0);

  function handleClick(disposition: string) {
    return () => {
      const range = [startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), endOfDay(new Date())];
      searchDetections({ dateRange: [range[0], range[1]], query: disposition });
    };
  }

  return (
    <DonutChart.Loadable
      css={{
        '&:has(path:hover) path:not(:hover)': { fill: '$gray100 !important' },
        '& path': { cursor: 'pointer', transition: 'fill 100ms linear' },
      }}
      height="280"
      thickness="wide"
      width="280"
    >
      <DonutChart.Caption testId="detection-stats-donut-chart">
        <Stack align="center">
          <LabeledValue gap="2" value={detectionsTotal} valueColor="blue500" weight="medium">
            {t('totalDetections')}
          </LabeledValue>
          <Text color="$gray400" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="light">
            {t('lastDays', { key: duration.toLocaleString() })}
          </Text>
        </Stack>
      </DonutChart.Caption>

      <DonutChart.Group>
        <DonutChart.Segment
          color="pink500"
          onClick={handleClick('final_disposition:malicious')}
          value={data?.breakdown?.current?.malicious || 0}
        />
        {data?.breakdown?.current?.maliciousBec && data.breakdown.current.maliciousBec !== 0 ? (
          <DonutChart.Segment
            color="pink450"
            onClick={handleClick('final_disposition:malicious-bec')}
            value={data?.breakdown?.current?.maliciousBec || 0}
          />
        ) : (
          <></>
        )}
        <DonutChart.Segment
          color="pink400"
          onClick={handleClick('final_disposition:suspicious')}
          value={data?.breakdown?.current?.suspicious || 0}
        />
        <DonutChart.Segment
          color="pink300"
          onClick={handleClick('final_disposition:spoof')}
          value={data?.breakdown?.current?.spoof || 0}
        />
      </DonutChart.Group>

      <DonutChart.Segment
        color="teal650"
        onClick={handleClick('final_disposition:spam')}
        value={data?.breakdown?.current?.spam || 0}
      />
      <DonutChart.Segment
        color="teal400"
        onClick={handleClick('final_disposition:bulk')}
        value={data?.breakdown?.current?.bulk || 0}
      />
    </DonutChart.Loadable>
  );
}
