import { Button, Cluster } from '@a1s/ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FPReportParams } from '../../types';

import FalsePositiveDialog from '../ReportLinkButton/FalsePositiveDialog';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface ReportButtonProps {
  disabled?: boolean;
  reportParams: FPReportParams[];
}

export function ReportButton({ disabled = false, reportParams }: ReportButtonProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation('unisearch');

  function handleClose() {
    setOpenDialog(false);
  }

  async function handlePress() {
    setOpenDialog(true);
  }

  return (
    <>
      <Button appearance="faded" disabled={disabled} onPress={handlePress}>
        <Cluster as="span" gap="1">
          {t('reportAsFP')}
          {reportParams.length > 1 && ` (${reportParams.length})`}
        </Cluster>
      </Button>
      <FalsePositiveDialog data={reportParams} onClose={handleClose} visible={openDialog} />
    </>
  );
}
