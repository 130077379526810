import { Box, Cluster, PercentageChange, SegmentChart, Sidebar, Stack, Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Searchable } from '../../../shared/Searchable';
import { ColorBlock, LabeledValue } from '../../ui';

import { APIData, Duration } from '.';

import ConditionalRender from 'ui/atoms/ConditionalRender';

//
// Main components
// -------------------------------------------------------------------------------------------------

interface DataBreakdownProps {
  data?: APIData;
  duration: Duration;
}

export default function DataDisplay({ data, duration }: DataBreakdownProps) {
  const displayPercentChange =
    data?.timeline.current.length === parseInt(duration) && data?.timeline.previous.length === parseInt(duration);

  const maliciousBecValues = {
    current: data?.breakdown?.current?.maliciousBec || 0,
    previous: data?.breakdown?.previous?.maliciousBec || 0,
  };
  const maliciousValues = {
    current: data?.breakdown?.current?.malicious || 0,
    previous: data?.breakdown?.previous?.malicious || 0,
  };
  const spoofValues = {
    current: data?.breakdown?.current?.spoof || 0,
    previous: data?.breakdown?.previous?.spoof || 0,
  };
  const suspiciousValues = {
    current: data?.breakdown?.current?.suspicious || 0,
    previous: data?.breakdown?.previous?.suspicious || 0,
  };

  const totalProcessValues = { current: data?.totals?.current || 0, previous: data?.totals?.previous || 0 };

  return (
    <Box bg="$gray100" p="4" rl>
      <Stack css={{ [`& button`]: { transform: 'translateX(calc(0px - $space$1-5))' } }} gap>
        <TotalEmailProcessedData displayPercentChange={displayPercentChange} values={totalProcessValues} />

        <TotalPhishData
          displayPercentChange={displayPercentChange}
          maliciousBecValues={maliciousBecValues}
          maliciousValues={maliciousValues}
          spoofValues={spoofValues}
          suspiciousValues={suspiciousValues}
          total={totalProcessValues.current}
        />
        <SpamData
          displayPercentChange={displayPercentChange}
          total={totalProcessValues.current}
          values={{ current: data?.breakdown?.current?.spam || 0, previous: data?.breakdown?.previous?.spam || 0 }}
        />
        <BulkData
          displayPercentChange={displayPercentChange}
          total={totalProcessValues.current}
          values={{ current: data?.breakdown?.current?.bulk || 0, previous: data?.breakdown?.previous?.bulk || 0 }}
        />
      </Stack>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ValueSet {
  current: number;
  previous: number;
}

interface BulkDataProps {
  displayPercentChange: boolean;
  total: number;
  values: ValueSet;
}

function BulkData({ displayPercentChange, total, values: { current, previous } }: BulkDataProps) {
  const percent = calculatePercent(current, total);

  const { t } = useTranslation('dashboardHome');

  return (
    <Sidebar gap>
      <Box pt="1">
        <ColorBlock borderColor="$white" color="$teal400" />
      </Box>

      <Cluster align="center" justify="space-between">
        <Searchable
          params={{
            disposition: 'bulk',
          }}
        >
          <LabeledValue value={current.toLocaleString()}>{t('bulk')}</LabeledValue>
        </Searchable>
        <Box pr="2">
          <Cluster gap="5" justify="space-between">
            <Text.Loadable
              color="$gray500"
              as="data"
              font="sans"
              placeholder="0%"
              size="sm"
              stretch="ultraCondensed"
              transform="uppercase"
              weight="light"
            >
              {Math.ceil(percent)}%
            </Text.Loadable>
            <ConditionalRender condition={displayPercentChange}>
              <PercentageChange.Calculate.Loadable
                color={current === previous ? 'gray400' : 'gray500'}
                current={current}
                previous={previous}
              />
            </ConditionalRender>
          </Cluster>
        </Box>
      </Cluster>
    </Sidebar>
  );
}

interface SpamDataProps {
  displayPercentChange: boolean;
  total: number;
  values: ValueSet;
}

function SpamData({ displayPercentChange, total, values: { current, previous } }: SpamDataProps) {
  const percent = calculatePercent(current, total);
  const { t } = useTranslation('dashboardHome');

  return (
    <Sidebar gap>
      <Box pt="1">
        <ColorBlock borderColor="$white" color="$teal650" />
      </Box>

      <Cluster align="center" justify="space-between">
        <Searchable
          params={{
            disposition: 'spam',
          }}
        >
          <LabeledValue value={current.toLocaleString()}>{t('spam')}</LabeledValue>
        </Searchable>
        <Box pr="2">
          <Cluster gap="5" justify="space-between">
            <Text.Loadable
              color="$gray500"
              as="data"
              font="sans"
              placeholder="0%"
              size="sm"
              stretch="ultraCondensed"
              transform="uppercase"
              weight="light"
            >
              {Math.ceil(percent)}%
            </Text.Loadable>
            <ConditionalRender condition={displayPercentChange}>
              <PercentageChange.Calculate.Loadable
                color={current === previous ? 'gray400' : 'gray500'}
                current={current}
                previous={previous}
              />
            </ConditionalRender>
          </Cluster>
        </Box>
      </Cluster>
    </Sidebar>
  );
}

interface TotalEmailProcessedDataProps {
  displayPercentChange: boolean;
  values: ValueSet;
}

function TotalEmailProcessedData({
  displayPercentChange,
  values: { current, previous },
}: TotalEmailProcessedDataProps) {
  const { t } = useTranslation('dashboardHome');

  return (
    <Cluster align="center" gap justify="space-between" testId="total-email-processed">
      <LabeledValue value={current.toLocaleString()} weight="semibold">
        {t('totalEmailProcessed')}
      </LabeledValue>

      <ConditionalRender condition={displayPercentChange}>
        <Box pr="2">
          <PercentageChange.Calculate.Loadable
            color={current === previous ? 'gray400' : 'gray500'}
            current={current}
            previous={previous}
          />
        </Box>
      </ConditionalRender>
    </Cluster>
  );
}

interface TotalPhishDataProps {
  displayPercentChange: boolean;
  maliciousBecValues: ValueSet;
  maliciousValues: ValueSet;
  spoofValues: ValueSet;
  suspiciousValues: ValueSet;
  total: number;
}

function TotalPhishData({
  displayPercentChange,
  maliciousBecValues,
  maliciousValues,
  spoofValues,
  suspiciousValues,
  total,
}: TotalPhishDataProps) {
  const currentSum =
    maliciousBecValues.current + maliciousValues.current + spoofValues.current + suspiciousValues.current;
  const previousSum =
    maliciousBecValues.previous + maliciousValues.previous + spoofValues.previous + suspiciousValues.previous;

  const percent = calculatePercent(currentSum, total);

  const { t } = useTranslation('dashboardHome');

  return (
    <Sidebar gap>
      <Box pt="2">
        <ColorBlock borderColor="$white" color="$pink400" />
      </Box>

      <Box bg="$white" p="2" r testId="total-phish-data">
        <Stack gap>
          <Cluster align="center" justify="space-between">
            <LabeledValue childrenColor="$pink500" value={currentSum} valueColor="$pink300" weight="medium">
              {t('totalPhish')}
            </LabeledValue>

            <Cluster gap="5" justify="space-between">
              <Text.Loadable
                color="$pink500"
                as="data"
                font="sans"
                placeholder="0%"
                size="sm"
                stretch="ultraCondensed"
                transform="uppercase"
                weight="light"
              >
                {Math.ceil(percent)}%
              </Text.Loadable>
              <ConditionalRender condition={displayPercentChange}>
                <PercentageChange.Calculate
                  color={currentSum === previousSum ? 'gray400' : 'gray500'}
                  current={currentSum}
                  previous={previousSum}
                />
              </ConditionalRender>
            </Cluster>
          </Cluster>

          <SegmentChart.Loadable placeholderCount="3" width="12">
            <SegmentChart.Segment color="pink500" value={maliciousValues.current}>
              <Searchable
                params={{
                  disposition: 'malicious',
                }}
              >
                <LabeledValue value={maliciousValues.current}>{t('malicious')}</LabeledValue>
              </Searchable>
            </SegmentChart.Segment>
            {maliciousBecValues.current === 0 ? (
              <></>
            ) : (
              <SegmentChart.Segment color="pink450" value={maliciousBecValues.current}>
                <Searchable
                  params={{
                    disposition: 'malicious-bec',
                  }}
                >
                  <LabeledValue value={maliciousBecValues.current}>{t('maliciousBec')}</LabeledValue>
                </Searchable>
              </SegmentChart.Segment>
            )}
            <SegmentChart.Segment color="pink400" value={suspiciousValues.current}>
              <Searchable
                params={{
                  disposition: 'suspicious',
                }}
              >
                <LabeledValue value={suspiciousValues.current}>{t('suspicious')}</LabeledValue>
              </Searchable>
            </SegmentChart.Segment>
            <SegmentChart.Segment color="pink300" value={spoofValues.current}>
              <Searchable
                params={{
                  disposition: 'spoof',
                }}
              >
                <LabeledValue value={spoofValues.current}>{t('spoof')}</LabeledValue>
              </Searchable>
            </SegmentChart.Segment>
          </SegmentChart.Loadable>
        </Stack>
      </Box>
    </Sidebar>
  );
}

function calculatePercent(current: number, total: number) {
  if (total === 0) return 0;
  return (current / total) * 100;
}
