// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { suspiciousTargetsRecord, suspiciousTargetsQuery } from './dataTypesAndUtils';

import TopTargetedRecipients from './TopTargetedRecipients';

import { DaysBackContext } from 'screens/App';

export default function SuspiciousTargetsOverview() {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');
  const { data, error, loading } = useQuery(suspiciousTargetsQuery, { variables: { currentInterval } });
  const { suspiciousTargetedRecipients } = get(data, 'suspiciousTargets', suspiciousTargetsRecord);

  return (
    <>
      <Helmet title={`${t('targets')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <TopTargetedRecipients data={suspiciousTargetedRecipients} error={error} loading={loading} />
    </>
  );
}
