// @flow

import { loader } from 'graphql.macro';
import { startCase } from 'lodash';
import { type TFunction } from 'react-i18next';
import styled from 'styled-components';

export const DetectionsMaliciousLinksQuery = loader('./queries/MaliciousLinksQuery.graphql');

export const StyledSpan = styled.span`
  font-family: 'roboto mono', monospace;
  margin-left: -7px;
`;

export type MaliciousLinkCountsByDate = { date: number, keys: string[], values: number[] };

export type PreviousMaliciousLinkCountsByDate = { date: number, keys: string[], values: number[] };

export type MaliciousLinkCountsByDataType = {
  count: number,
  threatCategory: string,
};

type GraphTableReturnType = {
  label: string,
  value: number,
};

type LineGraphType = {
  day: string,
  total: number,
  totals: Array<MaliciousLinkCountsByDataType>,
};

type LineGraphReturnType = {
  date: number,
  value: number,
};

export const defaultLineGraphData = {
  current: [{ day: '', total: 0, totals: [{ count: 0, threatCategory: '' }] }],
  previous: [{ day: '', total: 0, totals: [{ count: 0, threatCategory: '' }] }],
};

export function dataForGraphTable(
  data: Array<MaliciousLinkCountsByDataType>,
  t: TFunction
): Array<GraphTableReturnType> {
  return data.map((row) => ({ label: t(`threatTypes:${startCase(row.threatCategory)}`), value: row.count }));
}

export function mungeLineGraphData(data: Array<LineGraphType>): Array<LineGraphReturnType> {
  return [...data]
    .sort((a, b) => new Date(a.day) - new Date(b.day))
    .map((row) => ({ date: new Date(row.day), value: row.total }));
}
