// @flow

import { addDays } from 'date-fns';

import { startCase } from 'lodash';

type GraphTableReturnType = {
  label: string,
  value: number,
};

type LineGraphReturnType = {
  date: number,
  value: number,
};

type LineGraphType = {
  day: Date,
  total: number,
  totals: Object[],
};

export type InsightsNameSpoofList = { count: number, name: string };

export type InsightsNameSpoofType = {
  topNames: Array<InsightsNameSpoofList>,
  topTargets: Array<InsightsNameSpoofList>,
};

export type NameSpoofCountsByDate = { day: string, total: number, totals: Object[] };

export const defaultNameSpoofLineGraphData: Array<LineGraphType> = [
  {
    day: new Date('01/01/2020'),
    total: 0,
    totals: [
      { org_spoof: 'classic_spoof', count: 0 },
      { org_spoof: 'name_spoof', count: 0 },
    ],
  },
];

export const insightsNameSpoofRecord: InsightsNameSpoofType = {
  topNames: [{ count: 0, name: '' }],
  topTargets: [{ count: 0, name: '' }],
};

export const placeholderData = [
  { label: 'Alma Ballard', value: 9 },
  { label: 'Helena Martinez', value: 12 },
  { label: 'Kenneth Taylor', value: 3 },
  { label: 'Timothy Rodriguez', value: 23 },
  { label: 'Estelle Pena', value: 1 },
];

export function dataForGraphTableTargets(data: InsightsNameSpoofList[]): Array<GraphTableReturnType> {
  return data.map((row) => ({ label: row.name, value: row.count }));
}

export function dataForGraphTableUsers(data: InsightsNameSpoofList[]): Array<GraphTableReturnType> {
  return data.map((row) => ({ label: startCase(row.name), value: row.count }));
}

export function mungeLineGraphDataNameSpoof(data: Array<LineGraphType>): Array<LineGraphReturnType> {
  return data.map((row) => ({
    date: addDays(new Date(row.day), 1),
    value: row.totals.find((el) => el.orgSpoof === 'name_spoof')?.count || 0,
  }));
}
