import { styled } from '@a1s/ui';

export const Container = styled('div', {
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 998,
});
