"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDimensions = useDimensions;
var _react = require("react");
/**
 * Represents the dimensions of an HTML element.
 */

/**
 * Returns the size of the element within the current value of the given `ref` as a stateful value.
 * The stateful value is updated when the element is resized.
 * @deprecated
 */
function useDimensions(ref) {
  const [dimensions, setDimensions] = (0, _react.useState)({
    height: 0,
    width: 0
  });
  const observer = (0, _react.useRef)(new ResizeObserver(entries => {
    if (entries[0]) {
      window.requestAnimationFrame(() => {
        const {
          height,
          width
        } = entries[0].contentRect;
        setDimensions({
          height: Math.round(height),
          width: Math.round(width)
        });
      });
    }
  }));
  (0, _react.useEffect)(() => {
    const {
      current: obs
    } = observer;
    if (ref.current) obs.observe(ref.current);
    return () => obs.disconnect();
  }, [ref.current]);
  return dimensions;
}