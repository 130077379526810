// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Text = styled.div`
  align-items: center;
  display: flex;
  height: ${rem(136)};
  flex-direction: column;
  justify-content: center;
  margin-top: ${rem(-136)};
  width: ${rem(136)};
`;

export default Text;
