// @flow

import { random } from '@a1s/lib';

import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useCallback, useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled(animated.div).attrs({ role: 'alert' })`
  background-color: ${(p) => {
    if (p.variant === 'fail') return p.theme.colors.pastelRed;
    if (p.variant === 'warning') return p.theme.colors.neonCarrot;
    return p.theme.colors.green;
  }};
  border-radius: ${rem(4)};
  bottom: ${(p) => rem(p.theme.spacing.lg)};
  box-shadow: ${(p) => rgba(p.theme.colors.jet, 0.1)} 0 ${rem(2)} ${rem(4)};
  color: ${(p) => p.theme.colors.white};
  cursor: default;
  display: inline-block;
  font-size: ${rem(13)};
  font-weight: 500;
  left: 90%;
  max-width: 50%;
  padding: ${(p) => rem(p.theme.spacing.md)};
  position: sticky;
  z-index: 999;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  children?: string;
  onDismiss?: Function;
  open?: boolean;
  variant?: 'fail' | 'success' | 'warning';
}

export default function Toast({ children, onDismiss, open = true, variant = 'success' }: Props) {
  const [isOpen, setIsOpen] = useState(open);

  const transitions = useTransition(isOpen, null, {
    config: { tension: 500, friction: 35, mass: 1 },
    from: { opacity: 0, transform: 'translateY(-100%)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-100%)' },
  });

  const handleClose = useCallback(() => {
    setIsOpen(false);

    setTimeout(() => {
      if (onDismiss) onDismiss();
    }, 1000);
  }, [onDismiss]);

  useEffect(() => {
    setIsOpen(open);

    const timeout = setTimeout(() => {
      handleClose();
    }, 10000);
    return () => clearTimeout(timeout);
  }, [handleClose, open]);

  return transitions.map(
    ({ item, props: style }) =>
      item && (
        <Container key={`id${random()}`} style={style} onClick={handleClose} variant={variant}>
          {children}
        </Container>
      )
  );
}
