"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWindowSize = useWindowSize;
var _react = require("react");
/**
 * Represents the inner and outer diminsions of the browser's window.
 */

/**
 * Returns the size of the browser window as a stateful value. The stateful value is updated when the browser window
 * is resized.
 */
function useWindowSize() {
  const [size, setSize] = (0, _react.useState)({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth
  });
  function handleResize() {
    setSize({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      outerHeight: window.outerHeight,
      outerWidth: window.outerWidth
    });
  }
  (0, _react.useLayoutEffect)(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
}