// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import styled from 'styled-components';

import ContentBlock from 'ui/molecules/ContentBlock';

const ContentContainer = styled.div`
  width: ${rem(450)};
`;

type Props = { children: Node };

const Container = ({ children }: Props) => <ContentBlock top={<ContentContainer>{children}</ContentContainer>} />;

export default Container;
