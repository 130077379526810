// @flow

import styled from 'styled-components';

const Right = styled.div.attrs({ 'data-testid': 'atom-right' })`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`;

Right.displayName = 'Right';

export default Right;
