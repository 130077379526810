import { useFeatureFlag } from '@marshall/hooks';
import React, { createContext, useContext, PropsWithChildren } from 'react';

import useEndpointValue from './hooks/useEndpointValue';

import useAccessControl, { permissionTypes, PermissionsType } from 'utils/hooks/useAccessControl';

//
// Context set up
// -------------------------------------------------------------------------------------------------
interface ContextType {
  clawbackEnabled: boolean;
  userPermitted: boolean;
  retractEnabled: boolean;
}

const Context = createContext<ContextType>({
  clawbackEnabled: false,
  retractEnabled: false,
  userPermitted: false,
});

//
// Provider component
// -------------------------------------------------------------------------------------------------

export function SearchContextProvider({ children }: PropsWithChildren<{}>) {
  const { ADMIN, READ_WRITE } = permissionTypes;
  const { permissions } = useAccessControl([ADMIN, READ_WRITE] as PermissionsType[]);
  const [adminPermitted, readWritePermitted] = permissions as boolean[];
  const retractEnabled = useEndpointValue<boolean>('/api/horizonator/retraction_check', 'authorized', false);
  const clawbackEnabled = useFeatureFlag('clawback');

  const context = {
    clawbackEnabled,
    retractEnabled,
    userPermitted: adminPermitted || readWritePermitted,
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

//
// Hooks
// -------------------------------------------------------------------------------------------------

export function useSearchContext() {
  return useContext(Context);
}
