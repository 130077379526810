import { StackedBarChart, StackedBarChartProps } from '@a1s/ui';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIData } from '.';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface TimelineChartProps {
  data?: APIData;
  width?: number;
}

export default function TimelineChart({ data, width = 315 }: TimelineChartProps) {
  return (
    <StackedBarChart.Loadable
      data={data?.timeline?.current as StackedBarChartProps['data']}
      height="240"
      testId="detection-stats-stacked-bar-chart"
      LabelDisplay={DateDisplay}
      width={String(width)}
    />
  );
}

function DateDisplay({ label, value }: { value: Date; label: string }) {
  const { t } = useTranslation('dashboardHome');

  return label === format(new Date(), 'yyyy-MM-dd') ? (
    <tspan style={{ textTransform: 'uppercase' }}>{t('today')}</tspan>
  ) : (
    <tspan style={{ textTransform: 'uppercase' }}>{t('monthDate', { date: value })}</tspan>
  );
}
