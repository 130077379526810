// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

import Stack from 'ui/templates/Stack';

export default styled(Stack)`
  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;

      & > :first-child {
        margin-bottom: ${({ theme }) => rem(theme.spacing.md)};
      }
    `};
`;
