import { Box, Button, Cluster, Stack, Text } from '@a1s/ui';
import copy from 'copy-to-clipboard';
import { format, parseISO } from 'date-fns';
import React, { ComponentProps, MouseEventHandler, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckSVG } from './check.svg';
import { ReactComponent as Icon } from './copy.svg';
import { ReactComponent as ErrorSVG } from './error.svg';
import { ReactComponent as NullSVG } from './na.svg';
import { Container, CopyButton, StyledLink, StyledList, StyledSpan, ThreatTypeLink } from './styled';
import { ReactComponent as TriangleSVG } from './triangle.svg';
import Designation from './ui/Designation';

import { matchColorAndDisposition } from 'common';
import { canMarkAsFalsePositive } from 'screens/Search/lib';
import { useSearchContext } from 'screens/Search/lib/searchContext';
import {
  HighlightDataType as MailviewHighlightData,
  HighlightDataLinkType,
  SearchResultRow,
  ValidationType,
  RedressOperationTypes,
} from 'screens/Search/types';
import { Badge, Details, IconBackground, ReportLinkButton } from 'screens/Search/ui';
import { matchColorAndStatus, threatToPascalCase } from 'screens/Search/ui/common';
import { defang } from 'screens/shared/dataTypeAndUtils';
import { Dialog, InlineTable, Loader } from 'ui-new';
import MarkedDown from 'ui/molecules/MarkedDown';

//
// Main container
// -------------------------------------------------------------------------------------------------

interface Props {
  canReport?: boolean;
  isBenign?: boolean;
  loading: boolean;
  mailviewData?: MailviewHighlightData;
  searchInfo?: SearchResultRow;
}

export function DetailMetadata({ canReport = true, isBenign, loading, mailviewData, searchInfo }: Props) {
  const { userPermitted } = useSearchContext();
  const { t } = useTranslation('unisearch');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');

  const links = mailviewData?.links || [{ text: '-', href: '-' }];
  const senderInfo = mailviewData?.senderInfo || {
    ip: '_',
    asName: '_',
    asNumber: 0,
    geo: '_',
    pld: '_',
  };
  const { ip, asName, asNumber, geo, pld } = senderInfo;
  const unformattedSysNumber = asNumber && asNumber.toString().split(',').join();

  const threatCategories = mailviewData?.threatCategories || [];

  const validation = mailviewData?.validation || { dkim: 'none', dmarc: 'none', spf: 'none' };
  const { dkim, dmarc, spf } = validation;

  const {
    clientRecipients,
    finalDisposition = 'NONE',
    findings,
    from,
    fromName,
    isQuarantined,
    messageId,
    properties,
    redressedActions = [],
    subject,
    ts,
  } = searchInfo || {};

  const operations = redressedActions.reduce((acc, action) => {
    const total = (acc[action.operation] || 0) + 1;
    return { ...acc, [action.operation]: total };
  }, {} as Record<string, number>);

  function handleOpenDetailWindow(status: string) {
    setDialogOpen(true);
    setDialogTitle(status);
  }

  function handleCloseDetailWindow() {
    setDialogOpen(false);
  }

  const isAllowListed = () => {
    // Message is designated benign if whitelistedMessage is true OR whitelistedPattern and whitelistedPatternType are not blank
    return properties?.whitelistedMessage || (!!properties?.whitelistedPattern && !!properties?.whitelistedPatternType);
  };

  const isBlockListed = () => {
    // Allowed takes priority over blocked (https://jira.cfdata.org/browse/AREA1-3436?focusedId=3767109&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-3767109)
    if (isAllowListed()) return false;
    // Blocked messages are detected differently than allowed messages. A message is "blocked" if the `findings` array contains an object with the name "blacklisted_pattern".
    return findings?.some((finding: any) => finding?.name === 'blacklisted_pattern');
  };

  let designation: ComponentProps<typeof Designation>['type'];
  if (isAllowListed()) designation = 'allowed';
  if (isBlockListed()) designation = 'blocked';

  if (loading) return <Loader />;

  return (
    <Stack gap="8">
      <InlineTable css={{ width: 'fit-content' }}>
        <tbody>
          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('from')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{`${fromName || ''} <${from}>`}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell colSpan={2} css={{ height: '$space$3' }} />
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('subject')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{subject}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('date')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{formatTimestamp(t('const:formats.dateLongWithTimezone'), ts)}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('to')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <CellText>{clientRecipients?.join(', ') || t('undisclosedRecipients')}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>

          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('messageId')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell allowBreaks>
              <CellText>{messageId}</CellText>
            </InlineTable.Cell>
          </InlineTable.Row>
        </tbody>
      </InlineTable>

      <InlineTable css={{ width: 'fit-content' }} gap="0 $space$4">
        <tbody>
          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('disposition')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell css={{ paddingTop: '$1-5' }}>
              <Cluster gap>
                <Stack gap>
                  <Badge color={matchColorAndDisposition(finalDisposition)}>{t(finalDisposition)}</Badge>
                  {designation ? <Designation type={designation} /> : null}
                </Stack>
                {canReport && searchInfo && userPermitted && (
                  <ReportLinkButton
                    data={searchInfo}
                    kind={canMarkAsFalsePositive(finalDisposition) ? 'false-positive' : 'false-negative'}
                  />
                )}
              </Cluster>
            </InlineTable.Cell>
          </InlineTable.Row>
          <InlineTable.Row>
            <InlineTable.Cell>
              <HeaderText>{t('status')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <Cluster css={{ paddingTop: '$1-5' }} gap="2">
                <Badge color={matchColorAndStatus(isQuarantined)}>
                  {isQuarantined ? t('quarantined') : t('delivered')}
                </Badge>
                {Object.entries(operations).map(([operation, total]) => (
                  <Badge color="gray500">{`${t(operation)} (${total})`}</Badge>
                ))}
              </Cluster>
            </InlineTable.Cell>
          </InlineTable.Row>
          <InlineTable.Row>
            <InlineTable.Cell preventBreaks>
              <HeaderText>{t('threatType')}</HeaderText>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <Cluster gap="1">
                <RenderThreatCategories threatCategories={threatCategories} />
              </Cluster>
            </InlineTable.Cell>
          </InlineTable.Row>

          {isBenign ? null : (
            <InlineTable.Row>
              <InlineTable.Cell>
                <HeaderText>{t('validation')}</HeaderText>
              </InlineTable.Cell>
              <InlineTable.Cell valign="bottom">
                <Cluster gap="8" align="center">
                  <MatchValidationIcon label="SPF" val={spf} />
                  <MatchValidationIcon label="DKIM" val={dkim} />
                  <MatchValidationIcon label="DMARC" val={dmarc} />
                </Cluster>
              </InlineTable.Cell>
            </InlineTable.Row>
          )}
        </tbody>
      </InlineTable>

      {isBenign ? null : (
        <Details title={t('detectionDetails:senderDetails')}>
          <InlineTable>
            <tbody>
              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('ipAddress')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="xxx.xxx.xxx.xxx" {...textProps}>
                    {ip}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>

              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('country')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="-/-/-" {...textProps}>
                    {geo}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>

              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('regDomain')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="www.domain.com" {...textProps}>
                    {pld}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>
            </tbody>
          </InlineTable>
          <InlineTable gap="0 $space$4">
            <tbody>
              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('autonomousSysName')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="NAME" {...textProps}>
                    {asName}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>
              <InlineTable.Row>
                <InlineTable.Cell>
                  <HeaderText>{t('autonomousSysNumber')}</HeaderText>
                </InlineTable.Cell>
                <InlineTable.Cell>
                  <Text.Loadable loading={loading} placeholder="xxxxx" {...textProps}>
                    {unformattedSysNumber}
                  </Text.Loadable>
                </InlineTable.Cell>
              </InlineTable.Row>
            </tbody>
          </InlineTable>
        </Details>
      )}

      {isBenign || !links || links.length === 0 ? null : (
        <Details title={t('email:links')}>
          <InlineTable gap="0 $space$4">
            <tbody>
              <InlineTable.Row>
                <InlineTable.Cell css={{ wordBreak: 'break-word' }}>
                  <StyledList>
                    <RenderLinks links={links} loading={loading} textProps={textProps} />
                  </StyledList>
                </InlineTable.Cell>
              </InlineTable.Row>
            </tbody>
          </InlineTable>
        </Details>
      )}

      {redressedActions?.length > 0 && (
        <Details title={t('Action Log')}>
          <InlineTable gap="0 $space$2">
            <InlineTable.Row
              css={{
                backgroundColor: '$gray100',
                borderRadius: '$4',
                maxHeight: '100%',
                maxWidth: 300,
              }}
            >
              {getActionLogs(redressedActions, t('const:formats.dateLongTime')).map((action: ActionType) => (
                <InlineTable.Row>
                  <InlineTable.Cell css={{ fontSize: '0.75em', fontWeight: 600, paddingLeft: '0.5em' }}>
                    {action.completedTimestamp}
                  </InlineTable.Cell>
                  <InlineTable.Cell css={{ fontSize: '0.75em', fontWeight: 400 }}>
                    <ActionLog action={action} handleOpenDetailWindow={handleOpenDetailWindow} />
                  </InlineTable.Cell>
                </InlineTable.Row>
              ))}
            </InlineTable.Row>
          </InlineTable>
        </Details>
      )}
      {threatCategories?.map((threat) => {
        return (
          <Details id={threat.value} title={threatToPascalCase(threat.value)}>
            <InlineTable gap="0 $space$2">
              <InlineTable.Row>
                <InlineTable.Cell>
                  <MarkedDown>{threat.description || t('noDescription')}</MarkedDown>
                </InlineTable.Cell>
              </InlineTable.Row>
            </InlineTable>
          </Details>
        );
      })}
      <Dialog onClose={handleCloseDetailWindow} visible={dialogOpen}>
        <Box css={{ minHeight: 200, minWidth: 600, position: 'relative' }}>
          <Stack>
            <Text.Roboto
              size="md"
              stretch="ultraCondensed"
              transform="uppercase"
              css={{
                color: '$gray600',
                flexGrow: 1,
                margin: '1rem',
                padding: '1rem',
              }}
              weight="medium"
            >
              {dialogTitle}
            </Text.Roboto>

            <Box css={{ bottom: '1rem', position: 'fixed', right: '1rem' }}>
              <Button onPress={handleCloseDetailWindow}>Close</Button>
            </Box>
          </Stack>
        </Box>
      </Dialog>
    </Stack>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function formatTimestamp(dateFormat: string, timestamp?: string) {
  if (!timestamp) return null;

  return format(parseISO(timestamp), dateFormat);
}

function getActionLogs(actionsArr: SearchResultRow['redressedActions'] = [], dateFormat: string) {
  return actionsArr.map((action) => ({
    completedTimestamp: formatTimestamp(dateFormat, action.completedTimestamp),
    folder: action.properties.folder,
    operation: action.operation,
    requestedBy: action.properties.requestedBy,
    status: action.status,
  }));
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ActionType {
  completedTimestamp: string | null;
  folder: string;
  operation: RedressOperationTypes;
  requestedBy: string;
  status: string;
}

interface ActionLogProps {
  action: ActionType;
  handleOpenDetailWindow: Function;
}

function ActionLog({ action, handleOpenDetailWindow }: ActionLogProps) {
  const { t } = useTranslation('unisearch');

  const { folder, operation, requestedBy, status } = action;

  function handleOpenDetails() {
    return handleOpenDetailWindow(action.status);
  }

  if (!folder || !requestedBy) {
    return (
      <>
        <span>{t(operation)}</span>
        <LearnMore handleClick={handleOpenDetails} statusCode={status} />
      </>
    );
  }

  if (operation === 'RETRACTION') {
    return (
      <>
        <span>
          {t(operation)} {t('from')} {folder} {t('by')} {requestedBy}
        </span>
        <LearnMore handleClick={handleOpenDetails} statusCode={status} />
      </>
    );
  }

  return (
    <>
      <span>
        {t(operation)} {t('by')} {requestedBy}
      </span>
      <LearnMore handleClick={handleOpenDetails} statusCode={status} />
    </>
  );
}

const textProps = { color: '$gray600', font: 'sans', size: 'sm', stretch: 'ultraCondensed' } as const;
const headerTextProps = { ...textProps, transform: 'uppercase', weight: 'semibold' } as const;

function CellText({ children }: PropsWithChildren<{}>) {
  return <Text {...textProps}>{children}</Text>;
}

function HeaderText({ children }: PropsWithChildren<{}>) {
  return (
    <Text {...headerTextProps} css={{ paddingRight: '$3' }}>
      {children}
    </Text>
  );
}

interface LearnMoreProps {
  handleClick: MouseEventHandler<HTMLAnchorElement>;
  statusCode: string;
}

function LearnMore({ handleClick, statusCode }: LearnMoreProps) {
  const { t } = useTranslation('unisearch');
  const messageStatus = statusCode === 'OK' || statusCode === 'SUCCESS' ? t('ok') : t('fail');
  return (
    <StyledLink href="#" onClick={handleClick}>
      <StyledSpan color={statusCode === 'OK' || statusCode === 'SUCCESS' ? 'green' : 'red'}>
        {' '}
        [{messageStatus}] {t('learnMore')}
      </StyledSpan>
    </StyledLink>
  );
}

interface MatchValidationIconProps {
  label: 'DKIM' | 'DMARC' | 'SPF';
  val: ValidationType['dkim'] | ValidationType['dmarc'] | ValidationType['spf'] | string;
}

function MatchValidationIcon({ label, val }: MatchValidationIconProps) {
  const { t } = useTranslation('unisearch');

  if (val === null) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="#CCCCCC">
          <NullSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('na')}
        </Text.Title>
      </Cluster>
    );
  }
  if (['pass', 'success'].includes(val?.toLowerCase())) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="$green300">
          <CheckSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('passed')}
        </Text.Title>
      </Cluster>
    );
  }

  if (['error', 'fail', 'permerror', 'permfail', 'softfail', 'temperror', 'tempfail'].includes(val.toLowerCase())) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="$red300">
          <ErrorSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('failed')}
        </Text.Title>
      </Cluster>
    );
  }

  if (['mixed', 'neutral', 'none'].includes(val?.toLowerCase())) {
    return (
      <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
        <IconBackground color="$orange300">
          <TriangleSVG />
        </IconBackground>
        <Text
          color="$gray600"
          css={{ fontWeight: '700' }}
          font="sans"
          size="sm"
          stretch="ultraCondensed"
          transform="uppercase"
        >
          {label}
        </Text>
        <Text.Title css={{ color: '$gray200' }} placeholder="-" {...textProps}>
          {t('none')}
        </Text.Title>
      </Cluster>
    );
  }

  return (
    <Cluster align="center" css={{ flexFlow: 'nowrap' }} gap="2">
      <IconBackground color="$orange300">
        <TriangleSVG />
      </IconBackground>
      <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
        {label}
      </Text>
      <Text.Title css={{ color: 'gray200' }} placeholder="-" {...textProps}>
        -
      </Text.Title>
    </Cluster>
  );
}

interface RenderThreatCategoriesProps {
  threatCategories: MailviewHighlightData['threatCategories'];
}

function RenderThreatCategories({ threatCategories }: RenderThreatCategoriesProps) {
  const { t } = useTranslation('unisearch');

  if (threatCategories?.length === 0) return <Badge color="gray300">{t('none')}</Badge>;

  return (
    <>
      {threatCategories?.map((threat) => (
        <ThreatTypeLink href={`#${threat.value}`} key={threat.value}>
          <Badge color="gray500" key={threat.value}>
            {threatToPascalCase(threat.value)}
          </Badge>
        </ThreatTypeLink>
      ))}
    </>
  );
}

interface LinksProps {
  links?: HighlightDataLinkType[];
  loading: boolean;
  textProps: { [key: string]: string };
}

function RenderLinks({ links = [], loading, textProps: props }: LinksProps) {
  return (
    <>
      {links?.map((link) => (
        <li key={link.href}>
          <Text.Loadable loading={loading} placeholder="https://link.com" weight="medium" {...props}>
            {defang(link.href)}
          </Text.Loadable>
          {!loading && <Copy text={defang(link.href)} />}
        </li>
      ))}
    </>
  );
}

interface CopyProps {
  text: string;
}

function Copy({ text }: CopyProps) {
  const [copiedText, setCopiedText] = useState(false);
  const { t } = useTranslation('unisearch');

  const copyToClipboard =
    (link: string): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      event.preventDefault();
      copy(link);
      setCopiedText(true);
      setTimeout(() => setCopiedText(false), 5000);
    };

  return (
    <CopyButton onClick={copyToClipboard(text)} type="button">
      <Container>
        <Icon />
        {copiedText && <Text.Lato weight="light">{t('linkCopied')}</Text.Lato>}
      </Container>
    </CopyButton>
  );
}
