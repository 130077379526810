// @flow

import moment from 'moment';

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import FormatDate from '../FormatDate';

import ConditionalRender from 'ui/atoms/ConditionalRender';

const StyledTimeDiv = styled.time`
  display: flex;
  flex-flow: row wrap;
  font: ${(p) => p.theme.colors.raisinBlack};
  word-wrap: break-word;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ smaller }) =>
    smaller &&
    css`
      font-size: 80%;
    `}
`;

const Time = styled.div`
  word-wrap: normal;
`;

type Props = {
  bold?: boolean,
  date: Date | number | string,
  displayTime?: boolean,
  smaller?: boolean,
  utc?: boolean,
};

export default function FormatTime({
  bold = false,
  date,
  displayTime = false,
  smaller = false,
  utc = false,
}: Props): React$Node {
  const { t } = useTranslation('components');

  let localDate;
  let utcDate;
  let utcTime;

  if (typeof date === 'string' && date.includes('T')) {
    localDate = date.endsWith('Z') ? date : `${date}Z`;
    utcDate = moment.utc(localDate).format('MM/DD/YYYY');
    utcTime = moment.utc(localDate).format('HH:mm:ss z');
  } else {
    localDate = date;
    const dateInUTC = new Date(date).toUTCString();
    utcDate = moment.utc(dateInUTC).format('MM/DD/YYYY');
    utcTime = moment.utc(dateInUTC).format('HH:mm:ss z');
  }

  return (
    <StyledTimeDiv bold={bold} smaller={smaller}>
      <ConditionalRender condition={displayTime} fallback={<FormatDate date={utc ? utcDate : localDate} />}>
        <>
          <FormatDate date={utc ? utcDate : localDate} />{' '}
          <Time>{t('const:Incident.timeOnly', { date: utc ? utcTime : new Date(localDate) })}</Time>
        </>
      </ConditionalRender>
    </StyledTimeDiv>
  );
}
