// @flow

import { curveLinear as curve } from '@vx/curve';
import { LinearGradient } from '@vx/gradient';
import { scaleTime, scaleLinear } from '@vx/scale';
import { AreaClosed, LinePath } from '@vx/shape';
import { extent, max } from 'd3-array';
import React, { Fragment } from 'react';
import { withTheme } from 'styled-components';

import { HEIGHT, X_OFFSET, Y_OFFSET, type Data, type Tint } from './util';

import type { Theme } from 'config/theme';

type Props = {
  children: ({ lineTint: string }) => Node,
  closed: boolean,
  data: Data[],
  dataIndex: number,
  previousData?: Data[],
  theme: Theme,
  tint?: Tint,
  width: number,
};
const PreviousLine = (
  { children, closed, data, dataIndex, previousData = [], theme, width, tint = 'none', ...rest }: Props,
  ref: any
) => {
  const allData = [...data, ...previousData];
  const x = (d) => d.date;
  const y = (d) => d.value;
  const yMax = HEIGHT - Y_OFFSET - 18;

  const xScale = scaleTime({ domain: extent(allData, x), range: [0, width - X_OFFSET] });
  const yScale = scaleLinear({
    clamp: true,
    domain: [0, max(allData, y)],
    nice: true,
    range: [yMax, 0],
  });

  const props = { curve, data: previousData, x, xScale, y, yScale, ...rest };

  const tintFill = theme.tints[tint].fill;
  const tintFrom = theme.tints[tint].from;
  const tintTo = theme.tints[tint].to;

  return (
    <Fragment>
      <LinearGradient from={tintFrom} id={`current-line-${tint}`} to={tintTo} />
      {closed && <AreaClosed fill={tintFill} fillOpacity={0.1} strokeWidth={0} {...props} />}
      <LinePath
        data-index={dataIndex}
        innerRef={ref}
        stroke={`url('#current-line-${tint}')`}
        strokeDasharray="10,4"
        strokeWidth={closed ? 2 : 3}
        {...props}
      />
      {children && typeof children === 'function' ? children({ lineTint: tint }) : children}
    </Fragment>
  );
};

// $FlowIssue
export default withTheme(React.forwardRef(PreviousLine));
