// @flow

import { rem } from 'polished';
import styled from 'styled-components';

export default styled.div`
  align-content: center;
  display: flex;
  flex-grow: 1;
  margin: 0 ${({ theme }) => rem(theme.spacing.md)};
  max-width: ${rem(1304)};
`;
