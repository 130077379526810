import { styled, Box } from '@a1s/ui';
import React, { PropsWithChildren } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface DetailsProps {
  id?: string;
  title: string;
}

export function Details({ children, id = '1', title }: PropsWithChildren<DetailsProps>) {
  return (
    <Container id={id} open>
      <Summary>{title}</Summary>
      <Box px="2">{children}</Box>
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

const Container = styled('details', {
  backgroundColor: '$gray100',
  borderRadius: '$2',
  padding: '$1-5',
});

const Summary = styled('summary', {
  backgroundColor: '$gray200',
  backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.5 9.61651e-07L10.2631 6L0.736859 6L5.5 9.61651e-07Z' fill='white'/%3e%3c/svg%3e ")`,
  backgroundPosition: 'center right $space$2',
  borderRadius: '$1',
  backgroundRepeat: 'no-repeat',
  color: '$white',
  fontFamily: '$sans',
  fontWeight: '600',
  fontSize: '$sm',
  fontStretch: 'ultra-condensed',
  listStyle: 'none',
  padding: '$1 $2',
  textTransform: 'uppercase',

  'details[open] &': {
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.5 6L0.736859 2.32774e-06L10.2631 1.49493e-06L5.5 6Z' fill='white'/%3e%3c/svg%3e ")`,
  },

  '&::-webkit-details-marker': { display: 'none' },
});
