// @flow

import React from 'react';

import { useTranslation } from 'react-i18next';

import PopupMenu, { Group, Option } from 'ui/molecules/PopupMenu';

// Main Component
// -------------------------------------------------------------------------------------------------

interface Props {
  onClickProfile?: (event: SyntheticMouseEvent<HTMLDivElement>) => any;
  onClickLogout?: () => any;
}

export default function User({ onClickLogout, onClickProfile }: Props) {
  const { t } = useTranslation('common');

  return (
    <PopupMenu ariaLabel="User Options" opener="user">
      <Group>
        <Option icon="edit" onClick={onClickProfile}>
          {t('profile')}
        </Option>
      </Group>
      <Group>
        <Option icon="logout" onClick={onClickLogout}>
          {t('logOut')}
        </Option>
      </Group>
    </PopupMenu>
  );
}
