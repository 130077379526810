"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrucatedText = exports.Table = exports.StyledRow = exports.StyledLabel = exports.Graph = void 0;
var _styled = require("../../styled");
var _Text = require("../../base/Text");
const StyledLabel = (0, _styled.styled)('th', {
  fontSize: '$sm',
  fontWeight: 400,
  maxWidth: '$md',
  paddingRight: '$2',
  textAlign: 'left',
  verticalAlign: 'middle'
});
exports.StyledLabel = StyledLabel;
const Graph = (0, _styled.styled)('td', {
  color: '$white',
  fontSize: '$space$35',
  height: '$2xs',
  padding: '$2',
  textShadow: '$shadows$text',
  verticalAlign: 'middle',
  width: '100%',
  variants: {
    hiddenValue: {
      true: {
        color: 'transparent',
        textShadow: 'none'
      }
    }
  }
});
exports.Graph = Graph;
const StyledRow = (0, _styled.styled)('tr', {});
exports.StyledRow = StyledRow;
const Table = (0, _styled.styled)('table', {
  borderCollapse: 'separate',
  borderSpacing: '0px $space$1',
  position: 'relative',
  width: '100%',
  variants: {
    placeholder: {
      true: {
        opacity: '0.3',
        pointerEvents: 'none',
        '&::after': {
          color: 'black',
          fontFamily: '$roboto',
          fontSize: '$2xl',
          opacity: 1,
          position: 'absolute',
          textAlign: 'center',
          top: '40%',
          width: '100%'
        }
      }
    }
  }
});
exports.Table = Table;
const TrucatedText = (0, _styled.styled)(_Text.Text, {
  display: 'block',
  wordBreak: 'break-all'
});
exports.TrucatedText = TrucatedText;