import { css, Box, SegmentChart, styled } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { percentOf } from '../../lib';

import { ActionData } from './useRemoteData';

interface MiddleProps {
  actions: ActionData;
  total: number;
}

export default function Middle({ actions }: MiddleProps) {
  const { t } = useTranslation('dashboardHome');

  const containerCss = css({
    color: '$gray700',
    fontFamily: '$sans',
    fontSize: '$xs',
    fontStretch: '$ultraCondensed',
  });
  const handleCss = css({
    textTransform: 'uppercase',
  });
  const countCss = css({
    color: '$gray400',
    fontWeight: '$md',
  });
  const dividerCss = css({
    fontWeight: 'regular',
  });

  const ChartContainer = styled('div', {
    [`& ${SegmentChart.Background}`]: {
      fill: 'white',
      stroke: 'white',
      strokeWidth: 4,
    },
    '@lg': { maxWidth: '396px' },
  });

  const { eliClickDirect: directClick, eliClickRbi: rbiClick, eliInterstitial: totalClicks } = actions;

  const didntProceed: number = totalClicks - (directClick + rbiClick);

  return (
    <Box p>
      <Box bg="$gray100" p="2" r>
        <ChartContainer>
          <SegmentChart>
            <SegmentChart.Segment color="teal650" value={rbiClick}>
              <div className={containerCss()}>
                <SegmentChart.Handle>
                  <span className={handleCss()}>{t('rbiBrowse')}</span>
                </SegmentChart.Handle>
                <span className={dividerCss()}> | </span>
                <span className={countCss()}>{percentOf(rbiClick, totalClicks)}%</span>
              </div>
            </SegmentChart.Segment>
            <SegmentChart.Segment color="red400" value={directClick}>
              <div className={containerCss()}>
                <SegmentChart.Handle>
                  <span className={handleCss()}>{t('directBrowse')}</span>
                </SegmentChart.Handle>
                <span className={dividerCss()}> | </span>
                <span className={countCss()}>{percentOf(directClick, totalClicks)}%</span>
              </div>
            </SegmentChart.Segment>
            <SegmentChart.Segment color="gray300" value={didntProceed}>
              <div className={containerCss()}>
                <SegmentChart.Handle>
                  <span className={handleCss()}>{t('didNotProceed')}</span>
                </SegmentChart.Handle>
                <span className={dividerCss()}> | </span>
                <span className={countCss()}>{percentOf(didntProceed, totalClicks)}%</span>
              </div>
            </SegmentChart.Segment>
          </SegmentChart>
        </ChartContainer>
      </Box>
    </Box>
  );
}
