"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useDebounce = require("./useDebounce");
Object.keys(_useDebounce).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useDebounce[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useDebounce[key];
    }
  });
});
var _useDimensions = require("./useDimensions");
Object.keys(_useDimensions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useDimensions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useDimensions[key];
    }
  });
});
var _useMutations = require("./useMutations");
Object.keys(_useMutations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useMutations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useMutations[key];
    }
  });
});
var _useNetworkStatus = require("./useNetworkStatus");
Object.keys(_useNetworkStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useNetworkStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useNetworkStatus[key];
    }
  });
});
var _usePrefersDarkColorScheme = require("./usePrefersDarkColorScheme");
Object.keys(_usePrefersDarkColorScheme).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePrefersDarkColorScheme[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _usePrefersDarkColorScheme[key];
    }
  });
});
var _usePrevious = require("./usePrevious");
Object.keys(_usePrevious).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePrevious[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _usePrevious[key];
    }
  });
});
var _useWindowFocus = require("./useWindowFocus");
Object.keys(_useWindowFocus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useWindowFocus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useWindowFocus[key];
    }
  });
});
var _useWindowSize = require("./useWindowSize");
Object.keys(_useWindowSize).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useWindowSize[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useWindowSize[key];
    }
  });
});