import { Box, BoxProps } from '@a1s/ui';
import React from 'react';

import { StyledSVG } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

export interface ColorBlockProps {
  borderColor?: BoxProps['bg'];
  color: BoxProps['bg'];
}

export function ColorBlock({ borderColor, color }: ColorBlockProps) {
  return (
    <Box bg={borderColor} p={borderColor ? '1' : undefined} r="2">
      <StyledSVG
        css={{ '& > rect': { fill: `$colors${color}` } }}
        role="img"
        viewBox="0 0 8 8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect height="8" rx="1" width="8" />
      </StyledSVG>
    </Box>
  );
}
