// @flow

import { LegendOrdinal } from '@vx/legend';
import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.roboto};

  ${({ left }) =>
    left &&
    css`
      margin-left: ${rem(left)};
    `};

  ${({ top }) =>
    top &&
    css`
      margin-top: ${rem(top)};
    `};
`;

type Props = { left?: number, scale: any, top?: number };

const Legend = ({ left, scale, top }: Props) => (
  <Container top={top} left={left}>
    <LegendOrdinal scale={scale} direction="column" labelMargin="0 0 0 0" />
  </Container>
);

export default Legend;
