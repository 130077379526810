// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Sidebar = styled.aside`
  padding-bottom: ${({ theme }) => rem(theme.spacing.lg)};
  ${({ wide }) =>
    wide &&
    css`
      width: ${rem(368)};
    `};
`;

export default Sidebar;
