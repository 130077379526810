import { styled } from '@a1s/ui';

export const StyledCheckbox = styled('input', {
  appearance: 'none',
  backgroundColor: '$white',
  border: '0.15em solid currentColor',
  borderRadius: '$2',
  color: '$blue400',
  display: 'grid',
  flexShrink: 0,
  font: 'inherit',
  height: 18,
  margin: 0,
  placeContent: 'center',
  width: 18,

  '&:checked': {
    backgroundColor: '$blue400',
  },
  '&:checked::before': {
    content: '✔',
    color: '$white',
    fontSize: '$xl',
    transform: 'translate(1px, -2px)',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.7,
  },

  variants: {
    size: {
      sm: {
        height: 16,
        width: 16,
        '&:checked::before': {
          fontSize: '$lg',
        },
      },
    },
  },
});
