// @flow

import { loader } from 'graphql.macro';

export const detectionsBlockListQuery = loader('./queries/DetectionsBlockListQuery.graphql');
export const insightsBlockListByTypeQuery = loader('./queries/InsightsBlockedListByType.graphql');

export type BlockListList = { count: number, name: string };

export type BlockListCountsByDate = { date?: number, keys?: string[], values?: number[] };

type GraphTableDataType = {
  count: number,
  name: string,
};

type GraphTableReturnType = {
  label: string,
  value: number,
};

export function dataForGraphTable(data: Array<GraphTableDataType>): Array<GraphTableReturnType> {
  return data.map((row) => ({ label: row.name, value: row.count }));
}
