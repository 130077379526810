import { styled } from '@a1s/ui';
import React, { ComponentProps, MouseEventHandler, ReactElement } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface TabsProps {
  /**
   * The children **must** be `TabBar.Tab` elements.
   */
  children?: Array<ReactElement<TabProps>> | ReactElement<TabProps>;
}

export function Tabs({ children }: TabsProps) {
  return <StyledTabBar role="tablist">{children}</StyledTabBar>;
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface TabProps {
  children?: ComponentProps<typeof StyledTab>['children'];
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
  title?: string | null;
}

Tabs.Tab = Tab;

function Tab({ children, disabled = false, onClick, selected, title }: TabProps) {
  return (
    <StyledTab aria-disabled={disabled} aria-selected={selected} onClick={onClick} role="tab" title={title}>
      {children}
    </StyledTab>
  );
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

export const StyledTab = styled('div', {
  borderBottom: 'transparent solid 3px',
  color: '$gray500',
  cursor: 'pointer',
  fontFamily: '$sans',
  fontSize: '$md',
  fontStretch: 'ultra-condensed',
  fontWeight: '$light',
  pt: '$2',
  textTransform: 'uppercase',

  '&:hover': {
    color: '$gray600',
  },

  '&[aria-disabled=true]': {
    cursor: 'default',
    color: '$gray400',
  },

  '&[aria-selected="true"]': {
    borderBottomColor: '$gray500',
    color: '$gray800',
    fontWeight: '$semibold',
  },
});

export const StyledTabBar = styled('div', {
  display: 'flex',
  gap: '$8',
  px: '$3',
});
