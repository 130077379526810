// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  dataForSankeyGraph,
  type EmailReportEnvelopeAttachmentFormats,
  type EmailReportEnvelopeAttachmentTypes,
} from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import NoData from 'ui/molecules/NoData';
import SankeyGraph from 'ui/organisms/SankeyGraph';
import withDataHandler from 'utils/withDataHandler';

type EmailReportData = {
  extension: { [string]: any },
  formats: EmailReportEnvelopeAttachmentFormats[],
  types: EmailReportEnvelopeAttachmentTypes[],
};

type Props = {
  data: EmailReportData,
  error: boolean,
  loading: boolean,
};

const SankeyGraphWithDataHandler = withDataHandler({
  empty: <NoData />,
})(SankeyGraph);

export default function Attachments({ data, error, loading }: Props) {
  const { extension, formats, types } = data;
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <BlockHeader title={t('attachments')} />
          <Content>
            <p>{t('attachmentsFound', { count: formats.length, types: types.length })}</p>
            <p>{t('attachmentsCopy')}</p>
          </Content>
        </>
      }
      bottom={
        <PaddedContent pushBottom="lg">
          <SankeyGraphWithDataHandler
            data={dataForSankeyGraph(formats, types, extension)}
            dataTestId="attachments-sankey-graph"
            isEmpty={() => loading || error || Object.keys(data).length === 0}
            isLoading={loading}
          />
        </PaddedContent>
      }
      dataTestId="attachments-card"
    />
  );
}
