// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  dataForDonutChart,
  dataForStackedBarGraph,
  type DetectionsCountType,
  type EmailCountsRowTimeseriesType,
} from './dataTypesAndUtils';

import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import DonutChart from 'ui/organisms/DonutChart';
import PlaceholderStackedBarGraph from 'ui/organisms/PlaceholderStackedBarGraph';
import SectionOverview from 'ui/organisms/SectionOverview';
import StackedBarGraph from 'ui/organisms/StackedBarGraph';
import TwoColumn from 'ui/templates/TwoColumn';
import withDataHandler from 'utils/withDataHandler';

type Props = {
  becEnabled: boolean,
  error: boolean,
  data: {
    sectionOverview: {
      current: number,
      previous: number,
    },
    donutChart: DetectionsCountType,
    stackedBarGraph: EmailCountsRowTimeseriesType,
  },
  loading: boolean,
};

const StackedBarGraphWithDataHandler = withDataHandler({
  empty: <PlaceholderStackedBarGraph />,
})(StackedBarGraph);

export default function TotalDetections({ becEnabled, data, error, loading }: Props) {
  const { t } = useTranslation('email');

  const { sectionOverview, donutChart, stackedBarGraph } = data;
  const dataForGraph = dataForStackedBarGraph(becEnabled, stackedBarGraph);
  const noDataThisMonth = dataForGraph.filter((day) => day.values.filter((el) => el !== 0).length !== 0);

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <BlockHeader dataTestId="detection-details-total-detections" title={t('totalDetections')} />
          <SectionOverview
            currentValue={sectionOverview.current}
            dataTestId="total-emails-processed"
            dimmed={loading}
            formatValue="number"
            previousValue={sectionOverview.previous}
            size="large"
            title={t('totalEmailsProcessed')}
          />
        </>
      }
      bottom={
        <>
          <TwoColumn>
            <DonutChart
              colorScheme="detections"
              data={dataForDonutChart(becEnabled, donutChart, t)}
              dataTestId="detection-details-donut-chart"
              label={t('totalDetections')}
              legendVertical
              isLoading={loading}
            />
            <StackedBarGraphWithDataHandler
              colorScheme="detections"
              data={dataForGraph}
              dataTestId="detection-details-stacked-bar-graph"
              isEmpty={() => loading || error || noDataThisMonth.length === 0}
              isLoading={loading}
              withoutLegend
            />
          </TwoColumn>
        </>
      }
      dataTestId="total-detections-card"
    />
  );
}
