// @flow

import React, { type Node } from 'react';

import Option from './Option';

type Props = {
  children: Node,
  dataTestId?: string,
  decorated?: boolean,
  name: string,
  selected?: boolean,
  small?: boolean,
  onSelect: (string) => void,
};

export default function SectionOption({
  children,
  dataTestId = 'organism-section-option',
  decorated = true,
  name,
  onSelect,
  selected,
  small = false,
}: Props) {
  const onClickSelect = () => {
    if (onSelect) onSelect(name);
  };

  return (
    <Option data-testid={dataTestId} decorated={decorated} onClick={onClickSelect} selected={selected} small={small}>
      {children}
    </Option>
  );
}
