// @flow

import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as AutoProtectDisabled } from './icons/auto-protect-disabled.svg';
import { ReactComponent as AutoProtectEnabled } from './icons/auto-protect-enabled.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Duplicate } from './icons/duplicate.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Expand } from './icons/expand.svg';
import { ReactComponent as List } from './icons/list.svg';
import { ReactComponent as Logout } from './icons/logout.svg';
import { ReactComponent as MailRedressActions } from './icons/mail-redress-actions.svg';
import { ReactComponent as MailRetract } from './icons/mail-retract.svg';
import { ReactComponent as Preview } from './icons/preview.svg';
import { ReactComponent as Protect } from './icons/protect.svg';
import { ReactComponent as Reload } from './icons/reload.svg';

export type IconName =
  | 'autoProtectEnabled'
  | 'autoProtectDisabled'
  | 'delete'
  | 'download'
  | 'duplicate'
  | 'edit'
  | 'expand'
  | 'list'
  | 'logout'
  | 'preview'
  | 'protect'
  | 'reload'
  | 'viewDetails'
  | React$Node;

type Props = { name: IconName };

// Styled Components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => rem(theme.spacing.md)};
  margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  transform: translateY(3px);
`;

// Main Component
// -------------------------------------------------------------------------------------------------

export default function Icon({ name, ...props }: Props) {
  return (
    <Container>
      <Svg name={name} {...props} />
    </Container>
  );
}

// Private Component
// -------------------------------------------------------------------------------------------------

const Svg = ({ name, ...props }: Props) => {
  // If this is an object it's an already "called" component so just render it as-is
  if (typeof name === 'object') return name;

  switch (name) {
    case 'autoProtectDisabled':
      return <AutoProtectDisabled {...props} />;
    case 'autoProtectEnabled':
      return <AutoProtectEnabled {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'download':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" {...props}>
          <title>Download</title>
          <path fill="#b8c1cf" d="M16 11.001v4h3l-7 7-7-7h3v-4zM8 6.001h8v4H8zM8 2.001h8v3H8z" />
        </svg>
      );
    case 'duplicate':
      return <Duplicate {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'expand':
      return <Expand {...props} />;
    case 'list':
      return <List {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'mail-redress-actions':
      return <MailRedressActions {...props} />;
    case 'mail-retract':
      return <MailRetract {...props} />;
    case 'preview':
      return <Preview {...props} />;
    case 'protect':
      return <Protect {...props} />;
    case 'reload':
      return <Reload {...props} />;
    case 'viewDetails':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" {...props}>
          <title>View Details</title>
          <path
            d="M4 4.8v6.4h6.4V4.8zm2.4 2.4H8v1.6H6.4zm5.6 0v1.6h4V7.2zm5.6 0v1.6H20V7.2zM4 12.8v6.4h6.4v-6.4zm3.2 2.4a.8.8 0 110 1.6.8.8 0 010-1.6zm4.8 0v1.6h4v-1.6zm5.6 0v1.6H20v-1.6zm0 0"
            fill="#b8c1cf"
          />
        </svg>
      );
    default:
      return null;
  }
};
