// @flow

import { subDays } from 'date-fns';
import React from 'react';

import StackedBarGraph from 'ui/organisms/StackedBarGraph';

const data = [
  {
    date: subDays(new Date(), 1),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [63, 55, 109],
  },
  {
    date: subDays(new Date(), 2),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [69, 80, 99],
  },
  {
    date: subDays(new Date(), 3),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [53, 59, 69],
  },
  {
    date: subDays(new Date(), 4),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [72, 33, 80],
  },
  {
    date: subDays(new Date(), 5),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [23, 75, 55],
  },
  {
    date: subDays(new Date(), 6),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [53, 59, 69],
  },
  {
    date: subDays(new Date(), 7),
    keys: ['Allowed Senders', 'Acceptable Senders', 'Allowed Recipients'],
    values: [10, 121, 66],
  },
];

const PlaceholderStackedBarGraph = () => (
  <StackedBarGraph
    colorScheme="graphite"
    data={data}
    placeholder
    placeholderText="NoData.noDataObserved"
    withoutLegend
  />
);

export default PlaceholderStackedBarGraph;
