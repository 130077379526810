// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import styled from 'styled-components';

import { ReactComponent as SearchSVG } from './search.svg';

import Button from 'ui/atoms/Button';

type Props = {
  children?: Node,
  dataTestId?: string,
  handleSearch?: (searchTerm: string | null) => void,
};

const Search = styled(SearchSVG)`
  height: ${rem(18)};
  margin-left: ${(p) => rem(p.theme.spacing.xs)};
  position: relative;
  transition: all 150ms ${({ theme }) => theme.timing.easeOutQuart};
  width: ${rem(18)};
  z-index: 1;

  /* stylelint-disable */
  path {
    fill: ${({ theme }) => theme.colors.deepSkyBlue};
  }
  /* stylelint-enable */
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  text-transform: inherit;
  overflow: hidden;

  span {
    align-items: center;
    display: flex;
  }

  &:hover {
    /* stylelint-disable */
    ${Search} {
      transform: scale(1.1);

      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
    /* stylelint-enable */
  }
`;

export default function SearchLink({ children, dataTestId = 'molecule-search-link', handleSearch }: Props) {
  return (
    <StyledButton
      clickableText
      data-testid={dataTestId}
      onClick={handleSearch && typeof handleSearch === 'function' ? handleSearch : null}
    >
      {children}

      <Search />
    </StyledButton>
  );
}
