// @flow

import axios from 'axios';
import { rem } from 'polished';
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PaddedContent from 'ui/atoms/PaddedContent';
import Content from 'ui/molecules/Content';
import Field from 'ui/molecules/Field';

const Image = styled.div`
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};

  img {
    max-width: 100%;
  }
`;

const StyledField = styled(Field)`
  display: flex;
  justify-content: center;
  padding-left: 0px;
`;

export default function QRCode() {
  const [qrCode, setQrCode] = useState(null);
  const { t } = useTranslation('invitePage');

  useEffect(() => {
    async function fetchQrCode() {
      try {
        const { data, status } = await axios.get('/api/users/codes');
        if (status === 200 && 'qrcode' in data) {
          const { qrcode } = data;
          if (qrcode) setQrCode(qrcode);
        }
      } catch (error) {
        // fail silently
      }
    }
    fetchQrCode();
  }, []);

  if (!qrCode) return null;

  return (
    <PaddedContent dataTestId="login-qrcode" padding="sm" pushBottom="sm" pushTop="md">
      <StyledField>
        <Content>{t('tfaCopy')}</Content>
      </StyledField>
      <StyledField>
        <Image>
          <img alt="SVG" src={`data:image/svg+xml;utf8,${qrCode}`} />
        </Image>{' '}
      </StyledField>
    </PaddedContent>
  );
}
