// @flow

import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from './error.svg';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: ${({ theme }) => rem(theme.spacing.xl * 2)};
`;

type Props = {
  dataTestId?: string,
};

export default function Error({ dataTestId = 'atom-error' }: Props) {
  return (
    <Container data-testid={dataTestId}>
      <Icon />
    </Container>
  );
}
