// @flow

import React, { type Node } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Container from './Container';
import Description from './Description';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Link from 'ui/atoms/Link';
import PaddedContent from 'ui/atoms/PaddedContent';
import Title from 'ui/atoms/Title';
import Info from 'ui/molecules/Info';
import Stack from 'ui/templates/Stack';

// TODO: This needed to be more flexible than just accepting a button
// title: Can be a string or another React element (for example a link)
type Props = {
  action?: Button,
  children?: Node,
  dataTestId?: string,
  docsLink?: string,
  pullBottom?: boolean,
  title: Node,
};

const BlockHeader = ({
  action,
  children,
  dataTestId = 'molecule-block-header',
  docsLink = '',
  pullBottom = false,
  title,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Container data-testid={dataTestId} pullBottom={pullBottom}>
      <ConditionalRender
        condition={!!docsLink}
        fallback={
          <>
            <Title>{title}</Title>
            {action}

            {children && <Description>{children}</Description>}
          </>
        }
      >
        <>
          <Stack>
            <Title>{title}</Title>
            <ConditionalRender condition={!!docsLink}>
              <PaddedContent pushLeft="xs">
                <Info tooltipPosition="right">
                  <Trans i18nKey="">
                    <>
                      <p>
                        <Link external to={docsLink} rel="nofollow" target="_blank">
                          {t('seeDocumentation')}
                        </Link>
                      </p>
                    </>
                  </Trans>
                </Info>
              </PaddedContent>
            </ConditionalRender>
          </Stack>
          {action}

          {children && <Description>{children}</Description>}
        </>
      </ConditionalRender>
    </Container>
  );
};

export default BlockHeader;
