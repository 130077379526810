"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TooltipWrapper = exports.StyledContainer = void 0;
var _css = require("../../shared/css");
var _styled = require("../../styled");
/**
 * A wrapper for positioning the tooltip without having to drill refs down.
 */
const TooltipWrapper = (0, _styled.styled)('div', {
  ..._css.toggle.hide,
  left: '50%',
  position: 'absolute',
  top: '-50%',
  transform: 'translate(calc(-50% - 1px), calc(-100% - 1px))',
  whiteSpace: 'nowrap',
  zIndex: 0,
  // Show the tooltip when the button is hovered over *or* if it has focus after
  // being clicked on.
  [`button:hover + &`]: {
    ..._css.toggle.show
  },
  [`button:focus + &`]: {
    ..._css.toggle.show
  }
});

/**
 * We need a positioning context (position: relative, this case), to be able to position
 * the tooltip on top of the button.
 */
exports.TooltipWrapper = TooltipWrapper;
const StyledContainer = (0, _styled.styled)('div', {
  display: 'inline-block',
  lineHeight: 1,
  position: 'relative',
  [`& > button`]: {
    position: 'relative',
    zIndex: 1
  }
});
exports.StyledContainer = StyledContainer;