import { endOfDay, format, startOfDay, subDays } from 'date-fns';

import { Duration } from './duration';

interface SetInsightsDateRangeFromDurationType {
  fromDate: string; // String representation of ISO date
  toDate: string; // String representation of ISO date
}

export function setInsightsDateRangeFromDuration(duration: Duration): SetInsightsDateRangeFromDurationType {
  return {
    fromDate: format(startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), 'yyyy-MM-dd'),
    toDate: format(endOfDay(new Date()), 'yyyy-MM-dd'),
  };
}
