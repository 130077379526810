"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fontSizes = void 0;
const fontSizes = {
  xs: '0.6875rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.5rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem'
};
exports.fontSizes = fontSizes;