// @flow

// $FlowFixMe
import { Box, HorizontalBars, Loadable, Text } from '@a1s/ui';
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { get } from 'lodash';

// $FlowFixMe
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderDataArray } from '../dataTypesAndUtils';
import { LabeledValue } from '../ui/LabeledValue';

import { directoriesQuery, displayNamesQuery } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';

export const insightsBecTargetsQuery = loader('./queries/InsightsBecTargetsQuery.graphql');

export default function TopBecRecipients() {
  const { t } = useTranslation('email');
  const { currentInterval } = useContext(DaysBackContext);

  const {
    data: becData,
    error: becError,
    loading: becLoading,
  } = useQuery(insightsBecTargetsQuery, {
    variables: setInsightsDateRangeFromDuration(currentInterval),
  });

  const data = get(becData, 'insightsBecTargets.data', [
    {
      value: 0,
      becTargets: '',
    },
  ]);

  const {
    data: directoriesData = { directories: [] },
    error: directoriesError,
    loading: directoriesLoading,
  } = useQuery(directoriesQuery);

  const {
    data: displayNamesData,
    error: displayNamesError,
    loading: displayNamesLoading,
  } = useQuery(displayNamesQuery);

  const displayNames = displayNamesData?.displayNames || [];
  const directories = directoriesData?.directories || [];

  const error = becError || directoriesError || displayNamesError;
  const loading = becLoading || directoriesLoading || displayNamesLoading;

  const callToAction = directories.length <= 1 && displayNames.length === 0 && dataForGraphTable(data)?.length === 0;

  return (
    <>
      <ContentBlock
        dataTestId="top-bec-recipients-component"
        top={
          <>
            <BlockHeader title={t('topBecTargets')} />
            <ConditionalRender condition={callToAction && !loading && !error}>
              <Content>{t('directoriesCallToAction')}</Content>
            </ConditionalRender>
            <ConditionalRender
              condition={!error && (loading || !callToAction)}
              fallback={
                <Box css={{ textAlign: 'center' }} pb>
                  <Text color="$gray400" size="sm" stretch="ultraCondensed">
                    {t('components:NoData.noDataObserved')}
                  </Text>
                </Box>
              }
            >
              <HorizontalBars gap="2">
                {(loading ? placeholderDataArray(10) : dataForGraphTable(data)).map((row) => {
                  return (
                    <HorizontalBars.Bar color="$gray100" key={row[0]} value={row[1]}>
                      <Loadable loading={loading}>
                        <LabeledValue
                          childrenColor="$gray600"
                          gap="5"
                          params={{
                            metric: `bec_email_target:${row[0]}`,
                          }}
                          transform="lowercase"
                          value={row[1]}
                        >
                          {row[0]}
                        </LabeledValue>
                      </Loadable>
                    </HorizontalBars.Bar>
                  );
                })}
              </HorizontalBars>
            </ConditionalRender>
          </>
        }
      />
    </>
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------
const dataForGraphTable = (data) => {
  if (data[0]?.becTargets === '') return [];
  return data.map((row) => [row.becTargets, row.value]);
};
