// @flow

import hash from 'hash-sum';
// $FlowFixMe
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Body } from '../Table';

import Row, { type Tint } from './Row';
import Table from './Table';

type Data = { label: string, value: number };
type Props = {
  data: Data[],
  dataTestId?: string,
  hideValue?: boolean,
  placeholder?: boolean,
  placeholderText?: string,
  sortOtherLast?: boolean,
  tint?: Tint,
};

const StackedGraphTable = ({
  data,
  dataTestId = 'molecule-stacked-graph-table',
  hideValue = false,
  placeholder = false,
  placeholderText = 'sampleData',
  sortOtherLast = false,
  tint = 'none',
}: Props) => {
  const maxValue = Math.max(...data.map((d) => d.value));

  const sortedData = useMemo(() => {
    if (sortOtherLast) {
      const other = data.filter((d) => d.label.toLowerCase() === 'other');
      const sorted = data.filter((d) => d.label.toLowerCase() !== 'other').sort((a, b) => b.value - a.value);
      return [...sorted, ...other];
    }

    return data.sort((a, b) => b.value - a.value);
  }, [data, sortOtherLast]);

  const { t } = useTranslation('components');

  return (
    <Table data-testid={dataTestId} placeholder={placeholder ? 'true' : undefined} placeholderText={t(placeholderText)}>
      <Body>
        {sortedData.map((row, i) => {
          const percent = Math.max(row.value / maxValue, 0.1);

          return (
            <Row
              hideValue={hideValue}
              key={hash({ ...row, i })}
              label={row.label}
              percent={percent}
              tint={tint}
              value={row.value}
            />
          );
        })}
      </Body>
    </Table>
  );
};

export default StackedGraphTable;
