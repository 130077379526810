// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => rem(theme.spacing.md)};
  width: 100%;
`;

export default Row;
