// @flow

import { loader } from 'graphql.macro';

export const insightsExtensionsQuery = loader('./queries/InsightsMaliciousAttachmentsExtensions.graphql');

export type MaliciousAttachmentCountsByDate = { day: Date, total: number };

export type MaliciousAttachmentCountsByDataType = {
  count?: number,
  name?: string,
};

type LineGraphReturnType = {
  date: number,
  value: number,
};

type LineGraphType = {
  day: string,
  total: number,
};

type ProgressTableDataType = {
  count: number,
  name: string,
};

type MixType = string | number;

export function mungeLineGraphData(data: Array<LineGraphType>): Array<LineGraphReturnType> {
  return [...data]
    .sort((a, b) => new Date(a.day) - new Date(b.day))
    .map((row) => ({ date: new Date(row.day), value: row.total }));
}

export function mungeProgressTableData(data: Array<ProgressTableDataType>): Array<Array<MixType>> {
  const other = data.filter((o) => o.name === 'other');
  const sortedExtensions = [
    ...data.filter((d) => d.name !== 'other').sort((a, b) => b.count - a.count),
    ...other.filter(Boolean),
  ];
  return sortedExtensions.slice(0, 15).map((row) => [row.name || 'No name', row.count]);
}
