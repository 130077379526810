// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const TextStyle = styled.span.attrs({ 'data-testid': 'atom-text-style' })`
  ${({ align = 'left' }) =>
    align && (align === 'right' || align === 'center')
      ? css`
          display: block;
          text-align: ${align};
        `
      : css`
          text-align: ${align};
        `};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};

  ${({ fontSize }) =>
    fontSize &&
    fontSize === 'sm' &&
    css`
      font-size: ${rem(12)};
    `};

  ${({ italics }) =>
    italics &&
    css`
      font-style: italic;
    `};

  ${({ strong }) =>
    strong &&
    css`
      font-weight: 600;
    `};

  ${({ uppercased }) =>
    uppercased &&
    css`
      text-transform: uppercase;
    `};

  ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.redOrange};
      font-family: 'roboto mono', monospace;
      font-size: ${rem(12)};
      font-weight: 500;
      letter-spacing: ${rem(0.43)};
      opacity: 0.75;
      text-transform: uppercase;
    `};
`;

TextStyle.displayName = 'TextStyle';

export default TextStyle;
