// @flow

import React, { type Node } from 'react';

import Container from './Container';

type Props = { children: Node };

const SideNav = ({ children }: Props) => <Container data-testid="organism-sidenav">{children}</Container>;

export default SideNav;
export { default as Link } from './Link';
export { default as Section } from './Section';
