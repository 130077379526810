// @flow

import React from 'react';

import GraphTable from 'ui/molecules/GraphTable';

export const defaultData = [
  { label: 'Example 3', value: 3 },
  { label: 'Example 1', value: 5 },
  { label: 'Example 5', value: 1 },
  { label: 'Example 2', value: 4 },
  { label: 'Example 4', value: 2 },
];

type DataType = { label: string, value: number };

type Props = {
  data?: Array<DataType>,
};

const PlaceholderGraphTable = ({ data = defaultData }: Props) => (
  <GraphTable
    colorScheme="graphite"
    data={data}
    hideValue
    placeholder
    placeholderText="components:NoData.noDataObserved"
  />
);

export default PlaceholderGraphTable;
