// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { threatTypesRecord, threatTypesQuery } from './dataTypesAndUtils';

import TopThreatTypes from './TopThreatTypes';

import { DaysBackContext } from 'screens/App';

export default function SuspiciousTargetsOverview() {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(threatTypesQuery, { variables: { currentInterval } });
  const { threatTypes } = get(data, 'suspiciousThreatTypes', threatTypesRecord);

  return (
    <>
      <Helmet title={`${t('targets')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <TopThreatTypes data={threatTypes} error={error} loading={loading} />
    </>
  );
}
