import { styled, Cluster } from '@a1s/ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useReportedAlerts } from '../../lib/hooks';
import { SearchResultRow } from '../../types';

import FalseNegativeDialog from './FalseNegativeDialog';
import FalsePositiveDialog from './FalsePositiveDialog';
import { ReactComponent as InfoSVG } from './info.svg';

import { Text } from 'ui-new';

interface ReportLinkButtonProps {
  data: FalsePositiveButtonProps['data'];
  kind: 'false-negative' | 'false-positive';
}

export function ReportLinkButton({ data, kind }: ReportLinkButtonProps) {
  return kind === 'false-negative' ? <FalseNegativeButton data={data} /> : <FalsePositiveButton data={data} />;
}

//
// Private component(s)
// -------------------------------------------------------------------------------------------------

interface FalseNegativeButtonProps {
  data: SearchResultRow;
}

function FalseNegativeButton({ data }: FalseNegativeButtonProps) {
  const [ids] = useReportedAlerts();
  const [showFalseNegativeDialog, setShowFalseNegativeDialog] = useState(false);
  const { t } = useTranslation('unisearch');

  function handleClick() {
    setShowFalseNegativeDialog(true);
  }

  if (ids.includes(data.messageId))
    return (
      <Cluster align="center" css={{ svg: { transform: 'translateY(-2px)' } }} gap="1">
        <InfoSVG height="16" />
        <Text color="$gray400">{t('falseNegativeReported')}</Text>
      </Cluster>
    );

  return (
    <>
      <StyledButton color="$blue225" css={{ textDecoration: 'underline' }} onClick={handleClick}>
        {t('reportAsFN')}
      </StyledButton>
      <FalseNegativeDialog
        data={data}
        onClose={() => setShowFalseNegativeDialog(false)}
        visible={showFalseNegativeDialog}
      />
    </>
  );
}

interface FalsePositiveButtonProps {
  data: SearchResultRow;
}
export function FalsePositiveButton({ data }: FalsePositiveButtonProps) {
  const [ids] = useReportedAlerts();
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation('unisearch');

  function handleClick() {
    setOpenDialog(true);
  }

  function handleClose() {
    setOpenDialog(false);
  }

  if (ids.includes(data.alertId))
    return (
      <Cluster align="center" css={{ svg: { transform: 'translateY(-2px)' } }} gap="1">
        <InfoSVG height="16" />
        <Text color="$gray400">{t('falsePositiveReported')}</Text>
      </Cluster>
    );

  return (
    <>
      <StyledButton color="$blue225" css={{ textDecoration: 'underline' }} onClick={handleClick}>
        {t('reportAsFP')}
      </StyledButton>
      <FalsePositiveDialog data={[data]} onClose={handleClose} visible={openDialog} />
    </>
  );
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

export const StyledButton = styled('button', {
  background: 'inherit',
  border: 'none',
  color: '$blue225',
  cursor: 'pointer',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: 'ultra-condensed',
  textDecoration: 'underline',

  [`&:hover `]: {
    color: '$black',
  },
});
