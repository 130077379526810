import { useQuery, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';

import { MailviewData } from 'screens/Search/types';

//
// Private hooks
// -------------------------------------------------------------------------------------------------

export const query = loader('./load.graphql');

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: MailviewData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

export function useMailviewData(messageId: string | undefined): HookResult {
  const { data, error, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    skip: !messageId,
    variables: {
      id: messageId,
    },
  });

  if (error) return { data: undefined, error, loading: false };
  if (!data?.response) return { data: undefined, error: null, loading };

  return { data: data.response, error, loading };
}
