import { Box, Cluster, Loadable, Stack } from '@a1s/ui';
import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { ButtonFooter, InfoTooltip, PanelContainer } from '../../../../ui-new';
import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';
import { UpdatesDisplay } from '../../ui';

import { TopBECTargetsBarChart } from './TopBECTargetsBarChart';

import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration, useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

export function TopBECTargetsPanel() {
  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('topBecTargets')} titleDecoration={<Tooltip />}>
      <Stack gap>
        <Top />
        <Bottom />
      </Stack>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Bottom() {
  return (
    <Box pb px>
      <ButtonFooter to="/email/detections/malicious-email/targets">
        <UpdatesDisplay duration="PT15M" />
      </ButtonFooter>
    </Box>
  );
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.topBecTargets', { postProcess: 'markdown' })}</InfoTooltip>;
}

function Top() {
  const duration = useDuration();
  const { data, loading } = useRemoteData({ duration });

  return (
    <Loadable loading={loading}>
      <Box pt px testId="top-bec-targets-top">
        <Cluster justify="stretch">
          <TopBECTargetsBarChart data={data} loading={loading} />
        </Cluster>
      </Box>
    </Loadable>
  );
}

//
// Data fetching
// -------------------------------------------------------------------------------------------------
export interface APIData {
  becTargets: string;
  value: number;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData[];

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

/**
 * Private hook that encapsulates loading the data for the `TopBECTargetsPanel`.
 * Poll the endpoint every 15 minutes.
 */

interface UseRemoteDataType {
  duration: Duration;
}

function useRemoteData({ duration }: UseRemoteDataType): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });

  if (loading) return { data: undefined, error: null, loading: true };
  if (error) return { data: undefined, error, loading: false };

  if (!data?.insightsBecTargets?.data) return { data: undefined, error: null, loading: false };

  // "other" should appear last in the list; will be `undefined` if there is no "other"
  const other = data?.insightsBecTargets?.data.find((d: any) => d.becTargets === 'other');

  // Sort in descending order. TODO: Look into making types for the API values to avoid using `any` here,
  const sorted: APIData[] = (data?.insightsBecTargets.data || [])
    .filter((d: any) => d.becTargets !== 'other')
    .sort((a: any, b: any) => b.value - a.value);

  // If no `other` is returned, it will be `undefined` so we filter that out.
  return { data: [...sorted, ...[other].filter(Boolean)], error: null, loading: false };
}
