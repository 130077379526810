// @flow

import { rem } from 'polished';
import styled from 'styled-components';

export const HEIGHT = 56;

export default styled.header.attrs({ role: 'banner' })`
  height: ${rem(HEIGHT)};
  left: 0;
  min-width: ${rem(1115)};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;
