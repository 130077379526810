"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePrevious = usePrevious;
var _react = require("react");
/**
 * Returns the value that was passed in the last time the component was rendered.
 * This is really useful for accessing what a prop or state was previously.
 */
function usePrevious(value) {
  const ref = (0, _react.useRef)();
  (0, _react.useEffect)(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}