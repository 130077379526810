"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSVG = exports.StyledPath = exports.StyledMonthLabel = exports.StyledLine = exports.StyledLabel = exports.BaseContainer = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
/**
 * Main wrapper for the <BarChart> component.
 */
const BaseContainer = (0, _styled.styled)('section', {
  display: 'flex'
});
exports.BaseContainer = BaseContainer;
const StyledLabel = (0, _styled.styled)('text', {
  fontSize: '$xs',
  fill: '$gray400'
});
exports.StyledLabel = StyledLabel;
const StyledLine = (0, _styled.styled)('path', {
  fill: 'transparent',
  stroke: '$gray400',
  strokeWidth: 0.5
});
exports.StyledLine = StyledLine;
const StyledMonthLabel = (0, _styled.styled)('tspan', {
  fontWeight: '$thin'
});
exports.StyledMonthLabel = StyledMonthLabel;
const StyledPath = (0, _styled.styled)('path', {
  variants: {
    /**
     * Sets the fill of the path
     * @type $colors
     */
    fill: (0, _utils.tokenVariant)('fill', _styled.theme.colors)
  }
});
exports.StyledPath = StyledPath;
const StyledSVG = (0, _styled.styled)('svg', {});
exports.StyledSVG = StyledSVG;