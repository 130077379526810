// @flow

import type { Node } from 'react';
import { connect } from 'react-redux';

import { StateRecord } from 'store/reducers';
import { getCurrentUserPermissions } from 'store/selector/user';

export const permissionTypes = {
  ADMIN: 'admin',
  BETTERMAIL_ENABLED: 'bettermail:enabled',
  BETTERMAIL_ONLY: 'bettermail:only',
  BRS_ENABLED: 'brs:enabled',
  CONFIG_ADMIN: 'configAdmin',
  DEVELOPER: 'developer',
  DIRECTORIES_ENABLED: 'directories:enabled',
  DELEGATED_ACCOUNTS_ENABLED: 'delegatedAccounts:enabled',
  DELEGATED_ACCOUNTS_READ_ONLY: 'delegatedAccounts:readOnly',
  DELEGATED_ROLES_ENABLED: 'delegatedRoles:enabled',
  DEMO_ENABLED: 'demo:enabled',
  ENTERPRISE_ENABLED: 'enterprise:enabled',
  MAILSEARCH_ENABLED: 'mailsearch:enabled',
  MAILSTREAM_ENABLED: 'mailstream:enabled',
  MAILTRACE_ENABLED: 'mailtrace:enabled',
  MALICIOUS_BEC_ENABLED: 'maliciousBec:enabled',
  PHISHGUARD_ENABLED: 'phishguard:enabled',
  READ_WRITE: 'readWrite',
  READ_ONLY: 'readOnly',
  RECURSIVE_DNS: 'recursiveDns:enabled',
  RESUME_PARENT_ENABLED: 'resumeParent:enabled',
  SEARCH_ENABLED: 'search:enabled',
  SETTINGS_READ_ONLY: 'settings:readOnly',
};

export type PermissionsType =
  | 'admin'
  | 'bettermail:enabled'
  | 'bettermail:only'
  | 'brs:enabled'
  | 'configAdmin'
  | 'delegatedAccounts:enabled'
  | 'delegatedAccounts:readOnly'
  | 'delegatedRoles:enabled'
  | 'developer'
  | 'directories:enabled'
  | 'dnsRpz:enabled'
  | 'demo:enabled'
  | 'enterprise:enabled'
  | 'mailsearch:enabled'
  | 'mailstream:enabled'
  | 'mailtrace:enabled'
  | 'maliciousBec:enabled'
  | 'phishguard:enabled'
  | 'recursiveDns:enabled'
  | 'readOnly'
  | 'readWrite'
  | 'resumeParent:enabled'
  | 'search:enabled'
  | 'settings:readOnly';

type Props = {
  acceptablePermissions: PermissionsType[],
  children: (permitted: boolean) => Node,
  userPermissions: PermissionsType[],
};

const AccessControl = ({ acceptablePermissions = [], children, userPermissions = [] }: Props) => {
  const permitted = checkPermissions(userPermissions, acceptablePermissions);

  return children(permitted);
};

const mapStateToProps = (state: StateRecord) => ({
  userPermissions: getCurrentUserPermissions(state),
});

export default connect(mapStateToProps)(AccessControl);

/*
  Private functions
*/
function checkPermissions(userPermissions, acceptablePermissions) {
  if (acceptablePermissions.length === 0) return true;
  return userPermissions.some((permission) => acceptablePermissions.includes(permission));
}
