import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import { useMemo } from 'react';

//
// Message retraction hook
// -------------------------------------------------------------------------------------------------

const MUTATION = gql`
  mutation Clawback($input: ClawbackInput!) {
    clawback(input: $input) @rest(method: "POST", path: "/horizonator/clawback", type: "ClawbackResult") {
      responses {
        destination
        messageId
        status
      }
      statusCode
      statusMessage
    }
  }
`;

interface RetractEmailsMutationParams {
  clientRecipients: string[]; // array of email addresses. It's possible for clientRecipients to be an empty array.
  destination?: DestinationTypes; // If destination isn't passed with params, `fallbackDestination` will be used.
  messageId: string;
}

interface RetractEmailMutationRequest {
  destination: DestinationTypes;
  // eslint-disable-next-line camelcase
  message_id: string;
  recipient: string;
}

interface RetractEmailMutationInput {
  requests: RetractEmailMutationRequest[];
}

export interface RetractEmailMutationResultItem {
  destination: DestinationTypes;
  messageId: string;
  status: string;
}

interface RetractEmailsMutationResult {
  responses: RetractEmailMutationResultItem[];
  statusCode: number;
  statusMessage: string;
}

export const RETRACT_DESTINATIONS = [
  'Inbox',
  'JunkEmail',
  'DeletedItems',
  'RecoverableItemsDeletions',
  'RecoverableItemsPurges',
] as const;

export type DestinationTypes = typeof RETRACT_DESTINATIONS[number];

/**
 * Hook for making clawback requests to the API (Whisk).
 * @param {string} params - Array of objects where each object contains a clientRecipients property which is an array of email address strings and a messageId
 * property and an optional desination property.
 * @param {string} destinationFallback - used when destination isn't passed in `params`
 */
export default function useRetractEmailsMutation(
  params: RetractEmailsMutationParams[],
  destinationFallback: DestinationTypes = 'RecoverableItemsDeletions'
) {
  if (!Array.isArray(params)) throw new Error('You must pass an array to useRetractEmailsMutation');

  const options: MutationHookOptions<any, { input: RetractEmailMutationInput }> = {};

  const sanitizedRetractParams = useMemo(() => {
    return params?.filter((row: RetractEmailsMutationParams) => row.clientRecipients?.length !== 0);
  }, [params]);

  if (sanitizedRetractParams.length > 0) {
    const requests = sanitizedRetractParams.reduce(
      // eslint-disable-next-line camelcase
      (acc: object[], { clientRecipients, destination = destinationFallback, messageId: message_id }) => {
        const recipients = clientRecipients?.map((recipient: string) => ({
          destination,
          message_id,
          recipient,
        }));

        return [...acc, ...recipients];
      },
      [] as RetractEmailsMutationParams[]
    );

    options.variables = {
      input: {
        requests: requests as RetractEmailMutationRequest[],
      },
    };
  }

  return useMutation<{ clawback: RetractEmailsMutationResult }, { input: RetractEmailMutationInput }>(
    MUTATION,
    options
  );
}
