import axios from 'axios';
import { useEffect, useState } from 'react';

export default function useEndpointValue<T>(endpoint: string, key: string, initial: T): T {
  const [value, setValue] = useState<T>(initial);

  useEffect(() => {
    axios.get<Record<typeof key, T>>(endpoint).then(({ data }) => setValue(data?.[key]));
  }, [endpoint, key]); // eslint-disable-line react-hooks/exhaustive-deps

  return value;
}
