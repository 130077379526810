// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { HEIGHT as HEADER_HEIGHT } from '../App/Header';

export const HEIGHT = 48;

export default styled.div`
  height: ${rem(HEIGHT)};
  left: 0;
  min-width: ${rem(1115)};
  position: fixed;
  right: 0;
  top: ${rem(HEADER_HEIGHT)};
  z-index: 1;
`;
