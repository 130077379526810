import React, { ComponentProps } from 'react';

import { StyledDiv } from './styled';

export interface MaxHeightProps {
  children: ComponentProps<typeof StyledDiv>['children'];
  height: number;
}

export function MaxHeight({ children, height }: MaxHeightProps) {
  return <StyledDiv css={{ height }}>{children}</StyledDiv>;
}
