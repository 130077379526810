import { Stack, Text } from '@a1s/ui';
import React, { ComponentProps } from 'react';

import { LargeNumber } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface LabeledNumberProps {
  children?: ComponentProps<typeof LargeNumber>['children'];
  color?: ComponentProps<typeof Text>['color'];
  label?: string;
  value?: ComponentProps<typeof LargeNumber>['value'];
}

export function LabeledNumber({ children, color = '$gray600', label, value }: LabeledNumberProps) {
  return (
    <Stack gap="1">
      <Text
        color={color}
        css={{ lineBreak: 'strict' }}
        font="sans"
        size="sm"
        stretch="ultraCondensed"
        transform="uppercase"
        weight="medium"
      >
        {label}
      </Text>
      <LargeNumber color={color} value={value}>
        {children}
      </LargeNumber>
    </Stack>
  );
}
