// @flow

import { mix } from 'polished';
import React from 'react';
import { withTheme } from 'styled-components';

import Graph from './Graph';
import Label from './Label';
import Tr from './Tr';

import type { Theme } from 'config/theme';

export type Tint = 'blue' | 'dark' | 'green' | 'none' | 'aquamarine' | 'sulu';

type Props = { hideValue: boolean, label: string, percent: number, value: number, tint: Tint, theme: Theme };
const Row = ({ hideValue, label, percent, tint, value, theme }: Props) => [
  <tr>
    <Label>{label}</Label>
  </tr>,
  <Tr percent={percent}>
    <Graph
      hideValue={hideValue}
      style={{ backgroundColor: mix(percent, theme.tints[tint].from, theme.tints[tint].to) }}
      percent={percent}
    >
      {value}
    </Graph>
  </Tr>,
];

export default withTheme(Row);
