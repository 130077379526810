// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div`
  align-items: center;
  border-radius: ${rem(4)};
  display: inline-flex;
  grid-template-columns: ${rem(48)} 1fr;
  padding-right: ${({ boxed, theme }) => rem(boxed ? theme.spacing.xs : theme.spacing.md)};

  ${({ boxed, theme }) =>
    boxed &&
    css`
      background-color: ${theme.colors.mystic};
    `};
`;

export default Container;
