// @flow

import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledRule = styled.hr`
  background-color: ${({ theme }) => theme.colors.tuscanBrown};
  border: 0;
  height: ${rem(1)};
  margin: ${({ theme, margin }) => rem(theme.spacing[margin])} auto;

  ${({ expanded }) =>
    !expanded &&
    css`
      width: 80%;
    `};
`;

type Props = { dataTestId?: string, expanded?: boolean, margin?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' };

const Rule = ({ dataTestId = 'atom-rule', expanded = true, margin = 'xl' }: Props) => (
  <StyledRule data-testid={dataTestId} expanded={expanded} margin={margin} />
);

export default Rule;
