// @flow

// $FlowFixMe
import { useQuery } from '@apollo/client';
import { gql } from 'graphql.macro';
import { get } from 'lodash';
import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { useParams, withRouter, type ContextRouter, Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';

import AppScreen from './screens/App';

import InviteScreen from './screens/Invite';
import InviteExpiredScreen from './screens/Invite/Expired';
import LoginScreen from './screens/Login';
import { fetchUser } from './store/actions/user';
import { StateRecord } from './store/reducers';
import { getIsAuthenticated, getRequestComplete } from './store/selector/user';
import Centered from './ui/atoms/Centered';
import Loading from './ui/atoms/Loading';
import PageNotFound from './ui/organisms/PageNotFound';
import { AuthRoute, UnauthRoute } from './utils/Route';

type Props = { authenticated?: boolean, fetchUser: typeof fetchUser, requestCompleted?: boolean } & ContextRouter;

function trackGAPageview(location) {
  const { pathname, search } = location;
  ReactGA.pageview(pathname + search);
}

class Router extends PureComponent<Props> {
  componentDidMount() {
    /* eslint-disable react/destructuring-assignment */
    this.props.fetchUser();
    /* eslint-enable react/destructuring-assignment */
  }

  componentDidUpdate(oldProps: Props) {
    /* eslint-disable react/destructuring-assignment */
    if (oldProps.authenticated === true && this.props.authenticated === false) {
      // Instead of using React-Router, go head and tell the browser to reload the page to the login page
      // so it clears all of the Redux data out of memory.
      window.location = '/users/login';
    } else if (oldProps.authenticated === false && this.props.authenticated === true) {
      const { location } = this.props;
      if (location.state && location.state.from) {
        this.props.history.push(location.state.from.pathname);
      }
    } else if (this.props.location.pathname === '/cloudflare/login') {
      this.props.history.push('/users/login', { cloudflare_link: true });
    }

    if (this.props.location !== oldProps.location) {
      window.scrollTo(0, 0);
    }

    /* Google Analytics tracking */
    trackGAPageview(window.location);
    /* End Google Analytics tracking */
    /* eslint-enable react/destructuring-assignment */
  }

  render() {
    const { authenticated, requestCompleted } = this.props;

    if (!requestCompleted)
      return (
        <Centered>
          <Loading />
        </Centered>
      );

    return (
      <>
        <Switch>
          <UnauthRoute authenticated={authenticated} component={LoginScreen} path="/users/login" />

          <Route component={InviteExpiredScreen} path="/invite/expired" />
          <Route component={InviteScreen} path="/invite/:token" />

          <Route component={CheckURLForTFAReset} path="/tfa-reset/:token" />

          <Route path="/logout" />
          <AuthRoute authenticated={authenticated} component={AppScreen} path="/" />

          <Route path="*" component={PageNotFound} status={404} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state: StateRecord) => ({
  authenticated: getIsAuthenticated(state),
  requestCompleted: getRequestComplete(state),
});

export default compose(withRouter, connect(mapStateToProps, { fetchUser }))(Router);

//
// Private component
// -------------------------------------------------------------------------------------------------

const query = gql`
  query CheckURL($token: TokenType!) {
    checkURL(token: $token) @rest(endpoint: "currentUser", path: "/users/once/{args.token}", type: "CurrentUser") {
      email
      userId
    }
  }
`;

function CheckURLForTFAReset() {
  const { token } = useParams();
  const { data, error, loading } = useQuery(query, { skip: !token, variables: { token } });

  if (!token) return <Redirect to="/users/login" />;

  if (loading) {
    return (
      <Centered>
        <Loading />
      </Centered>
    );
  }

  if (error || !data) return <Redirect to="/users/login" />;

  return <Redirect to={{ pathname: '/users/login', state: { tfa_reset_email: get(data, 'checkURL.email', null) } }} />;
}
