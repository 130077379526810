// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.deepSkyBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(14)};
  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.colors.starCommandBlue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.bleuDeFrance};
    text-decoration: underline;
  }
`;

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
