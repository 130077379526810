// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Percent = styled.div`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fonts.din};
  font-size: ${rem(24)};
  font-weight: 500;
  line-height: 1;
  margin-bottom: ${({ theme }) => rem(theme.spacing.sm)};
`;

export default Percent;
