// @flow

// $FlowFixMe
import { Map, UIProvider } from '@a1s/ui-deprecated';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import NoData from 'ui/molecules/NoData';

type Props = {
  data: Array<{ count: number, lat: number, lng: number, location: string }>,
  error: boolean,
  loading: boolean,
};

export default function GeoOrigins({ data, error, loading }: Props) {
  // TODO: Width observer and pass width/height to Map as props

  const { t } = useTranslation('email');

  const dataPresent = data && data.length > 0;
  const formattedData = (data || []).map(({ count, lat, lng, location }) => {
    return { count, latitude: lat, location, longitude: lng };
  });

  return (
    <ContentBlock
      dataTestId="top-geo-origins"
      top={
        <>
          <BlockHeader title={t('threatOrigins')} />
          <ConditionalRender condition={loading}>
            <Loading />
          </ConditionalRender>
          <ConditionalRender condition={error || (!loading && !dataPresent)}>
            <NoData message="noResults" />
          </ConditionalRender>
          <ConditionalRender condition={dataPresent && !error && !loading}>
            <UIProvider>
              <Map points={formattedData} />
            </UIProvider>
          </ConditionalRender>
        </>
      }
    />
  );
}
