// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import NavLink from 'ui/atoms/NavLink';

const BORDER_WIDTH = 2;

const Link = styled(NavLink)`
  background-color: transparent;
  border-left: ${rem(BORDER_WIDTH)} solid transparent;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.independence};
  display: block;
  font-size: ${rem(14)};
  font-weight: 400;
  margin-top: ${({ theme }) => rem(theme.spacing.xs)};
  padding: ${({ theme }) => rem(theme.spacing.xs)};
  padding-left: ${({ theme }) => rem(theme.spacing.md - BORDER_WIDTH)};

  &:first-child {
    margin-top: 0;
  }

  &[aria-current='page'] {
    background-color: transparent;
    border-left-color: ${({ theme }) => theme.colors.deepSkyBlue};
    color: ${({ theme }) => theme.colors.deepSkyBlue};
    font-weight: 500;
  }
`;

Link.displayName = 'Link';

export default Link;
