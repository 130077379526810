import { Box, Cluster, Loadable, SegmentChart, Separator, Stack, Text } from '@a1s/ui';
import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { startCase } from 'lodash';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { PanelContainer, ButtonFooter, InfoTooltip } from '../../../../ui-new';

import { Searchable } from '../../../shared/Searchable';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';
import { UpdatesDisplay } from '../../ui';
import { filterAndSortData } from '../dataTypesAndUtils';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration, useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

export function MaliciousThreatTypePanel() {
  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('maliciousThreatType')} titleDecoration={<Tooltip />}>
      <Box p>
        <Stack gap>
          <Chart />
          <ButtonFooter testId="malicious-threat-type-bottom" to="/email/detections/malicious-email/threat-types">
            <UpdatesDisplay duration="PT15M" />
          </ButtonFooter>
        </Stack>
      </Box>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Chart() {
  const duration = useDuration();
  const { data, loading } = useRemoteData({ duration });

  const colors = ['pink500', 'purple600', 'teal700', 'blue400', 'teal500', 'gray300'];
  const { t } = useTranslation('dashboardHome');

  return (
    <Loadable loading={loading}>
      <ConditionalRender
        condition={!loading && data?.length === 0}
        fallback={
          <SegmentChart.Loadable placeholderCount={6} testId="malicious-threat-type-chart">
            {data?.map(({ count, percentage, threatType }, i) => {
              return (
                <SegmentChart.Segment color={colors[i]} key={threatType} value={count}>
                  <ThreatType name={threatType} percent={Math.round(percentage * 100).toString()} />
                </SegmentChart.Segment>
              );
            })}
          </SegmentChart.Loadable>
        }
      >
        <Box pb>
          <Text color="$gray400" size="sm" stretch="ultraCondensed">
            {t('noThreatTypes')}
          </Text>
        </Box>
      </ConditionalRender>
    </Loadable>
  );
}

interface ThreatTypeProps {
  name: string;
  percent: string;
}

function ThreatType({ name, percent }: ThreatTypeProps) {
  const { t } = useTranslation('threatTypes');

  return (
    <Cluster align="center" gap="3" justify="start">
      <Searchable
        params={{
          metric: `threat_cats_blocking:${name}`,
        }}
      >
        <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="medium">
          {t(startCase(name))}
        </Text>
      </Searchable>

      <Separator color="gray800" />

      <Text color="$gray400" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="regular">
        {percent}%
      </Text>
    </Cluster>
  );
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.maliciousThreatType', { postProcess: 'markdown' })}</InfoTooltip>;
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

/**
 * Represents threat-type data that has been returned from the insights API
 */
interface APIData {
  /**
   * The number of instances of this particular threat-type.
   */
  count: number;

  /**
   * The percentage of all the threat types for this particular threat-type. The value is represented as
   * a fraction of 100 (e.g. 58% == 0.58).
   */
  percentage: number;

  /**
   * A key-safe string representing the threat type.
   */
  threatType: string;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data: APIData[] | null;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

/**
 * The data as returned back from the GraphQL query (which, right now, matches the responses of the API).
 */
interface QueryResult {
  maliciousThreatTypesData: {
    data: Array<{ threatType: string; value: number }>;
  };
}

const query = loader('./load.graphql');

/**
 * Private hook that encapsulates loading the data for the `DetectionStatsPanel`.
 * Poll the endpoint every 15 minutes.
 */

interface UseRemoteDataType {
  duration: Duration;
}

function useRemoteData({ duration = '30' }: UseRemoteDataType): HookResult {
  const { data, error, loading } = useQuery<QueryResult>(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });
  if (loading) return { data: null, error: null, loading: true };
  if (error) return { data: null, error, loading: false };

  if (!data?.maliciousThreatTypesData?.data) return { data: null, error: null, loading: false };

  // The chart only displays the top 10 threat types, so sort the threat types first.
  const sortedThreatTypes = filterAndSortData(
    { filterBy: 'threatType', sortBy: 'value' },
    data.maliciousThreatTypesData?.data
  );

  // Calculate the total from the threat types returned from the API and calculate the percentage
  // for each of the type five threat types.
  const total = sortedThreatTypes.reduce((acc, val) => acc + val.value, 0) || 1;
  const returned = sortedThreatTypes.slice(0, 10).map(({ threatType, value }): APIData => {
    return { count: value, percentage: value / total, threatType };
  });

  return { data: returned, error: null, loading: false };
}
