// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import { insightsMaliciousNewDomainsQuery } from './dataTypesAndUtils';

import MostCommonTopLevelDomains from './MostCommonTopLevelDomains';
import RecentlyRegisteredDomains from './RecentlyRegisteredDomains';

import { DaysBackContext } from 'screens/App';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';

export default function MaliciousNewDomainsOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(insightsMaliciousNewDomainsQuery, {
    variables: setInsightsDateRangeFromDuration(currentInterval),
  });
  const domainsCurrent = get(data, 'maliciousNewDomains.data.timeline.current', []);
  const domainsPrevious = get(data, 'maliciousNewDomains.data.timeline.previous', []);
  const totalDomains = get(data, 'maliciousNewDomains.data.totals', { current: 0, previous: 0 });
  const commonDomains = get(data, 'maliciousNewDomains.data.breakdown.current', {});

  return (
    <>
      <Helmet title={`${t('newDomains')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <>
        <RecentlyRegisteredDomains
          data={{
            lineGraph: {
              current: domainsCurrent,
              previous: domainsPrevious,
            },
            ringChart: calculatePercent(totalDomains.current, detectionCount),
            sectionOverview: {
              current: totalDomains.current,
              previous: totalDomains.previous,
            },
          }}
          error={error || detectionError}
          loading={loading || detectionLoading}
        />
        <MostCommonTopLevelDomains error={error} loading={loading} data={commonDomains} />
      </>
    </>
  );
}
