// @flow

import { subDays } from 'date-fns'; // 👈 for the mock data. Remove when real data is there.
import React from 'react';
import { useTranslation } from 'react-i18next';

import LineGraph from 'ui/organisms/LineGraph';

const data = [
  { date: subDays(new Date(), 0), value: 920 },
  { date: subDays(new Date(), 1), value: 1254 },
  { date: subDays(new Date(), 2), value: 2305 },
  { date: subDays(new Date(), 3), value: 2832 },
  { date: subDays(new Date(), 4), value: 1249 },
  { date: subDays(new Date(), 5), value: 1044 },
  { date: subDays(new Date(), 6), value: 1923 },
];
const previousData = [
  { date: subDays(new Date(), 7), value: 2034 },
  { date: subDays(new Date(), 8), value: 1938 },
  { date: subDays(new Date(), 9), value: 1254 },
  { date: subDays(new Date(), 10), value: 203 },
  { date: subDays(new Date(), 11), value: 1827 },
  { date: subDays(new Date(), 12), value: 1562 },
  { date: subDays(new Date(), 13), value: 1723 },
];

interface Props {
  placeholderText?: string;
}

export default function PlaceholderLineGraph(props: Props) {
  const { t } = useTranslation('components');

  const { placeholderText = t('NoData.noDataObserved') } = props;

  return (
    <LineGraph
      currentLineProps={{}}
      data={data}
      placeholder
      placeholderText={placeholderText}
      previousData={previousData}
      previousLineProps={{}}
      tint="dark"
    />
  );
}
