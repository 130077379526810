// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Number = styled.span`
  color: ${({ theme }) => theme.colors.darkSlateGray};
  font-family: ${({ theme }) => theme.fonts.din};
  font-size: ${rem(24)};

  ${({ size }) => {
    if (size === 'medium')
      return css`
        font-size: ${rem(20)};
      `;

    if (size === 'small')
      return css`
        font-size: ${rem(16)};
      `;

    if (size === 'tiny')
      return css`
        font-size: ${rem(14)};
      `;

    return null;
  }};
`;

export default Number;
