import { Box, Button, Cluster, Stack, Text } from '@a1s/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { CfAccountType } from './shared/types';
import { P, Title } from './shared/typography';

import { CloseButton, Dialog } from 'ui-new';

export const SuccessDialog = ({ account, success }: { account?: CfAccountType; success: boolean }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation('linkCfAccount');

  useEffect(() => {
    if (!!account && !!success) setShowModal(true);
  }, [account, success]);

  return (
    <Dialog maxWidth onClose={() => window.location.reload()} visible={showModal}>
      <Box p>
        <Stack gap="4">
          <Cluster gap>
            <Title>{t('linkAccountSuccessTitle')}</Title>
            <CloseButton onPress={() => setShowModal(false)} />
          </Cluster>
          <Trans i18nKey="linkAccountSuccessText">
            <P>
              Your Cloudflare account{' '}
              <Text weight="bold">
                {account?.name} ({account?.id})
              </Text>{' '}
              has been linked successfully to this Area 1 account. If you think you may have accidentally linked the
              wrong account, please contact support.
            </P>
          </Trans>
          <Cluster justify="center">
            <Button onPress={() => setShowModal(false)}>{t('close')}</Button>
          </Cluster>
        </Stack>
      </Box>
    </Dialog>
  );
};
