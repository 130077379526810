import { Cluster, Stack, Text } from '@a1s/ui';
import { useApolloClient } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, ModalHeader, ReleaseButton, ReportButton, RetractButton } from '..';
import { canMarkAsFalsePositive } from '../../lib';
import { Dispositions, ReleaseParams, FPReportParams, RetractParams, SearchResultRow } from '../../types';

import { useSearchContext } from 'screens/Search/lib/searchContext';
import Loading from 'ui/atoms/Loading';

//
// Main component
// -------------------------------------------------------------------------------------------------

type SearchCounts = Record<'detections' | 'total', number>;

interface SectionHeaderProps {
  countsLoading: boolean;
  dateRange: [string, string] | unknown;
  messageIds: Array<SearchResultRow['messageId']>;
  onSelectAll?: ComponentProps<typeof SelectAll>['onPress'];
  params: ComponentProps<typeof ModalHeader>['params'];
  searchCounts?: SearchCounts;
  selectedIds: Array<SearchResultRow['id']>;
}

/**
 * Displays the header of each search section.
 */
export function SectionHeader({
  countsLoading,
  dateRange,
  onSelectAll,
  params,
  searchCounts,
  selectedIds,
}: SectionHeaderProps) {
  const { releaseParams, reportParams, retractParams } = useMessageData(selectedIds);
  const { clawbackEnabled, retractEnabled, userPermitted } = useSearchContext();
  const [selectAll, setSelectAll] = useState(false);

  // Uncheck the search all button when a new search is run
  const deps = JSON.stringify(params);
  useEffect(() => {
    setSelectAll(false);
  }, [deps]);

  function handlePressed(all: boolean) {
    if (onSelectAll) onSelectAll(all);
    setSelectAll(all);
  }

  return (
    <Cluster align="center" justify="space-between">
      <Cluster gap="5">
        {userPermitted && (
          <>
            <SelectAll onPress={handlePressed} checked={selectAll} />
            <ReleaseButton appearance="faded" disabled={releaseParams.length === 0} releaseParams={releaseParams} />
            {retractEnabled && (
              <RetractButton
                appearance="faded"
                clawbackFeatureEnabled={clawbackEnabled}
                disabled={retractParams.length === 0}
                retractParams={retractParams}
              />
            )}
            <ReportButton disabled={reportParams.length === 0} reportParams={reportParams} />
          </>
        )}
      </Cluster>

      <Text as="div" color="$white" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="light">
        <Stack justify="end">
          <DisplayCounts loading={countsLoading} searchCounts={searchCounts} /> <DateRange dateRange={dateRange} />
        </Stack>
      </Text>
    </Cluster>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function DateRange({ dateRange }: Pick<SectionHeaderProps, 'dateRange'>) {
  const { t } = useTranslation('const');

  if (!isDateRange(dateRange)) return null;
  return (
    <Text css={{ opacity: 0.8, textAlign: 'right' }} weight="medium">
      {`${format(parseISO(dateRange[0]), t('formats.dateLongWithTimezone'))} -
        ${format(parseISO(dateRange[1]), t('formats.dateLongWithTimezone'))}`}
    </Text>
  );
}

interface DisplayCountsProps {
  loading: boolean;
  searchCounts: SectionHeaderProps['searchCounts'];
}

function DisplayCounts({ loading, searchCounts }: DisplayCountsProps) {
  const { t } = useTranslation('unisearch');

  if (loading) return <Loading small />;

  if (!searchCounts) return null;

  if (searchCounts.total === searchCounts.detections)
    return (
      <Text css={{ textAlign: 'right' }} weight="semibold">
        {t('showingEmails', { count: searchCounts.total })}
      </Text>
    );

  return (
    <Text css={{ textAlign: 'right' }} weight="semibold">
      {t('showingEmails', { count: searchCounts.total })}{' '}
      <Text css={{ opacity: 0.8 }}>{t('showingEmailDetections', { count: searchCounts.detections })}</Text>
    </Text>
  );
}

interface SelectAllProps {
  checked: ComponentProps<typeof Checkbox>['checked'];
  // eslint-disable-next-line
  onPress?(shouldSelectAll: boolean): void;
}

function SelectAll({ checked = false, onPress }: SelectAllProps) {
  const { t } = useTranslation('unisearch');

  return (
    <Cluster align="center" gap="2">
      <Checkbox checked={checked} onChange={() => onPress && onPress(!checked)} />
      <Text color="$white" font="roboto" stretch="ultraCondensed">
        {t('selectAll')}
      </Text>
    </Cluster>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function isDateRange(value: [string, string] | unknown): value is [string, string] {
  return Array.isArray(value);
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------
interface ApolloRecordType {
  alertId: string;
  clientRecipients: string[];
  finalDisposition: Dispositions;
  id: string;
  isQuarantined: boolean;
  messageId: string;
  storedAt: string;
  __typename: string;
}

interface MessageCollection {
  alertId: SearchResultRow['alertId'];
  clientRecipients: SearchResultRow['clientRecipients'];
  finalDisposition: SearchResultRow['finalDisposition'];
  isQuarantined: SearchResultRow['isQuarantined'];
  messageId: SearchResultRow['messageId'];
  storedAt: SearchResultRow['storedAt'];
}

function useMessageData(selectedIds: Array<SearchResultRow['id']>): {
  releaseParams: ReleaseParams[];
  reportParams: FPReportParams[];
  retractParams: RetractParams[];
} {
  const client = useApolloClient();

  const messages: MessageCollection[] = useMemo(() => {
    const data: Record<string, ApolloRecordType | null> | null = get(client, 'cache.data.data');
    if (!data) return [];

    // eslint-disable-next-line no-underscore-dangle
    const rawMessages = Object.values(data).filter((row) => row?.__typename === 'UnisearchMessage');

    return rawMessages
      .filter((record) => {
        const id = record?.id;
        if (!id) return false;
        return selectedIds.includes(id);
      })
      .reduce((acc, record) => {
        if (!record) return acc;

        const message = {
          alertId: record.alertId,
          clientRecipients: record.clientRecipients,
          finalDisposition: record.finalDisposition,
          isQuarantined: record.isQuarantined,
          messageId: record.messageId,
          storedAt: record.storedAt,
        };

        return [...acc, ...[message]];
      }, [] as MessageCollection[]);
  }, [client, selectedIds]);

  const releaseParams: ReleaseParams[] = useMemo(() => {
    return messages.reduce(
      (
        acc: { clientRecipients: string[]; storedAt: string }[],
        row: { clientRecipients: string[]; isQuarantined: boolean; storedAt: string }
      ) => {
        const { clientRecipients, isQuarantined, storedAt } = row;
        // We only want to release messaages where `isQuarantined` is true.
        if (isQuarantined) return [...acc, ...[{ clientRecipients, storedAt }]];
        return acc;
      },
      []
    );
  }, [messages]);

  const reportParams: FPReportParams[] = useMemo(() => {
    return messages.reduce(
      (acc: { alertId: string; expectedDisposition: string }[], row: { alertId: string; finalDisposition: string }) => {
        const { alertId, finalDisposition } = row;
        if (!canMarkAsFalsePositive(finalDisposition as Dispositions)) return acc;
        return [...acc, ...[{ alertId, expectedDisposition: 'BENIGN' }]];
      },
      []
    );
  }, [messages]);

  const retractParams: RetractParams[] = useMemo(() => {
    return messages.reduce((acc, message) => {
      const { clientRecipients, isQuarantined, messageId } = message;
      // Messages in quarantine cannot be retracted
      if (!isQuarantined) return [...acc, ...[{ clientRecipients, messageId }]];
      return acc;
    }, [] as RetractParams[]);
  }, [messages]);

  return { releaseParams, reportParams, retractParams };
}
