// @flow

import { loader } from 'graphql.macro';

export type SuspiciousTargetedRecipient = {
  address?: string,
  count?: number,
};

type SuspiciousTargetsType = {
  previousSuspiciousTargetedRecipients: Array<SuspiciousTargetedRecipient>,
  suspiciousTargetedRecipients: Array<SuspiciousTargetedRecipient>,
};

export const suspiciousTargetsQuery = loader('./queries/SuspiciousTargetsQuery.graphql');

export const suspiciousTargetsRecord: SuspiciousTargetsType = {
  previousSuspiciousTargetedRecipients: [],
  suspiciousTargetedRecipients: [],
};
