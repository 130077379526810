// @flow

import { rem } from 'polished';
import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

/**
 * Wrap the link and strip out our custom style props that are displaying console.error
 */

type Props = {
  dataTestId?: string,
  lined?: boolean,
  to?: string,
};

function WrappedLink({ dataTestId = 'atom-nav-link', lined, to = '#', ...rest }: Props) {
  return <Link data-testid={dataTestId} to={to} {...rest} />;
}

const NavLink = styled(WrappedLink)`
  align-items: center;
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.colors.grayBlue};
  display: inline-flex;
  font-size: ${rem(12)};
  font-weight: 600;
  justify-content: center;
  padding: ${({ theme }) => rem(theme.spacing.sm)} ${({ theme }) => rem(theme.spacing.md)};
  text-decoration: none;
  transition: color 150ms ${({ theme }) => theme.timing.easeOutCirc};
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: ${({ theme }) => theme.colors.onyx};
  }

  &[aria-current='page'] {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
    color: ${({ theme }) => theme.colors.deepSkyBlue};
  }

  ${({ lined }) =>
    lined &&
    css`
      border-bottom: ${rem(2)} solid transparent;
      border-radius: 0;
      border-top: ${rem(2)} solid transparent;
      height: 100%;
      margin: 0 ${({ theme }) => rem(theme.spacing.md)};
      padding: 0;

      &[aria-current='page'] {
        background-color: inherit;
        border-bottom-color: ${({ theme }) => theme.colors.deepSkyBlue};
        color: ${({ theme }) => theme.colors.deepSkyBlue};
      }

      &:first-child {
        margin-left: 0;
      }
    `};
`;

NavLink.displayName = 'NavLink';

export default NavLink;
