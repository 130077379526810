"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fontWeights = void 0;
const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extraBold: 800,
  heavy: 900
};
exports.fontWeights = fontWeights;