// @flow

import { Record } from 'immutable';

import type { RecordFactory } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { currentUser, initialCurrentUserState, type CurrentUserState } from './user';

type StateType = {
  currentUser: CurrentUserState,
};

const defaultRecord: StateType = {
  currentUser: initialCurrentUserState,
};
export const StateRecord: RecordFactory<StateType> = Record(defaultRecord);

export const initialState = new StateRecord(defaultRecord);
export type State = typeof initialState;

export default combineReducers(
  {
    currentUser,
  },
  StateRecord
);
