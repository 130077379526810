import { Box, HorizontalBars, Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Searchable } from '../../../shared/Searchable';
import { placeholderData } from '../../lib';
import { LabeledValue } from '../../ui';

import { APIData } from '.';

import ConditionalRender from 'ui/atoms/ConditionalRender';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface TopBECTargetsBarChartProps {
  data?: APIData[];
  loading: boolean;
}

export function TopBECTargetsBarChart({ data, loading }: TopBECTargetsBarChartProps) {
  const { t } = useTranslation('dashboardHome');

  const nothingToDisplay = !loading && data?.length === 0;

  return (
    <ConditionalRender
      condition={!nothingToDisplay}
      fallback={
        <Box pb>
          <Text color="$gray400" size="sm" stretch="ultraCondensed">
            {t('noBecTargets')}
          </Text>
        </Box>
      }
    >
      <HorizontalBars gap="2">
        {(data || placeholderData(6))?.map(({ becTargets, value }) => {
          return (
            <HorizontalBars.Bar color="$gray100" key={becTargets} value={value}>
              <Text.Loadable placeholder="-- | 0" testId="email-placeholder">
                <Searchable
                  params={{
                    disposition: '',
                    metric: `bec_email_target:${becTargets}`,
                  }}
                >
                  <LabeledValue childrenColor="$gray600" gap="2" transform="lowercase" value={value} weight="medium">
                    {becTargets}
                  </LabeledValue>
                </Searchable>
              </Text.Loadable>
            </HorizontalBars.Bar>
          );
        })}
      </HorizontalBars>
    </ConditionalRender>
  );
}
