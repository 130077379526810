// @flow

import hash from 'hash-sum';
import { range } from 'lodash';
import { rem } from 'polished';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Body } from '../Table';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Cell = styled.td`
  border-left: ${(p) => p.theme.colors.aliceBlue} solid 1px;
  border-right: ${(p) => p.theme.colors.aliceBlue} solid 1px;
  font-size: rem(11);
  padding: ${(p) => rem(p.theme.spacing.sm)};
  text-align: right;

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
`;

const Head = styled(Cell)`
  &[scope='row'] {
    text-align: left;
  }
`;

const Row = styled.tr`
  /* stylelint-disable */
  &:first-of-type ${Head} {
    background-color: ${(p) => p.theme.colors.aliceBlue};
    border: ${(p) => p.theme.colors.aliceBlue} solid 1px;
    color: ${(p) => p.theme.colors.dimGray};
  }
  /* stylelint-enable */

  &:nth-child(odd) {
    background-color: ${(p) => p.theme.colors.ghostWhite};
  }
`;

const Table = styled.table`
  border: ${(p) => p.theme.colors.aliceBlue} solid 1px;
  border-collapse: collapse;
  color: ${(p) => p.theme.colors.lightSlateGray};
  font-size: ${rem(13)};
  width: 100%;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type Data = { [group: string]: { date: Date, value: number }[] };
type LineGraphTypes =
  | ''
  | 'mailBec'
  | 'mailBulk'
  | 'mailMalicious'
  | 'mailSpam'
  | 'mailSpoof'
  | 'mailSuspicious'
  | string;

interface Props {
  data: Data;
  dataTestId?: string;
  renderHeader?: (group: LineGraphTypes) => Node;
}

export default function DataTable({ data, dataTestId = 'molecule-data-table', renderHeader }: Props) {
  const { t } = useTranslation('components');
  const groups = Object.keys(data);

  // Figure out how many date we have by looking at the first group.
  const indexes = range(data[groups[0]].length);

  const renderTableHead = () => {
    if (typeof renderHeader === 'undefined') return null;

    return (
      <>
        <Head as="th" />
        {groups.map((group) => (
          <Head as="th" key={group} scope="col">
            {renderHeader(group)}
          </Head>
        ))}
      </>
    );
  };

  return (
    <Table data-testid={dataTestId}>
      <Body>
        <Row>{renderTableHead()}</Row>

        {indexes.map((i) => (
          <Row key={hash(`${data[groups[0]][i].date}-${i}`)}>
            <Head as="th" scope="row">
              {t('const:DatePicker.dateShortMonth', { date: new Date(data[groups[0]][i].date) })}
            </Head>

            {groups.map((group) => (
              <Cell key={group}>{data[group][i] ? t('Statistic.number', { value: data[group][i].value }) : 0}</Cell>
            ))}
          </Row>
        ))}
      </Body>
    </Table>
  );
}
