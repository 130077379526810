import { styled } from '@a1s/ui';

export const Dialog = styled('dialog', {
  background: '$white',
  borderRadius: '$4',
  borderTop: '$colors$orange500 solid',
  borderWidth: '4px 0 0 0',
  boxShadow: '$1',
  margin: '0 auto',
  overflow: 'hidden',
  overflowY: 'auto',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 999,

  '&::backdrop': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken',
    backdropFilter: 'blur(5px)',
    WebkitBackdropFilter: 'blur(5px)',
  },

  variants: {
    open: {
      true: {
        visibility: 'visible',
      },
    },
    maxWidth: {
      true: {
        maxWidth: '50%',
      },
    },
  },
});
