import { styled } from '@a1s/ui';
import React, { useState, ButtonHTMLAttributes } from 'react';

export function Menu({ children }: React.PropsWithChildren<{}>) {
  const [isExpanded, setIsExpanded] = useState(false);

  function handleBlur() {
    setIsExpanded(false);
  }

  function handleClick() {
    setIsExpanded(!isExpanded);
  }

  return (
    <Container>
      <Button aria-expanded={isExpanded} onBlur={handleBlur} onClick={handleClick} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 2">
          <circle cx="1" cy="1" r="1" fill="#000" />
          <circle cx="9" cy="1" r="1" fill="#000" />
          <circle cx="5" cy="1" r="1" fill="#000" />
        </svg>
      </Button>
      <Items>{children}</Items>
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

function Item({ children, ...rest }: React.PropsWithChildren<ItemProps>) {
  return (
    <MenuItem>
      <button type="button" {...rest}>
        {children}
      </button>
    </MenuItem>
  );
}

Menu.Item = Item;

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Button = styled('button', {
  $$color: '$colors$gray500',
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderColor: '$$color',
  borderRadius: '$2',
  borderStyle: 'solid',
  borderWidth: 1,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '$3',

  '& svg': { height: 2.5, width: 'auto' },
  '& svg circle': { fill: '$$color' },

  '&:hover': { $$color: '$colors$gray600', backgroundColor: '$gray100' },
  '&:active': { $$color: '$colors$gray700', backgroundColor: '$gray150' },

  '&[aria-expanded="true"]': {
    backgroundColor: '$gray400',
    borderColor: '$gray400',
    '& svg circle': { fill: '$white' },
  },
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const Items = styled('menu', {
  backgroundColor: '$white',
  borderRadius: '$2',
  bottom: 0,
  boxShadow: '$1',
  display: 'none',
  listStyle: 'none',
  overflow: 'hidden',
  position: 'absolute',
  right: 0,
  transform: 'translateY(100%)',
  zIndex: 999,

  '[aria-expanded="true"] + &': {
    display: 'block',
  },
});

const MenuItem = styled('li', {
  '& button': {
    backgroundColor: 'transparent',
    border: 0,
    color: '$gray700',
    cursor: 'pointer',
    fontFamily: '$sans',
    fontSize: '$sm',
    fontWeight: '350',
    fontStretch: '50%',
    textTransform: 'uppercase',
    minWidth: '$md',
    px: '$3',
    py: '$3',
    textAlign: 'left',

    '&:hover': { backgroundColor: '$gray100', color: '$black' },
    '&:disabled': { backgroundColor: 'transparent', color: '$gray500', cursor: 'not-allowed' },
  },
});
