// @flow

import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { type DetectionsType } from './dataTypesAndUtils';

import SidebarOverview from 'ui/molecules/SidebarOverview';
import SideNav, { Link, Section } from 'ui/organisms/SideNav';
import { calculatePercentDifference } from 'utils/util';

type Props = {
  breakdown: { current: DetectionsType, previous: DetectionsType },
  current: number,
  previous: number,
};

export default function Sidebar({ breakdown, current, previous }: Props) {
  const { path } = useRouteMatch();
  const { t } = useTranslation('email');

  const { malicious, suspicious, spoof } = get(breakdown, 'current', { malicious: 0, suspicious: 0, spoof: 0 });
  const {
    malicious: previousMalicious,
    suspicious: previousSuspicious,
    spoof: previousSpoof,
  } = get(breakdown, 'previous', { malicious: 0, suspicious: 0, spoof: 0 });

  return (
    <SideNav>
      <Section
        baseUrl={`${path}/malicious-email`}
        extra={
          <SidebarOverview
            format="abbreviatedNumber"
            percentChange={calculatePercentDifference(malicious, previousMalicious)}
            statistic={malicious}
          />
        }
        title={t('maliciousEmails')}
      >
        <Link to={`${path}/malicious-email/attachments`}>{t('attachments')}</Link>
        <Link to={`${path}/malicious-email/targets`}>{t('targets')}</Link>
        <Link to={`${path}/malicious-email/new-domains`}>{t('newDomains')}</Link>
        <Link to={`${path}/malicious-email/links`}>{t('links')}</Link>
        <Link to={`${path}/malicious-email/threat-types`}>{t('threatTypes')}</Link>
        <Link to={`${path}/malicious-email/origins`}>{t('threatOrigins')}</Link>
      </Section>

      <Section
        baseUrl={`${path}/suspicious-email`}
        extra={
          <SidebarOverview
            format="abbreviatedNumber"
            percentChange={calculatePercentDifference(suspicious, previousSuspicious)}
            statistic={suspicious}
          />
        }
        title={t('suspiciousEmails')}
      >
        <Link to={`${path}/suspicious-email/targets`}>{t('targets')}</Link>
        <Link to={`${path}/suspicious-email/new-domains`}>{t('newDomains')}</Link>
        <Link to={`${path}/suspicious-email/links`}>{t('links')}</Link>
        <Link to={`${path}/suspicious-email/threat-types`}>{t('threatTypes')}</Link>
      </Section>

      <Section
        baseUrl={`${path}/spoofs`}
        extra={
          <SidebarOverview
            format="abbreviatedNumber"
            percentChange={calculatePercentDifference(spoof, previousSpoof)}
            statistic={spoof}
          />
        }
        title={t('spoofs')}
      >
        <Link to={`${path}/spoofs/sender-mismatches`}>{t('senderMismatches')}</Link>
        <Link to={`${path}/spoofs/name-spoof`}>{t('nameSpoof')}</Link>
        <Link to={`${path}/spoofs/similar-domains`}>{t('similarDomains')}</Link>
      </Section>

      <Section
        baseUrl={`${path}/policy-detection`}
        extra={
          <SidebarOverview
            format="abbreviatedNumber"
            percentChange={calculatePercentDifference(current, previous)}
            statistic={current}
          />
        }
        title={t('policyDetections')}
      >
        <Link to={`${path}/policy-detection/allow-list`}>{t('allowList')}</Link>
        <Link to={`${path}/policy-detection/block-list`}>{t('blockList')}</Link>
      </Section>
    </SideNav>
  );
}
