// @flow

import React from 'react';

import Graph from './Graph';
import Label from './Label';

import TruncateText from 'ui/atoms/TruncateText';

type Props = { hideValue: boolean, label: string, percent: number, value: number | string, colorScheme?: string };
const Row = ({ hideValue, label, percent, value, colorScheme }: Props) => {
  return (
    <tr>
      <Label>
        <TruncateText width={200}>{label}</TruncateText>
      </Label>
      <Graph colorScheme={colorScheme} hideValue={hideValue} percent={percent}>
        {value}
      </Graph>
    </tr>
  );
};

export default Row;
