"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseContainer = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
const BaseContainer = (0, _styled.styled)('div', {
  $$end: '$colors$green500',
  $$start: '$colors$green300',
  background: 'linear-gradient($$start, $$end)',
  variants: {
    /**
     * Sets the color that the gradient ends on
     * @type $colors
     */
    end: (0, _utils.tokenVariant)('$$end', _styled.theme.colors, undefined, '$colors'),
    /**
     * Sets the color that the gradient starts on
     * @type $colors
     */
    start: (0, _utils.tokenVariant)('$$start', _styled.theme.colors, undefined, '$colors')
  }
});
exports.BaseContainer = BaseContainer;