// @flow

import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as MediumSVG } from './arrow-up-right-medium.svg';
import { ReactComponent as SmallSVG } from './arrow-up-right-small.svg';
import { ReactComponent as TinySVG } from './arrow-up-right-tiny.svg';
import { ReactComponent as DefaultSVG } from './arrow-up-right.svg';

const sharedStyles = css`
  height: ${rem(22)};
  transform: rotate(${({ negative }) => (negative ? 0 : -90)}deg);
  width: ${rem(22)};

  path {
    stroke: ${({ theme }) => theme.colors.darkSlateGray};
  }

  ${({ size }) => {
    if (size === 'medium')
      return css`
        height: ${rem(17)};
        width: ${rem(17)};
      `;

    if (size === 'small')
      return css`
        height: ${rem(11)};
        width: ${rem(11)};
      `;

    if (size === 'tiny')
      return css`
        height: ${rem(8)};
        width: ${rem(8)};
      `;

    return null;
  }};
`;

const DefaultIcon = styled(({ negative, ...props }) => <DefaultSVG {...props} />)`
  ${sharedStyles};
`;
const MediumIcon = styled(({ negative, ...props }) => <MediumSVG {...props} />)`
  ${sharedStyles};
`;
const SmallIcon = styled(({ negative, ...props }) => <SmallSVG {...props} />)`
  ${sharedStyles};
`;
const TinyIcon = styled(({ negative, ...props }) => <TinySVG {...props} />)`
  ${sharedStyles};
`;

type Props = { negative?: boolean, size: 'tiny' | 'small' | 'medium' | 'large' };

const Icon = (props: Props) => {
  const { size } = props;
  if (size === 'medium') return <MediumIcon {...props} />;
  if (size === 'small') return <SmallIcon {...props} />;
  if (size === 'tiny') return <TinyIcon {...props} />;

  return <DefaultIcon {...props} />;
};

export default Icon;
