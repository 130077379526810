// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.colors.grayBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(12)};
  line-height: 1;
  outline: 0;
  padding: ${({ theme }) => rem(theme.spacing.sm)} ${({ theme }) => rem(theme.spacing.md)};
`;

export default Container;
