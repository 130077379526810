// @flow

import axios from 'axios';

export const assumeAccount = async (accountId: string) => {
  try {
    await axios.post(`/api/users/assume_child_account/${accountId}`);
    window.location.replace('/');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('***** There was an error assuming a child role', error);
  }
};

export const resumePrimaryAccount = async () => {
  try {
    await axios.post(`/api/users/resume_parent_account`);
    window.location.replace('/settings/account-delegation');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('**** There was an error resuming the parent role', error);
  }
};
