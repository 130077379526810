// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';

// $FlowFixMe
import React from 'react';
import { useTranslation } from 'react-i18next';

import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import SectionOverview from 'ui/organisms/SectionOverview';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';

export const insightsQuarantineStatsQuery = loader('./queries/InsightsQuarantineStats.graphql');

export default function FirstCard() {
  const duration = useDuration();
  const { t } = useTranslation('common');

  const { data, error, loading } = useQuery(insightsQuarantineStatsQuery, {
    variables: setInsightsDateRangeFromDuration(duration),
  });
  const insightsQuarantineData = get(data, 'quarantineCount.data.totals.current', 0);

  return (
    <>
      <ContentBlock
        top={
          <>
            <BlockHeader dataTestId="admin-quarantine-overview-title" title={t('email:adminQuarantine')} />
            <SectionOverview
              currentValue={insightsQuarantineData}
              dataTestId="total-emails-quarantined"
              dimmed={loading || error}
              formatValue="number"
              size="large"
              title={t('totalEmailsQuarantined')}
            />
          </>
        }
        dataTestId="admin-quarantine-overview"
        zebraStripe={false}
      />
    </>
  );
}
