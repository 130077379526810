// @flow

import { AxisLeft } from '@vx/axis';
import { Group } from '@vx/group';
import { scaleLinear } from '@vx/scale';
import { max } from 'd3-array';
import { rgba } from 'polished';
import React from 'react';
import { withTheme } from 'styled-components';

import { HEIGHT, X_OFFSET, Y_OFFSET } from './util';

import type { Data } from './util';

import type { Theme } from 'config/theme';

type Props = { data: Data[], theme: Theme, previousData?: Data[] };
const YAxis = ({ data, previousData = [], theme }: Props) => {
  const allData = [...data, ...previousData];
  const y = (d) => d.value;
  const yScale = scaleLinear({
    clamp: true,
    domain: [0, max(allData, y)],
    range: [HEIGHT - Y_OFFSET, 0],
  });

  return (
    <Group left={X_OFFSET}>
      <AxisLeft
        hideAxisLine
        hideTicks
        numTicks={4}
        scale={yScale}
        tickLabelProps={() => ({
          dy: 3,
          fill: rgba(theme.colors.raisinBlack, 0.5),
          fontSize: 10,
          fontFamily: theme.fonts.roboto,
          fontWeight: 500,
          textAnchor: 'end',
        })}
      />
    </Group>
  );
};

export default withTheme(YAxis);
