// @flow

import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useState, type Node } from 'react';
import styled, { css } from 'styled-components';

import SegmentedControl from '../../atoms/SegmentedControl';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  align-items: center;
  background-color: ${(p) => rgba(p.theme.colors.white, 0.5)};
  border: ${(p) => p.theme.colors.aliceBlue} solid 1px;
  border-radius: ${rem(4)};
  display: flex;
  flex-direction: ${(p) => (p.reverse ? 'column-reverse' : 'column')};
  padding: ${(p) => rem(p.theme.spacing.md)};
`;

const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Controls = styled.div`
  margin: ${(p) => rem(p.theme.spacing.md)} 0 ${(p) => rem(0 - p.theme.spacing.md - 17)} 0;

  ${(p) =>
    p.top &&
    css`
      margin: ${rem(0 - p.theme.spacing.md - 17)} 0 ${rem(p.theme.spacing.md)} 0;
    `};
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type RenderTabCallback = (index: number) => Node;

interface Props {
  dataTestId?: string;
  renderTab?: RenderTabCallback;
  tabs: string[];
  tabsLocation?: 'bottom' | 'top';
}

export default function TabView({
  dataTestId = 'molecule-tab-view',
  renderTab = renderIndex,
  tabs,
  tabsLocation = 'bottom',
}: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleChange(index) {
    setSelectedIndex(index);
  }

  return (
    <Container data-testid={dataTestId} reverse={tabsLocation === 'top'}>
      <Content>{renderTab(selectedIndex)}</Content>

      <Controls top={tabsLocation === 'top'}>
        <SegmentedControl onChange={handleChange} segments={tabs} selectedIndex={selectedIndex} />
      </Controls>
    </Container>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function renderIndex(index) {
  return String(index);
}
