import { css, Badge as BaseBadge } from '@a1s/ui';
import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

//
// Main component
// -------------------------------------------------------------------------------------------------

type BadgeProps = ComponentProps<typeof BaseBadge>;

/**
 * Use the same props as the `Badge` component from `@a1s/ui` but displays the badge in the style
 * made for the unified search design.
 */
export function Badge({ css: _css, ...props }: BadgeProps) {
  const styleProps = css({ fontSize: '0.75em', r: 0, whiteSpace: 'nowrap', width: 'min-content' });

  return <BaseBadge {...props} bg="white" className={styleProps()} />;
}

//
// Pre-Build Badges
// -------------------------------------------------------------------------------------------------

Badge.Disposition = {
  Benign: () => <Badge color="gray400">{useTranslation('dashboardPhishguard').t('BENIGN')}</Badge>,
  Bulk: () => <Badge color="teal400">{useTranslation('dashboardPhishguard').t('BULK')}</Badge>,
  Malicious: () => <Badge color="pink500">{useTranslation('dashboardPhishguard').t('MALICIOUS')}</Badge>,
  MaliciousBEC: () => <Badge color="tealpink450400">{useTranslation('dashboardPhishguard').t('MALICIOUS-BEC')}</Badge>,
  None: () => <Badge color="gray400">{useTranslation('dashboardPhishguard').t('NONE')}</Badge>,
  Released: () => <Badge color="gray400">{useTranslation('dashboardPhishguard').t('QUARANTINE_RELEASE')}</Badge>,
  Spoof: () => <Badge color="pink300">{useTranslation('dashboardPhishguard').t('SPOOF')}</Badge>,
  Suspicious: () => <Badge color="pink400">{useTranslation('dashboardPhishguard').t('SUSPICIOUS')}</Badge>,
};
