// @flow

import hash from 'hash-sum';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import Body from './Body';
import Cell from './Cell';
import Container from './Container';
import Head from './Head';
import Header from './Header';
import Row from './Row';

type RenderCellCallback<T> = (data: T, rowIndex: number, cellIndex: number) => Node;
type RenderHeaderCallback = (heading: string, index: number) => Node;
type PercentCallback<T> = (data: Array<Array<T>>, row: Array<T>) => number;

type Props<T> = {
  calculatePercent: PercentCallback<T>,
  cellWidths: string[],
  data: Array<Array<T>>,
  dataTestId?: string,
  headings?: string[],
  hideValue?: boolean,
  placeholder?: boolean,
  placeholderText?: string,
  renderCell?: RenderCellCallback<T>,
  renderHeader?: RenderHeaderCallback,
};

const ProgressTable = <T>({
  calculatePercent,
  cellWidths,
  data,
  dataTestId = 'molecule-progress-table',
  headings,
  hideValue = false,
  placeholder = false,
  placeholderText = 'sampleData',
  renderCell = dataPassthrough,
  renderHeader = dataPassthrough,
}: Props<T>) => {
  const { t } = useTranslation('common');

  return (
    <Container
      data-testid={dataTestId}
      placeholder={placeholder ? 'true' : undefined}
      placeholderText={t(placeholderText)}
    >
      {headings && renderHeadings(cellWidths, headings, renderHeader)}
      {renderData(cellWidths, data, hideValue, renderCell, calculatePercent)}
    </Container>
  );
};

export default ProgressTable;

// Private Functions

// The default render for both headings and cells just outputs the data (usually text, but doesn't
// have to be) it is given. It also the default valyye for the `rowData` prop, allowing the default
// behavior rendering an array table.
function dataPassthrough(text): string {
  return String(text);
}

/* eslint-disable react/no-array-index-key */
function renderData<T>(
  cellWidths: string[],
  data: Array<Array<T>>,
  hideValue: boolean,
  renderCell: RenderCellCallback<T>,
  calculatePercent: PercentCallback<T>
) {
  return (
    <Body>
      {data.map((row, i) => (
        <Row data-testid={`row-${i}`} key={`${hash(row)}-${i}`} percent={calculatePercent(data, row)}>
          {row.map((cell, j) => (
            <Cell
              data-testid="molecule-progress-table-cell"
              hideValue={hideValue && typeof cell === 'number'}
              key={`${hash(cell)}-${j}`}
              width={cellWidths && cellWidths[j]}
            >
              {renderCell(cell, i, j)}
            </Cell>
          ))}
        </Row>
      ))}
    </Body>
  );
}
/* eslint-enable */

function renderHeadings(cellWidths: string[], headings: string[], renderHeader: RenderHeaderCallback) {
  return (
    <Head>
      <Row>
        {headings.map((heading, i) => (
          <Header key={hash(heading)} width={cellWidths && cellWidths[i]}>
            {renderHeader(heading, i)}
          </Header>
        ))}
      </Row>
    </Head>
  );
}
