// @flow

// $FlowFixMe
import { useState, type Node } from 'react';

type Props = { children: ({ selected: string }, handleSelect: Function) => Node, initialSelected?: string };

export default function SectionSelect({ children, initialSelected = '' }: Props) {
  const [selected, setSelected] = useState(initialSelected);

  const handleSelect = (selectedOption: string) => {
    setSelected(selected === selectedOption ? initialSelected : selectedOption);
  };

  return children({ selected }, handleSelect);
}

export { default as SectionOption } from './SectionOption';
