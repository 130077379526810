// @flow

import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { defaultLineGraphData, mungeLineGraphDataInsights } from '../dataTypesAndUtils';

import type { SuspiciousDomainCountsByDate } from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import PlaceholderLineGraph from 'ui/molecules/PlaceholderLineGraph';
import RingChart from 'ui/molecules/RingChart';
import DataView from 'ui/organisms/DataView';
import SectionOverview from 'ui/organisms/SectionOverview';
import Split from 'ui/templates/Split';
import withDataHandler from 'utils/withDataHandler';

type Data = {
  lineGraph: {
    current: SuspiciousDomainCountsByDate[],
    previous: SuspiciousDomainCountsByDate[],
  },
  ringChart: number,
  sectionOverview: {
    current: number,
    previous: number,
  },
};

type Props = {
  data: Data,
  error: boolean,
  loading: boolean,
};

const DataViewWithDataHandler = withDataHandler({
  empty: <PlaceholderLineGraph />,
})(DataView);

export default function RecentlyRegisteredDomains({ data, error, loading }: Props) {
  const lineGraphData = get(data, 'lineGraph', defaultLineGraphData);
  const ringChartData = get(data, 'ringChart', 0);
  const sectionOverviewData = get(data, 'sectionOverview', { current: 0, previous: 0 });
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="recently-registered-domains-component"
      top={
        <>
          <BlockHeader pullBottom title={t('recentlyRegisteredDomains')} />
          <Split>
            <SectionOverview
              currentValue={sectionOverviewData.current}
              dataTestId="recently-registered-domains-statistic"
              dimmed={loading}
              formatValue="number"
              previousValue={sectionOverviewData.previous}
            />
            <RingChart formatValue="number" label={t('totalSuspiciousEmails')} percent={ringChartData} />
          </Split>
          <PaddedContent pushTop="xl">
            <DataViewWithDataHandler
              data={{
                emailPhish: {
                  current: mungeLineGraphDataInsights(lineGraphData.current),
                  previous: mungeLineGraphDataInsights(lineGraphData.previous),
                },
              }}
              dataTestId="data-view-recently-registered-domains"
              isEmpty={() => loading || error || lineGraphData.current.length === 0}
              isLoading={loading}
              previousData={mungeLineGraphDataInsights(lineGraphData.previous)}
            />
          </PaddedContent>
        </>
      }
    />
  );
}
