// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Text = styled.h1`
  color: ${({ theme }) => theme.colors.raisinBlack};
  font-family: ${({ theme }) => theme.fonts.din};
  font-size: ${rem(32)};
  font-weight: 600;
  line-height: 1;
  margin: 0;
  max-width: 100%;
  transition: opacity 250ms ${({ theme }) => theme.timing.easeOutQuart}, visibility 0s linear 0s;
  white-space: nowrap;

  ${({ dimmed }) =>
    dimmed &&
    css`
      opacity: 0.3;
    `};

  ${({ size }) => {
    if (size === 'x-small')
      return css`
        font-size: ${rem(24)};
      `;

    if (size === 'medium')
      return css`
        font-size: ${rem(76)};
        letter-spacing: ${rem(-3.17)};
      `;

    if (size === 'large')
      return css`
        font-size: ${rem(128)};
        font-weight: 700;
        letter-spacing: ${rem(-3.17)};
      `;

    return null;
  }};
`;

export default Text;
