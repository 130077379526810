// @flow

type MixType = string | number;

export type SenderMismatchList = { count: number, envelopeFrom: string, messageFrom: string };

export type SenderMismatchCountsByDate = { date: number, keys: string[], values: number[] };

export type PreviousSenderMismatchCountsByDate = { date: number, keys: string[], values: number[] };

type ProgressTableType = {
  count: number,
  envelopeFrom: string,
  messageFrom: string,
};

type SenderMismatchCounts = {
  spoofSenderMismatchesTotal: number,
  spoofTotal: number,
};

type SenderMismatchType = {
  previousSpoofSenderMismatchCounts: SenderMismatchCounts,
  previousSpoofSenderMismatchCountsByDate: Array<PreviousSenderMismatchCountsByDate>,
  spoofSenderMismatchCounts: SenderMismatchCounts,
  spoofSenderMismatchCountsByDate: Array<SenderMismatchCountsByDate>,
  spoofSenderMismatchesListByName: Array<SenderMismatchList>,
};

export const senderMismatchRecord: SenderMismatchType = {
  previousSpoofSenderMismatchCounts: { spoofSenderMismatchesTotal: 0, spoofTotal: 0 },
  previousSpoofSenderMismatchCountsByDate: [],
  spoofSenderMismatchCounts: { spoofSenderMismatchesTotal: 0, spoofTotal: 0 },
  spoofSenderMismatchCountsByDate: [],
  spoofSenderMismatchesListByName: [],
};

export function mungeProgressTableData(data: Array<ProgressTableType>): Array<Array<MixType>> {
  return data
    .sort((a, b) => b.count - a.count)
    .slice(0, 6)
    .map((row) => [row.envelopeFrom, row.messageFrom, row.count]);
}
