import { Box, Cluster, DonutChart, Loadable, Stack, Text, TextBlock } from '@a1s/ui';
import { ApolloError, useQuery } from '@apollo/client';

import { loader } from 'graphql.macro';
import React, { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import { ButtonFooter, InfoTooltip, PanelContainer } from '../../../../ui-new';
import { Searchable } from '../../../shared/Searchable';

import { FIFTEEN_MINUTE_POLL_INTERVAL, placeholderData } from '../../lib';
import { ColorBlock, ColorBlockProps, UpdatesDisplay } from '../../ui';
import { filterAndSortData } from '../dataTypesAndUtils';

import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration, useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

export interface OrgSpoofPanelProps {}

// eslint-disable-next-line no-empty-pattern
export function OrgSpoofPanel({}: OrgSpoofPanelProps) {
  const duration = useDuration();
  const { data, error, loading } = useRemoteData(duration);

  const { t } = useTranslation('dashboardHome');

  // We'll also need to communicate if there has been an error, possibility
  // with a way to retry making the API request
  if (error) return null;

  return (
    <PanelContainer title={t('orgSpoof')} titleDecoration={<Tooltip />}>
      <Box p>
        <Stack gap="5">
          <Loadable loading={loading}>
            <Top classicSpoofCount={data?.classicSpoofCount} nameSpoofCount={data?.nameSpoofCount} />
            <Table topNames={data?.topNames} topTargets={data?.topTargets} />
          </Loadable>

          <ButtonFooter to="/email">
            <UpdatesDisplay duration="PT15M" />
          </ButtonFooter>
        </Stack>
      </Box>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Divider() {
  return (
    <Text as="span" color="$gray400" weight="regular">
      <> | </>
    </Text>
  );
}

interface NameCellProps {
  children?: string;
  value?: number;
}

function NameCell({ children, value }: NameCellProps) {
  return (
    <Text font="sans" size="sm" stretch="ultraCondensed">
      <Searchable
        params={{
          metric: `org_spoof:${children}`,
        }}
      >
        <TextBlock.Loadable as="span" color="$gray600" css={{ '& > abbr': { textDecoration: 'none' } }} weight="medium">
          {children && children.length > 25 ? <abbr title={children}>{children.slice(0, 25)}…</abbr> : children}
        </TextBlock.Loadable>
      </Searchable>
      <Divider />
      <Text.Loadable as="span" color="$gray400" placeholder="—" weight="regular">
        {value}
      </Text.Loadable>
    </Text>
  );
}

function SpoofType({ children, color, value }: PropsWithChildren<{ color: ColorBlockProps['color']; value?: number }>) {
  return (
    <Cluster align="center" gap="2">
      <ColorBlock color={color} />
      <Text font="sans" size="xs" stretch="extraCondensed" transform="uppercase" weight="medium">
        <Searchable
          params={{
            metric: `org_spoof:${children === 'Name Spoofs' ? 'name_spoof' : 'classic_spoof'}`,
          }}
        >
          <Text as="span" color="$gray500">
            {children}
          </Text>
        </Searchable>
        <Divider />
        <Text.Loadable as="span" color="$gray400" placeholder="—">
          {value}
        </Text.Loadable>
      </Text>
    </Cluster>
  );
}

interface TableProps {
  topNames?: APIData['topNames'];
  topTargets?: APIData['topTargets'];
}

function Table({ topNames, topTargets }: TableProps) {
  const { t } = useTranslation('dashboardHome');

  const capitalizedNames = filterAndSortData({ filterBy: 'name', sortBy: 'count' }, topNames);

  const sortedTargets = filterAndSortData({ filterBy: 'name', sortBy: 'count' }, topTargets);

  return (
    <Box bg="$gray100" p="3" r="2" testId="org-spoof-table">
      <Cluster gap="3" justify="space-between">
        <Stack gap="1">
          <TableHeader>{t('topNames')}</TableHeader>
          <Stack gap="1">
            {(capitalizedNames || placeholderData(3)).map(({ count, name }, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <NameCell key={`${name}-${idx}`} value={count}>
                {name}
              </NameCell>
            ))}
          </Stack>
        </Stack>

        <Stack gap="1">
          <TableHeader> {t('topTargets')} </TableHeader>
          <Stack gap="1">
            {(sortedTargets || placeholderData(3)).map(({ name }) => (
              <Searchable
                params={{
                  metric: `org_spoof:${name}`,
                }}
              >
                <TargetCell key={name}>{name}</TargetCell>
              </Searchable>
            ))}
          </Stack>
        </Stack>
      </Cluster>
    </Box>
  );
}

function TableHeader({ children }: PropsWithChildren<{}>) {
  return (
    <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="semibold">
      {children}
    </Text>
  );
}

interface TargetCellProps {
  children?: string;
}

function TargetCell({ children }: TargetCellProps) {
  return (
    <Text.Loadable color="$gray400" font="sans" placeholder="mailbox@example.com" size="sm" stretch="ultraCondensed">
      {children && children.length > 25 ? <abbr title={children}>{children.slice(0, 25)}…</abbr> : children}
    </Text.Loadable>
  );
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.orgSpoof', { postProcess: 'markdown' })}</InfoTooltip>;
}

interface TopProps {
  classicSpoofCount?: APIData['classicSpoofCount'];
  nameSpoofCount?: APIData['nameSpoofCount'];
}

function Top({ classicSpoofCount, nameSpoofCount }: TopProps) {
  const { t } = useTranslation('dashboardHome');
  const total = (classicSpoofCount || 0) + (nameSpoofCount || 0);

  return (
    <Cluster justify="space-between" testId="org-spoof-top">
      <Stack gap="4">
        <Text font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="semibold">
          <Text as="span" color="$gray600" weight="semibold">
            {t('totalSpoofDetection')}
          </Text>
          <Divider />
          <Text.Loadable as="span" color="$blue400" placeholder="—" weight="regular">
            {total}
          </Text.Loadable>
        </Text>
        <Stack gap="2">
          <SpoofType color="$blue400" value={nameSpoofCount}>
            {t('nameSpoofs')}
          </SpoofType>
          <SpoofType color="$pink400" value={classicSpoofCount}>
            {t('classicSpoofs')}
          </SpoofType>
        </Stack>
      </Stack>

      <DonutChart.Loadable height={128} width={128}>
        <DonutChart.Caption testId="org-spoof-donut-chart">
          <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="medium">
            {total}
          </Text>
        </DonutChart.Caption>
        <DonutChart.Segment color="blue400" value={nameSpoofCount} />
        <DonutChart.Segment color="pink400" value={classicSpoofCount} />
      </DonutChart.Loadable>
    </Cluster>
  );
}

//
// Data fetching
// -------------------------------------------------------------------------------------------------

interface CountValue {
  count: number;
  name: string;
}

export interface APIData {
  classicSpoofCount: number;
  nameSpoofCount: number;
  topNames: CountValue[];
  topTargets: CountValue[];
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

// How many of the top data to display
const TOP_COUNTS = 3;

/**
 * Private hook that encapsulates loading the data for the `DetectionStatsPanel`.
 * Poll the endpoint every 15 minutes.
 */
function useRemoteData(duration: Duration = '30'): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });

  if (loading) return { data: undefined, error: null, loading: true };
  if (error) return { data: undefined, error, loading: false };

  if (!data?.insightsOrgSpoofs?.data) return { data: undefined, error: null, loading: false };

  const topNames = [...data.insightsOrgSpoofs.data.topNames].sort(sortByCount).slice(0, TOP_COUNTS);
  const topTargets = [...data.insightsOrgSpoofs.data.topTargets].sort(sortByCount).slice(0, TOP_COUNTS);

  return { data: { ...data.insightsOrgSpoofs.data, topNames, topTargets }, error: null, loading: false };
}

//
// Private function
// -------------------------------------------------------------------------------------------------

function sortByCount(a: CountValue, b: CountValue) {
  return b.count - a.count;
}
