// @flow

import { type FetchUserResponse } from '../data/user';

export const FETCH_USER_FAILED = '@currentUser/FETCH_USER_FAILED';
export const FETCH_USER_REQUESTED = '@currentUser/FETCH_USER_REQUESTED';
export const FETCH_USER_SUCCEEDED = '@currentUser/FETCH_USER_SUCCEEDED';
export type FetchUserFailedAction = { error: true, payload: string, type: typeof FETCH_USER_FAILED };
export type FetchUserRequestedAction = { type: typeof FETCH_USER_REQUESTED };
export type FetchUserSucceededAction = {
  payload: FetchUserResponse,
  type: typeof FETCH_USER_SUCCEEDED,
};

export type FetchUserAction = FetchUserFailedAction | FetchUserRequestedAction | FetchUserSucceededAction;
