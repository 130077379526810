// @flow
// $FlowFixMe
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { Cookies } from 'js-cookie';
import { get } from 'lodash';
// $FlowFixMe
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_DICTIONARY, LanguageCodeType, LanguageLabelType, LanguageType } from './types';

import { type OptionTypes } from 'config/commonTypes';
import Dropdown from 'ui/organisms/Dropdown';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';

const getSupportedLanguages = loader('./GetSupportedLanguages.graphql');
const UpdateLanguagePreference = loader('./UpdateLanguagePreference.graphql');

// Main Component
// -------------------------------------------------------------------------------------------------

const LANGUAGE_DICTIONARY_KEYS = Object.keys(LANGUAGE_DICTIONARY);

interface Props {
  direction?: 'down' | 'up';
}

export default function LanguageSelector({ direction = 'up' }: Props) {
  const { i18n, t } = useTranslation();

  const { user: currentUser } = useCurrentUser();
  const userLanguageCode = get(currentUser, 'langPref');
  const userIsAuthenticated = get(currentUser, 'isAuthenticated', false);

  const initialLanguagueCode = useMemo(() => {
    const abbreviation = LANGUAGE_DICTIONARY_KEYS.find((l: LanguageLabelType) => l === i18n.language);
    const languageEntry = get(LANGUAGE_DICTIONARY, abbreviation, LANGUAGE_DICTIONARY.en);
    return languageEntry.code;
  }, [i18n.language]);

  const [languageCode, setLanguageCode] = useState(userLanguageCode || initialLanguagueCode);

  const languages = useLanagugeList();
  const [updateLanguagePreference] = useMutation(UpdateLanguagePreference);

  const handleLangugageSelection = async ([code, selected]) => {
    setLanguageCode(code);

    try {
      if (userIsAuthenticated) {
        await updateLanguagePreference({ variables: { input: { lang_pref: code } } });
      }
      Cookies.set('currentLanguage', selected);
    } catch (e) {
      // Sentry
    }

    const languageLabel = getLabel(selected);
    return i18n.changeLanguage(languageLabel);
  };

  const languageName = getLanguageName(languageCode);

  // $FlowFixMe
  const options: OptionTypes[] = Object.entries(languages);

  return (
    <>
      <Dropdown
        dataTestId="language-dropdown"
        direction={direction}
        expanded={false}
        noMargin
        options={options}
        onOptionSelected={(selected) => handleLangugageSelection(selected)}
        // eslint-disable-next-line no-unused-vars
        renderAsOption={([_, l]) => t(`common:${l}`)}
      >
        {languageName || Cookies.get('currentLanguage')}
      </Dropdown>
    </>
  );
}

//
// Private hook
// -------------------------------------------------------------------------------------------------

function useLanagugeList() {
  const { data } = useQuery(getSupportedLanguages);
  return get(data, 'supportedLanguages.languages', { 'en-US': 'English' });
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

// converts language name to language label for internationalization ('Português' => 'pt')
function getLabel(selected: LanguageLabelType) {
  return LANGUAGE_DICTIONARY_KEYS.find((l: LanguageLabelType) => LANGUAGE_DICTIONARY[l].name === selected) || 'en';
}

// converts language name to language code ('Português' => 'pt-BR')
export function getLanguageCode(selectedLanguage: LanguageType) {
  const abbreviation = LANGUAGE_DICTIONARY_KEYS.find(
    (l: LanguageLabelType) => LANGUAGE_DICTIONARY[l].name === selectedLanguage
  );
  const languageEntry = get(LANGUAGE_DICTIONARY, abbreviation);

  return languageEntry.code;
}

// converts language code to full language name ('pt-BR' -> 'Português')
export function getLanguageName(languageCode: LanguageCodeType) {
  const abbreviation = LANGUAGE_DICTIONARY_KEYS.find(
    (l: LanguageLabelType) => LANGUAGE_DICTIONARY[l].code === languageCode
  );
  const languageEntry = get(LANGUAGE_DICTIONARY, abbreviation);

  return languageEntry.name;
}
