// @flow

import hash from 'hash-sum';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Data } from '../util';

import Circle from './Circle';
import Container from './Container';
import Label from './Label';
import Row from './Row';
import Value from './Value';

type Props = { color: Function, data: Data[], renderLabel?: (any) => string };

const Legend = ({ color, data, renderLabel = defaultRenderLabel }: Props) => {
  const total = data.reduce((acc, row) => acc + row.value, 0);
  const { t } = useTranslation('components');

  return (
    <Container>
      {data.map((row) => {
        const percentage = row.value / total;
        return (
          <Row key={hash(row)}>
            <Circle color={color(row.name)} />
            <Label>{renderLabel(row.name)}</Label>
            <Value>{t('Statistic.percent', { value: percentage || 0 })}</Value>
          </Row>
        );
      })}
    </Container>
  );
};

/*
  Private functions
*/
function defaultRenderLabel(labelName) {
  return String(labelName);
}

export default Legend;
