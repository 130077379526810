import { css, styled, Badge, Button, Box, Cluster, Dropdown, Stack, Text } from '@a1s/ui';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import * as dateFns from 'date-fns';
import React, { useEffect, useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton, Dialog } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

type DateRange = [Date, Date];
type DaysBack = '7' | '30' | '90';
type ValueType = DaysBack | [string, string];

interface DateRangeDropdownProps {
  // eslint-disable-next-line  no-unused-vars
  onChange(selected: ValueType): void;
  value: ValueType;
}

export function DateRangeDropdown({ value, onChange }: DateRangeDropdownProps) {
  const [calendarVisible, setCalendarVisible] = useState(false);

  const { t } = useTranslation('components');

  function handleChange(selected: DaysBack | 'custom') {
    if (!onChange) return;

    if (selected === 'custom') {
      setCalendarVisible(true);
    } else {
      onChange(selected as DaysBack);
    }
  }

  function handleClose() {
    setCalendarVisible(false);
  }

  function handleSelect(range: DateRange) {
    setCalendarVisible(false);
    onChange([dateFns.formatISO(range[0]), dateFns.formatISO(range[1])]);
  }

  const transformed = parseValue(value);
  const transformedValue = Array.isArray(value)
    ? ([dateFns.parseISO(value[0]), dateFns.parseISO(value[1])] as DateRange)
    : null;

  return (
    <>
      <Dropdown onChange={handleChange} value={transformed}>
        <Dropdown.Option value="90">{t('DateRangePicker.last90Days')}</Dropdown.Option>
        <Dropdown.Option value="30">{t('DateRangePicker.last30Days')}</Dropdown.Option>
        <Dropdown.Option value="7">{t('DateRangePicker.last7Days')}</Dropdown.Option>
        <Dropdown.Option value="custom">{t('unisearch:customRange')}</Dropdown.Option>
      </Dropdown>

      <Dialog visible={calendarVisible}>
        <Calendar onClose={handleClose} onSelect={handleSelect} value={transformedValue} />
      </Dialog>
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface CalendarProps {
  // eslint-disable-next-line  no-unused-vars
  onClose(): void;
  // eslint-disable-next-line  no-unused-vars
  onSelect(range: DateRange): void;

  value: DateRange | null;
}

function Calendar({ onClose, onSelect, value }: CalendarProps) {
  const [range, setRange] = useState<DateRange | null>(value);

  const { t } = useTranslation('components');

  const effectDependency = JSON.stringify(value);
  useEffect(() => {
    setRange(value);
  }, [effectDependency]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClosePress() {
    if (onClose) onClose();
  }

  function handleSelectPress() {
    if (!onSelect) return;

    if (range) onSelect(range);
    else onClose();
  }

  return (
    <Box p>
      <Stack>
        <Cluster justify="end">
          <CloseButton css={{ zIndex: 2 }} onPress={handleClosePress} small />
        </Cluster>

        <Text
          as="h2"
          color="$gray600"
          css={{ transform: 'translateY(-20px)', zIndex: 1 }}
          font="sans"
          size="lg"
          stretch="ultraCondensed"
          weight="bold"
        >
          {t('GlobalSearch.dateRange')}
        </Text>

        <Stack gap>
          <RangePicker onChange={setRange} value={range} />
          <Cluster gap="2">
            <Shortcut onPress={setRange} range={[dateFns.startOfToday(), new Date()]}>
              {t('DateRangePicker.today')}
            </Shortcut>
            <Shortcut onPress={setRange} range={[dateFns.startOfYesterday(), dateFns.endOfYesterday()]}>
              {t('DateRangePicker.yesterday')}
            </Shortcut>
            <Shortcut onPress={setRange} range={[dateFns.sub(dateFns.startOfToday(), { days: 6 }), new Date()]}>
              {t('DateRangePicker.last7Days')}
            </Shortcut>
            <Shortcut onPress={setRange} range={[dateFns.sub(dateFns.startOfToday(), { days: 29 }), new Date()]}>
              {t('DateRangePicker.last30Days')}
            </Shortcut>
            <Shortcut onPress={setRange} range={[dateFns.sub(dateFns.startOfToday(), { days: 89 }), new Date()]}>
              {t('DateRangePicker.last90Days')}
            </Shortcut>
            <Shortcut onPress={setRange} range={[dateFns.startOfMonth(new Date()), dateFns.endOfMonth(new Date())]}>
              {t('DateRangePicker.thisMonth')}
            </Shortcut>
          </Cluster>
          <Cluster justify="end" gap="4">
            <Button appearance="primary" onPress={handleSelectPress}>
              {t('unisearch:selectRange')}
            </Button>
          </Cluster>
        </Stack>
      </Stack>
    </Box>
  );
}

const picker = css({
  '& .react-datetimerange-picker': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '$4',
  },
  '& .react-datetimerange-picker__wrapper': { border: 0, bg: '$gray300', p: '$2', r: '$2' },
  '& .react-datetimerange-picker__wrapper input, & .react-datetimerange-picker__wrapper select': {
    appearance: 'none',
    color: '$gray500',
    fontFamily: '$sans',
    fontSize: '$sm',
    fontStretch: '50%',
  },
  '& .react-datetime-picker__inputGroup__divider, & .react-datetimerange-picker__inputGroup__leadingZero': {
    color: '$gray500',
    fontFamily: '$sans',
    fontSize: '$sm',
    fontStretch: '50%',
  },
  '& .react-datetimerange-picker__range-divider': {
    opacity: 0,
  },

  '& .react-datetimerange-picker__inputGroup:nth-of-type(1)::before': {
    content: 'From: ',
    color: '$gray700',
    display: 'inline-block',
    fontFamily: '$sans',
    fontSize: '$sm',
    fontStretch: '50%',
    fontWeight: 600,
    marginRight: '$4,',
    textTransform: 'uppercase',
  },

  '& .react-datetimerange-picker__inputGroup:nth-of-type(2)::before': {
    content: 'To: ',
    color: '$gray700',
    display: 'inline-block',
    fontFamily: '$sans',
    fontSize: '$sm',
    fontStretch: '50%',
    fontWeight: 600,
    marginRight: '$4,',
    textTransform: 'uppercase',
  },

  '& .react-datetimerange-picker__calendar': {
    display: 'flex !important',
    height: 'auto !important',
    position: 'static !important',
    width: '100% !important',
  },

  '& .react-calendar': { bg: '$gray50', border: 0, flexGrow: 1, r: '$2' },
  '& .react-calendar__navigation__label': {
    color: '$gray700',
    fontFamily: '$sans',
    fontSize: '$sm',
    fontStretch: '50%',
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  '& .react-calendar__month-view__weekdays__weekday': {
    color: '$gray600',
    fontFamily: '$sans',
    fontSize: '$xs',
    fontStretch: '50%',
    fontWeight: 600,
    textTransform: 'uppercase',
  },

  '& .react-calendar__navigation': {
    backgroundColor: '$gray100',
    rt: '$2',
  },

  '& .react-calendar__navigation__arrow': {
    color: '$gray800',
    fontFamily: '$sans',
    fontSize: '$lg',
    fontWeight: 800,
  },

  '& .react-calendar__navigation__arrow:disabled': {
    color: '$gray300',
  },

  '& .react-calendar__tile': {
    color: '$gray600',
    fontFamily: '$sans',
    fontWeight: 600,
    py: '$4',
    textTransform: 'uppercase',
  },

  '& .react-calendar__tile:disabled': { backgroundColor: 'transparent', color: '$gray300' },

  '& .react-calendar__tile--now': { backgroundColor: '$gray300', color: '$blue600' },
  '& .react-calendar__tile--now:hover': { backgroundColor: '$gray400 !important', color: '$white' },
  '& .react-calendar__tile:hover': { backgroundColor: '$gray200' },
  '& .react-calendar__month-view__days__day--neighboringMonth': { color: '$gray500', fontWeight: 300 },
  '& .react-calendar__tile--active': { background: '$blue500', color: '$white !important' },
  '& .react-calendar__tile--active:hover': { background: '$blue600 !important', color: '$white' },
});

interface RangePickerProps {
  // eslint-disable-next-line  no-unused-vars
  onChange(range: DateRange): void;
  value: DateRange | null;
}

function RangePicker({ onChange, value }: RangePickerProps) {
  const { i18n } = useTranslation('components');

  function handleChange(val: DateRange) {
    if (onChange && Array.isArray(val)) onChange(val);
  }

  return (
    <div className={picker()}>
      <DateTimeRangePicker
        calendarIcon={null}
        clearIcon={null}
        disableClock
        hideTime
        isCalendarOpen
        locale={i18n.language}
        maxDate={new Date()}
        minDate={dateFns.sub(new Date(), { months: 12 })}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
}

interface ShortcutProps {
  children?: ComponentProps<typeof Badge>['children'];
  // eslint-disable-next-line  no-unused-vars
  onPress(range: DateRange): void;
  range: DateRange;
}

function Shortcut({ children, onPress, range }: ShortcutProps) {
  const styleProps = css({
    borderColor: '$gray400',
    color: '$gray400',
    fontSize: '10px !important',
    r: '$1',
    pb: 0.5,
    pt: 1,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    width: 'min-content',

    '&:hover': {
      borderColor: '$gray600',
      color: '$gray600',
      cursor: 'pointer',
    },
  });

  function handleClick() {
    onPress(range);
  }

  return (
    <StyledButton onClick={handleClick} type="button">
      <Badge className={styleProps()}>{children}</Badge>
    </StyledButton>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function parseValue(value: ValueType): DaysBack | 'custom' {
  if (!Array.isArray(value)) return value;

  const today = new Date();
  if (!dateFns.isSameDay(today, dateFns.parseISO(value[1]))) return 'custom';

  const start = dateFns.parseISO(value[0]);
  if (dateFns.isSameDay(dateFns.subDays(today, 7), start)) return '7';
  if (dateFns.isSameDay(dateFns.subDays(today, 30), start)) return '30';
  if (dateFns.isSameDay(dateFns.subDays(today, 90), start)) return '90';

  return 'custom';
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledButton = styled('button', {
  appearrance: 'button',
  background: 'none',
  border: 'none',
  margin: 0,
  padding: 0,
});
