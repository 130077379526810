"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSidebar = void 0;
var _styled = require("../../styled");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledSidebar = (0, _styled.styled)('div', {
  $$threshold: '50%',
  display: 'flex',
  flexWrap: 'wrap',
  '& > :first-child': {
    flexGrow: '1'
  },
  '& > :last-child': {
    flexBasis: 0,
    flexGrow: 999,
    minWidth: '$$threshold'
  },
  variants: {
    /**
     * The amount of horizontal space to insert between the child components.
     * @type $space
     */
    gap: {
      true: {
        columnGap: '$3'
      },
      none: {
        columnGap: '$none'
      },
      px: {
        columnGap: '$px'
      },
      0.5: {
        columnGap: '$0.5'
      },
      1: {
        columnGap: '$1'
      },
      1.5: {
        columnGap: '$1.5'
      },
      2: {
        columnGap: '$2'
      },
      2.5: {
        columnGap: '$2.5'
      },
      3: {
        columnGap: '$3'
      },
      3.5: {
        columnGap: '$3.5'
      },
      4: {
        columnGap: '$4'
      },
      5: {
        columnGap: '$5'
      },
      6: {
        columnGap: '$6'
      },
      7: {
        columnGap: '$7'
      },
      8: {
        columnGap: '$8'
      },
      9: {
        columnGap: '$9'
      },
      10: {
        columnGap: '$10'
      },
      11: {
        columnGap: '$11'
      },
      12: {
        columnGap: '$12'
      }
    }
  }
});
exports.StyledSidebar = StyledSidebar;