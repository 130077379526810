import { Stack, Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import usePreviewsMutation, { PreviewType } from 'screens/Search/lib/hooks/usePreviewsMutation';
import { useSearchContext } from 'screens/Search/lib/searchContext';

interface BenignEmailPreviewType {
  clientRecipients?: string[];
  clientUuid?: string;
  messageId?: string;
}

export function BenignEmailPreview({ clientRecipients, clientUuid, messageId }: BenignEmailPreviewType) {
  const { userPermitted } = useSearchContext();
  const { t } = useTranslation('unisearch');
  const { data: previews, error, loading } = usePreviewsMutation({ clientRecipients, clientUuid, messageId });

  // Early return if user doesn't have permission
  if (!userPermitted)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('components:NoAccess.needAccessFromAdmin')}
      </Text>
    );

  // Early return if clientRecipients is empty
  if (clientRecipients?.length === 0)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('noPreview')}
      </Text>
    );

  if (loading)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('loading')}
      </Text>
    );

  if (error)
    return (
      <Text font="sans" size="sm" stretch="ultraCondensed">
        {t('unisearch:messagePreviewError')}
      </Text>
    );

  return (
    <>
      {previews?.map(({ preview }: PreviewType) => (
        <Stack data-testid="benign-email-preview" key={messageId}>
          <Stack>
            <Text font="sans" size="sm" stretch="ultraCondensed">
              <Text weight="medium">{t('messagePreviewId')}</Text> {messageId}
            </Text>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: preview?.html }} />
          </Stack>
        </Stack>
      ))}
    </>
  );
}
