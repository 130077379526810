// @flow

// $FlowFixMe
import { useCallback, useEffect, useState } from 'react';
// $FlowFixMe
import { useMappedState } from 'redux-react-hook';

import { permissionTypes, type PermissionsType } from '../AccessControl';

import { getCurrentUserIsLoading, getCurrentUserPermissions } from 'store/selector/user';

export default function useAccessControl(allowed: PermissionsType | PermissionsType[]) {
  const [loading, setLoading] = useState(true);
  const [isPermitted, setIsPermitted] = useState(initialStateForArgs(allowed));
  const mapLoadingState = useCallback((state) => getCurrentUserIsLoading(state), []);
  const mapUserState = useCallback((state) => getCurrentUserPermissions(state), []);
  const userLoading = useMappedState(mapLoadingState);
  const userPermissions = useMappedState(mapUserState);

  useEffect(
    () => {
      setIsPermitted(checkPermissions(userPermissions, allowed));
      setLoading(userLoading);
    },
    // This is an anti-pattern, I know, however, the issue is with `useMappedState`.
    // React does not allow it to be wrapped inside of another hook (so useCallback/useMemo) are not an option
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { loading, permissions: isPermitted };
}

export { permissionTypes };
export type { PermissionsType };

/*
  Private functions
*/
function checkPermissions(userPermissions, allowed) {
  if (!String(allowed)) return false;
  if (Array.isArray(allowed)) {
    return allowed.map((permission) => userPermissions.includes(permission));
  }

  return userPermissions.includes(allowed);
}

function initialStateForArgs(allowed: PermissionsType | PermissionsType[]): boolean | boolean[] {
  if (Array.isArray(allowed)) {
    return new Array(allowed.length).fill(false);
  }

  return false;
}
