// @flow

import { Group } from '@vx/group';
import { scaleOrdinal } from '@vx/scale';
import { arc as d3Arc, pie as d3Pie } from 'd3-shape';
import hash from 'hash-sum';
import { rgb, rgba, meetsContrastGuidelines } from 'polished';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import type { TFunction } from 'react-i18next';
import { withTheme } from 'styled-components';

import colorSchemes from './colorSchemes';
import Legend from './Legend';
import Stack from './Stack';
import Text from './Text';

import { DEFAULT_LABEL_FILL, HEIGHT, THICKNESS, WIDTH } from './util';

import type { Data } from './util';

import { calculateInterpolatedColors } from 'utils/util';

type ColorSchemeTypes = 'detections' | 'graphite' | 'inferno' | 'lavender' | 'sea';

type Props = {
  colorScheme: ColorSchemeTypes,
  data: Data[],
  dataTestId?: string,
  label: string,
  legendVertical?: boolean,
  interpolateColors?: boolean,
  renderLegendLabel?: (any) => string,
  t: TFunction,
  withoutLegend?: boolean,
};

class DonutChart extends PureComponent<Props> {
  container: ?HTMLDivElement = null;

  labelText: any = null;

  labelValue: any = null;

  assignLabelRef = (ref: any) => {
    this.labelText = ref;
  };

  assignValueRef = (ref: any) => {
    this.labelValue = ref;
  };

  handleMouseOver = ({ name, value }: { name: string, value: string }, color: string) => {
    const { t }: { t: TFunction } = this.props;
    this.labelText.style.fill = setLabelColor(color);
    this.labelText.textContent = name;
    this.labelValue.style.fill = setLabelColor(color);
    this.labelValue.textContent = t('Statistic.number', { value });
  };

  handleMouseOut = (value: number) => {
    const { label, t }: { label: string, t: TFunction } = this.props;
    this.labelText.style.fill = DEFAULT_LABEL_FILL;
    this.labelText.textContent = label;
    this.labelValue.style.fill = DEFAULT_LABEL_FILL;
    this.labelValue.textContent = t('Statistic.number', { value });
  };

  render() {
    const {
      colorScheme = 'graphite',
      data,
      dataTestId = 'organisms-donut-chart',
      label,
      legendVertical = false,
      interpolateColors = false,
      t,
      renderLegendLabel,
      withoutLegend = false,
    } = this.props;

    const getColor = (color, position) => {
      if (color === 'detections') {
        if (position === 4) {
          return colorSchemes[color].Suspicious;
        }

        return colorSchemes[color]['Malicious BEC'];
      }

      return colorSchemes[color][position];
    };

    // prettier-ignore
    const colors = interpolateColors
      ? calculateInterpolatedColors(
          rgba(getColor(colorScheme, 0), 1),
          rgba(getColor(colorScheme, 4), 1),
          10
        ).map((color) => rgb(color[0], color[1], color[2]))
      : colorSchemes[colorScheme];

    const total = data.reduce((acc, row) => acc + row.value, 0);

    const radius = Math.min(WIDTH, HEIGHT) / 2;

    const dispositionNames = data.map((row) => row.name);

    let color = scaleOrdinal({
      domain: dispositionNames,
      // $FlowFixMe
      range: colorScheme === 'detections' ? dispositionNames.map((disposition) => colors[disposition]) : colors,
    });

    // This is to map our set disposition colors to the right stack
    if (colorScheme === 'detections') {
      const domainKeys = Object.keys(colorSchemes[colorScheme]);

      color = scaleOrdinal({
        domain: domainKeys,
        range: domainKeys.map((domainKey) => colorSchemes[colorScheme][domainKey]),
      });
    }

    const arc = d3Arc()
      .innerRadius(radius - THICKNESS)
      .outerRadius(radius);

    const pie = d3Pie()
      .value((d) => d.value)
      .sort(null);

    const paths = pie(data).map((d) => (
      <path
        d={arc(d)}
        fill={color(d.data.name)}
        key={hash(d)}
        onBlur={() => this.handleMouseOut(total)}
        onFocus={() => this.handleMouseOver(d.data, color(d.data.name))}
        onMouseOut={() => this.handleMouseOut(total)}
        onMouseOver={() => this.handleMouseOver(d.data, color(d.data.name))}
      />
    ));

    return (
      <Stack data-testid={dataTestId} spaced={!legendVertical} vertical={legendVertical} xl={!legendVertical}>
        <svg height={HEIGHT} width={WIDTH}>
          <Group left={WIDTH / 2} top={HEIGHT / 2}>
            {paths}
            <Text dy="-0.3em" ref={this.assignLabelRef} textAnchor="middle">
              {label}
            </Text>
            <Text dy="1em" number ref={this.assignValueRef} textAnchor="middle">
              {t('Statistic.number', { value: total })}
            </Text>
          </Group>
        </svg>
        {!withoutLegend && <Legend color={color} data={data} renderLabel={renderLegendLabel} />}
      </Stack>
    );
  }
}

export default withTranslation('components')(withTheme(DonutChart));

//
// Private functions
// -------------------------------------------------------------------------------------------------

function setLabelColor(color: string): string {
  if (meetsContrastGuidelines(color, '#ffffff').AALarge) {
    return color;
  }
  return 'inherit';
}
