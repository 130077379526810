import { gql, useMutation } from '@apollo/client';

const LINK_CF_ACCOUNT_MUTATION = gql`
  mutation LinkCfAccount($input: LinkCfAccountInput!) {
    linkAccount(input: $input) @rest(method: "POST", path: "/cf-auth/link_account", type: "LinkCfAccount") {
      id
      name
    }
  }
`;

export function useLinkCfAccount() {
  return useMutation(LINK_CF_ACCOUNT_MUTATION);
}
