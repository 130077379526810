import { css, Box } from '@a1s/ui';
import React, { ComponentProps, ReactNode } from 'react';

interface IconProps {
  children?: ReactNode;
  color: ComponentProps<typeof Box>['bg'];
}

export function IconBackground({ children, color }: IconProps) {
  const styleProps = css({
    alignItems: 'center',
    color: '$white',
    display: 'flex',
    height: '16px',
    marginBottom: '1px',
    justifyContent: 'center',
    width: '16px',

    '& svg': {
      $$size: '10px',
      display: 'block',
      height: '$$size',
      width: '$$size',
    },
  });

  return (
    <Box bg={color} className={styleProps()} r="full" role="img">
      {children}
    </Box>
  );
}
