// @flow

// $FlowFixMe
import { Box, HorizontalBars, Loadable, Text } from '@a1s/ui';
// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderDataArray } from '../dataTypesAndUtils';
import { LabeledValue } from '../ui/LabeledValue';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';

const DetailsTable = lazy(() => import('./DetailsTable'));

type Props = {
  data: Object,
  error: boolean,
  loading: boolean,
};

export default function MostCommonTopLevelDomains({ data, error, loading }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  const noData = Object.keys(data).length === 0;

  return (
    <ContentBlock
      dataTestId="most-common-top-level-domains-component"
      top={
        <>
          <BlockHeader
            title={t('mostCommonTopLevelDomains')}
            action={
              <Button
                disabled={noData}
                icon="hamburger"
                muted
                onClick={() => Object.keys(data).length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
          <ConditionalRender
            condition={!error && (loading || !noData)}
            fallback={
              <Box css={{ textAlign: 'center' }} pb>
                <Text color="$gray400" size="sm" stretch="ultraCondensed">
                  {t('components:NoData.noDataObserved')}
                </Text>
              </Box>
            }
          >
            <HorizontalBars gap="2">
              {(loading ? placeholderDataArray(10) : dataForGraphTable(data)).map((row) => {
                return (
                  <HorizontalBars.Bar color="$gray100" key={row[0]} value={row[1]}>
                    <Loadable loading={loading}>
                      <LabeledValue
                        childrenColor="$gray600"
                        gap="5"
                        params={{
                          disposition: 'suspicious',
                          metric: `newtld:${row[0]}`,
                        }}
                        value={row[1]}
                      >
                        {row[0]}
                      </LabeledValue>
                    </Loadable>
                  </HorizontalBars.Bar>
                );
              })}
            </HorizontalBars>
          </ConditionalRender>
        </>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>{openModal && <DetailsTable fetchData={openModal} />}</Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}

const dataForGraphTable = (data) => {
  if (!data) return [];
  return Object.keys(data)
    .map((d) => ({ domain: d, count: data[d] }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 10)
    .map((row) => [row.domain, row.count]);
};
