// @flow

import { loader } from 'graphql.macro';

export type ThreatType = {
  count: number,
  threatType: string,
};

type ThreatTypesType = {
  previousThreatTypes: Array<ThreatType>,
  threatTypes: Array<ThreatType>,
};

export const threatTypesQuery = loader('./queries/SuspiciousThreatTypesQuery.graphql');

export const threatTypesRecord: ThreatTypesType = {
  previousThreatTypes: [],
  threatTypes: [],
};
