import { css, CSS, Text } from '@a1s/ui';
import React, { ComponentProps, ReactNode } from 'react';

import { StyledTable, StyledTD, StyledTR } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface InlineTableProps {
  /**
   * Typescript wasn't cooperating, but children should be one-or-more instances
   * of `InlineTable.Row`.
   */
  children?: ComponentProps<typeof StyledTable>['children'];

  /**
   * Escape-hatch for styling a box when none of the variant props will work.
   */
  css?: CSS;

  /**
   * Adjust the spacing between the cells.
   * @beta
   */
  gap?: string;

  /**
   * Set to `true` if the table should stretch vertically to fill the availble space.
   */
  stretch?: ComponentProps<typeof StyledTable>['stretch'];
}

/**
 * Helps display the nested table data, without any borders.
 */
export function InlineTable({ children, gap, stretch, ...props }: InlineTableProps) {
  const className = css(gap ? { borderCollapse: 'separate', borderSpacing: gap } : {});
  return (
    <StyledTable className={className()} stretch={stretch} {...props}>
      {children}
    </StyledTable>
  );
}

//
// Sub-components
// -------------------------------------------------------------------------------------------------

InlineTable.Cell = Cell;

export interface TableCellProps extends ComponentProps<typeof StyledTD> {
  /**
   * If set to `true`, allows the text to break and wrap to other lines.
   */
  allowBreaks?: ComponentProps<typeof StyledTD>['allowBreaks'];

  /**
   * The contents of an individual table cell.
   */
  children?: ComponentProps<typeof StyledTD>['children'];

  /**
   * Escape-hatch for styling a box when none of the variant props will work.
   */
  css?: CSS;

  /**
   * If set to `true`, prevents text from breaking within.
   */
  preventBreaks?: ComponentProps<typeof StyledTD>['preventBreaks'];
}

export function Cell({ children, css: cssPassthrough, ...props }: TableCellProps) {
  return (
    <StyledTD css={cssPassthrough} {...props}>
      <div>{children}</div>
    </StyledTD>
  );
}

InlineTable.Row = Row;

export interface TableRowProps {
  /**
   * Typescript wasn't cooperating, but children should be one-or-more instances
   * of `InlineTable.Cell`.
   */
  children?: ComponentProps<typeof StyledTR>;

  /**
   * Escape-hatch for styling a box when none of the variant props will work.
   */
  css?: CSS;
}

export function Row({ children, css: cssPassthrough }: TableRowProps) {
  return <StyledTR css={cssPassthrough}>{children}</StyledTR>;
}

//
// Helper Components
// -------------------------------------------------------------------------------------------------

//
// Private components
// -------------------------------------------------------------------------------------------------

InlineTable.DataRow = DataRow;

interface DataRowProps {
  label?: ReactNode;
  nowrap?: boolean;
  value?: ReactNode;
}

function DataRow({ label, nowrap, value = '' }: DataRowProps) {
  return (
    <InlineTable.Row>
      <InlineTable.Cell>
        {label && (
          <Text
            as="div"
            color="$gray800"
            font="sans"
            size="sm"
            stretch="ultraCondensed"
            transform="uppercase"
            weight="bold"
          >
            {label}:
          </Text>
        )}
      </InlineTable.Cell>
      <InlineTable.Cell>
        <Text
          as="div"
          color="$gray800"
          css={{ maxWidth: 200, wordBreak: !nowrap ? 'break-word' : 'unset' }}
          font="sans"
          nowrap={nowrap}
          size="sm"
          stretch="ultraCondensed"
          weight="light"
        >
          {value}
        </Text>
      </InlineTable.Cell>
    </InlineTable.Row>
  );
}
