// @flow

import hash from 'hash-sum';
// $FlowFixMe
import React, { lazy, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { alignHeading } from './dataTypesAndUtils';
import DetectionStatisticsTableRow from './DetectionStatisticsTableRow';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import NoData from 'ui/molecules/NoData';
import { Body, Cell, Row, Container as Table } from 'ui/molecules/Table';
import TableHeader from 'ui/molecules/TableHeader';

const DetailsTable = lazy(() => import('./DetailsTable'));
const DownloadReports = lazy(() => import('./DownloadReports'));

type Props = {
  becEnabled: boolean,
  data: Array<Object>,
  error: boolean,
  loading: boolean,
};

export default function DetectionStatistics({ becEnabled, data, error, loading }: Props) {
  const [detailRow, setDetailRow] = useState(null);
  const [detectionsKey, setDetectionsKey] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation('email');

  const onRequestOpen = (_event: SyntheticMouseEvent<any>, key: Object) => {
    setDetailRow(key);
    setDetectionsKey(!key ? null : key);
    setOpenModal(true);
  };

  const headings = [
    'date',
    'volume',
    'mailMalicious',
    ...(becEnabled ? ['mailBec'] : []),
    'mailSpam',
    'mailBulk',
    'mailSuspicious',
    'mailSpoof',
  ];

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <BlockHeader
            title={t('detectionStatistics')}
            action={
              <Button onClick={onRequestOpen} data-testid="download-reports">
                {t('downloadReports')}
              </Button>
            }
          />
          <Content>{t('detectionStatisticsCopy')}</Content>
        </>
      }
      dataTestId="detection-statistics-card"
    >
      <Table data-testid="detection-details-table" dimmed fixed spaced zebraStripes>
        <TableHeader headings={headings} ns="email" textAlign={alignHeading} />
        <ConditionalRender condition={loading}>
          <Body>
            <Row />
            <Row>
              <Cell colSpan="7">
                <Loading />
              </Cell>
            </Row>
          </Body>
        </ConditionalRender>

        <ConditionalRender condition={!loading && (!data.length || error)}>
          <Body>
            <Row />
            <Row>
              <Cell colSpan="7">
                <NoData message="noResults" />
              </Cell>
            </Row>
          </Body>
        </ConditionalRender>

        <ConditionalRender condition={!loading && !error && data.length > 0}>
          <Body>
            {data.map((row, index) => (
              <DetectionStatisticsTableRow
                becEnabled={becEnabled}
                key={hash(`${row[Object.keys(row)[0]].totals}-${index}`)}
                onRequestOpen={onRequestOpen}
                row={row}
              />
            ))}
          </Body>
        </ConditionalRender>
      </Table>

      <Drawer.Modal
        visible={openModal}
        onClose={() => setOpenModal(false)}
        size={detectionsKey ? 'large' : 'full'}
        paddedSides={false}
      >
        <Suspense fallback={<Loading />}>
          {detectionsKey ? (
            <DetailsTable
              becEnabled={becEnabled}
              data={detailRow}
              detectionsKey={detectionsKey}
              error={error}
              loading={loading}
            />
          ) : (
            <DownloadReports becEnabled={becEnabled} data={data} />
          )}
        </Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
