// @flow

import { loader } from 'graphql.macro';

export const fetchInsightsDetectionStatsQuery = loader('./InsightsDetectionStatsQuery.graphql');

export const fetchInsightsAllowList = loader('./screens/AllowList/queries/InsightsAllowList.graphql');
export const fetchInsightsBlockList = loader('./screens/BlockList/queries/InsightsBlockList.graphql');

export type PropType = {
  detectionCount: number,
  detectionError: boolean,
  detectionLoading: boolean,
};

export type DetectionsType = {
  bulk: number,
  malicious: number,
  maliciousBec: number,
  none: number,
  spam: number,
  spoof: number,
  suspicious: number,
};

export const defaultDetections = {
  bulk: 0,
  malicious: 0,
  maliciousBec: 0,
  none: 0,
  spam: 0,
  spoof: 0,
  suspicious: 0,
};

export const defaultDetectionsTimeline = {
  bulk: 0,
  malicious: 0,
  maliciousBec: 0,
  none: 0,
  spam: 0,
  spoof: 0,
  suspicious: 0,
  date: '',
};

export const allowListRecord = {
  breakdown: { current: {}, previous: 0 },
  timeline: {
    current: [{ day: '', total: 0, totals: [{ count: 0, policyType: '' }] }],
    previous: [{ day: '', total: 0, totals: [{ count: 0, policyType: '' }] }],
  },
  totals: { current: 0, previous: 0 },
};

export const blockListRecord = {
  breakdown: {
    current: { blockedSender: 0 },
    previous: { blockedSender: 0 },
  },
  timeline: {
    current: [
      {
        total: 0,
        totals: [
          {
            policyType: 'BLOCKED_SENDER',
            count: 0,
          },
        ],
        day: '',
      },
    ],
    previous: [
      {
        total: 0,
        totals: [
          {
            policyType: 'BLOCKED_SENDER',
            count: 0,
          },
        ],
        day: '',
      },
    ],
  },
  totals: {
    current: 0,
    previous: 0,
  },
};
