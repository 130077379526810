import { Text as BaseText } from '@a1s/ui';
import React, { ComponentProps } from 'react';

//
// Typescript
// -------------------------------------------------------------------------------------------------

type TextProps = ComponentProps<typeof BaseText>;
type BaseProps = Omit<TextProps, 'css'>;

//
// Text style defintions
// -------------------------------------------------------------------------------------------------

export const BASE_STYLES: BaseProps = {
  font: 'sans',
  size: 'sm',
  stretch: 'ultraCondensed',
};

export const LARGE_STYLES: BaseProps = {
  ...BASE_STYLES,
  color: '$gray700',
  font: 'lato',
  lineHeight: 1,
  size: '4xl',
  stretch: 'expanded',
  weight: 'light',
};

export const TITLE_STYLES: BaseProps = {
  ...BASE_STYLES,
  color: '$gray600',
  transform: 'uppercase',
  weight: 'medium',
};

//
// Components for text styles
// -------------------------------------------------------------------------------------------------

export function Text({ ...props }: TextProps) {
  return <BaseText {...BASE_STYLES} {...props} />;
}

Text.Large = Large;

function Large({ ...props }: TextProps) {
  return <BaseText {...LARGE_STYLES} {...props} />;
}

Text.Title = Title;

function Title({ ...props }: TextProps) {
  return <BaseText {...TITLE_STYLES} {...props} />;
}
