// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type PropType } from '../../dataTypesAndUtils';

import { calculatePercent } from '../dataTypesAndUtils';

import { insightsNameSpoofRecord } from './dataTypesAndUtils';

import MostCommonNameSpoofs from './MostCommonNameSpoofs';
import NameSpoofs from './NameSpoofs';

// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';

export const fetchInsightsOrgSpoofs = loader('./queries/FetchInsightsOrgSpoofs.graphql');
export const fetchInsightsOrgSpoofsByDay = loader('./queries/InsightsOrgSpoofsByDayQuery.graphql');

export default function NameSpoofOverview({ detectionCount, detectionError, detectionLoading }: PropType) {
  const duration = useDuration();
  const { t } = useTranslation('email');

  const {
    data: insightsData,
    error: insightsError,
    loading: insightsLoading,
  } = useQuery(fetchInsightsOrgSpoofs, {
    variables: { limit: 5, ...setInsightsDateRangeFromDuration(duration) },
  });

  const { topNames, topTargets } = get(insightsData, 'insightsOrgSpoofs.data', insightsNameSpoofRecord);

  const {
    data: spoofs,
    error,
    loading,
  } = useQuery(fetchInsightsOrgSpoofsByDay, { variables: setInsightsDateRangeFromDuration(duration) });
  const totalNameSpoof = get(spoofs, 'insightsOrgSpoofsByDay.data.breakdown', {
    current: { classicSpoof: 0, nameSpoof: 0 },
    previous: { classicSpoof: 0, nameSpoof: 0 },
  });

  const spoofsByDayCurrent = get(spoofs, 'insightsOrgSpoofsByDay.data.timeline.current', []);
  const spoofsByDayPrevious = get(spoofs, 'insightsOrgSpoofsByDay.data.timeline.previous', []);

  return (
    <>
      <Helmet title={`${t('nameSpoof')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <>
        <NameSpoofs
          data={{
            lineGraph: {
              current: spoofsByDayCurrent,
              previous: spoofsByDayPrevious,
            },
            ringChart: calculatePercent(totalNameSpoof.current.nameSpoof, detectionCount),
            sectionOverview: {
              current: totalNameSpoof.current.nameSpoof || 0,
              previous: totalNameSpoof.previous.nameSpoof || 0,
            },
          }}
          error={error || detectionError || insightsError}
          loading={loading || detectionLoading || insightsLoading}
        />
        <MostCommonNameSpoofs data={{ topNames, topTargets }} error={insightsError} loading={insightsLoading} />
      </>
    </>
  );
}
