import { gql, useLazyQuery } from '@apollo/client';

const GET_CF_REDIRECT_URL_QUERY = gql`
  query GetRedirectUrl {
    getRedirectUrlResponse @rest(path: "/cf-auth/accounts_oauth", type: "CfAccountOauth") {
      redirectUrl
      __typename
    }
  }
`;

export function useGetCfRedirectUrl() {
  return useLazyQuery(GET_CF_REDIRECT_URL_QUERY, { fetchPolicy: 'network-only' });
}
