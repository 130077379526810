// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import Content from 'ui/molecules/Content';

const Label = styled(Content)`
  margin-right: ${({ theme }) => rem(theme.spacing.md)};
  width: ${rem(275)};
`;

export default Label;
