// @flow

// $FlowFixMe
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from './Container';

import Badge from 'ui/atoms/Badge';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

export type DispositionType =
  | 'BULK'
  | 'MALICIOUS'
  | 'MALICIOUS-BEC'
  | 'NONE'
  | 'QUARANTINE_RELEASE'
  | 'SPAM'
  | 'SPOOF'
  | 'SUSPICIOUS'
  | string;

interface Props {
  children?: React$Node;
  disposition?: DispositionType;
}

const SEARCHABLE_DISPOSITIONS = ['bulk', 'malicious', 'malicious-bec', 'spam', 'spoof', 'suspicious'];

export default function DispositionBadge({ children, disposition = 'NONE' }: Props) {
  const searchDetections = useGlobalSearch('detectionSearch');
  const { t } = useTranslation('components');

  const canLinkToDisposition = useMemo(() => {
    return SEARCHABLE_DISPOSITIONS.includes(disposition.toLowerCase());
  }, [disposition]);

  const lowerCasedDisposition = disposition.toLowerCase();
  const upperCasedDisposition = disposition.toUpperCase();

  return (
    <Container
      as={canLinkToDisposition ? 'button' : 'span'}
      canLinkToDisposition={canLinkToDisposition}
      onClick={() => canLinkToDisposition && searchDetections({ query: `final_disposition:${lowerCasedDisposition}` })}
      disposition={upperCasedDisposition}
    >
      <Badge dataTestId={`molecule-disposition-badge-${lowerCasedDisposition}`}>
        {children || t(`ApiDispositions.${upperCasedDisposition}`)}
      </Badge>
    </Container>
  );
}
