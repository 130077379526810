// @flow

import { curveLinear as curve } from '@vx/curve';
import { LinearGradient } from '@vx/gradient';
import { scaleTime, scaleLinear } from '@vx/scale';
import { AreaClosed, LinePath } from '@vx/shape';
import { extent, max } from 'd3-array';
import React, { Fragment, type Node } from 'react';
import { withTheme } from 'styled-components';

import { HEIGHT, X_OFFSET, Y_OFFSET, type Data, type Tint } from './util';

import type { Theme } from 'config/theme';

type Props = {
  children: ({
    series: mixed[],
    lineTint: string,
    xMax: number,
    xScale: Function,
    yMax: number,
    yScale: Function,
  }) => Node,
  closed: boolean,
  data: Data[],
  dataIndex: number,
  previousData?: Data[],
  tint?: Tint,
  theme: Theme,
  width: number,
};

const CurrentLine = (
  { children, closed, data, dataIndex, previousData = [], theme, tint = 'dark', width, ...rest }: Props,
  ref: any
) => {
  const series = [data, previousData];
  const allData = [...data, ...previousData];
  const x = (d) => d.date;
  const y = (d) => d.value;
  const xMax = width - X_OFFSET;
  const yMax = HEIGHT - Y_OFFSET - 18;

  const xScale = scaleTime({ domain: extent(allData, x), range: [0, xMax] });
  const yScale = scaleLinear({
    clamp: true,
    domain: [0, max(allData, y)],
    nice: true,
    range: [yMax, 0],
  });
  const props = { curve, data, x, xScale, y, yScale, ...rest };
  const tintFill = theme.tints[tint].fill;
  const tintFrom = theme.tints[tint].from;
  const tintTo = theme.tints[tint].to;

  return (
    <Fragment>
      <LinearGradient from={tintFrom} id={`current-line-${tint}`} to={tintTo} />
      {closed && <AreaClosed fill={tintFill} fillOpacity={0.1} strokeWidth={0} {...props} />}
      <LinePath
        data-index={dataIndex}
        innerRef={ref}
        stroke={`url('#current-line-${tint}')`}
        strokeWidth={closed ? 2 : 3}
        {...props}
      />
      {children && typeof children === 'function'
        ? children({ series, lineTint: tint, xMax, xScale, yMax, yScale })
        : children}
    </Fragment>
  );
};

// $FlowIssue
export default withTheme(React.forwardRef(CurrentLine));
