export const LANGUAGE_DICTIONARY = {
  de: {
    code: 'de-DE',
    name: 'Deutsch', // German
  },
  en: {
    code: 'en-US',
    name: 'English',
  },
  es: {
    code: 'es-ES',
    name: 'Español',
  },
  fr: {
    code: 'fr-FR',
    name: 'Français',
  },
  it: {
    code: 'it-IT',
    name: 'Italiano',
  },
  ja: {
    code: 'ja-JP',
    name: '日本語', // Japanese
  },
  ko: {
    code: 'ko-KR',
    name: '한국어', // Korean
  },
  pt: {
    code: 'pt-BR',
    name: 'Português',
  },
  'zh-CN': {
    code: 'zh-CN',
    name: '简体中文', // Chinese (simplified)
  },
  'zh-TW': {
    code: 'zh-TW',
    name: '繁体中文', // Chinese (traditional)
  },
};

export type LanguageCodeType =
  | 'de-DE'
  | 'en-US'
  | 'es-ES'
  | 'fr-FR'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'pt-BR'
  | 'zh-CN'
  | 'zh-TW';

export type LanguageLabelType = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'ko' | 'pt' | 'zh';

export type LanguageType =
  | 'Deutsch'
  | 'English'
  | 'Español'
  | 'Français'
  | 'Italiana'
  | '日本語'
  | '한국인'
  | 'Português'
  | '简体中文'
  | '中國傳統的';
