// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { HEIGHT as HEADER_HEIGHT } from './Header';

export default styled.main`
  margin-top: ${rem(HEADER_HEIGHT)};
  min-width: ${rem(1115)};
  position: relative;
  z-index: 0;
`;
