// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import ProgressTable from '../ProgressTable';

const ProgressTablePlaceholder = () => {
  const data = [
    ['Example 1', 30],
    ['Example 2', 20],
    ['Example 3', 10],
  ];
  // eslint-disable-next-line no-unused-vars
  const calculatePercent = (d, [_0, number]) => Number(number) / d.reduce((a, b) => Number(a) + Number(b[1]), 0);

  const { t } = useTranslation('components');

  return (
    <ProgressTable
      calculatePercent={calculatePercent}
      cellWidths={['50%', '40%']}
      data={data}
      hideValue
      placeholder
      placeholderText={t('NoData.noDataObserved')}
    />
  );
};

export default ProgressTablePlaceholder;
