import { Box } from '@a1s/ui';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Text } from 'ui-new';

export function Loader() {
  const { t } = useTranslation('common');

  return (
    <Box css={{ height: 300 }}>
      <Text.Title>{t('checkingForDetails')}</Text.Title>
    </Box>
  );
}
