// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import { Route } from 'react-router';
import styled from 'styled-components';

import Header from './Header';

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.tuscanBrown};
  padding-bottom: ${({ theme }) => rem(theme.spacing.md)};

  &:last-child {
    border-bottom: none;
  }

  &[aria-expanded='false'] {
    padding-bottom: ${rem(0)};

    & > *:first-child {
      background-color: ${({ theme }) => theme.colors.ghostWhite};
      padding: ${({ theme }) => rem(theme.spacing.md)};
    }

    /* Hide the links */
    & > nav {
      display: none;
    }
  }
`;

type Props = { baseUrl: string, children: Node, extra?: Node, title: string };
const Section = ({ baseUrl, children, extra, title }: Props) => {
  const path = typeof baseUrl === 'object' ? baseUrl.pathname : baseUrl;

  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

  return (
    <Route path={escapedPath}>
      {({ match }) => {
        const expanded = !!match;
        return (
          <Container aria-expanded={expanded}>
            <Header extra={extra} to={baseUrl}>
              {title}
            </Header>
            <nav>{children}</nav>
          </Container>
        );
      }}
    </Route>
  );
};

export default Section;
