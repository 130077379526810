import { Box, Text } from '@a1s/ui';
import { useQuery, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InlineTable, Loader } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------
interface Props {
  lookupKey?: string;
  showOutbound: boolean;
}

export function MailTrace({ lookupKey, showOutbound }: Props) {
  const { data, loading } = useRemoteData(lookupKey);
  const { t } = useTranslation('unisearch');

  const { inboundLines = [], outboundLines = [] } = data || {};

  const textProps = { color: '$gray800', font: 'sans', size: 'sm', stretch: 'ultraCondensed' } as const;
  const headerTextProps = { ...textProps, size: 'xs', transform: 'uppercase', weight: 'semibold' } as const;

  const renderLine = (line: LineType, index: number) => {
    return (
      <InlineTable.Row key={`${line.ts}-${index}`}>
        <InlineTable.Cell css={{ width: '15%' }}>
          <Text {...headerTextProps}>{line.ts}</Text>
        </InlineTable.Cell>
        <InlineTable.Cell>
          <Text {...textProps}>{line.message}</Text>
        </InlineTable.Cell>
      </InlineTable.Row>
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <Text>{t('inbound')}</Text>
      <InlineTable css={{ marginInline: '$4', '& td:first-of-type': { pr: '$4' }, width: '100%' }}>
        <tbody>{inboundLines.length > 0 ? inboundLines?.map(renderLine) : <NoData />}</tbody>
      </InlineTable>
      {showOutbound && (
        <Box pt="4">
          <Text>{t('outbound')}</Text>
          <InlineTable css={{ marginInline: '$4', '& td:first-of-type': { pr: '$4' }, width: '100%' }}>
            <tbody>{outboundLines.length > 0 ? outboundLines?.map(renderLine) : <NoData />}</tbody>
          </InlineTable>
        </Box>
      )}
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------
function NoData() {
  const { t } = useTranslation('unisearch');

  const textProps = { color: '$gray800', font: 'sans', size: 'sm', stretch: 'ultraCondensed' } as const;

  return (
    <InlineTable.Row>
      <InlineTable.Cell>
        <Text {...textProps}>{t('dataStillProcessing')}</Text>
      </InlineTable.Cell>
    </InlineTable.Row>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = loader('./query.graphql');

interface LineType {
  message: string;
  ts: string;
}

interface APIData {
  inboundLines: LineType[];
  outboundLines: LineType[];
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

function useRemoteData(lookupKey: string | undefined): HookResult {
  const { data, error, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    skip: !lookupKey,
    variables: { key: lookupKey },
  });

  if (error) return { data: undefined, error, loading: false };
  if (!data?.unisearchLogs?.inbound) return { data: undefined, error: null, loading };

  return {
    data: {
      // We return undefined so the default value is set when this variable is destructured
      inboundLines: data?.unisearchLogs?.inbound?.lines || undefined,
      // We return undefined so the default value is set when this variable is destructured
      outboundLines: data?.unisearchLogs?.outbound?.lines || undefined,
    },
    error,
    loading,
  };
}
