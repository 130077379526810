// @flow

import React, { type Node } from 'react';

import Content from './Content';
import Main from './Main';

type Props = { main: Node };

const Login = ({ main }: Props) => (
  <Main>
    <Content>{main}</Content>
  </Main>
);

export default Login;
