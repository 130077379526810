// @flow

import copy from 'copy-to-clipboard';
import { rem } from 'polished';
// $FlowFixMe
import React, { type Node } from 'react';
import styled from 'styled-components';

import Copy from './Copy';

type AlignType = 'center' | 'inherit' | 'initial' | 'justify' | 'left' | 'right';

type Props = {
  children: Node,
  dataTestId?: string,
  text?: string,
  textAlign?: AlignType,
};

const Container = styled.div`
  color: inherit;
  cursor: copy;
  position: relative;

  ${/* sc-custom 'a' */ Copy} {
    opacity: 0.3;
  }

  &:hover {
    ${/* sc-custom 'a:hover' */ Copy} {
      opacity: 1;
    }
  }

  &:active {
    ${/* sc-custom 'a:active' */ Copy} {
      opacity: 1;
      transform: translateY(${rem(1)});
    }
  }
`;

const CopyButton = styled.button`
  background: none;
  border-width: 0;
  color: inherit;
  cursor: copy;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: ${({ textAlign }) => textAlign};
  width: 100% !important;
  word-break: break-all;
  word-wrap: break-word;
`;

export default function Text({ children, dataTestId = 'molecule-copy', text = '', textAlign = 'inherit' }: Props) {
  const handleCopy = () => {
    if (text) copy(text);
  };

  return (
    <Container data-testid={dataTestId}>
      <CopyButton textAlign={textAlign} onClick={handleCopy} type="button">
        {children} <Copy onCopy={handleCopy} text={text || children} />
      </CopyButton>
    </Container>
  );
}
