// @flow

import React, { type Node } from 'react';

import Container from './Container';
import Text from './Text';

type Props = {
  children?: Node,
  dataTestId?: string,
  small?: boolean,
};

const Badge = ({ children, dataTestId = 'atom-badge', small = false, ...props }: Props) => {
  return (
    <Container data-testid={dataTestId} {...props}>
      <Text small={small}>{children}</Text>
    </Container>
  );
};

export default Badge;
