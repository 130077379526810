// @flow
import { addDays } from 'date-fns';

import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Cell, Header } from 'ui/molecules/Table';

export type GeoMapType = {
  count: number,
  geolocation: {
    accuracyRadius: number,
    city: string,
    country: string,
    latitude: number,
    longitude: number,
    subdivisions: Array<string>,
  },
};

type GeoMapResultType = {
  count: number,
  description: string,
  latitude: number,
  longitude: number,
};

export type GraphDataType = {
  count: number,
  threatCategory: string,
};

type GraphTableDataType = {
  count: number,
  threatType: string,
};

type GraphTableReturnType = {
  label: string,
  value: number,
};

type LineGraphReturnType = {
  date: number,
  value: number,
};

type LineGraphType = {
  date: number,
  keys: Array<string>,
  values: Array<number>,
};

type LineGraphInsightsType = {
  day: string,
  total: number,
  totals: Array<Object>,
};

type MixType = string | number;

type ProgressTableDataType = {
  count: number,
  domain: string,
};

export type TableDataType = {
  count: number,
  daysRegistered: number,
  domain: string,
};

export const defaultLineGraphData = {
  current: [{ date: 0, keys: [''], values: [0] }],
  previous: [{ date: 0, keys: [''], values: [0] }],
};

export const placeholderData = [
  { label: 'example1@email.com', value: 5 },
  { label: 'example2@email.com', value: 4 },
  { label: 'example3@email.com', value: 3 },
  { label: 'example4@email.com', value: 2 },
  { label: 'example5@email.com', value: 1 },
];

export const StyledCell = styled(Cell)`
  vertical-align: ${({ centered }) => (centered ? 'middle' : 'top')};
  word-wrap: break-word !important;
  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      :not(:first-of-type) {
        padding-left: 0 !important;
      }
    `};
`;

export const StyledHeader = styled(Header)`
  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

export const StyledSpan = styled.span`
  font-family: 'roboto mono', monospace;
  margin-left: -7px;
`;

export function calculatePercent(numerator: number, denominator: number) {
  return numerator && denominator ? numerator / denominator : 0;
}

export function mungeLineGraphData(data: Array<LineGraphType>): Array<LineGraphReturnType> {
  return data.map((row) => ({ date: row.date, value: row.values[0] }));
}

export function mungeLineGraphDataInsights(data: Array<LineGraphInsightsType>): Array<LineGraphReturnType> {
  return data.map((row) => ({ date: addDays(new Date(row.day), 1), value: row.total }));
}

export function mungeLineGraphDataNameSpoof(data: Array<LineGraphType>): Array<LineGraphReturnType> {
  return data.map((row) => ({ date: row.date, value: row.values[0] + row.values[1] }));
}

export function dataForGraphTable(data: Array<GraphDataType>): Array<GraphTableReturnType> {
  return data.map((row) => ({ label: row.threatCategory, value: row.count }));
}

export function dataForThreatTypeGraphTable(data: Array<GraphTableDataType>, total: number): Array<Array<string>> {
  return data.map((row) => [row.threatType, `${((row.count / total) * 100).toFixed(1)}%`]);
}

export function dataForGeoMap(data: Array<GeoMapType>): Array<GeoMapResultType> {
  return data
    .filter((row) => !!row.geolocation)
    .map((row) => {
      const { count, geolocation } = row;
      const { latitude, longitude, city, country } = geolocation;
      const description = `${city}, ${country}`.replace('null, ', '').replace(', null', '');
      return {
        count,
        description,
        latitude,
        longitude,
      };
    });
}

export function mungeProgressTableData(data: Array<ProgressTableDataType>): Array<Array<MixType>> {
  return [...data]
    .sort((a, b) => b.count - a.count)
    .slice(0, 15)
    .map((row) => [row.domain, row.count]);
}

export function placeholderDataArray(length: number = 5) {
  // $FlowFixMe - flow doesn't like missing type annotation
  return Array.from({ length }, () => ({}));
}
