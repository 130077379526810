import { styled } from '@a1s/ui';
import React, { ComponentProps, PropsWithChildren } from 'react';

//
// Main container
// -------------------------------------------------------------------------------------------------

type ModalProps = PropsWithChildren<ComponentProps<typeof Container>>;

export function Modal({ children, ...props }: ModalProps) {
  return <Container {...props}>{children}</Container>;
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled('div', {
  background: '$white',
  borderTop: '$colors$orange500 solid 4px',
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 999,
});
