import { Box, Stack } from '@a1s/ui';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import React, { useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailDetails } from 'screens/Search/ui';
import { useMailviewData } from 'screens/shared/hooks/useMailviewData';
import { useUnisearchAllMailData } from 'screens/shared/hooks/useUnisearchAllMailData';

import { DetectionEmailPreview, MailTrace, RawEmail, Scrollable, Tabs } from 'ui-new';

interface ViewEmailProps {
  emailId?: string;
}

export function ViewEmail({ emailId }: ViewEmailProps) {
  const { data: mailviewData, loading: mailviewLoading } = useMailviewData(emailId);
  const { data: searchData, loading: searchLoading } = useUnisearchAllMailData({
    end: String(startOfDay(subDays(new Date(), Number.parseInt('365', 10)))),
    finalDisposition: '',
    alertId: emailId,
    searchMode: 'fielded',
    searchTerm: '',
    searchType: 'all-mail',
    start: String(endOfDay(new Date())),
  });

  const row = searchData?.[0];

  return (
    <EmailData
      clientRecipients={row?.clientRecipients}
      detailData={mailviewData}
      emlPath={row?.storedAt}
      isJournaled={row?.isJournaled}
      isQuarantined={row?.isQuarantined}
      lookupKey={row?.key}
      mailviewLoading={mailviewLoading || searchLoading}
      searchInfo={row}
    />
  );
}

//
//
// -------------------------------------------------------------------------------------------------

interface EmailDataProps {
  emlPath?: ComponentProps<typeof RawEmail>['emlPath'];
  clientRecipients?: string[];
  detailData?: {
    highlightData?: ComponentProps<typeof EmailDetails>['mailviewHighlightData'];
    screenshot?: ComponentProps<typeof DetectionEmailPreview>['image'];
  };
  isJournaled?: boolean;
  isQuarantined?: boolean;
  lookupKey?: string;
  mailviewLoading?: boolean;
  searchInfo?: ComponentProps<typeof EmailDetails>['searchInfo'];
}

function EmailData({
  emlPath,
  detailData,
  isJournaled,
  isQuarantined,
  lookupKey,
  mailviewLoading,
  searchInfo,
}: EmailDataProps) {
  const { t } = useTranslation('unisearch');
  type TabState = 'details' | 'downloads' | 'mailtrace' | 'preview' | 'raw';
  const [selected, setSelected] = useState<TabState>('details');
  const { screenshot } = detailData || {};

  return (
    <Stack css={{ flexGrow: 2 }}>
      <Tabs>
        <Tabs.Tab onClick={() => setSelected('details')} selected={selected === 'details'}>
          {t('emailDetails')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('preview')} selected={selected === 'preview'}>
          {t('emailPreview')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('raw')} selected={selected === 'raw'}>
          {t('rawEmail')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('mailtrace')} selected={selected === 'mailtrace'}>
          {t('mailTrace')}
        </Tabs.Tab>
      </Tabs>

      <Box bg="$white" css={{ flexGrow: 1, height: '100%' }} r p="2">
        <Box css={{ height: '100%', position: 'relative' }}>
          <Scrollable>
            <Box p="2">
              {selected === 'details' && (
                <EmailDetails
                  canReport={false}
                  isBenign={false}
                  loading={mailviewLoading}
                  mailviewHighlightData={detailData?.highlightData}
                  searchInfo={searchInfo}
                />
              )}
              {selected === 'preview' && <DetectionEmailPreview image={screenshot} loading={mailviewLoading} />}
              {selected === 'raw' && <RawEmail emlPath={emlPath} />}
              {selected === 'mailtrace' && (
                <MailTrace lookupKey={lookupKey} showOutbound={!isJournaled && !isQuarantined} />
              )}
            </Box>
          </Scrollable>
        </Box>
      </Box>
    </Stack>
  );
}
