// @flow

import styled from 'styled-components';

export default styled.main`
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;
