// @flow

/**
 * Info Component
 *
 * Displays an "i" icon that, when hovered over shows a tooltip with more information.
 */

// $FlowFixMe
import React, { type Node, useState } from 'react';

import Container from './Container';
import Icon from './Icon';

import Tooltip from 'ui/atoms/Tooltip';

type Props = {
  children?: Node,
  dataTestId?: string,
  icon?: 'mail-retract',
  onClick?: Function,
  tooltipPosition?: 'bottom' | 'left' | 'right' | 'top',
  withoutDelay?: boolean,
};

export default function Info({
  children,
  dataTestId = 'molecule-info',
  icon,
  onClick,
  tooltipPosition = 'bottom',
  withoutDelay = false,
}: Props) {
  const [anchor, setAnchor] = useState(null);
  const [tooltipShown, setTooltipShown] = useState(false);

  let timeoutId = null;

  const hideTooltip = () => {
    if (withoutDelay) {
      setTooltipShown(false);
    } else {
      timeoutId = setTimeout(() => setTooltipShown(false), 500);
    }
  };

  const showTooltip = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setTooltipShown(true);
  };

  return (
    <Container data-testid={dataTestId} onClick={onClick}>
      <Icon
        icon={icon}
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onMouseOut={hideTooltip}
        onMouseOver={showTooltip}
        ref={(ref) => setAnchor(ref)}
      />
      <Tooltip
        anchor={anchor}
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onMouseOut={hideTooltip}
        onMouseOver={showTooltip}
        position={tooltipPosition}
        visible={tooltipShown}
      >
        {children}
      </Tooltip>
    </Container>
  );
}
