// @flow

type CustomerActivePermissionType = Array<string | mixed> | string;

type PermissionPermissionValues = 'none' | 'parent no access' | 'parent admin' | 'parent read only';

const PERMISSIONS = {
  child: ['child read only', 'child admin'],
  parent: ['parent no access', 'parent read only', 'parent admin'],
};

const getPermissionLevel = (
  activePermissions: CustomerActivePermissionType,
  permissionType: string
): PermissionPermissionValues => {
  const possiblePermissions = PERMISSIONS[permissionType];
  if (activePermissions) {
    return possiblePermissions.find((permission) => activePermissions.includes(permission)) || 'none';
  }
  return 'none';
};

export default getPermissionLevel;
