// @flow

// $FlowFixMe
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Input from 'ui/atoms/Input';
import { ReactComponent as Eyeball } from 'ui/atoms/Input/icon.svg';
import Container from 'ui/atoms/TextInput/Container';

const StyledEyeBall = styled(Eyeball)`
  fill: ${({ theme }) => theme.colors.gainsboro};
  ${({ canSeeIt }) =>
    canSeeIt &&
    css`
      fill: ${({ theme }) => theme.colors.grayBlue};
    `};
`;

const StyledInput = styled(Input)`
  display: none;
`;

type Props = {
  dataTestId?: string,
  placeholder?: string,
};

export default function PasswordInput({ dataTestId = 'organism-password-input', placeholder = '', ...rest }: Props) {
  /* eslint-disable-next-line no-unused-vars */
  const [canSeeIt, setCanSeeIt] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // The 'input' here is to prevent FF from auto filling login credentials because it ignores autoComplete="off"
  // https://stackoverflow.com/questions/17781077/autocomplete-off-is-not-working-on-firefox
  return (
    <Container data-testid={dataTestId}>
      <StyledInput type="password" />
      <Input
        aria-label="password-input"
        autoComplete="off"
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        {...rest}
      />
      <StyledEyeBall canSeeIt={showPassword} onClick={() => setShowPassword(!showPassword)} />
    </Container>
  );
}
