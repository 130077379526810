// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import Chart from './Chart';
import Container from './Container';
import Label from './Label';
import Percent from './Percent';
import checkPercent from './sentry';
import Text from './Text';

import theme from 'config/theme';

type Props = { color?: string, dataTestId?: string, label?: string, percent: number, size?: 'small' | 'large' };

const RingChart = ({
  color = theme.colors.deepSpaceSparkle,
  dataTestId = 'molecule-ring-chart',
  label,
  percent,
  size = 'large',
}: Props) => {
  const { t } = useTranslation('components');

  return (
    <Container data-testid={dataTestId}>
      <Chart color={color} percent={checkPercent(percent)} />

      <Text>
        <Percent color={color}>{t('PercentChange.percent', { value: percent })}</Percent>

        {size === 'large' && <Label>{label}</Label>}
      </Text>
    </Container>
  );
};

export default RingChart;
