// @flow

import { rem } from 'polished';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import logo from './cloudflare.svg';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Icon = styled.img.attrs({ src: logo })`
  height: ${rem(40)};
  position: relative;
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutBack};
  transform: translateY(-3px);
`;

Icon.displayName = 'Icon';

const Link = styled.span`
  border-radius: ${rem(4)};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: ${rem(-2)};
  padding: ${({ theme }) => rem(theme.spacing.sm)};

  &[href]:hover {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type Props = {
  dataTestId?: string,
  link?: boolean,
  onClick?: () => void,
};

function Logo({ dataTestId = 'atom-logo', link = true, onClick }: Props) {
  const props = link ? { as: NavLink, onClick, to: '/' } : {};

  return (
    <Link {...props} data-testid={dataTestId}>
      <Icon />
    </Link>
  );
}

export default Logo;
