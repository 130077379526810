// @flow

import axios from 'axios';

// import { defaultRecord, useCurrentUser } from './utils/hooks/useCurrentUser';
import traceId from './utils/traceId';

export const csrfAxios = axios.create();

csrfAxios.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: { ...config.headers, 'X-TRACE-ID': traceId() },
  }),
  (err) => Promise.reject(err)
);

axios.interceptors.request.use(
  async (config) => {
    if (['delete', 'post', 'put'].includes(config.method)) {
      const {
        data: { token },
      } = await csrfAxios.post('/create_csrf_token');
      return {
        ...config,
        headers: {
          ...config.headers,
          'X-CSRF-TOKEN': token,
          'X-TRACE-ID': traceId(),
        },
      };
    }
    return {
      ...config,
      headers: { ...config.headers, 'X-TRACE-ID': traceId() },
    };
  },
  (err) => Promise.reject(err)
);
