import { styled } from '@a1s/ui';
import { throttle } from 'lodash';
import React, { useEffect, useRef, useState, MouseEvent, PropsWithChildren } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

/**
 * This component displays an icon that when the mouse is hovered over will reveal a tooltip with
 * additional information.
 */
export function InfoTooltip({ children }: PropsWithChildren<{}>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [align, setAlign] = useState<'left' | 'right'>('left');

  function handleMouseDown(event: MouseEvent<HTMLButtonElement | HTMLDivElement>) {
    event.preventDefault(); // Keeps the click on the link/button from closing the tooltip
  }

  function handleResize() {
    if (!ref.current) return;

    // If the component is on the right side of the window, align the tooltip to the right.
    // Align to the left if its on the left side of the window.
    const { left } = ref.current.getBoundingClientRect();
    if (left > window.innerWidth / 2) {
      setAlign('right');
    } else {
      setAlign('left');
    }
  }

  // Since `getBoundingClientRect` inside of `handleResize` is an expensive function
  // to run, we throttle calls to `handleResize` so it doesn't get called excessively.
  const throttledHandleResize = throttle(handleResize, 250);
  useEffect(() => {
    window.addEventListener('resize', throttledHandleResize);
    return () => window.removeEventListener('resize', throttledHandleResize);
  }, [throttledHandleResize]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      handleResize();
    });

    if (ref.current) {
      let el: HTMLElement | null = ref.current;

      // Find the nearest region element to observer for changes
      while (!el.matches("[role='region']")) {
        el = el.parentNode as HTMLElement;
        if (el.parentNode === document) break; // Don't go past the document root
      }

      // Watch the region element that we found for any DOM changes resize when they happen
      if (el) observer.observe(el, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Container ref={ref}>
      <HoverFocusButton />
      <Tooltip align={align} onMouseDown={handleMouseDown}>
        {children}
      </Tooltip>
    </Container>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function HoverFocusButton() {
  const ref = useRef<HTMLButtonElement | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  // If the mouse is hovered over the button for a second or more, set the browser's focus
  // to the button, which keeps the tooltip open.
  function handlePointerEnter() {
    timer.current = setTimeout(() => {
      if (ref.current) ref.current.focus();
    }, 500);
  }

  function handlePointrLeave() {
    if (timer.current) clearTimeout(timer.current);
  }

  return (
    <PassthroughButton onPointerEnter={handlePointerEnter} onPointerLeave={handlePointrLeave} ref={ref}>
      <Icon />
    </PassthroughButton>
  );
}

function Icon() {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="5.5" />
      <path d="M6.344 7.623H5.313c.003-.323.035-.586.094-.791.063-.209.164-.399.305-.57.14-.171.327-.366.56-.585.17-.158.325-.305.465-.443.144-.141.26-.292.35-.454.088-.164.132-.361.132-.59 0-.231-.046-.431-.138-.6a.903.903 0 0 0-.4-.387 1.39 1.39 0 0 0-.648-.136c-.214 0-.417.035-.61.105a1.04 1.04 0 0 0-.465.328c-.118.144-.18.334-.183.57H3.75c.007-.38.11-.706.31-.978.204-.272.477-.48.82-.625a2.947 2.947 0 0 1 1.153-.217c.47 0 .868.077 1.197.232.333.154.586.376.76.665.173.286.26.625.26 1.018 0 .303-.068.582-.205.837a3.203 3.203 0 0 1-.515.71c-.211.222-.435.434-.671.636-.203.171-.34.364-.41.58-.07.214-.105.446-.105.695ZM5.268 9.221c0-.151.052-.28.156-.383.103-.104.253-.157.449-.157.199 0 .35.053.454.157a.523.523 0 0 1 .155.383.51.51 0 0 1-.155.373c-.103.104-.255.156-.454.156-.196 0-.346-.052-.45-.156a.51.51 0 0 1-.155-.373Z" />
    </SVG>
  );
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled('div', {
  $$iconSize: '12px',
  display: 'inline-block',
  marginLeft: '$1-5',
  marginTop: 2,
  position: 'relative',
  verticalAlign: 'top',
});

const PassthroughButton = styled('button', {
  $$hitslop: '$spacing$1',
  appearance: 'none',
  background: 'transparent',
  border: 'none',
  margin: 'calc(0px - $$hitslop) 0 0 calc(0px - $$hitslop)',
  outline: 'none',
  padding: '$$hitslop',
});

const SVG = styled('svg', {
  $$color: '$colors$white',
  height: '$$iconSize',
  cursor: 'help',
  width: '$$iconSize',

  circle: { stroke: '$$color' },
  path: { fill: '$$color' },

  'button:hover &, button:focus &': {
    circle: { fill: '$$color' },
    path: { fill: '$colors$orange500' },
  },
});

const Tooltip = styled('div', {
  $$arrowSize: '8px',
  backgroundColor: '$yellow100',
  border: '$gray400 solid 1px',
  borderRadius: '$2',
  boxShadow: '$1',
  color: '$gray700',
  fontSize: '$sm',
  fontWeight: 400,
  lineHeight: 1.2,
  opacity: 0,
  padding: '$2 $4',
  position: 'absolute',
  textTransform: 'none',
  top: 'calc($$iconSize + $$arrowSize + $space$2)',
  transform: 'translateY(20px)',
  transition: 'opacity 250ms $quick, transform 250ms $quick, visibility 0s linear 250ms',
  userSelect: 'none',
  visibility: 'hidden',
  width: '$sizes$lg',
  zIndex: 1000,

  '& a': {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    color: '$blue400',
    cursor: 'pointer',
    fontFamily: 'inherit',
    padding: 0,
    textDecoration: 'underline',
    '&:hover': { color: '$blue500', textDecoration: 'none' },
  },

  'button:hover + &, button:focus + &': {
    opacity: 1,
    transition: 'opacity 250ms $quick, transform 250ms $quick, visibility 0s linear 0s',
    transform: 'translateY(0px)',
    visibility: 'visible',
  },

  '& > * + *': { marginTop: '$3' },

  '&::before': {
    borderBottom: '$$arrowSize solid $colors$gray400 ',
    borderLeft: '$$arrowSize solid transparent',
    borderRight: '$$arrowSize solid transparent',
    content: '',
    display: 'block',
    height: 0,
    position: 'absolute',
    top: 'calc(0px - $$arrowSize)',
    width: 0,
  },

  variants: {
    align: {
      left: {
        left: 'calc(0px - $4 - ($$arrowSize / 3))',
        '&::before': { left: '$space$4' },
      },
      right: {
        right: 'calc(0px - $4 - ($$arrowSize / 3))',
        '&::before': { right: '$space$4' },
      },
    },
  },
});
