// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Content = styled.div`
  margin: 0 auto;
  max-width: ${rem(1304)};
`;

export default Content;
