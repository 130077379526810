import { CSS } from '@a1s/ui';
import React, { createElement, useEffect, useState, Fragment } from 'react';
import rehypeReact from 'rehype-react';
import remarkGfm from 'remark-gfm';
import remarkParse, { Options } from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

import { Content } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface MarkdownProps {
  /**
   * Escape-hatch for styling a box when none of the variant props will work.
   */
  css?: CSS;

  /**
   * The text to format with markdown
   */
  markdown: string;
}

export function Markdown({ css: cssProps, markdown }: MarkdownProps) {
  return <Content css={cssProps}>{useMarkdown(markdown)}</Content>;
}

function useMarkdown(text: string = '') {
  const [content, setContent] = useState(<Fragment />);

  useEffect(() => {
    unified()
      .use(remarkParse, { fragment: true } as Options)
      .use(remarkGfm)
      .use(remarkRehype)
      .use(rehypeReact, { createElement, Fragment })
      .process(text)
      .then((file) => {
        setContent(file.result);
      });
  }, [text]);

  return content;
}
