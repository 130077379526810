import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';

import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration } from 'utils/duration';

//
// Data fetching and its types
// -------------------------------------------------------------------------------------------------

const placeholderData = {
  breakdown: {
    eliBlocked: 0,
    eliClickDirect: 0,
    eliClickRbi: 0,
    eliInterstitial: 0,
    eliNoAction: 0,
  },
  total: 0,
};

export interface ActionData {
  eliBlocked: number;
  eliClickDirect: number;
  eliClickRbi: number;
  eliInterstitial: number;
  eliNoAction: number;
}

interface APIData {
  breakdown: ActionData;
  total: number;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

interface UseRemoteData {
  duration: Duration;
}

/**
 * Private hook that encapsulates loading the data for the `EmailLinkIsolation.Middle` panel. It returns placeholder data until the API data is loaded.
 * Polls the endpoint every 15 minutes.
 */

export default function useRemoteData({ duration = '30' }: UseRemoteData): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });

  if (loading) return { data: placeholderData, error: null, loading: true };
  if (error) return { data: placeholderData, error, loading: false };

  if (!data?.insightsELIStats?.data) return { data: placeholderData, error: null, loading: false };

  const transformedBreadkown = transformBreakdown(data?.insightsELIStats?.data?.breakdown);

  return {
    data: { ...data?.insightsELIStats?.data, ...{ breakdown: transformedBreadkown } },
    error: null,
    loading: false,
  };
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface BreakdownDataType {
  eliBlocked?: string;
  eliClickDirect?: string;
  eliClickRbi?: string;
  eliInterstitial?: string;
  eliNoAction?: string;
}

function transformBreakdown(breakdownData: BreakdownDataType): ActionData {
  return {
    eliBlocked: Number(breakdownData?.eliBlocked || 0),
    eliClickDirect: Number(breakdownData?.eliClickDirect || 0),
    eliClickRbi: Number(breakdownData?.eliClickRbi || 0),
    eliInterstitial: Number(breakdownData?.eliInterstitial || 0),
    eliNoAction: Number(breakdownData?.eliNoAction || 0),
  };
}
