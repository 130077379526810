"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useResizeObserver = useResizeObserver;
var _lodash = require("lodash");
var _react = require("react");
var _resizeObserverPolyfill = _interopRequireDefault(require("resize-observer-polyfill"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// $FlowFixMe
function useResizeObserver(ref) {
  const [state, dispatch] = (0, _react.useReducer)(reducer, null);
  (0, _react.useEffect)(() => {
    const observer = new _resizeObserverPolyfill.default(
    // use debounce to avoid reporting to Sentry while the element's size still changing.
    (0, _lodash.debounce)(([entry]) => {
      if (!entry) return;
      dispatch({
        payload: entry.contentRect,
        type: 'set'
      });
    }, 200));
    const {
      current
    } = ref;
    if (current) observer.observe(current);
    return () => {
      if (current) observer.unobserve(current);
    };
  }, [ref]);
  return state;
}
function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      throw new Error();
  }
}