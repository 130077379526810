// @flow

import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Content from 'ui/molecules/Content';

const StyledContent = styled(Content).attrs({ 'data-testid': 'molecule-no-data' })`
  padding: ${({ theme }) => rem(theme.spacing.lg)} 0;
`;

type Props = { children?: string, message?: string };

const NoData = ({ children, message = 'copy' }: Props) => {
  const { t } = useTranslation('components');

  return <StyledContent centered>{`- ${children || t(`NoData.${message}`)} -`}</StyledContent>;
};

export default NoData;
