import { styled, Box, Center, Cluster, Dropdown, Stack, TabList, Text } from '@a1s/ui';
import React, { ComponentProps, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmissionTable } from './ui/SubmissionTable';
import { ViewEmail } from './ViewEmail';

import { SUBMISSION_DISPOSITIONS } from 'common';

import { SearchContextProvider } from 'screens/Search/lib';
import { usePhishguardData } from 'screens/shared/hooks/usePhishguardData';
import { Dialog, LabeledNumber, PanelContainer } from 'ui-new';
import { useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------
export const DISPOSITION_FILTER_DEFAULT_OPTION = 'orig. disposition';

const DISPOSITION_FILTER_OPTIONS = [...[DISPOSITION_FILTER_DEFAULT_OPTION, ...SUBMISSION_DISPOSITIONS]];

export default function SubmissionsScreen() {
  const { t } = useTranslation('submission');
  const duration = useDuration();
  const { data } = usePhishguardData({ duration });
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [emailId, setEmailId] = useState<null | string>(null);

  const allSubmissionsCount = data?.all?.totals?.currentTotal || 0;
  const teamSubmissionsCount = data?.team?.totals?.currentTotal || 0;
  const userSubmissionsCount = data?.user?.totals?.currentTotal || 0;

  return (
    <SearchContextProvider>
      <Center max="7xl">
        <Box px="10" py="12">
          <PanelContainer title={t('falseNegativeSubmissions')}>
            <Box p="5">
              <Stack gap="5">
                <Cluster gap="10">
                  <LabeledNumber color="$gray700" label={t('totalFnSubmissions')} value={allSubmissionsCount} />
                  <LabeledNumber color="$gray500" label={t('teamFnSubmissions')} value={teamSubmissionsCount} />
                  <LabeledNumber color="$gray500" label={t('userFnSubmissions')} value={userSubmissionsCount} />
                </Cluster>
                <Cluster align="end" justify="space-between" gap="5">
                  <TabList appearance="arrow-down">
                    <TabList.Tab
                      onClick={() => dispatch({ payload: 'all', type: 'set_submission_type' })}
                      selected={state.submissionType === 'all'}
                    >
                      {t('allSubmissions')}
                    </TabList.Tab>
                    <TabList.Tab
                      onClick={() => dispatch({ payload: 'team', type: 'set_submission_type' })}
                      selected={state.submissionType === 'team'}
                    >
                      {t('teamSubmissions')}
                    </TabList.Tab>
                    <TabList.Tab
                      onClick={() => dispatch({ payload: 'user', type: 'set_submission_type' })}
                      selected={state.submissionType === 'user'}
                    >
                      {t('userSubmissions')}
                    </TabList.Tab>
                  </TabList>

                  <Stack align="end">
                    <Cluster gap="5">
                      <Dropdown
                        onChange={(value: State['dispositionType']) =>
                          dispatch({ payload: value, type: 'set_disposition_type' })
                        }
                        value={state.dispositionType}
                      >
                        {DISPOSITION_FILTER_OPTIONS.map((disposition) => (
                          <Dropdown.Option value={disposition}>{t(disposition)}</Dropdown.Option>
                        ))}
                      </Dropdown>

                      <Search
                        onChange={(e) => dispatch({ payload: e.target.value, type: 'set_submission_id' })}
                        placeholder={t('searchSubmissionId')}
                      />
                    </Cluster>
                  </Stack>
                </Cluster>

                <SubmissionTable {...state} onViewEmail={(id) => setEmailId(id)} />
              </Stack>
            </Box>
          </PanelContainer>
        </Box>
      </Center>

      <Dialog.Closable
        onClose={() => setEmailId(null)}
        title={
          <Text.Title color="$orange500" size="$md">
            {t('viewEmail')}
          </Text.Title>
        }
        visible={!!emailId}
      >
        <Box bg="$blue200" css={{ flexGrow: 2, height: '100%', position: 'relative' }} r p="4">
          <Cluster css={{ minHeight: 600, minWidth: 900, width: 'max-content' }} gap="5">
            {!!emailId && <ViewEmail emailId={emailId} />}
          </Cluster>
        </Box>
      </Dialog.Closable>
    </SearchContextProvider>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

const Search = styled('input', {
  appearance: 'textfield',
  backgroundColor: '$gray100',
  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(136,136,136)" d="M19.094 17.797a.916.916 0 1 1-1.297 1.296l-2.97-2.97a5.53 5.53 0 0 1-3.243 1.044 5.543 5.543 0 0 1-3.948-1.636A5.546 5.546 0 0 1 6 11.583c0-1.49.582-2.893 1.636-3.947A5.544 5.544 0 0 1 11.583 6c1.492 0 2.895.581 3.947 1.635a5.546 5.546 0 0 1 1.637 3.948 5.535 5.535 0 0 1-1.044 3.244l2.97 2.97zm-7.511-2.464a3.724 3.724 0 0 0 2.652-1.098 3.722 3.722 0 0 0 1.098-2.652 3.722 3.722 0 0 0-1.099-2.651 3.72 3.72 0 0 0-2.65-1.099 3.72 3.72 0 0 0-2.652 1.1 3.722 3.722 0 0 0-1.099 2.651 3.723 3.723 0 0 0 1.1 2.652 3.723 3.723 0 0 0 2.65 1.097z"  /></svg>')`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  borderRadius: '$2',
  color: '$gray600',
  flexGrow: 1,
  fontFamily: '$roboto',
  fontSize: '$sm',
  fontStretch: 'ultra-condensed',
  fontWeight: 500,
  minWidth: 300,
  paddingLeft: '$9',
  paddingRight: '$4',
  py: '$2',

  '&:focus': {
    backgroundColor: '$gray50',
  },

  '&::placeholder': {
    color: '$gray400',
    textTransform: 'uppercase',
  },
});

//
// Private reducer
// -------------------------------------------------------------------------------------------------

interface SetDispositionTypeAction {
  payload: State['dispositionType'];
  type: 'set_disposition_type';
}

interface SetSubmissionIdAction {
  payload: State['submissionId'];
  type: 'set_submission_id';
}

interface SetSubmissionTypeAction {
  payload: State['submissionType'];
  type: 'set_submission_type';
}

type Action = SetDispositionTypeAction | SetSubmissionIdAction | SetSubmissionTypeAction;
type State = Omit<ComponentProps<typeof SubmissionTable>, 'offset' | 'onViewEmail'>;

const INITIAL_STATE: State = {
  dispositionType: DISPOSITION_FILTER_DEFAULT_OPTION,
  submissionId: '',
  submissionType: 'all',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_disposition_type':
      return { ...state, dispositionType: action.payload };
    case 'set_submission_id':
      return { ...state, submissionId: action.payload };
    case 'set_submission_type':
      return { ...state, submissionType: action.payload };
    default:
      return { ...state };
  }
}
