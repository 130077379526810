// @flow

import Base64 from 'Base64';
import { get } from 'lodash';
import { type Node } from 'react';

export function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(str), (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join('')
  );
}

export function calculateInterpolatedColors(color1: string, color2: string, steps: number) {
  const stepFactor = 1 / (steps - 1);
  const interpolatedColorArray = [];

  /* eslint-disable no-underscore-dangle */
  // $FlowFixMe
  const _color1 = color1.match(/\d+/g).map(Number);
  // $FlowFixMe
  const _color2 = color2.match(/\d+/g).map(Number);
  /* eslint-enable no-underscore-dangle */

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < steps; i++) {
    interpolatedColorArray.push(calcualteInterpolatedColor(_color1, _color2, stepFactor * i));
  }

  return interpolatedColorArray;
}

/*
  Calculate the percent of the difference between two numbers
*/
export function calculatePercentDifference(number: number, originalNumber: number) {
  if (number && originalNumber) {
    const result = number - originalNumber;
    return result / originalNumber;
  }
  return 0;
}

/*
  Used to combine two different data sets of arrays containing an object with a `date` and `value` property
*/
export function combineArrayObjectsOnDate(one: Array<Object>, two: Array<Object>) {
  const dates1 = new Set(one.map((row) => row.date));
  const dates2 = new Set(two.map((row) => row.date));

  const combinedDates = new Set([
    ...[...dates1].filter((date) => !!date && typeof date.getTime === 'function').map((date) => date.getTime()),
    ...[...dates2].filter((date) => !!date && typeof date.getTime === 'function').map((date) => date.getTime()),
  ]);

  return [...combinedDates].sort().map<Object>((epoch) => {
    const firstValue = get(
      one.find((row) => row.date.getTime() === epoch),
      'value',
      0
    );
    const secondValue = get(
      two.find((row) => row.date.getTime() === epoch),
      'value',
      0
    );

    return {
      date: new Date(epoch),
      value: firstValue + secondValue,
    };
  });
}

export function decodeValue(value: ?string): any {
  if (value && typeof value === 'string') {
    return decodeURIComponent(
      atob(value)
        .split('')
        .map((w) => {
          // eslint-disable-next-line prefer-template
          return '%' + ('00' + w.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  return value;
}

export const developerEmails = [
  'alex.newton@area1security.com',
  'andrea.merten@area1security.com',
  'jeremy.boles@area1security.com',
  'josh+pt@area1security.com',
  'joshua.chavez@area1security.com',
  'krystyna.lemenipop@area1security.com',
];

/*
  This function is used to calculate the pixel width of a string.
  Requires the string `text`, and the font with size.
  eg (with theme): const width = getTextWidth(String(<somebigNumber>), `bold ${rem(10) ${theme.fonts.roboto}`)
*/
export function getTextWidth(text: string, font: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const { width } = context.measureText(text);
  return width;
}

/*
 Converts a sql array into a normal Javascript array.
 SQL array: '{MALICIOUS,MALICIOUS-BEC,SPOOF,SUSPICIOUS,SPAM}'
 (An empty SQL array will be '{}' and that case needs to be handled, too)
 TODO: make sure the API returns a proper array.
*/
export function sqlArrayToJSON(input: string): Array<string> {
  if (!input || input === '{}') return [];

  return input.replace(/\{/g, '').replace(/}/g, '').split(',');
}

export function times(number: number, f: Function) {
  return Array.from({ length: number }).map<Node>((_, i) => f(i));
}

export function utf8ToBase64(str: string): string {
  const binstr = utf8ToBinaryString(str);
  return Base64.btoa(binstr);
}

/*
  Private functions
*/

function calcualteInterpolatedColor(color1: Array<number>, color2: Array<number>, factor: number) {
  const result: Array<number> = color1.slice();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

function utf8ToBinaryString(str) {
  const escstr = encodeURIComponent(str);

  // replaces any uri escape sequence, such as %0A,
  // with binary escape, such as 0x0A
  return escstr.replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)));
}
