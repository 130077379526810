import { Box, Cluster, ClusterProps, Row, Text, TextProps } from '@a1s/ui';
import React, { ReactNode } from 'react';

import { Searchable } from '../../../../../shared/Searchable';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface SearchableProps {
  metric?: string;
  searchType: 'detectionSearch' | 'mailTrace';
  target: string;
}

export interface LabeledValueProps {
  children: ReactNode;
  childrenColor?: TextProps['color'];
  gap?: ClusterProps['gap'];
  params?: SearchableProps;
  transform?: TextProps['transform'];
  value: number | string;
  valueColor?: TextProps['color'];
  weight?: TextProps['weight'];
  width?: string;
}

export function LabeledValue({
  children,
  childrenColor = '$gray600',
  gap = '5',
  params,
  transform,
  value,
  valueColor = '$gray400',
  weight = 'normal',
  width = 'xl',
}: LabeledValueProps) {
  const displayValue = typeof value === 'number' ? new Intl.NumberFormat(undefined).format(value) : value;

  return (
    <>
      <Row>
        <Cluster align="center" gap={gap} justify="start">
          <Box pl width={width} data-testid="boxx">
            {params ? (
              <Searchable params={params}>
                <Text.Loadable
                  color={childrenColor}
                  font="ronotoMono"
                  placeholder="--"
                  size="md"
                  stretch="expanded"
                  transform={transform}
                  weight={weight}
                >
                  {children}
                </Text.Loadable>
              </Searchable>
            ) : (
              <Text.Loadable
                color={childrenColor}
                font="ronotoMono"
                placeholder="--"
                size="md"
                stretch="expanded"
                transform={transform}
                weight={weight}
              >
                {children}
              </Text.Loadable>
            )}
          </Box>

          <Box>
            <Text.Loadable
              color={valueColor}
              font="ronotoMono"
              placeholder="0"
              size="md"
              stretch="expanded"
              transform={transform}
              weight={weight}
            >
              {displayValue}
            </Text.Loadable>
          </Box>
        </Cluster>
      </Row>
    </>
  );
}
