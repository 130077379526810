"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSVG = exports.StyledPath = exports.StyledCaption = exports.StyledBase = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledBase = (0, _styled.styled)('figure', {
  alignItems: 'stretch',
  display: 'flex',
  minHeight: '$sm',
  justifyContent: 'center',
  minWidth: '$sm',
  position: 'relative'
});
exports.StyledBase = StyledBase;
const StyledCaption = (0, _styled.styled)('figcaption', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  pointerEvents: 'none',
  zIndex: 2,
  '& > *': {
    pointerEvents: 'initial'
  }
});
exports.StyledCaption = StyledCaption;
const StyledPath = (0, _styled.styled)('path', {
  variants: {
    /**
     * Sets the fill of the path
     * @type $colors
     */
    fill: (0, _utils.tokenVariant)('fill', _styled.theme.colors)
  }
});
exports.StyledPath = StyledPath;
const StyledSVG = (0, _styled.styled)('svg', {
  display: 'block',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1
});
exports.StyledSVG = StyledSVG;