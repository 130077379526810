// @flow

import React, { type Node } from 'react';

import Container from './Container';
import Main from './Main';
import Sidebar from './Sidebar';
import Sticky from './Sticky';

type Props = { dataTestId?: string, children: Node, sidebar: Node, wide?: boolean };
const Settings = ({ dataTestId = 'template-settings', children, sidebar, wide = false }: Props) => (
  <Container data-testid={dataTestId}>
    <Sidebar wide={wide}>
      <Sticky>{sidebar}</Sticky>
    </Sidebar>
    <Main>{children}</Main>
  </Container>
);

Settings.displayName = 'Settings';

export default Settings;
