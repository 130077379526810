import { gql, useMutation, QueryResult } from '@apollo/client';
import { useEffect } from 'react';

interface BenignEmailPreviewType {
  clientRecipients?: string[];
  clientUuid?: string;
  messageId?: string;
}

export interface PreviewType {
  preview: {
    email: string;
    html: string;
    image64: string;
  };
}

interface ResponseType {
  previews: PreviewType[];
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data: PreviewType[];

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const MUTATION = gql`
  mutation Previews($input: PreviewsInput!) {
    previews(input: $input) @rest(method: "POST", path: "/search/previews", type: "PreviewsResponse") {
      error
      previews
    }
  }
`;

export default function usePreviewsMutation({
  clientRecipients,
  clientUuid,
  messageId,
}: BenignEmailPreviewType): HookResult {
  const [runFetchPreviews, { data, error, loading }] = useMutation<{ previews: ResponseType }>(MUTATION);

  useEffect(() => {
    // Early return
    if (clientRecipients?.length === 0) return;

    const requests = clientRecipients?.map((recipient: string) => ({
      message_id: messageId,
      recipient,
    }));

    const fetchPreviews = async () => {
      try {
        await runFetchPreviews({
          variables: {
            input: {
              client_id: clientUuid,
              requests,
            },
          },
        });
      } catch (e) {
        // check Sentry for potential errors
      }
    };
    if (requests?.length) fetchPreviews();
  }, [clientRecipients, clientUuid, messageId, runFetchPreviews]);

  return { data: data?.previews?.previews || [], error, loading };
}
