// @flow

import { rem, rgba } from 'polished';
import styled from 'styled-components';

export default styled.div`
  align-content: stretch;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: ${({ theme }) => theme.colors.gainsboro} solid 1px;
  box-shadow: ${({ theme }) => rgba(theme.colors.jet, 0.1)} 0 ${rem(2)} ${rem(4)};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: ${rem(48)};
`;
