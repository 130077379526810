// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Label = styled.th.attrs({ scope: 'row' })`
  font-size: ${rem(14)};
  font-weight: 400;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: ${rem(200)};
`;

export default Label;
