import { downloadBlob } from '@a1s/lib';
import { useApolloClient } from '@apollo/client';
import { formatISO } from 'date-fns';
import { loader } from 'graphql.macro';
import hash from 'hash-sum';
import React, { useMemo, useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '..';
import { detectionsToCSV, toVariables } from '../../lib';
import { APIData as DetectionsData } from '../../views/DetectionSearch/ui/Results';

import { ModalHeader } from '.';

//
// Load GraphQL Query
// -------------------------------------------------------------------------------------------------

export const query = loader('./queries/all.graphql');

//
// Main component
// -------------------------------------------------------------------------------------------------

interface CSVDownloadButtonProps {
  params: ComponentProps<typeof ModalHeader>['params'];
}

export default function CSVDownloadButton({ params }: CSVDownloadButtonProps) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const date = useMemo(() => new Date(), []);
  const { t } = useTranslation('unisearch');

  async function handleClick() {
    setLoading(true);

    const variables = {
      ...params,
      offset: 0,
      redress: true,
    };

    type QueryResult = { unisearchResults: { messages: DetectionsData[] } };
    const { data } = await client.query<QueryResult>({ query, variables: toVariables(date, variables, 1000) });

    const csv = detectionsToCSV(data.unisearchResults.messages);

    downloadBlob(new Blob([csv]), filename(hash(params)));

    setLoading(false);
  }

  return <IconButton icon="xls" onClick={handleClick} title={t('downloadCSV')} waiting={loading} />;
}

//
// Private function
// -------------------------------------------------------------------------------------------------

function filename(term: string): string {
  const timestamp = formatISO(new Date());

  if (term === '') return `${timestamp}.csv`;
  return `${timestamp}-${term}.csv`;
}
