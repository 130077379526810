// @flow

import React, { PureComponent, type Node } from 'react';

type Props = { children: Node };
type State = { openMenuId: ?string, setOpenMenuId: null | ((menuId: string) => void) };

export const Context = React.createContext<State>({ openMenuId: null, setOpenMenuId: null });

export default class Provider extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openMenuId: null,
      setOpenMenuId: (menuId: string) => {
        this.setState({ openMenuId: menuId });
      },
    };
  }

  setOpenMenuId = (menuId: string) => {
    this.setState({ openMenuId: menuId });
  };

  render() {
    const { children } = this.props;
    const { openMenuId, setOpenMenuId } = this.state;
    return <Context.Provider value={{ openMenuId, setOpenMenuId }}>{children}</Context.Provider>;
  }
}
