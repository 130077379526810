// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  width: ${rem(350)};
`;

export default Container;
