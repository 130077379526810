import { styled, Box, Cluster, Loadable, Stack, Text } from '@a1s/ui';
import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FIFTEEN_MINUTE_POLL_INTERVAL, placeholderData } from '../../lib';
import { UpdatesDisplay } from '../../ui';

import { InfoTooltip, PanelContainer } from 'ui-new';

import ConditionalRender from 'ui/atoms/ConditionalRender';

import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration, useDuration } from 'utils/duration';

export function DomainProximityPanel() {
  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('domainProximity')} titleDecoration={<Tooltip />}>
      <Box p>
        <Stack gap>
          <Top />
          <UpdatesDisplay duration="PT15M" />
        </Stack>
      </Box>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface RowProps {
  dateRegistered: string;
  proximityDomain: string;
  referenceDomain: string;
}

function Row({ dateRegistered, proximityDomain, referenceDomain }: RowProps) {
  return (
    <Text as="p" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
      <Cluster gap>
        <Text.Loadable color="$gray400" placeholder="--/--/----" weight="regular">
          {dateRegistered}
        </Text.Loadable>
        <RadarLink
          data-testid={`radar-link-${proximityDomain}`}
          href={`https://radar.cloudflare.com/domains/domain/${proximityDomain}`}
          target="_blank"
        >
          <Text.Loadable color="$gray700" placeholder="example.com" weight="regular">
            {highlightDifference(referenceDomain, proximityDomain)}
          </Text.Loadable>
        </RadarLink>
      </Cluster>
    </Text>
  );
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.domainProximity', { postProcess: 'markdown' })}</InfoTooltip>;
}

function Top() {
  const duration = useDuration();
  const { data, loading } = useRemoteData({ duration });
  const { t } = useTranslation('dashboardHome');

  const nothingToDisplay = !loading && data?.length === 0;

  return (
    <Loadable loading={loading}>
      <Stack gap="2" testId="domain-proximity-top">
        <ConditionalRender
          condition={!nothingToDisplay}
          fallback={
            <Box pb>
              <Text color="$gray400" size="sm" stretch="ultraCondensed">
                {t('noProximityDomains')}
              </Text>
            </Box>
          }
        >
          {(data || placeholderData(5)).map(({ dateRegistered, proximityDomain, referenceDomain }, index: number) => (
            <Row
              dateRegistered={dateRegistered}
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${dateRegistered}-${proximityDomain}-${referenceDomain}`}
              proximityDomain={proximityDomain}
              referenceDomain={referenceDomain}
            />
          ))}
        </ConditionalRender>
      </Stack>
    </Loadable>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function highlightDifference(referenceString: string = '', proximityString: string = '') {
  const referenceStringChars = [...referenceString.split('')];
  const proximityStringChars = [...proximityString.split('')];

  return proximityStringChars.reduce((memo, char, i) => {
    if (char !== referenceStringChars[i]) {
      memo.push(
        <Text color="$blue300" weight="regular">
          {proximityStringChars[i]}
        </Text>
      );
    } else {
      memo.push(proximityStringChars[i]);
    }
    return memo;
  }, [] as ReactNode[]);
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

interface APIData {
  dateRegistered: string;
  proximityDomain: string;
  referenceDomain: string;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data: APIData[] | null;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

interface UseRemoteDataType {
  duration: Duration;
}

/**
 * Private hook that encapsulates loading the data for the `DomainProximity panel`.
 * Poll the endpoint every 15 minutes.
 */
function useRemoteData({ duration }: UseRemoteDataType): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });

  if (loading) return { data: null, error: null, loading: true };
  if (error) return { data: null, error, loading: false };
  if (!data?.domainProximity?.data) return { data: null, error: null, loading: false };

  return { data: data.domainProximity.data.slice(0, 5), error: null, loading: false };
}

//
// Styled component
// -------------------------------------------------------------------------------------------------

const RadarLink = styled('a', {
  cursor: 'pointer',
  textDecoration: 'none',

  [`&:hover  span`]: {
    color: '$black',
  },
});
