import { useQuery, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useMemo } from 'react';

import { fixMessagesTs, toVariables } from 'screens/Search/lib';
import { SearchResultRow } from 'screens/Search/types';
import { ResultsProps } from 'screens/Search/views/AllMailSearch/ui/Results';

const query = loader('./load.graphql');

interface HookResult {
  data?: SearchResultRow[];
  error: QueryResult['error'] | null;
  fetchMore?(): void;
  loading: boolean;
}

export function useUnisearchAllMailData(search: ResultsProps['search']): HookResult {
  const date = useMemo(() => new Date(), []);

  const { data, error, fetchMore, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: toVariables(date, search),
  });

  if (error) return { data: undefined, error, fetchMore: undefined, loading: false };
  if (!data?.unisearchAllMailResults?.messages) return { data: undefined, error: null, fetchMore: undefined, loading };

  function more() {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          unisearchAllMailResults: {
            error: null,
            messages: [
              ...(prev.unisearchAllMailResults.messages || []),
              ...(fetchMoreResult.unisearchAllMailResults.messages || []),
            ],
            __typename: 'UnisearchAllMailSearchResults',
          },
        };
      },
      variables: { limit: 50, offset: data?.unisearchAllMailResults?.messages.length },
    });
  }

  const results = fixMessagesTs(data.unisearchAllMailResults.messages);

  return { data: results, error, fetchMore: more, loading };
}
