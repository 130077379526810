// @flow

import styled from 'styled-components';

const Tr = styled.tr`
  display: table;
  width: ${({ percent }) => percent * 100}%;
`;

export default Tr;
