// @flow

import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type BlockListCountsByDate } from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import Content from 'ui/molecules/Content';
import ContentBlock from 'ui/molecules/ContentBlock';
import PlaceholderStackedBarGraph from 'ui/organisms/PlaceholderStackedBarGraph';
import SectionOverview from 'ui/organisms/SectionOverview';
import StackedBarGraph from 'ui/organisms/StackedBarGraph';
import withDataHandler from 'utils/withDataHandler';

type Data = {
  sectionOverview: {
    current: number,
    previous: number,
  },
  stackBarGraph: BlockListCountsByDate[],
};

type Props = {
  data: Data,
  error: boolean,
  loading: boolean,
};

const StackBarGraphWithDataHandler = withDataHandler({
  empty: <PlaceholderStackedBarGraph />,
})(StackedBarGraph);

export default function BlockedPatterns({ data, error, loading }: Props) {
  const { t } = useTranslation('email');

  const sectionOverviewData = get(data, 'sectionOverview', { current: 0, previous: 0 });
  const stackBarGraphData = get(data, 'stackBarGraph', []);

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader title={t('blockedPatterns')} />
          <SectionOverview
            formatValue="number"
            currentValue={sectionOverviewData.current}
            dimmed={loading}
            previousValue={sectionOverviewData.previous}
          />
          <Content>
            <PaddedContent pushTop="xl">
              <StackBarGraphWithDataHandler
                colorScheme="sea"
                data={stackBarGraphData}
                isEmpty={() => loading || error || stackBarGraphData.length === 0}
                isLoading={loading}
              />
            </PaddedContent>
          </Content>
        </>
      }
    />
  );
}
