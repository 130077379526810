// @flow

import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as SearchSVG } from './search.svg';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  border: ${({ theme }) => theme.colors.antiFlashWhite} solid ${rem(2)};
  border-radius: ${rem(4)};
  cursor: pointer;
  display: flex;
  height: ${rem(36)};
  padding: 0 ${({ theme }) => theme.spacing.md};
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.azureishWhite};
    border-color: ${({ theme }) => theme.colors.azureishWhite};
  }
`;

const Icon = styled(SearchSVG)`
  height: ${rem(24)};
  margin-right: ${({ theme }) => rem(theme.spacing.sm)};
  width: ${rem(24)};

  path {
    fill: ${(p) => p.theme.colors.independence};
  }
`;

const Placeholder = styled.span`
  color: ${({ theme }) => theme.colors.grayBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(12)};
  font-weight: 400;
  line-height: ${rem(24)};
`;

// const Placeholder = styled.span`
//   color: ${({ theme }) => theme.colors.grayBlue};
//   font-family: var(--font-sans);
//   font-size: 16px;
//   font-stretch: 50%;
//   font-weight: 500;
//   line-height: ${rem(24)};
//   text-transform: uppercase;
// `;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  onClick?: (event?: SyntheticMouseEvent<any>) => void;
}

export default function SearchPlaceholder({ onClick }: Props) {
  const { t } = useTranslation('components');

  return (
    <Container onClick={onClick}>
      <Icon />
      <Placeholder>{t('SearchInput.placeholder')}</Placeholder>
    </Container>
  );
}
