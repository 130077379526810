// @flow

export type Data = { date: number, value: number };
export type LineProps = Object;
export type Tint =
  | 'aquamarine'
  | 'blue'
  | 'bulk'
  | 'dark'
  | 'green'
  | 'malicious'
  | 'malicious-bec'
  | 'none'
  | 'orange'
  | 'spam'
  | 'spoof'
  | 'sulu'
  | 'suspicious';

export const HEIGHT: number = 224;
export const PADDING: number = 20;
export const X_OFFSET = 25;
export const Y_OFFSET = 12;

type SVGPathElement = Element & {
  getPointAtLength: (length: number) => { x: number, y: number },
  getTotalLength: () => number,
};

const pathYCache: { [key: string]: number } = {};

export function findPathYAtX(x: number, path: Element | null, name: number | string, error: any): number {
  if (!path) return 0;

  const key = `${name}-${x}`;

  if (key in pathYCache) {
    return pathYCache[key];
  }

  const margin = error || 0.01;

  const maxIterations = 100;

  const pathElement = ((path: any): SVGPathElement);

  let lengthStart = 0;
  let lengthEnd = pathElement.getTotalLength();
  let point = pathElement.getPointAtLength((lengthEnd + lengthStart) / 2);
  let iterations = 0;

  while (x < point.x - margin || x > point.x + margin) {
    const midpoint = (lengthStart + lengthEnd) / 2;

    point = pathElement.getPointAtLength(midpoint);

    if (x < point.x) {
      lengthEnd = midpoint;
    } else {
      lengthStart = midpoint;
    }

    iterations += 1;
    if (maxIterations < iterations) {
      break;
    }
  }

  pathYCache[key] = point.y;

  return pathYCache[key];
}
