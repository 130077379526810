import { styled } from '@a1s/ui';
import React, { ComponentProps, MouseEvent, PropsWithChildren } from 'react';

import { SearchType, DispositionType } from './dataTypeAndUtils';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

interface SearchableParams {
  disposition?: DispositionType;
  metric?: string;
  query?: string;
  sender?: string;
  target?: string;
}

interface SearchableProps {
  css?: ComponentProps<typeof SearchableLink>['css'];
  params: SearchableParams;
  renderAsButton?: boolean;
  searchType?: SearchType;
}

export function Searchable({
  children,
  css,
  params,
  renderAsButton = false,
  searchType = 'detectionSearch',
}: PropsWithChildren<SearchableProps>) {
  const searchDetections = useGlobalSearch(searchType);

  const { disposition = '', metric, query, sender, target } = params;

  function handleClick(event: MouseEvent<HTMLElement>) {
    event.preventDefault();

    searchDetections({
      finalDisposition: disposition,
      metric: metric ? `m:${metric}` : '',
      query,
      recipient: target,
      sender,
    });
  }

  const doNotSearchForOther =
    target?.toLowerCase() === 'other' || metric?.toLowerCase().includes('other') || sender?.toLowerCase() === 'other';

  return (
    <>
      <ConditionalRender
        condition={doNotSearchForOther}
        fallback={
          <ConditionalRender
            condition={renderAsButton}
            fallback={
              <SearchableLink css={css} data-testid="searchable-link" onClick={handleClick}>
                {children}
              </SearchableLink>
            }
          >
            <SearchableButton data-testid="searchable-button" onClick={handleClick}>
              {children}
            </SearchableButton>
          </ConditionalRender>
        }
      >
        {children}
      </ConditionalRender>
    </>
  );
}

//
// Styled component
// -------------------------------------------------------------------------------------------------

const SearchableButton = styled('button', {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'inline-block',
  r: '$1',
  px: '$1-5',
  py: '$1',
  textDecoration: 'none',

  '&:hover': { backgroundColor: 'rgba(108, 144, 161, 0.15)' },
});

const SearchableLink = styled('a', {
  cursor: 'pointer',
  textDecoration: 'none',

  [`&:hover  span`]: {
    color: '$black',
  },
});
