// @flow

import type { Node } from 'react';

type Props = {
  children: any,
  condition: boolean,
  fallback?: Node,
};

export default function ConditionalRender({ children, condition, fallback }: Props) {
  if (condition) return children;
  return fallback || null;
}
