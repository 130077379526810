// @flow

import hash from 'hash-sum';
import { rem } from 'polished';
import React, { Fragment } from 'react';
import styled from 'styled-components';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div.attrs({ role: 'tablist' })`
  background-color: ${(p) => p.theme.colors.aliceBlue};
  border-radius: ${rem(3)};
  box-sizing: content-box;
  display: inline-flex;
  padding: ${rem(2)};
  position: relative;
  width: ${(p) => rem(p.count * 144)};
`;

const Rule = styled.hr`
  background-color: ${(p) => p.theme.colors.gainsboro};
  border: 0;
  flex-shrink: 0;
  height: ${rem(14)};
  position: relative;
  transition: opacity 250ms ${(p) => p.theme.timing.easeOutCirc};
  width: 1px;
  z-index: 2;

  &[aria-hidden='true'] {
    opacity: 0;
  }
`;

const Segment = styled.button.attrs({ role: 'tab' })`
  background: none;
  border: 0;
  box-sizing: content-box;
  color: ${(p) => p.theme.colors.ebony};
  font-family: ${(p) => p.theme.fonts.roboto};
  font-size: ${rem(12)};
  line-height: 1;
  outline: 0;
  padding: ${(p) => rem(p.theme.spacing.sm)} ${(p) => rem(p.theme.spacing.sm)};
  position: relative;
  width: calc(${(p) => (1 / p.count) * 100}% - (${(p) => p.count} * 1px));
  z-index: 1;

  &[aria-selected='true'] {
    font-weight: 600;
  }
`;

const Selection = styled.div.attrs({ role: 'presentation' })`
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${rem(2)};
  bottom: ${rem(2)};
  box-shadow: 0 ${rem(2)} ${rem(1)} ${rem(1)} rgba(0, 0, 0, 0.05);
  display: block;
  left: ${rem(2)};
  position: absolute;
  top: ${rem(2)};
  transform: translateX(calc((100% * ${(p) => p.selectedIndex}) - (${(p) => p.selectedIndex} * 1.5px)));
  transition: transform 150ms ${(p) => p.theme.timing.easeOutCirc};
  width: calc((100% / ${(p) => p.count}) - 1px);
  z-index: 0;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  onChange?: (index: number) => void;
  selectedIndex?: number;
  segments: string[];
}

export default function SegmentedControl({ onChange, selectedIndex = 0, segments }: Props) {
  function handleClick(index) {
    if (onChange) return () => onChange(index);
    return null;
  }

  return (
    <Container count={segments.length}>
      {segments.map((segment, i) => (
        <Fragment key={hash(`${segment}-${i}`)}>
          {i !== 0 && <Rule aria-hidden={selectedIndex === i || selectedIndex + 1 === i} />}
          <Segment aria-selected={i === selectedIndex} count={segments.length} onClick={handleClick(i)}>
            {segment}
          </Segment>
        </Fragment>
      ))}

      {selectedIndex < segments.length && selectedIndex >= 0 && (
        <Selection count={segments.length} selectedIndex={selectedIndex} />
      )}
    </Container>
  );
}
