// @flow

import { loader } from 'graphql.macro';

export const maliciousNewDomainsQuery = loader('./queries/MaliciousNewDomainsQuery.graphql');
export const insightsMaliciousNewDomainsQuery = loader('./queries/InsightsMaliciousNewDomainsQuery.graphql');

export type MaliciousDomainCountsByDate = { day: number, total: number, totals: string[] };

export type MaliciousDomainsListByName = { count: number, domain?: string, daysRegistered?: number };

export const defaultLineGraphData = {
  day: '',
  total: 0,
  totals: [],
};
