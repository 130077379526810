import { styled, Table, Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MaxHeight } from '..';
import { SearchResultRow } from '../../types';

import { translateReason } from 'screens/shared/dataTypeAndUtils';
import { InlineTable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

/**
 * Displays the raw data about the _reason_ an email was flagged.
 */

interface ReasonCellProps {
  data?: SearchResultRow['findings'];
  hidden?: boolean;
}

// eslint-disable-next-line no-unused-vars
export function ReasonCell({ data, hidden = false }: ReasonCellProps) {
  const { t } = useTranslation('adminQuarantine');
  const allReasons = data?.map((finding) => (finding.reason ? translateReason(finding, t) : ''));

  return (
    <Table.Cell border="none" css={{ display: hidden === true ? 'none' : undefined, width: 420 }}>
      <InlineTable css={{ width: 420 }}>
        <tbody>
          <InlineTable.Row>
            <InlineTable.Cell>
              <Text
                color="$gray600"
                font="sans"
                size="sm"
                stretch="ultraCondensed"
                transform="uppercase"
                weight="semibold"
              >
                {t('reason')}
              </Text>
            </InlineTable.Cell>
            <InlineTable.Cell>
              <MaxHeight height={150}>
                {allReasons?.map((reason, index) => (
                  <Text
                    as="p"
                    color="$gray500"
                    css={{ lineHeight: 1.2, marginTop: 5, overflowWrap: 'anywhere', wordWrap: 'break-all' }}
                    font="sans"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    size="sm"
                    stretch="ultraCondensed"
                  >
                    <Line dangerouslySetInnerHTML={{ __html: reason }} />
                  </Text>
                ))}
              </MaxHeight>
            </InlineTable.Cell>
          </InlineTable.Row>
        </tbody>
      </InlineTable>
    </Table.Cell>
  );
}

//
// Styled component
// -------------------------------------------------------------------------------------------------

const Line = styled('span', {
  margin: '0',
});
