// @flow
// $FlowFixMe
import { Cluster } from '@a1s/ui';
// $FlowFixMe
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get, truncate } from 'lodash';
import { rem, rgba } from 'polished';
// $FlowFixMe
import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
// $FlowFixMe
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import styled, { css } from 'styled-components';

import ReleaseFailures from './ReleaseFailures';

import { downloadEml, translateReason } from 'screens/shared/dataTypeAndUtils';

import Blip from 'ui/atoms/Blip';
import Checkbox from 'ui/atoms/Checkbox';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import MaxHeight from 'ui/atoms/MaxHeight';
import PaddedContent from 'ui/atoms/PaddedContent';
import Subtitle from 'ui/atoms/Subtitle';
import TextStyle from 'ui/atoms/TextStyle';

import Alert from 'ui/molecules/Alert';
import AlertDialog from 'ui/molecules/AlertDialog';
import DispositionBadge from 'ui/molecules/DispositionBadge';
import ErrorMessage from 'ui/molecules/ErrorMessage';
import FormatTime from 'ui/molecules/FormatTime';
import Info from 'ui/molecules/Info';
import NoData from 'ui/molecules/NoData';
import PopupMenu, { Group, Option } from 'ui/molecules/PopupMenu';
import { Body, Cell, Col, Head, Header, Row, Container as Table } from 'ui/molecules/Table';
import Toast from 'ui/molecules/Toast';

const adminQuarantineMailLoad = loader('./queries/AdminQuarantineMailLoad.graphql');
const releaseMessages = loader('./queries/ReleaseMessages.graphql');

//
// Styled Components
// -------------------------------------------------------------------------------------------------
const Line = styled.p`
  margin: 0;
`;

const StyledCell = styled(Cell)`
  vertical-align: ${({ centered }) => (centered ? 'middle' : 'top')};
  word-wrap: break-word !important;
  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  [aria-selected='true'] > & {
    background-color: ${(p) => p.theme.colors.deepSkyBlue};
    color: ${(p) => p.theme.colors.antiFlashWhite};
    [data-testid='atom-badge'] {
      background-color: ${(p) => rgba(p.theme.colors.antiFlashWhite, 0.75)};
      span {
        color: ${(p) => p.theme.colors.deepSkyBlue};
      }
    }
    button {
      path {
        fill: ${(p) => rgba(p.theme.colors.antiFlashWhite, 0.75)};
      }
      &[aria-expanded='true'] path,
      &:hover path {
        fill: ${(p) => p.theme.colors.deepSkyBlue};
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  :not(:first-of-type) {
    padding-left: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  :not(:last-of-type) {
    padding-right: ${(p) => rem(p.theme.spacing.sm)} !important;
  }
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

const StyledRow = styled(Row)`
  max-width: ${rem(1304)};
`;

const StyledTable = styled(Table)`
  width: 100%;
  &::after {
    display: none !important;
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

const INITIAL_SELECTED_KEYS_STATE = new Set();
const INITIAL_TOAST_STATE = { highlightedIds: [], isVisible: false, message: null, variant: null };

interface Props {
  data: Object;
  loadMore: Function;
}

export default function ResultsTable({ data, loadMore }: Props) {
  const [runMutation, { loading }] = useMutation(releaseMessages);

  const [alertOpen, setAlertOpen] = useState(false);
  const [emlDownloadPath, setEmlDownloadPath] = useState(null);
  const [headerChecked, setHeaderChecked] = useState(false);
  const [releaseFailures, setReleaseFailures] = useState({ failures: [], message: '' });

  const [selectedKeys, selectedKeysDispatch] = useReducer(selectedKeysReducer, INITIAL_SELECTED_KEYS_STATE);
  const [toastState, toastDispatch] = useReducer(toastReducer, INITIAL_TOAST_STATE);
  const { t } = useTranslation('adminQuarantine');

  const { loading: adminQuarantineMailLoading } = useQuery(adminQuarantineMailLoad, {
    fetchPolicy: 'network-only',
    skip: !emlDownloadPath,
    variables: {
      path: emlDownloadPath,
    },
    onCompleted: (emailData: any) => {
      if (emailData) {
        triggerEmlDownload(emailData);
        setEmlDownloadPath(null);
      }
    },
  });

  const messagesNoRecipients = data.filter(({ clientRecipients }) => !clientRecipients.length);

  const history = useHistory();
  const location = useLocation();

  function handleActionCancel() {
    setAlertOpen(false);
  }

  function resetReleaseFailures() {
    setReleaseFailures({ failures: [], message: '' });
  }

  const getCatchSideFailedRecipents = (request) =>
    /* eslint-disable camelcase */
    request.reduce(
      (failedEmails, { envelope_to }) => [
        ...failedEmails,
        ...envelope_to.map((recipient) => ({ recipient, status: t('noMessageRelease') })),
      ],
      []
    );
  /* eslint-enable camelcase */

  const getFailedRecipients = (failures) =>
    failures.reduce((failedEmails, { serverResponse }) => {
      if (serverResponse.failed) {
        return [
          ...failedEmails,
          ...serverResponse.failed.map((recipient) => ({ recipient, status: t('noMessageRelease') })),
        ];
      }
      if (serverResponse.undelivered) {
        return [
          ...failedEmails,
          ...serverResponse.undelivered.map((recipient) => ({ recipient, status: t('noMessageRelease') })),
        ];
      }
      return failedEmails;
    }, []);

  async function handleActionConfirm() {
    /* eslint-disable camelcase */
    const request = [...selectedKeys]
      .map((key) => data.find((r) => r.storedAt === key))
      .map(({ storedAt: message_path, clientRecipients: envelope_to }) => ({
        message_path,
        envelope_to,
      }));
    /* eslint-enable camelcase */
    try {
      const { data: releaseMessagesData } = await runMutation({
        variables: { input: { request } },
      });

      const responses = get(releaseMessagesData, 'releaseMessages.responses', []);

      const failures = responses.filter(({ serverResponse }) => serverResponse.failed || serverResponse.undelivered);
      const failedIds = failures.map(({ messagePath }) => messagePath);

      const succeededCount = request.length - failures.length;

      if (failures.length) {
        toastDispatch({ payload: { highlightedIds: failedIds, isVisible: false }, type: 'warning' });
        setReleaseFailures({
          failures: getFailedRecipients(failures),
          message:
            request.length > 1
              ? t('releaseFailurePlural', { count: request.length, succeededCount })
              : t('releaseFailure'),
        });
      } else {
        toastDispatch({
          payload: {
            // eslint-disable-next-line camelcase
            highlightedIds: request.map(({ message_path }) => message_path),
            message: request.length > 1 ? t('releaseSuccessPlural') : t('releaseSuccess'),
          },
          type: 'success',
        });
      }
    } catch (e) {
      /* eslint-disable camelcase */
      const failedIds = request.map(({ message_path }) => message_path);
      /* eslint-enable camelcase */
      toastDispatch({ payload: { highlightedIds: failedIds, isVisible: false }, type: 'warning' });
      setReleaseFailures({
        failures: getCatchSideFailedRecipents(request),
        message:
          request.length > 1 ? t('releaseFailurePlural', { count: request.length, succeeded: 0 }) : t('releaseFailure'),
      });
    }

    setAlertOpen(false);
    selectedKeysDispatch({ type: 'reset' });
  }

  function handleClick() {
    setAlertOpen(true);
  }

  function handleExpandDetectionClick(postfixIdent: string) {
    history.push({
      pathname: `/detection/${postfixIdent}`,
      state: {
        background: location,
      },
    });
  }

  function handleHeaderCheckChange() {
    if (selectedKeys.size === data.length - messagesNoRecipients.length) {
      selectedKeysDispatch({ type: 'reset' });
      setHeaderChecked(false);
    } else {
      const keys = data.reduce(
        (validKeys, { clientRecipients, storedAt }) => (clientRecipients.length ? [...validKeys, storedAt] : validKeys),
        []
      );

      selectedKeysDispatch({ payload: keys, type: 'replace' });
      setHeaderChecked(true);
    }
  }

  function handleRelease(storedAt: string) {
    selectedKeysDispatch({ payload: [storedAt], type: 'replace' });
    setAlertOpen(true);
  }

  function handleWaypointEnter() {
    if (loadMore) loadMore();
  }

  function triggerEmlDownload({ mail = {} }) {
    const { email } = mail;
    downloadEml(emlDownloadPath, email);
  }

  return (
    <>
      <AlertDialog
        busy={loading}
        buttonText={<span>{t('releaseWithCount', { count: selectedKeys.size })}</span>}
        message={
          <>
            <PaddedContent pushBottom="lg">
              <Subtitle>{t('releaseWarning', { count: selectedKeys.size })}</Subtitle>
            </PaddedContent>
            <TextStyle warning>{t('releaseNoUndo')}</TextStyle>
          </>
        }
        onActionCancel={handleActionCancel}
        onActionConfirm={(handleActionConfirm: any)}
        open={alertOpen}
        zebraStripes
      />
      <StyledTable data-testid="admin-quarantine-table" fixed spaced verticalAlign="top" zebraStripes>
        <colgroup>
          <Col width={75} />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col width={75} />
        </colgroup>
        <Head>
          <StyledRow>
            <>
              <StyledHeader>
                <Checkbox checked={headerChecked} onChange={handleHeaderCheckChange} />
              </StyledHeader>
              <StyledHeader>{t('searchResults:dateAndDisposition')}</StyledHeader>
              <StyledHeader>{t('searchResults:conversation')}</StyledHeader>
              <StyledHeader>{t('searchResults:subject')}</StyledHeader>
              <StyledHeader>{t('searchResults:reasons')}</StyledHeader>
              <StyledHeader>
                <ConditionalRender condition={selectedKeys.size > 0}>
                  <PopupMenu>
                    <Group>
                      <Option icon="reload" onClick={handleClick}>
                        {t('releaseWithCount', { count: selectedKeys.size })}
                      </Option>
                    </Group>
                  </PopupMenu>
                </ConditionalRender>
              </StyledHeader>
            </>
          </StyledRow>
        </Head>
        <Body>
          <ConditionalRender
            condition={data && data.length}
            fallback={
              <>
                <StyledRow />
                <StyledRow>
                  <StyledCell colSpan="6">
                    <NoData />
                  </StyledCell>
                </StyledRow>
              </>
            }
          >
            {data.map((result, index) => {
              const {
                clientRecipients,
                finalDisposition,
                findings,
                from,
                id,
                postfixIdent,
                redressedActions,
                storedAt,
                subject,
                to,
                ts,
              } = result;

              // $FlowFixMe
              const allReasons = findings?.map((finding) => (finding.reason ? translateReason(finding, t) : ''));

              let insertWaypoint: boolean = false;
              const messageReleased: boolean = redressedActions.some(
                (action) => action.operation === 'QUARANTINE_RELEASE'
              );

              /*
                Trigger `fetchMore` 6 elements before the last.
                If there are 20 elements, the waypoint will be inserted at item 14.
                6 is an arbitrary number. The idea is to fetch the next row before the user reached the bottom of the list.
              */
              if (index + 1 === data.length - 6) insertWaypoint = true;

              function handleChange({ currentTarget }) {
                const type = currentTarget.checked ? 'add' : 'remove';
                selectedKeysDispatch({ payload: storedAt, type });

                if (selectedKeys.size === data.length) {
                  setHeaderChecked(true);
                } else if (selectedKeys.size === 0) {
                  setHeaderChecked(false);
                } else {
                  setHeaderChecked('indeterminate');
                }
              }

              return (
                <StyledRow aria-selected={selectedKeys.has(storedAt)} key={id}>
                  <>
                    <StyledCell>
                      <ConditionalRender condition={!!clientRecipients.length}>
                        <Checkbox checked={selectedKeys.has(storedAt)} onChange={handleChange} />
                      </ConditionalRender>
                      {toastState.highlightedIds.includes(storedAt) && (
                        <ConditionalRender
                          condition={toastState.variant === 'success'}
                          fallback={
                            <Blip>
                              <span role="img" aria-label="warning">
                                !
                              </span>
                            </Blip>
                          }
                        >
                          <Blip success>
                            <span role="img" aria-label="checkmark">
                              ✓
                            </span>
                          </Blip>
                        </ConditionalRender>
                      )}
                    </StyledCell>
                    <StyledCell>
                      <ConditionalRender condition={insertWaypoint}>
                        <Waypoint onEnter={handleWaypointEnter} />
                      </ConditionalRender>
                      <FormatTime date={ts} displayTime />
                      <PaddedContent pushBottom="sm" />
                      <DispositionBadge disposition={finalDisposition} />
                      <ConditionalRender condition={messageReleased}>
                        <PaddedContent pushBottom="sm" />
                        <Cluster gap="1">
                          <DispositionBadge disposition="QUARANTINE_RELEASE" />
                          <Info tooltipPosition="right">{t('releasedFromQuarantine')}</Info>
                        </Cluster>
                      </ConditionalRender>
                    </StyledCell>
                    <StyledCell>
                      <MaxHeight height={150}>
                        <Line>{t('searchResults:from')}</Line>
                        <Line>{from}</Line>
                        <Line>{t('searchResults:to')}</Line>
                        {to && to.map((intended) => <Line key={intended}>{intended}</Line>)}
                      </MaxHeight>
                    </StyledCell>
                    <StyledCell>{truncate(subject, { length: 200 })}</StyledCell>
                    <StyledCell>
                      <MaxHeight height={150}>
                        {/* $FlowFixMe */}
                        {allReasons?.map((reason, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Line dangerouslySetInnerHTML={{ __html: reason }} key={i} />
                        ))}
                      </MaxHeight>
                    </StyledCell>
                    <StyledCell verticalAlign="top">
                      <RowOptions
                        handleExpandDetectionClick={handleExpandDetectionClick}
                        handleReleaseClick={handleRelease}
                        postfixIdent={postfixIdent}
                        releaseAllowed={!!clientRecipients.length}
                        setEmlDownloadPath={setEmlDownloadPath}
                        storedAt={storedAt}
                      />
                    </StyledCell>
                  </>
                </StyledRow>
              );
            })}
          </ConditionalRender>
        </Body>
      </StyledTable>

      <Alert.Modal
        expanded
        onDismiss={resetReleaseFailures}
        open={!!releaseFailures.failures.length}
        title={<ErrorMessage>{releaseFailures.message}</ErrorMessage>}
        zebraStripe
      >
        <ReleaseFailures failedReleases={releaseFailures} title={releaseFailures.message} />
      </Alert.Modal>

      <Toast open={adminQuarantineMailLoading} variant="success">
        {t('email:processingFile')}
      </Toast>

      <Toast onDismiss={() => toastDispatch({ type: 'hide' })} open={toastState.isVisible} variant={toastState.variant}>
        {toastState.message}
      </Toast>
    </>
  );
}

//
// Popup Menu
// -------------------------------------------------------------------------------------------------
const ReleaseMenuContainer = styled.div`
  align-items: center;
  display: flex;
`;

interface RowOptionsProps {
  handleExpandDetectionClick: Function;
  handleReleaseClick: Function;
  postfixIdent: string;
  releaseAllowed: boolean;
  setEmlDownloadPath: Function;
  storedAt: string;
}

function RowOptions({
  handleExpandDetectionClick,
  handleReleaseClick,
  postfixIdent,
  releaseAllowed,
  setEmlDownloadPath,
  storedAt,
}: RowOptionsProps) {
  const { t } = useTranslation('adminQuarantine');

  return (
    <PopupMenu>
      <ConditionalRender condition={!!storedAt}>
        <Group>
          <Option icon="expand" onClick={() => handleExpandDetectionClick(postfixIdent)}>
            {t('searchResults:viewDetection')}
          </Option>
        </Group>
      </ConditionalRender>
      <Group>
        <Option icon="download" onClick={() => setEmlDownloadPath(storedAt)}>
          {t('searchResults:download')}
        </Option>
      </Group>
      <Group>
        <ReleaseMenuContainer>
          <Option disabled={!releaseAllowed} icon="reload" onClick={() => handleReleaseClick(storedAt)}>
            {t('release')}
          </Option>
          <ConditionalRender condition={!releaseAllowed}>
            <Info tooltipPosition="left">{t('noneConfigured')}</Info>
          </ConditionalRender>
        </ReleaseMenuContainer>
      </Group>
    </PopupMenu>
  );
}

//
// Private function
// -------------------------------------------------------------------------------------------------

function selectedKeysReducer(state, action) {
  switch (action.type) {
    case 'add':
      state.add(action.payload);
      return new Set([...state]);
    case 'remove':
      state.delete(action.payload);
      return new Set([...state]);
    case 'replace':
      return new Set([...action.payload]);
    case 'reset':
      return new Set();
    default:
      throw new Error();
  }
}

function toastReducer(state, action) {
  const { payload, type } = action;
  switch (type) {
    case 'hide':
      return { ...state, isVisible: false };
    case 'success':
      return {
        ...state,
        highlightedIds: payload.highlightedIds,
        isVisible: true,
        message: payload.message,
        variant: 'success',
      };
    case 'warning': {
      return { ...state, highlightedIds: payload.highlightedIds, isVisible: payload.isVisible, variant: 'warning' };
    }
    default:
      throw new Error();
  }
}
