// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 ${rem(1)};
  position: relative;
  width: 100%;

  ${({ placeholder, placeholderText }) =>
    placeholder &&
    css`
      & > * {
        opacity: 0.3;
        pointer-events: none;
      }

      &::after {
        color: ${({ theme }) => theme.colors.manatee};
        content: '${placeholderText}';
        font-family: ${({ theme }) => theme.fonts.roboto};
        font-size: ${rem(24)};
        font-weight: 500;
        opacity: 1;
        position: absolute;
        text-align: center;
        top: 40%;
        width: 100%;
      }
    `};
`;

export default Table;
