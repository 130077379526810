// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../Login/logo.svg';

import Container from './Container';
import InviteForm from './InviteForm';

import PaddedContent from 'ui/atoms/PaddedContent';
import Title from 'ui/atoms/Title';
import Content from 'ui/molecules/Content';
import LoginTemplate from 'ui/templates/Login';

interface PasswordFormProps {
  showOnboarding: boolean;
}

export default function PasswordForm({ showOnboarding = false }: PasswordFormProps) {
  const { t } = useTranslation('invitePage');

  return (
    <LoginTemplate
      main={
        <>
          <Helmet title={`${t('welcome')} - ${t('const:names:area1security')}`} />
          <Container>
            <Logo />
            <PaddedContent pushBottom="md" pushTop="md">
              <Title>{t('welcome')}</Title>
              <Content>
                <p>{t('welcomeCopy')}</p>
              </Content>
            </PaddedContent>
            <InviteForm showOnboarding={showOnboarding} />
          </Container>
        </>
      }
    />
  );
}
