// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Content = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(14)};
  margin: 0;
  padding: 0;

  a:link,
  a:visited {
    color: ${({ theme }) => theme.colors.deepSkyBlue};
    text-decoration: none !important;

    &:active {
      color: ${({ theme }) => theme.colors.starCommandBlue};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.bleuDeFrance};
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: 400;
    margin: 0 0 ${({ theme }) => rem(theme.spacing.sm)} 0;
    text-transform: uppercase;
  }

  h2,
  h3 {
    color: ${({ theme }) => theme.colors.raisinBlack};
    font-weight: 600;
  }

  hr {
    background-color: ${({ theme }) => theme.colors.tuscanBrown};
    border: 0;
    height: ${rem(1)};
    margin: ${({ theme }) => rem(theme.spacing.xl)} 0;
  }

  pre {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
    border-radius: ${rem(6)};
    color: ${({ theme }) => theme.colors.raisinBlack};
    font-size: 85%;
    overflow: auto;
    padding: ${({ theme }) => rem(theme.spacing.md)};
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  table {
    max-width: 100%;
    padding: ${({ theme }) => rem(theme.spacing.lg)} 0;
    width: 100%;

    th {
      text-align: left;
    }
  }

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
`;

export default Content;
