"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledPercent = exports.StyledData = void 0;
var _styled = require("../../styled");
require("../Badge");
var _arrowDown = _interopRequireDefault(require("./arrow-down.svg"));
var _arrowUp = _interopRequireDefault(require("./arrow-up.svg"));
var _minus = _interopRequireDefault(require("./minus.svg"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const StyledData = (0, _styled.styled)('data', {
  display: 'contents',
  '&::before': {
    backgroundColor: 'currentColor',
    content: '',
    display: 'inline-block',
    height: 12,
    marginRight: '$1',
    verticalAlign: 'baseline',
    width: 12
  },
  variants: {
    change: {
      down: {
        '&::before': {
          maskImage: `url('${_arrowDown.default}')`
        }
      },
      none: {
        '&::before': {
          maskImage: `url('${_minus.default}')`
        }
      },
      up: {
        '&::before': {
          maskImage: `url('${_arrowUp.default}')`
        }
      }
    }
  }
});
exports.StyledData = StyledData;
const StyledPercent = (0, _styled.styled)('span', {
  fontWeight: 200
});
exports.StyledPercent = StyledPercent;