import { Table } from '@a1s/ui';

import React, { ComponentProps } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface RowWrapperProps {
  children?: ComponentProps<typeof Table.Row>['children'];
  collapsed?: boolean;
  onClick?: ComponentProps<typeof Table.Row>['onClick'];
  selected?: boolean;
}

export function RowWrapper({ children, collapsed = false, onClick, selected }: RowWrapperProps) {
  let css = { cursor: collapsed ? 'pointer' : 'default' };
  const selectedCss = {
    '& > td': { backgroundColor: '$blue100' },
    '& > td:nth-child(2)': { borderRight: '$blue700 solid 8px' },
  };

  if (selected) css = { ...css, ...selectedCss };

  return (
    <Table.Row onClick={onClick} css={css}>
      {children}
    </Table.Row>
  );
}
