import React, { ButtonHTMLAttributes } from 'react';

import { Button, WaitingSVG } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The icon to display within the button.
   */
  icon: NamedIconProps['name'];

  title?: string;

  waiting?: boolean;
}

//
// Main component
// -------------------------------------------------------------------------------------------------

/**
 * Convenience component for concisely creating a component with only an icon displayed.
 */
export function IconButton({ icon, title, waiting = false, ...props }: IconButtonProps) {
  return (
    <Button title={title} type="button" {...props}>
      {waiting ? <WaitingAnimation /> : <NamedIcon name={icon} />}
    </Button>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface NamedIconProps {
  /**
   * The icon to display within the button.
   */
  name: 'pdf' | 'xls';
}

function NamedIcon({ name }: NamedIconProps) {
  switch (name) {
    case 'pdf':
      return <PDFIcon />;
    case 'xls':
      return <XLSIcon />;
    default:
      throw new Error(`No icon named "${name}"`);
  }
}

function PDFIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
      <path
        d="M128 209H96a15 15 0 0 0-15 15v114a15 15 0 0 0 30 0v-35h17c26 0 48-21 48-47s-22-47-48-47zm0 64h-17a4151 4151 0 0 1 0-34h17c9 0 18 8 18 17s-9 17-18 17zm127-64h-31a15 15 0 0 0-15 15v111a15 15 0 0 0 15 15l33-1c33 0 58-30 58-70 0-42-24-70-60-70zm1 110-17 1v-81h16c28 0 30 31 30 40 0 20-9 40-29 40zm158-81a15 15 0 0 0 0-30h-46c-8 0-15 7-15 15v113a15 15 0 0 0 30 0v-43h27a15 15 0 0 0 0-30h-27v-25z"
        fill="currentColor"
      />
      <path
        d="M458 145h-11v-4c0-20-7-38-21-52l-62-66a75 75 0 0 0-54-23H110C85 0 65 20 65 45v100H54c-25 0-45 20-45 45v180c0 25 20 45 45 45h11v52c0 25 20 45 45 45h292c25 0 45-20 45-45v-52h11c25 0 45-20 45-45V190c0-25-20-45-45-45zM95 45c0-8 7-15 15-15h200c12 0 24 5 32 14l63 66c8 8 12 19 12 31v4H95zm322 422c0 8-7 15-15 15H110c-8 0-15-7-15-15v-52h322zm56-97c0 8-7 15-15 15H54c-8 0-15-7-15-15V190c0-8 7-15 15-15h404c8 0 15 7 15 15z"
        fill="currentColor"
      />
    </svg>
  );
}

function XLSIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
      <path
        d="M282 20c-3-3-8-5-13-4L13 64C5 66 0 72 0 80v352c0 8 5 14 13 16l256 48h3a16 16 0 0 0 16-16V32c0-5-2-9-6-12zm-26 441L32 419V93l224-42v410z"
        fill="currentColor"
      />
      <path
        d="M496 80H272a16 16 0 0 0 0 32h208v288H272a16 16 0 0 0 0 32h224c9 0 16-7 16-16V96c0-9-7-16-16-16z"
        fill="currentColor"
      />
      <path
        d="M336 144h-64a16 16 0 0 0 0 32h64a16 16 0 0 0 0-32zm0 64h-64a16 16 0 0 0 0 32h64a16 16 0 0 0 0-32zm0 64h-64a16 16 0 0 0 0 32h64a16 16 0 0 0 0-32zm0 64h-64a16 16 0 0 0 0 32h64a16 16 0 0 0 0-32zm96-192h-32a16 16 0 0 0 0 32h32a16 16 0 0 0 0-32zm0 64h-32a16 16 0 0 0 0 32h32a16 16 0 0 0 0-32zm0 64h-32a16 16 0 0 0 0 32h32a16 16 0 0 0 0-32zm0 64h-32a16 16 0 0 0 0 32h32a16 16 0 0 0 0-32zm-212-27L108 181a16 16 0 1 0-24 22l112 128a16 16 0 0 0 23 1c6-6 7-16 1-23z"
        fill="currentColor"
      />
      <path d="M218 163c-7-5-17-4-23 3L83 310a16 16 0 0 0 26 20l112-144c5-7 4-17-3-23z" fill="currentColor" />
    </svg>
  );
}

function WaitingAnimation() {
  return (
    <WaitingSVG xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" />
    </WaitingSVG>
  );
}
