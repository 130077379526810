import { useLoadable, theme, Cluster, HorizontalBars, HorizontalBarsBarProps, Separator, Stack, Text } from '@a1s/ui';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Searchable } from '../../../shared/Searchable';

import ConditionalRender from 'ui/atoms/ConditionalRender';
//
// Typescript types
// -------------------------------------------------------------------------------------------------

export interface SourcesData {
  top: Array<{ country: string; count: number; isoName: string | undefined }>;
  other: number;
}

//
// Main comnponent
// -------------------------------------------------------------------------------------------------

interface SourcesGroupsProps {
  globalSources?: SourcesData;
  orgSources?: SourcesData;
  testId?: string;
}

export default function SourcesGraphs({ globalSources, orgSources, testId }: SourcesGroupsProps) {
  return (
    <Cluster data-testid={testId} gap justify="stretch">
      <OrgSources sources={orgSources} />
      <GlobalSources sources={globalSources} />
    </Cluster>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface GlobalSourcesProps {
  sources?: SourcesData;
}

function GlobalSources({ sources }: GlobalSourcesProps) {
  const loading = useLoadable();
  const dataForGraph = () => {
    if (!loading && sources && 'top' in sources && sources.top.length >= 3) {
      return [
        {
          color: '$pink600',
          isoName: sources.top[0]?.isoName,
          label: sources.top[0]?.country || '-',
          value: sources.top[0]?.count || 0,
        },
        {
          color: transparentize(0.25, theme.colors.pink600.value),
          isoName: sources.top[1]?.isoName,
          label: sources.top[1]?.country || '-',
          value: sources.top[1]?.count || 0,
        },
        {
          color: transparentize(0.33, theme.colors.pink600.value),
          isoName: sources.top[2]?.isoName,
          label: sources.top[2]?.country || '-',
          value: sources.top[2]?.count || 0,
        },
        {
          color: transparentize(0.5, theme.colors.pink600.value),
          isoName: sources.top[3]?.isoName,
          label: sources.top[3]?.country || '-',
          value: sources.top[3]?.count || 0,
        },
        { color: '$gray200', label: 'Other', value: sources.other },
      ];
    }
    return placeholder(5);
  };

  const data = dataForGraph();
  const { t } = useTranslation('dashboardHome');

  return <PercentGraph data={data} title={t('threatGlobally')} />;
}

interface OrgSourcesProps {
  sources?: SourcesData;
}

function OrgSources({ sources }: OrgSourcesProps) {
  const loading = useLoadable();
  const dataForGraph = () => {
    if (!loading && sources && 'top' in sources && sources.top.length >= 1) {
      return [
        {
          color: '$blue400',
          isoName: sources.top[0]?.isoName,
          label: sources.top?.[0]?.country || 'No data',
          value: sources.top?.[0]?.count || 0,
        },
        {
          color: transparentize(0.25, theme.colors.blue400.value),
          isoName: sources.top[1]?.isoName,
          label: sources.top?.[1]?.country || 'No data',
          value: sources.top?.[1]?.count || 0,
        },
        {
          color: transparentize(0.33, theme.colors.blue400.value),
          isoName: sources.top[2]?.isoName,
          label: sources.top?.[2]?.country || 'No data',
          value: sources.top[2]?.count || 0,
        },
        {
          color: transparentize(0.5, theme.colors.blue400.value),
          isoName: sources.top[3]?.isoName,
          label: sources.top[3]?.country || 'No data',
          value: sources.top[3]?.count || 0,
        },
        { color: '$gray200', label: 'Other', value: sources.other },
      ];
    }
    return placeholder(5);
  };

  const data = dataForGraph();
  const { t } = useTranslation('dashboardHome');

  return <PercentGraph data={data} title={t('threatToYourOrg')} />;
}

interface PercentGraphProps {
  data: Array<{ color: HorizontalBarsBarProps['color']; isoName: string | undefined; label: string; value: number }>;
  title: string;
}

function PercentGraph({ data, title }: PercentGraphProps) {
  const { t } = useTranslation('landscape');

  const total = data.reduce((acc, { value }) => acc + value, 0);

  return (
    <Stack gap>
      <Text font="sans" size="sm" stretch="ultraCondensed" weight="semibold">
        {title}
      </Text>
      <Cluster gap="1" justify="end">
        <Stack justify="space-around">
          {data.map(({ label, value }, i) => (
            <Text.Loadable
              color="$gray600"
              font="sans"
              // eslint-disable-next-line react/no-array-index-key
              key={`${label}-${value}-${i}`}
              placeholder="—%"
              size="sm"
              stretch="ultraCondensed"
              transform="uppercase"
              weight="normal"
            >
              {displayPercent(value, total)}
            </Text.Loadable>
          ))}
        </Stack>

        <Separator color="gray300" direction="vertical" />

        <HorizontalBars>
          {data.map(({ color, isoName, label, value }, i) => {
            const cssColor = (color as string)?.startsWith('$') ? `$colors${color}` : color;

            return (
              <HorizontalBars.Bar
                color={color}
                // eslint-disable-next-line react/no-array-index-key
                key={`${label}-${value}-${i}`}
                value={value}
              >
                <ConditionalRender
                  condition={!!isoName}
                  fallback={<DisplayCountry color={cssColor} country={t(label)} />}
                >
                  <Searchable
                    css={{
                      '&:hover, &:hover span': {
                        color: '$gray600 !important',
                        textShadow: 'none !important',
                      },
                    }}
                    params={{
                      metric: `toc:${isoName}`,
                    }}
                  >
                    <DisplayCountry color={cssColor} country={t(label)} />
                  </Searchable>
                </ConditionalRender>
              </HorizontalBars.Bar>
            );
          })}
        </HorizontalBars>
      </Cluster>
    </Stack>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface CountryLabelProps {
  color: HorizontalBarsBarProps['color'];
  country: string;
}

function DisplayCountry({ color, country }: CountryLabelProps) {
  return (
    <Text.Loadable
      color="$white"
      css={{
        textShadow: `-1px -1px 0 ${color}, 1px -1px 0 ${color}, -1px 1px 0 ${color}, 1px 1px 0 ${color}`,
      }}
      font="sans"
      size="sm"
      stretch="ultraCondensed"
      transform="uppercase"
      weight="semibold"
    >
      {country}
    </Text.Loadable>
  );
}

function displayPercent(count: number, total: number) {
  const percent = (count / total) * 100;
  return `${Math.round(percent).toLocaleString()}%`;
}

function placeholder(count: number): PercentGraphProps['data'] {
  return Array.from({ length: count }).map(() => {
    return { color: '$gray100', isoName: undefined, label: '—', value: Math.floor(Math.random() * 90 + 10) };
  });
}
