// @flow

import { scaleLinear } from '@vx/scale';
import { max } from 'd3-array';
import React from 'react';
import { withTheme } from 'styled-components';

import { HEIGHT, X_OFFSET, Y_OFFSET } from './util';

import type { Data } from './util';

import type { Theme } from 'config/theme';
import { GridRows } from 'ui/atoms/SVGGrid';

type Props = { data: Data[], previousData?: Data[], theme: Theme, width: number };
const Rows = ({ data, previousData = [], theme, width }: Props) => {
  const allData = [...data, ...previousData];
  const y = (d) => d.value;
  const yScale = scaleLinear({
    clamp: true,
    domain: [0, max(allData, y)],
    range: [HEIGHT - Y_OFFSET, 0],
  });

  return (
    <GridRows left={X_OFFSET} numTicks={4} scale={yScale} stroke={theme.colors.tuscanBrown} width={width - X_OFFSET} />
  );
};

export default withTheme(Rows);
