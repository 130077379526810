import { Box, Cluster, Stack } from '@a1s/ui';
import React, { useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { MailviewData, SearchResultRow } from 'screens/Search/types';
import { BackButton, EmailDetails } from 'screens/Search/ui';

import { useMailviewData } from 'screens/shared/hooks/useMailviewData';

import { DetectionEmailPreview, MailTrace, RawEmail, Scrollable, Tabs } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface DetailsProps {
  onPressBackButton?: ComponentProps<typeof BackButton>['onPress'];
  row?: SearchResultRow;
}

export function Details({ onPressBackButton, row }: DetailsProps) {
  const { data: mailviewData, loading: mailviewLoading } = useMailviewData(row?.postfixIdent);

  return (
    <Box bg="$blue200" css={{ flexGrow: 2, height: '100%', position: 'relative' }} r p="4">
      <BackButton css={{ position: 'absolute', right: '$5', top: '$4', zIndex: 999 }} onPress={onPressBackButton} />

      <Cluster css={{ height: '100%' }} gap="5">
        <EmailData
          emlPath={row?.storedAt}
          clientRecipients={row?.clientRecipients}
          detailData={mailviewData}
          isJournaled={row?.isJournaled}
          isQuarantined={row?.isQuarantined}
          lookupKey={row?.key}
          mailviewLoading={mailviewLoading}
          searchInfo={row}
        />
      </Cluster>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface EmailDataProps {
  emlPath?: string;
  clientRecipients?: string[];
  detailData?: MailviewData;
  isJournaled?: boolean;
  isQuarantined?: boolean;
  lookupKey?: string;
  mailviewLoading?: boolean;
  searchInfo?: SearchResultRow;
}

function EmailData({
  emlPath,
  detailData,
  isJournaled,
  isQuarantined,
  lookupKey,
  mailviewLoading,
  searchInfo,
}: EmailDataProps) {
  const { t } = useTranslation('unisearch');
  type TabState = 'details' | 'downloads' | 'mailtrace' | 'preview' | 'raw';
  const [selected, setSelected] = useState<TabState>('details');
  const { screenshot } = detailData || {};

  return (
    <Stack css={{ flexGrow: 2 }}>
      <Tabs>
        <Tabs.Tab onClick={() => setSelected('details')} selected={selected === 'details'}>
          {t('emailDetails')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('preview')} selected={selected === 'preview'}>
          {t('emailPreview')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('raw')} selected={selected === 'raw'}>
          {t('rawEmail')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('mailtrace')} selected={selected === 'mailtrace'}>
          {t('mailTrace')}
        </Tabs.Tab>
      </Tabs>

      <Box bg="$white" css={{ flexGrow: 1, height: '100%' }} r p="2">
        <Box css={{ height: '100%', position: 'relative' }}>
          <Scrollable>
            <Box p="2">
              {selected === 'details' && (
                <EmailDetails
                  isBenign={false}
                  loading={mailviewLoading}
                  mailviewHighlightData={detailData?.highlightData}
                  searchInfo={searchInfo}
                />
              )}
              {selected === 'preview' && <DetectionEmailPreview image={screenshot} loading={mailviewLoading} />}
              {selected === 'raw' && <RawEmail emlPath={emlPath} />}
              {selected === 'mailtrace' && (
                <MailTrace lookupKey={lookupKey} showOutbound={!isJournaled && !isQuarantined} />
              )}
            </Box>
          </Scrollable>
        </Box>
      </Box>
    </Stack>
  );
}
