// @flow

import hash from 'hash-sum';

import { rem, rgba } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { type OptionTypes } from 'config/commonTypes';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue};
  font-weight: 400;
  left: 50%;
  margin-top: ${({ theme }) => rem(theme.spacing.md)};
  min-width: 100%;
  position: absolute;
  transform: translate(-50%, 55%);
  z-index: 999;

  ${({ direction }) =>
    direction === 'up' &&
    css`
      transform: translate(-50%, -70%);
    `}
`;

const Pointer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => rgba(theme.colors.black, 0.1)};
  border-style: solid;
  border-width: ${rem(1)} 0 0 ${rem(1)};
  height: ${rem(7)};
  margin: 0 auto;
  transform: rotate(45deg);
  width: ${rem(7)};
`;

const OptionsContainer = styled.div`
  background-color: #fff;
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  border-color: ${({ theme }) => rgba(theme.colors.black, 0.1)};
  border-radius: 4px;
  border-style: solid;
  border-width: ${rem(1)};
  margin-bottom: ${rem(-3)};
  margin-top: ${rem(-3)};
  padding: ${({ theme }) => rem(theme.spacing.sm)} 0;
  max-height: ${rem(200)};
  overflow-x: hidden;
  overflow-y: auto;

  & > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    & > li {
      cursor: pointer;
      outline: none;
      padding: ${({ theme }) => rem(theme.spacing.sm)} ${({ theme }) => rem(theme.spacing.md)};
      transition: all 150ms ${({ theme }) => theme.timing.easeOutCirc};
      white-space: nowrap;

      &:hover {
        background-color: ${({ theme }) => theme.colors.deepSkyBlue};
        color: ${({ theme }) => theme.colors.white};
      }
      &:focus {
        background-color: ${({ theme }) => theme.colors.deepSkyBlue};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type RenderCallback = (value: OptionTypes, index: number) => OptionTypes;

type Props = {
  direction: 'down' | 'up',
  handleSelect: Function,
  options: OptionTypes[],
  renderAsOption: RenderCallback,
};

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
export default function Option({ direction, handleSelect, options, renderAsOption }: Props) {
  return (
    <Container direction={direction}>
      {direction === 'down' && <Pointer />}
      <OptionsContainer>
        <ul>
          {options.map((option, i) => (
            <li
              data-optionindex={`${i}-option`}
              tabIndex={0}
              key={hash(`${String(option)}-${i}`)}
              onClick={() => handleSelect(option)}
              onKeyDown={(event) => handleKeyDown(event, handleSelect, option)}
              role="button"
            >
              {renderAsOption(option, i)}
            </li>
          ))}
        </ul>
      </OptionsContainer>
      {direction === 'up' && <Pointer />}
    </Container>
  );
}
/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */

//
// Private functions
// -------------------------------------------------------------------------------------------------

function handleKeyDown(event: SyntheticKeyboardEvent<>, handleSelect: (OptionTypes) => any, option: OptionTypes) {
  const { which } = event;
  if (which === 13 || which === 32) {
    handleSelect(option);
  }
}
