// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PasswordForm from './PasswordForm';

import Centered from 'ui/atoms/Centered';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Loading from 'ui/atoms/Loading';

export const FetchValidInvite = loader('./queries/FetchValidInvite.graphql');

export default function Invite() {
  const { token } = useParams();

  const {
    data: validInvite,
    error: inviteError,
    loading: inviteLoading,
  } = useQuery(FetchValidInvite, {
    variables: { token },
    skip: !token,
  });
  if (window.Sentry) {
    // $FlowFixMe
    Sentry.addBreadcrumb({
      category: 'ValidInvite',
      data: validInvite,
      message: 'catching breadcrumbs from fetching user invite',
      type: 'query',
    });
  }

  const history = useHistory();
  if (inviteError) history.push('/invite/expired');

  if (inviteLoading)
    return (
      <Centered>
        <Loading />
      </Centered>
    );

  const showOnboarding = validInvite?.validInvite.showOnboarding;

  return (
    <ConditionalRender condition={validInvite}>
      <PasswordForm showOnboarding={showOnboarding} />
    </ConditionalRender>
  );
}
