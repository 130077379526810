// @flow

import styled from 'styled-components';

const Opacity = styled.div.attrs({ 'data-testid': 'atom-opacity' })`
  opacity: ${({ value }) => value || 1};
`;

Opacity.displayName = 'Opacity';

export default Opacity;
