// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;

  & > :first-child {
    margin-bottom: ${({ theme }) => rem(theme.spacing.sm)};
  }

  ${({ dimmed }) =>
    dimmed &&
    css`
      opacity: 0.3;
    `};
`;

export default Container;
