// @flow

import { random } from '@a1s/lib';

// $FlowFixMe
import React, { useEffect, useRef, type Node } from 'react';

import Container from './Container';
import Input from './Input';
import Label from './Label';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import DisabledContainer from 'ui/atoms/DisabledContainer';

type Props = {
  block?: boolean,
  checked?: boolean | 'indeterminate',
  dataTestId?: string,
  disabled?: boolean,
  disabledTooltipContents?: Node,
  id?: string,
  info?: Node,
  label?: string,
};

const Checkbox = ({
  block = false,
  checked,
  dataTestId = 'atom-checkbox',
  disabled = false,
  disabledTooltipContents,
  id = `id${random()}`,
  info,
  label,
  ...props
}: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.indeterminate = checked === 'indeterminate';
  }, [checked]);

  return (
    <Container block={block} data-testid={dataTestId}>
      <DisabledContainer disabled={disabled} tooltipContents={disabledTooltipContents}>
        <Input checked={!!checked} disabled={disabled} id={id} ref={ref} {...props} />
        <ConditionalRender condition={Boolean(label)}>
          <Label disabled={disabled} htmlFor={id}>
            {label}
          </Label>
          {info && info}
        </ConditionalRender>
      </DisabledContainer>
    </Container>
  );
};

export default Checkbox;
