// @flow

// $FlowFixMe
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import AlertDialog from '.';

import Backdrop from 'ui/atoms/Backdrop';

interface Props {
  onClose?: (?SyntheticEvent<HTMLDivElement>) => void;
  visible?: boolean;
}

export default function Modal({ onClose, visible, ...props }: Props) {
  const elRef = useRef(document.createElement('div'));
  const dialogRef = useRef(document.getElementById('alert-dialog'));

  useEffect(() => {
    const el = elRef.current;
    const dialog = dialogRef.current;

    if (el && dialog) dialog.appendChild(el);
    return () => {
      if (el && dialog) dialog.removeChild(el);
    };
  });

  function handleClick(event: SyntheticEvent<HTMLDivElement>) {
    if (onClose) onClose(event);
  }

  return ReactDOM.createPortal(
    <>
      <AlertDialog open={!visible} {...props} />
      <Backdrop invisible={!visible} onClick={handleClick} />
    </>,
    elRef.current
  );
}
