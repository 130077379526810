import { Box, Cluster } from '@a1s/ui';
import { useQuery, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { ComponentProps } from 'react';

import { MailviewData, SearchResultRow } from '../../../types';
import { BackButton } from '../../../ui';

import { EmailData } from '.';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface DetailsProps {
  onPressBackButton?: ComponentProps<typeof BackButton>['onPress'];
  row?: SearchResultRow;
}

export function Details({ onPressBackButton, row }: DetailsProps) {
  const isBenign = row?.finalDisposition?.toLowerCase() === 'none';
  const { data: mailviewData, loading: mailviewLoading } = useRemoteData(row?.postfixIdent, isBenign);

  return (
    <Box bg="$blue200" css={{ flexGrow: 2, height: '100%', position: 'relative' }} r p="4">
      <BackButton css={{ position: 'absolute', right: '$5', top: '$4', zIndex: 999 }} onPress={onPressBackButton} />

      <Cluster css={{ height: '100%' }} gap="5">
        <EmailData
          emlPath={row?.storedAt}
          clientRecipients={row?.clientRecipients}
          clientUuid={row?.clientUuid}
          detailData={mailviewData}
          isBenign={isBenign}
          isJournaled={row?.isJournaled}
          isQuarantined={row?.isQuarantined}
          lookupKey={row?.key}
          mailviewLoading={mailviewLoading}
          searchInfo={row}
          messageId={row?.messageId}
          previewEnabled={row?.storedAt === null}
        />
      </Cluster>
    </Box>
  );
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = loader('../queries/mailview.graphql');

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: MailviewData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

function useRemoteData(messageId: string | undefined, isBenign: boolean): HookResult {
  const { data, error, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    skip: isBenign || typeof messageId === 'undefined',
    variables: {
      id: messageId,
    },
  });

  if (error) return { data: undefined, error, loading: false };
  if (!data?.response) return { data: undefined, error: null, loading };

  return { data: data.response, error, loading };
}
