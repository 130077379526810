import { Box, Stack } from '@a1s/ui';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ButtonFooter, InfoTooltip, PanelContainer } from '../../../../ui-new';
import { UpdatesDisplay } from '../../ui';

import Middle from './Middle';
import Top from './Top';

import { useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

const LIVE_POLL_INTERVAL = 5000; // 5 seconds

export function SystemStatsPanel() {
  const duration = useDuration();
  const [isLiveMode, setIsLiveMode] = useState<boolean>(false);

  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('systemStats')} titleDecoration={<Tooltip />}>
      <Stack gap="4">
        <Top duration={duration} isLiveMode={isLiveMode} setIsLiveMode={setIsLiveMode} />
        <Middle duration={duration} isLiveMode={isLiveMode} />
        <Bottom isLiveMode={isLiveMode} />
      </Stack>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------
interface BottomProps {
  /**
   * Whether or not live mode is on
   */
  isLiveMode: boolean;
}

function Bottom({ isLiveMode = false }: BottomProps) {
  const duration = isLiveMode ? `PT${Math.round(LIVE_POLL_INTERVAL / 1000)}S` : 'PT15M';

  return (
    <Box pb px testId="system-stats-box-bottom">
      <ButtonFooter to="/email">
        <UpdatesDisplay duration={duration} />
      </ButtonFooter>
    </Box>
  );
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.systemStats', { postProcess: 'markdown' })}</InfoTooltip>;
}
