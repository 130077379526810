import {
  Box,
  Cluster,
  Dropdown,
  Loadable,
  PercentageChange,
  Stack,
  StackedBarChart,
  StackedBarChartProps,
} from '@a1s/ui';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDimensions from 'react-use-dimensions';

import { AbbreviatedNumber, ButtonFooter, PanelContainer, Text } from '../../../../ui-new';

import { FilterType, usePhishguardData } from '../../../shared/hooks/usePhishguardData';

import { ColorBlock, LabeledValue, UpdatesDisplay } from '../../ui';

import { Searchable } from 'screens/shared/Searchable';
import { useDuration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

export function PhishSubmissionsPanel() {
  const duration = useDuration();
  const [filter, setFilter] = useState<FilterType>('all');
  const { t } = useTranslation('dashboardHome');

  const { data, loading } = usePhishguardData({ duration });

  const handleFilterChange = (newValue: FilterType) => {
    setFilter(newValue);
  };

  return (
    <PanelContainer title={t('phishSubmissionStats')}>
      <Box p>
        <Stack>
          <Stack gap>
            <Top data={data?.[filter]?.totals} filter={filter} onFilterChange={handleFilterChange} />
            <Middle loading={loading} timelineData={data?.[filter]?.timeline} />
          </Stack>
          <Bottom />
        </Stack>
      </Box>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Bottom() {
  return (
    <ButtonFooter i18nKey="common:viewDetails" to="/submissions">
      <UpdatesDisplay duration="PT15M" />
    </ButtonFooter>
  );
}

interface MiddleType {
  loading: boolean;
  timelineData?: TimelineData[];
}

function Middle({ loading, timelineData = [] }: MiddleType) {
  const [baseRef, { width }] = useDimensions();
  const { t } = useTranslation('dashboardHome');

  return (
    <Loadable loading={loading}>
      {timelineData.length > 0 ? (
        <div ref={baseRef}>
          <StackedBarChart.Loadable
            data={timelineData as StackedBarChartProps['data']}
            height="180"
            width={width}
            LabelDisplay={DateDisplay}
          />
        </div>
      ) : (
        <Text>{t('noDataToDisplay')}</Text>
      )}
    </Loadable>
  );
}

interface TopType {
  data?: {
    bulkPercent: number;
    currentTotal: number;
    maliciousPercent: number;
    previousTotal: number;
    spamPercent: number;
  };
  filter: FilterType;
  // eslint-disable-next-line no-unused-vars
  onFilterChange: (newValue: FilterType) => void;
}

function Top({ data, filter, onFilterChange }: TopType) {
  const { t } = useTranslation('dashboardHome');

  // gets the right params for detection search like q and finalDisposition based on different report options
  const setSearchParams = (disposition: 'bulk' | 'malicious' | 'spam') => {
    // for User submissions (reports) that are also MALICIOUS, you would pass:
    // &metric=m:sbmsn:USER&metric=m:sbmsn:MALICIOUS

    if (filter === 'user') {
      return {
        metric: `sbmsn:USER&metric=m:sbmsn:${disposition.toUpperCase()}`,
      };
    }
    if (filter === 'team') {
      return {
        metric: `sbmsn:TEAM&metric=m:sbmsn:${disposition.toUpperCase()}`,
      };
    }
    return {
      disposition,
      metric: 'sbmsn',
    };
  };

  return (
    <Cluster align="start" justify="space-between">
      <Stack gap="4">
        <Cluster align="baseline" gap="2">
          <Text.Title>{t(`reports-${filter}`)}</Text.Title>
          <PercentageChange.Calculate.Loadable
            color="gray400"
            current={data?.currentTotal || 0}
            previous={data?.previousTotal || 0}
          />
        </Cluster>

        <Text.Large as="div">
          <AbbreviatedNumber value={data?.currentTotal || 0} />
        </Text.Large>
      </Stack>

      <Dropdown onChange={onFilterChange} value={filter}>
        <Dropdown.Option value="all">{t('allReports')}</Dropdown.Option>
        <Dropdown.Option value="user">{t('userReports')}</Dropdown.Option>
        <Dropdown.Option value="team">{t('socTeam')}</Dropdown.Option>
      </Dropdown>

      <Cluster gap>
        <Text.Title>{t('reportedAs')}</Text.Title>
        <Stack>
          <Cluster gap>
            <Box pt="1">
              <ColorBlock color="$pink500" />
            </Box>

            <Cluster align="center" justify="space-between">
              <Searchable params={setSearchParams('malicious')}>
                <LabeledValue gap="2" value={t('percent', { number: data?.maliciousPercent || 0 })}>
                  {t('malicious')}
                </LabeledValue>
              </Searchable>
            </Cluster>
          </Cluster>
          <Cluster gap>
            <Box pt="1">
              <ColorBlock color="$teal650" />
            </Box>

            <Cluster align="center" justify="space-between">
              <Searchable params={setSearchParams('spam')}>
                <LabeledValue gap="2" value={t('percent', { number: data?.spamPercent || 0 })}>
                  {t('spam')}
                </LabeledValue>
              </Searchable>
            </Cluster>
          </Cluster>
          <Cluster gap>
            <Box pt="1">
              <ColorBlock color="$teal400" />
            </Box>

            <Cluster align="center" justify="space-between">
              <Searchable params={setSearchParams('bulk')}>
                <LabeledValue gap="2" value={t('percent', { number: data?.bulkPercent || 0 })}>
                  {t('bulk')}
                </LabeledValue>
              </Searchable>
            </Cluster>
          </Cluster>
        </Stack>
      </Cluster>
    </Cluster>
  );
}

interface TimelineData {
  BULK: number;
  date: string;
  MALICIOUS: number;
  SPAM: number;
}

function DateDisplay({ label, value }: { value: Date; label: string }) {
  const { t } = useTranslation('dashboardHome');

  return label === format(new Date(), 'yyyy-MM-dd') ? (
    <tspan style={{ textTransform: 'uppercase' }}>{t('today')}</tspan>
  ) : (
    <tspan style={{ textTransform: 'uppercase' }}>{t('monthDate', { date: value })}</tspan>
  );
}
