// @flow

import { endOfDay, subDays } from 'date-fns';
// @$FlowFixMe
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { DaysBackContext } from 'screens/App';
import DaysBackDropdown from 'screens/shared/DaysBackDropdown';
import DatePicker from 'ui/molecules/DatePicker';

type Props = {
  excludePathname?: string,
};

export default function HeaderRight({ excludePathname = '' }: Props) {
  const { currentInterval } = useContext(DaysBackContext);
  const endDate = new Date();
  const startDate = subDays(endOfDay(new Date()), currentInterval);

  return (
    <Route>
      {({ location: { pathname } }) =>
        pathname !== excludePathname && (
          <>
            <DaysBackDropdown />
            <DatePicker end={endDate} start={startDate} />
          </>
        )
      }
    </Route>
  );
}
