// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Circle = styled.div`
  align-self: top;
  background-color: ${({ color }) => color};
  border-radius: ${rem(8)};
  height: ${rem(16)};
  min-height: ${rem(16)};
  margin-right: ${({ theme }) => rem(theme.spacing.md)};
  margin-top: ${({ theme }) => rem(theme.spacing.xs)};
  min-width: ${rem(16)};
  width: ${rem(16)};
`;

export default Circle;
