import { Box, Cluster, Stack, Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Bottom from './Bottom';
import Middle from './Middle';

import useRemoteData from './useRemoteData';

import { InfoTooltip, PanelContainer } from 'ui-new';
import { useDuration } from 'utils/duration';

export function EmailLinkIsolation() {
  const duration = useDuration();
  const { data } = useRemoteData({ duration });
  const { t } = useTranslation('dashboardHome');

  const { breakdown: actions } = data;
  const totalClicks = actions?.eliInterstitial || 0;

  return (
    <PanelContainer title={t('eli')} titleDecoration={<Tooltip />}>
      <Stack gap>
        <Top totalClicks={totalClicks} />
        <Middle actions={actions} total={totalClicks} />
        <Bottom />
      </Stack>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.emailLinkIsolation', { postProcess: 'markdown' })}</InfoTooltip>;
}

interface TopProps {
  /**
   * Total number of ELI clicks
   */
  totalClicks: number;
}

function Top({ totalClicks }: TopProps) {
  const { t } = useTranslation('dashboardHome');

  return (
    <Box p>
      <Cluster align="start" justify="space-between">
        <Stack>
          <Cluster gap="2">
            <Text
              color="$gray600"
              font="sans"
              size="sm"
              stretch="ultraCondensed"
              transform="uppercase"
              weight="regular"
            >
              {t('eliClicks')}
            </Text>
            {/* Insights isn't returning this yet */}
            {/* <PercentageChange change="down" color="gray400" css={{ fontSize: '$xs', padding: 0 }} value="33%" /> */}
          </Cluster>
          <Text as="div" color="$black" font="lato" lineHeight="1" size="4xl" stretch="expanded" weight="light">
            {totalClicks}
          </Text>
        </Stack>
      </Cluster>
    </Box>
  );
}
