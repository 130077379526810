// @flow

import { rem } from 'polished';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

import Link from 'ui/atoms/Link';
import LanguageSelector from 'ui/molecules/LanguageSelector';
import OneTrustButton from 'ui/molecules/OneTrustButton';
import Split from 'ui/templates/Split';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Center = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  margin: 0 auto;
  padding: ${({ theme }) => rem(theme.spacing.md)};
`;

const StyledP = styled.p`
  color: ${({ theme }) => theme.colors.steelTeal};
  font-size: ${rem(12)};
  line-height: ${rem(12)};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.steelTeal};
  font-size: ${rem(12)};
  line-height: ${rem(12)};
  padding-right: ${({ theme }) => rem(theme.spacing.md)};
  transition: color 150ms ${({ theme }) => theme.timing.easeOutCirc};

  &:hover {
    color: ${({ theme }) => theme.colors.bleuDeFrance};
  }
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  dataTestId?: string;
}

export default function Footer({ dataTestId = 'molecule-footer' }: Props) {
  const { t } = useTranslation('components');

  return (
    <div data-testid={dataTestId}>
      <Center bordered>
        <StyledP>
          <StyledLink external rel="nofollow" target="_blank" to="https://developers.cloudflare.com/email-security/">
            {t('Footer.documentation')}
          </StyledLink>{' '}
          <StyledLink external rel="nofollow" target="_blank" to="https://area1security.zendesk.com/hc/en-us">
            {t('Footer.support')}
          </StyledLink>
          <OneTrustButton />
        </StyledP>
        <Split>
          <StyledP>
            <Trans i18nKey="components:Footer.copyright">
              Copyright &copy; {{ year: new Date().getFullYear() }} Cloudflare Area 1 Security. All rights reserved.
            </Trans>
          </StyledP>
          <LanguageSelector direction="up" />
        </Split>
      </Center>
    </div>
  );
}
