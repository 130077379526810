import { Box, Cluster, Text } from '@a1s/ui';
import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ConfigRuleLink } from '../styled';

interface DesignationProps {
  type: 'allowed' | 'blocked' | undefined;
}

export default function Designation({ type }: DesignationProps) {
  const history = useHistory();

  return (
    <Box bg="$gray100" css={{ maxWidth: '$lg' }} r p>
      <Cluster css={{ svg: { flexShrink: 0 } }} gap nowrap>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
          <path
            fill="#fff"
            stroke="#6C90A1"
            d="M20.5 10.5a9.977 9.977 0 0 1-3.523 7.62A9.958 9.958 0 0 1 10.5 20.5c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10Z"
          />
          <path
            fill="#6C90A1"
            d="M11.208 8.604V16h-1.203V8.604h1.203ZM9.937 6.642c0-.205.056-.379.17-.52.114-.141.285-.212.513-.212.228 0 .399.07.513.212a.781.781 0 0 1 .178.52c0 .196-.06.364-.178.505-.114.137-.285.206-.513.206-.228 0-.399-.069-.513-.206a.78.78 0 0 1-.17-.505Z"
          />
        </svg>
        <Text as="div" color="$gray600" font="sans" size="sm" stretch="ultraCondensed">
          {type === 'allowed' ? (
            <Trans i18nKey="emailDesignationAllowed">
              <>
                Designated as a non-detection based on a <b>allow list</b> (
                <ConfigRuleLink onClick={() => history.push('/settings/email/allow/allow-patterns')}>
                  Configure rule
                </ConfigRuleLink>
                )
              </>
            </Trans>
          ) : (
            <Trans i18nKey="emailDesignationBlocked">
              <>
                Designated as Malicious based on a <b>block list</b> (
                <ConfigRuleLink onClick={() => history.push('/settings/email/block/senders')}>
                  Configure rule
                </ConfigRuleLink>
                )
              </>
            </Trans>
          )}
        </Text>
      </Cluster>
    </Box>
  );
}
