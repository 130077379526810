// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Text = styled.div`
  font-size: ${rem(14)};
  grid-area: text !important;
  line-height: ${rem(21)};
  margin-top: ${({ theme }) => rem(theme.spacing.sm)};
  max-width: ${rem(336)};
`;

export default Text;
