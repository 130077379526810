// @flow

import { usePrevious, useWindowFocus } from '@a1s/hooks';
import hash from 'hash-sum';
import React, { type Element, type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

import Text from './Text';

import theme from 'config/theme';

// $FlowFixMe
import { AbbreviatedNumber } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

type Format = 'abbreviatedNumber' | 'number' | 'percent' | 'percentWithDecimal';
export type Size = 'x-small' | 'small' | 'medium' | 'large';

type Props = {
  animate?: boolean,
  cappedValue?: number,
  children?: Node | Element<typeof AbbreviatedNumber>,
  colorize?: boolean,
  dataTestId?: string,
  dimmed?: boolean,
  format?: Format,
  size?: Size,
};

export default function Statistic(props: Props) {
  const { t } = useTranslation('components');

  let { animate = false } = props;
  const { cappedValue, children, format } = props;

  const { colorize = false, dataTestId = 'atom-statistic', dimmed = false, size = 'small' } = props;
  // $FlowFixMe: Flow isn't picking up that we're checking that children is a number before comparing
  const isCapped = Number.isFinite(children) && children >= cappedValue;
  const value = isCapped ? cappedValue : children;
  const processedValue = format ? t(`Statistic.${format}`, { value }) : value;
  const abbreviatedNumberComponentType = (<AbbreviatedNumber />).type;

  const getDisplayValue = () => {
    // This is adding a way to pass in an instance of <AbbreviatedNumber /> like we want to do here but without breaking everything else.
    // https://github.area1security.com/engineering/marshall/blob/jb/marshall/abbrivated-number/apps/marshall/src/screens/Email/screens/Overview/OverviewPhishLineGraph.js#L224-L228
    // $FlowFixMe - children.type will return `undefined` at the very least
    if (children && children.type && children.type === abbreviatedNumberComponentType) {
      return children;
    }
    return `${isCapped ? '>' : ''}${processedValue ? String(processedValue) : ''}`;
  };

  if (typeof children !== 'number') animate = false;

  const displayValue = getDisplayValue();

  return (
    <Text data-testid={dataTestId} dimmed={dimmed} size={size}>
      {animate ? <Animated colorize={colorize}>{displayValue}</Animated> : displayValue}
    </Text>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface AnimatedProps {
  children?: Node;
  colorize?: boolean;
}

function Animated({ children, colorize = false }: AnimatedProps) {
  const prev = usePrevious(children);

  if (typeof children !== 'string') return <>{children}</>;

  const prevValue = prev && stringToFloat(prev);
  const currentValue = stringToFloat(children);

  let direction;
  if (currentValue > prevValue) {
    direction = 'increased';
  } else if (currentValue < prevValue) {
    direction = 'decreased';
  }

  return children.split('').map((digit, i) => {
    return (
      <Digit colorize={colorize} direction={direction} key={hash(`${digit}-${i}`)}>
        {digit}
      </Digit>
    );
  });
}

const DigitWrapper = styled.div`
  background: none;
  display: inline-block;
  line-height: 1;
  max-height: 1em;
  overflow: hidden;
  perspective: 1.5em;
  position: relative;
  white-space: nowrap;

  & > span {
    display: inline-block;
    position: relative;
    will-change: transform;
  }

  & > span:not(:first-child) {
    display: inline-block;
    position: absolute;
    right: 0px;
  }

  /* Keeps the last digit from being partially cut off (EN-12618). */
  & > span:last-child {
    overflow: visible;
    padding-right: 2px;
  }
`;

interface DigitProps {
  children: string;
  colorize?: boolean;
  direction?: 'decreased' | 'increased';
}

function Digit({ children, colorize = true, direction }: DigitProps) {
  const isFocused = useWindowFocus();

  let color = theme.colors.raisinBlack;
  if (colorize && direction === 'increased') color = theme.colors.green;
  else if (colorize && direction === 'decreased') color = theme.colors.redOrange;

  const enter = { color: theme.colors.raisinBlack, opacity: 1, transform: 'translate3d(0, 0%, 0%) rotateX(0)' };
  const from =
    direction === 'increased'
      ? { color, opacity: 0, transform: 'translate3d(0, -50%, -50px) rotateX(80deg)' }
      : { color, opacity: 0, transform: 'translate3d(0, 50%, -50px) rotateX(-80deg)' };
  const leave =
    direction === 'increased'
      ? { color: theme.colors.raisinBlack, opacity: 0, transform: 'translate3d(0, 50%, -50px) rotateX(-80deg)' }
      : { color: theme.colors.raisinBlack, opacity: 0, transform: 'translate3d(0, -50%, -50px) rotateX(80deg)' };

  const config = { tension: 500, friction: 50, mass: 1 };
  const transitions = useTransition(children, null, { ...config, enter, from, initial: enter, leave });

  // Animated digit
  if (isFocused) {
    return (
      <DigitWrapper>
        {transitions.map(({ item, props }) => (
          <animated.span key={item} style={props}>
            {item}
          </animated.span>
        ))}
      </DigitWrapper>
    );
  }

  // Un-animated digit
  return (
    <DigitWrapper>
      <span>{children}</span>
    </DigitWrapper>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function stringToFloat(str: string): number {
  return parseFloat(str.replace(',', '').replace('%', ''));
}
