// @flow

import styled from 'styled-components';

const Rect = styled.rect`
  cursor: pointer;
  opacity: 0.9;
  shape-rendering: crispEdges;
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc};
`;

export default Rect;
