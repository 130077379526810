// @flow

import copy from 'copy-to-clipboard';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from './copy.svg';

type Props = {
  onCopy?: () => void,
  text: string,
};

function Copy({ onCopy, text, ...props }: Props) {
  const copyToClipboard = (event) => {
    event.preventDefault();
    copy(text);
    if (onCopy) onCopy();
  };

  return <Icon onClick={copyToClipboard} {...props} />;
}

export default styled(Copy)`
  background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  cursor: pointer;
  height: ${rem(10)};
  width: ${rem(10)};

  path {
    fill: ${({ theme }) => theme.colors.independence};
    stroke: ${({ theme }) => theme.colors.independence};
  }

  &:hover path {
    fill: ${({ theme }) => theme.colors.grayBlue};
  }
`;
