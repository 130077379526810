// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { defaultDetections, defaultDetectionsTimeline, defaultTotals } from './dataTypesAndUtils';

import DetectionStatistics from './DetectionStatistics';
import TotalDetections from './TotalDetections';

// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';

export const dashboardCountsQuery = loader('./queries/DashboardCountsQuery.graphql');

export const detectionStatsQuery = loader('./queries/InsightsDetectionStatsQuery.graphql');

export default function DetectionDetails() {
  const { t } = useTranslation('common');

  const { user } = useCurrentUser();
  const becEnabled = get(user, 'maliciousBecEnabled', false);

  const {
    data: detectionStatsData,
    error: detectionStatsError,
    loading: detectionStatsLoading,
  } = useQuery(detectionStatsQuery, {
    pollInterval: 900000, // 15 minutes in milliseconds,
    variables: setInsightsDateRangeFromDuration('30'),
  });
  const totalDetections = get(detectionStatsData, 'insightsDetectionStats.data', [
    { totalEmailsProcessedPrevious: 0 },
    { totalEmailsProcessed: 0 },
    { breakdown: { current: defaultDetections, previous: defaultDetections } },
    { timeline: { current: [defaultDetectionsTimeline], previous: [defaultDetectionsTimeline] } },
  ]);
  const current = totalDetections.find((d) => 'totalEmailsProcessed' in d)?.totalEmailsProcessed;
  const previous = totalDetections.find((d) => 'totalEmailsProcessedPrevious' in d)?.totalEmailsProcessedPrevious;
  const breakdown = totalDetections.find((d) => 'breakdown' in d)?.breakdown || {
    current: defaultDetections,
    previous: defaultDetections,
  };

  const { data, error, loading } = useQuery(dashboardCountsQuery);
  const todayDate = new Date();
  const dateMonth = format(todayDate, 'MM/yyyy');
  const formattedDate = todayDate.toLocaleDateString('en-US');

  const mailReportingData = get(data, 'mailReporting', {
    totalmailReporting: [
      {
        last30Days: {
          totals: defaultTotals,
          timeseries: {
            [formattedDate]: defaultTotals,
          },
        },
      },
      {
        [dateMonth]: {
          totals: defaultTotals,
          timeseries: {
            [formattedDate]: defaultTotals,
          },
        },
      },
    ],
  });

  const totalmailReportingData = get(mailReportingData, 'totalmailReporting', []);
  const last30DaysData = get(mailReportingData, 'totalmailReporting[0].last30Days', {
    timeseries: { [formattedDate]: defaultTotals },
    totals: defaultTotals,
  });
  const stackedBarGraphData = get(last30DaysData, 'timeseries', {});

  return (
    <>
      <Helmet title={`${t('email')} :: ${t('email:detectionDetails')} - ${t('const:names:area1security')}`} />
      <>
        <TotalDetections
          becEnabled={becEnabled}
          data={{
            donutChart: breakdown.current,
            sectionOverview: {
              current,
              previous,
            },
            stackedBarGraph: stackedBarGraphData,
          }}
          error={error || detectionStatsError}
          loading={loading || detectionStatsLoading}
        />
        <DetectionStatistics becEnabled={becEnabled} data={totalmailReportingData} error={error} loading={loading} />
      </>
    </>
  );
}
