"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledButton = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
const StyledButton = (0, _styled.styled)('button', {
  $$color: '$colors$blue500',
  backgroundColor: '$white',
  borderColor: '$$color',
  borderRadius: '$2',
  borderStyle: '$solid',
  borderWidth: '$1',
  color: '$$color',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: '50%',
  padding: '$2 $5',
  textTransform: 'uppercase',
  '&:hover': {
    $$color: '$colors$blue600',
    backgroundColor: '$gray50'
  },
  '&:active': {
    $$color: '$colors$gray600',
    backgroundColor: '$white',
    transform: 'translateY($space$px)'
  },
  '&:disabled': {
    $$color: '$colors$gray300 !important',
    backgroundColor: '$gray50',
    pointerEvents: 'none'
  },
  variants: {
    appearance: {
      faded: {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        color: 'rgba(255, 255, 255, 0.75)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.35)',
          borderColor: 'rgba(255, 255, 255, 0.85)',
          color: 'rgba(255, 255, 255, 0.85)'
        },
        '&:active': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderColor: 'rgba(255, 255, 255, 0.95)',
          color: 'rgba(255, 255, 255, 0.95)'
        },
        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderColor: 'rgba(255, 255, 255, 0.3)',
          color: 'rgba(255, 255, 255, 0.3)',
          cursor: 'not-allowed',
          textDecorationLine: 'line-through'
        }
      },
      primary: {
        backgroundColor: '$colors$blue500',
        borderColor: '$colors$blue500',
        color: '$white',
        '&:disabled': {
          backgroundColor: '$colors$blue500',
          borderColor: '$colors$white',
          color: 'rgba(255, 255, 255, 0.3)',
          cursor: 'not-allowed',
          textDecorationLine: 'line-through'
        },
        '&:hover': {
          backgroundColor: '$colors$blue400',
          borderColor: '$colors$blue400',
          color: '$white'
        }
      }
    },
    /**
     * Sets the font family of the text.
     * @type $fonts
     */
    font: (0, _utils.tokenVariant)('fontFamily', _styled.theme.fonts)
  }
});
exports.StyledButton = StyledButton;