// @flow

import styled from 'styled-components';

const Path = styled.path`
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 150ms ${({ theme }) => theme.timing.easeOutCirc};

  &:hover {
    opacity: 0.9;
  }
`;

export default Path;
