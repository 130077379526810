// @flow

import React from 'react';
import { type ContextRouter, Route, Switch } from 'react-router-dom';

import HeaderLeft from './HeaderLeft';

import AdminQuarantine from './screens/AdminQuarantine';
import DetectionDetails from './screens/DetectionDetails';
import Detections from './screens/Detections';
import Overview from './screens/Overview';
import UserActivity from './screens/UserActivity';

import HeaderRight from 'screens/shared/HeaderRight';

import AccessChecker, { canAccessOptions, permissionTypes } from 'ui/molecules/AccessChecker';
import SectionHeader from 'ui/molecules/SectionHeader';
import PageNotFound from 'ui/organisms/PageNotFound';
import SectionTemplate from 'ui/templates/Section';

export default ({ match: { path } }: ContextRouter) => {
  const { ADMIN, MAILSEARCH_ENABLED, READ_WRITE } = permissionTypes;

  return (
    <AccessChecker allowed={[MAILSEARCH_ENABLED]} renderNoAccess={false}>
      <SectionTemplate
        header={
          <SectionHeader left={<HeaderLeft />} right={<HeaderRight excludePathname="/email/detection-details" />} />
        }
        main={
          <Switch>
            <Route component={Overview} exact path={`${path}`} />
            <Route component={Detections} path={`${path}/detections`} />
            <Route component={UserActivity} exact path={`${path}/user-activity`} />
            <Route component={DetectionDetails} exact path={`${path}/detection-details`} />
            <AccessChecker allowed={[ADMIN, READ_WRITE]} canAccess={canAccessOptions.any}>
              <Route component={AdminQuarantine} path={`${path}/admin-quarantine`} />
            </AccessChecker>
            <Route component={PageNotFound} path="*" status={404} />
          </Switch>
        }
      />
    </AccessChecker>
  );
};
