import { Button, Box, Cluster, Stack } from '@a1s/ui';
import React, { useEffect, useRef, ComponentProps, KeyboardEvent, MouseEvent, ReactChild } from 'react';

import { CloseButton } from '../CloseButton';

import { Dialog as Container } from './styled';

import { useShowModal } from './useShowModal';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  /**
   * The contents inside of the dialog - some text, a button, maybe even a form.
   */
  children?: ComponentProps<typeof Container>['children'];

  maxWidth?: boolean;

  onClose?: ComponentProps<typeof Container>['onClose'];

  /**
   * Boolean value that indicates whether the dialog is visible or not.
   */
  visible: boolean;
}

export function Dialog({ children, maxWidth, onClose, visible }: Props) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useShowModal(dialogRef, visible);

  return (
    <Container maxWidth={maxWidth} onClose={onClose} open={visible} ref={dialogRef}>
      {children}
    </Container>
  );
}

//
// Dialog with close button
// -------------------------------------------------------------------------------------------------

Dialog.Closable = Closable;

interface ClosableProps extends Props {
  title?: React.ReactNode;
}

function Closable({ children, onClose, title, ...rest }: ClosableProps) {
  return (
    <Dialog {...rest}>
      <Box p>
        <Stack gap="4">
          <Cluster gap="4" justify={title ? 'space-between' : 'end'}>
            {title}
            <CloseButton onPress={onClose as ComponentProps<typeof Button>['onPress']} />
          </Cluster>
          {children}
        </Stack>
      </Box>
    </Dialog>
  );
}

//
// Alert dialog
// -------------------------------------------------------------------------------------------------

Dialog.Alert = Alert;

interface AlertProps {
  /**
   * The text that appears on the confirmation button. Defaults to "OK".
   */
  confirmButtonText?: string;

  /**
   * Callback function invoked when the "Close" button is clicked.
   */
  handleClose: ComponentProps<typeof Button>['onPress'];

  /**
   * This is the message the user sees eg: "Are you sure you want to do that?". It can also be more React components.
   */
  message: ReactChild;

  maxWidth?: boolean;

  /**
   * Whether the dialog should be open (visible) or not.
   */
  visible: boolean;
}

function Alert({ confirmButtonText = 'OK', handleClose, maxWidth, message, visible }: AlertProps) {
  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent<HTMLElement>) => {
      event.stopPropagation();
      if (event.key === 'Escape' && handleClose) handleClose(event as unknown as MouseEvent<HTMLButtonElement>);
    };

    if (visible) window.addEventListener('keyup', handleEscPress as any);
    return () => window.removeEventListener('keyup', handleEscPress as any);
  }, [handleClose, visible]);

  return (
    <Dialog maxWidth={maxWidth} visible={visible}>
      <Box p>
        <Stack gap="4">
          <Cluster justify="end">
            <CloseButton onPress={handleClose} small />
          </Cluster>
          <Stack gap="9">
            {message}
            <Cluster justify="end" gap="4">
              <Button onPress={handleClose}>{confirmButtonText}</Button>
            </Cluster>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}

//
// Confirmation dialog
// -------------------------------------------------------------------------------------------------

Dialog.Confirmation = Confirmation;

interface ConfirmationProps {
  /**
   * The text that appears on the cancel button. Defaults to "Cancel".
   */
  cancelButtonText?: string;

  /**
   * The text that appears on the confirmation button. Defaults to "Confirm".
   */
  confirmButtonText?: string;

  /**
   * Callback function invoked when the "Close" button is clicked.
   */
  handleClose: ComponentProps<typeof Button>['onPress'];

  /**
   * Callback function invoked when the "Confirm" button is clicked.
   */
  handleConfirm: ComponentProps<typeof Button>['onPress'];

  /**
   * This is the message the user sees eg: "Are you sure you want to do that?". It can also be more React components.
   */
  message: ReactChild;

  /**
   * Whether the dialog should be open (visible) or not.
   */
  visible: boolean;
}

function Confirmation({
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  handleClose,
  handleConfirm,
  message,
  visible,
}: ConfirmationProps) {
  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent<HTMLElement>) => {
      event.stopPropagation();
      if (event.key === 'Escape' && handleClose) handleClose(event as unknown as MouseEvent<HTMLButtonElement>);
    };

    if (visible) window.addEventListener('keyup', handleEscPress as any);
    return () => window.removeEventListener('keyup', handleEscPress as any);
  }, [handleClose, visible]);

  return (
    <Dialog visible={visible}>
      <Box p>
        <Stack gap="4">
          <Cluster justify="end">
            <CloseButton onPress={handleClose} small />
          </Cluster>
          <Stack gap="9">
            {message}
            <Cluster justify="end" gap="4">
              <Button onPress={handleClose}>{cancelButtonText}</Button>
              <Button onPress={handleConfirm}>{confirmButtonText}</Button>
            </Cluster>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
