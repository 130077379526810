// @flow

import { get } from 'lodash';
import moment from 'moment';

export const MAX_CAPPED_VALUE = 100000;

export type CurrentIntervalValue = 7 | 30 | 90;

type TopType = {
  count: number,
  name: string,
};

type InsightsType = {
  classicSpoofCount: number,
  nameSpoofCount: number,
  topNames: TopType[],
  topTargets: TopType[],
};

export const defaultDetections = {
  bulk: 0,
  malicious: 0,
  maliciousBec: 0,
  none: 0,
  spam: 0,
  spoof: 0,
  suspicious: 0,
};

export const defaultDetectionsTimeline = {
  bulk: 0,
  malicious: 0,
  maliciousBec: 0,
  none: 0,
  spam: 0,
  spoof: 0,
  suspicious: 0,
  date: '',
};

export const defaultInsightsSpoofData: InsightsType = {
  classicSpoofCount: 0,
  nameSpoofCount: 0,
  topNames: [],
  topTargets: [],
};

type UserOverviewTopSpoofedNames = {
  count: number,
  key: string,
};

type UserOverviewTopSpoofedTargets = {
  count: number,
  key: string,
};

type UserOverviewType = {
  phishSenders: number,
  phishRecipients: number,
  topSpoofedTargets: Array<UserOverviewTopSpoofedTargets>,
  topSpoofedNames: Array<UserOverviewTopSpoofedNames>,
  totalSpoofedNames: number,
  totalSpoofedTargets: number,
};

export const defaultUserOverview: UserOverviewType = {
  phishSenders: 0,
  phishRecipients: 0,
  topSpoofedTargets: [{ count: 0, key: '' }],
  topSpoofedNames: [{ count: 0, key: '' }],
  totalSpoofedNames: 0,
  totalSpoofedTargets: 0,
};

// email report

type EmailReportType = {
  recipientCount: number,
  senderCount: number,
};

export const defaultEmailReport: EmailReportType = {
  recipientCount: 0,
  senderCount: 0,
};

// dashboardCounts

type EmailCountsRowTimeseriesType = {
  [date: string]: {
    mailBec: number,
    mailMalicious: number,
    mailSpam: number,
    mailSpoof: number,
    mailSuspicious: number,
    mailTotal: number,
  },
};

type EmailCountsRowTotalsType = {
  mailBec: number,
  mailMalicious: number,
  mailSpam: number,
  mailSpoof: number,
  mailSuspicious: number,
  mailTotal: number,
};

type EmailCountsMarshallTypeRow = {|
  timeseries: EmailCountsRowTimeseriesType,
  totals: EmailCountsRowTotalsType,
|};

type EmailCountsMarshallType = {|
  '7': EmailCountsMarshallTypeRow,
  '30': EmailCountsMarshallTypeRow,
  '90': EmailCountsMarshallTypeRow,
|};

export const defaultEmailCountsMarshall: EmailCountsMarshallType = {
  '7': {
    timeseries: {},
    totals: {
      mailBec: 0,
      mailMalicious: 0,
      mailSpam: 0,
      mailSpoof: 0,
      mailSuspicious: 0,
      mailTotal: 0,
    },
  },
  '30': {
    timeseries: {},
    totals: {
      mailBec: 0,
      mailMalicious: 0,
      mailSpam: 0,
      mailSpoof: 0,
      mailSuspicious: 0,
      mailTotal: 0,
    },
  },
  '90': {
    timeseries: {},
    totals: {
      mailBec: 0,
      mailMalicious: 0,
      mailSpam: 0,
      mailSpoof: 0,
      mailSuspicious: 0,
      mailTotal: 0,
    },
  },
};

const epochToBeginningOfDayDate = (epoch: string) =>
  new Date(moment(new Date(Number(epoch)), 'DD/MM/YYYY').startOf('day'));

export const overviewTotalPhishForLineGraph = (
  emails: EmailCountsMarshallTypeRow,
  becEnabled: boolean,
  detectionType: string
) => {
  const emailCount = get(emails, 'timeseries', {});
  const excludeKeys = [...(becEnabled ? ['mailBec'] : []), 'mailBulk', 'mailSpam', 'mailTotal'];

  if (detectionType) {
    return Object.keys(emailCount).map<any>((timestamp) => ({
      date: new Date(epochToBeginningOfDayDate(timestamp)),
      value: emailCount[timestamp][detectionType] || 0,
    }));
  }

  return Object.keys(emailCount).map<any>((timestamp) => ({
    date: new Date(epochToBeginningOfDayDate(timestamp)),
    value: Object.keys(emailCount[timestamp]).reduce(
      (acc, key) => acc + (!excludeKeys.includes(key) && emailCount[timestamp][key]),
      0
    ),
  }));
};
