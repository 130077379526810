// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Attachments from './Attachments';
import { determineReportInterval, userActivityRecord } from './dataTypesAndUtils';
import EnvelopeVsHeaderFromMismatches from './EnvelopeVsHeaderFromMismatches';
import TopMessageSendersAndRecipients from './TopMessageSendersAndRecipients';

import { DaysBackContext } from 'screens/App';

export const userActivityQuery = loader('./queries/UserActivityQuery.graphql');

export default function UserActivity() {
  const { currentInterval } = useContext(DaysBackContext);

  const { loading, error, data } = useQuery(userActivityQuery, {
    fetchPolicy: 'network-only',
    variables: { currentInterval: determineReportInterval(currentInterval) },
  });

  const {
    attachmentFormats,
    attachmentTypes,
    attachmentTypeToExtensions,
    envelopeMismatches,
    topRecipients,
    topSenders,
  } = get(data, 'userActivity', userActivityRecord);

  const { t } = useTranslation('email');

  return (
    <>
      <Helmet title={`${t('common:email')} :: ${t('common:userActivity')} - ${t('const:names:area1security')}`} />
      <>
        <TopMessageSendersAndRecipients
          data={{
            senders: topSenders || [],
            recipients: topRecipients || [],
          }}
          error={error}
          loading={loading}
        />
        <EnvelopeVsHeaderFromMismatches data={envelopeMismatches || []} error={error} loading={loading} />
        <Attachments
          data={{
            extension: attachmentTypeToExtensions || {},
            formats: attachmentFormats || [],
            types: attachmentTypes || [],
          }}
          error={error}
          loading={loading}
        />
      </>
    </>
  );
}
