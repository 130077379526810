"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalStorage = useLocalStorage;
var _Base = _interopRequireDefault(require("Base64"));
var _react = require("react");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// $FlowFixMe
/**
 * Returns a value from `window.localStorage`, if it exists, along with a setting function, much like
 * React's `useState` hook. Values can optionally be base64 encoded.
 */
function useLocalStorage(itemKey, options = {}) {
  const {
    encode = true
  } = options;
  const [foundItem, setFoundItem] = (0, _react.useState)(fetchLocalStorageItem(itemKey, encode));
  (0, _react.useEffect)(() => {
    const storedItem = fetchLocalStorageItem(itemKey, encode);
    setFoundItem(storedItem);
  }, [encode, itemKey]);
  const setItem = value => {
    window.localStorage.setItem(itemKey, encode ? encodeValue(stringifyJson(value)) : stringifyJson(value));
    setFoundItem(value);
  };
  return [foundItem, setItem];
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function decode64(value) {
  try {
    return _Base.default.atob(value);
  } catch (exception) {
    return value;
  }
}
function decodeValue(value) {
  if (value && typeof value === 'string') {
    return decode64(value);
  }
  return value;
}
function encodeOrNot(item, encode) {
  return encode ? decodeValue(item) : item;
}
function encodeValue(value) {
  if (value) {
    return _Base.default.btoa(value);
  }
  return value;
}
function fetchLocalStorageItem(itemKey, encode) {
  try {
    const item = parseJson(decode64(window.localStorage.getItem(itemKey)));
    return item ? encodeOrNot(item, encode) : null;
  } catch (error) {
    return null;
  }
}
function parseJson(value) {
  if (!value) return value;
  try {
    return JSON.parse(value);
  } catch (exception) {
    return value;
  }
}
function stringifyJson(value) {
  try {
    return JSON.stringify(value);
  } catch (exception) {
    return value;
  }
}