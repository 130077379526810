// @flow

import { loader } from 'graphql.macro';

export const insightSimilarDomains = loader('./queries/InsightsSimilarDomainsQuery.graphql');

type GraphTableResultType = {
  label: string,
  value: number,
};

export interface SimilarDomainsList {
  total: number;
  similarDomain: string;
}

export interface SimilarDomainsCounts {
  spoofSimilarDomainsTotal: number;
  spoofTotal: number;
}

export const placeholderData = [
  { label: 'are61.com', value: 11 },
  { label: 'aa1security.com', value: 17 },
  { label: 'aracsecurity.com', value: 2 },
  { label: 'arealsecurity.com', value: 15 },
  { label: 'are-1.com', value: 5 },
];

export function dataForGraphTable(data: Array<SimilarDomainsList>): Array<GraphTableResultType> {
  return data.map((row) => ({ label: row.similarDomain, value: row.total }));
}
