// @flow

import { random } from '@a1s/lib';

import React, { type Node } from 'react';

import TextInput from 'ui/atoms/TextInput';
import Field from 'ui/molecules/Field';

type Props = { dataTestId?: string, id?: string, label?: string, info?: Node, stacked?: boolean };
const Text = (
  { dataTestId = 'molecule-text-field', id = `id${random()}`, info, label, stacked, ...props }: Props,
  ref
) => (
  <Field dataTestId={dataTestId} info={info} label={label} labelFor={id} stacked={stacked}>
    <TextInput id={id} {...props} ref={ref} />
  </Field>
);

Text.displayName = 'Field.Text';

// $FlowIssue
export default React.forwardRef(Text);
