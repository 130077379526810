"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitleText = exports.Label = exports.Input = exports.Container = void 0;
var _styled = require("../../styled");
var _base = require("../../base");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const Container = (0, _styled.styled)('span', {
  display: 'inline',
  '& + &': {
    marginLeft: '1rem'
  }
});
exports.Container = Container;
const Input = (0, _styled.styled)('input', {
  display: 'inlineBlock'
});
exports.Input = Input;
const Label = (0, _styled.styled)('label', {
  color: '$gray600',
  fontSize: '0.875rem',
  marginLeft: '0.5rem',
  marginRight: '1rem'
});
exports.Label = Label;
const TitleText = (0, _styled.styled)(_base.Text, {
  color: '$gray600',
  fontFamily: '$sans',
  lineHeight: 1,
  size: '2.5rem',
  stretch: 'expanded',
  transform: 'uppercase',
  weight: 'medium'
});
exports.TitleText = TitleText;