// @flow
// $FlowFixMe
import { gql, useQuery } from '@apollo/client';
import { useLocalStorage } from '@marshall/hooks';

import { difference, uniq } from 'lodash';
// $FlowFixMe - no Flow types in package
import React, { useCallback, useEffect, useState } from 'react';
// $FlowFixMe - no Flow types in package
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

// $FlowFixMe - no Flow types in Typescript file
import { LOCAL_STORAGE_KEY } from '../../Announcements';

import NotificationsLink from 'ui/atoms/NotificationsLink';

export default function Announcements() {
  const history = useHistory();
  const [announcementIds] = useLocalStorage(LOCAL_STORAGE_KEY, { encode: true });
  const location = useLocation();
  const { data, loading, refetch } = useRemoteData();
  const [modalShown, setModalShown] = useState(false);
  const [unread, setUnread] = useState(false);

  const showModal = useCallback(() => {
    // $FlowFixMe - no Flow types for dataset
    document.body.dataset.closePath = location.pathname;
    history.push({ pathname: `/announcements`, state: { background: location } });
    setModalShown(true);

    // Schedule a refresh of the data sometime after the modal has been shown
    window.setTimeout(() => refetch({}), 5000);
  }, [history, location, refetch]);

  useEffect(() => {
    if (loading) return;

    // See what announcement ids we get from the API
    // $FlowFixMe - Flow does not yet support method or property calls in optional chains
    const fetchedIds = uniq(data?.productAnnouncements.map(({ id }) => id) || []);
    if (fetchedIds.length === 0) return;

    // Check what announcement ids we've already seen
    const ids = uniq(Array.isArray(announcementIds) ? announcementIds : []);

    // See if there is a difference and set unread to true if there is
    setUnread(difference(fetchedIds, ids).length > 0);
  }, [announcementIds, data, loading]);

  useEffect(() => {
    // If there are unread messages show the modal, if it hasn't already been shown
    if (unread && !modalShown) showModal();
  }, [modalShown, showModal, unread]);

  return <NotificationsLink onClick={showModal} unread={unread} />;
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = gql`
  query CheckProductAnnouncements {
    productAnnouncements @rest(endpoint: "announcements", path: "/announcements", type: "ProductAnnouncement") {
      body
      id
      publishedAt
      title
      __typename
    }
  }
`;

function useRemoteData() {
  return useQuery(query);
}
