// @flow

import { format as dateFormat, formatDistanceToNow } from 'date-fns';
import { format as timeZoneFormat } from 'date-fns-tz';
import * as localeObj from 'date-fns/locale';

import countries from 'i18n-iso-countries';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import abbreviate from 'number-abbreviate';
import { createElement, Fragment } from 'react';
import { initReactI18next } from 'react-i18next';
import rehypeReact from 'rehype-react';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

import deConst from './i18n/de-DE/const.json';
import deDE from './i18n/de-DE/index.json';
import enConst from './i18n/en-US/const.json';
import enUS from './i18n/en-US/index.json';
import esConst from './i18n/es-ES/const.json';
import esES from './i18n/es-ES/index.json';
import frConst from './i18n/fr-FR/const.json';
import frFR from './i18n/fr-FR/index.json';
import itConst from './i18n/it-IT/const.json';
import itIT from './i18n/it-IT/index.json';
import jaConst from './i18n/ja-JP/const.json';
import jaJP from './i18n/ja-JP/index.json';
import koConst from './i18n/ko-KR/const.json';
import koKR from './i18n/ko-KR/index.json';
import ptConst from './i18n/pt-BR/const.json';
import ptBR from './i18n/pt-BR/index.json';
/* eslint-disable no-shadow */
import zhCNConst from './i18n/zh-CN/const.json';
import zhCN from './i18n/zh-CN/index.json';
import zhTWConst from './i18n/zh-TW/const.json';
import zhTW from './i18n/zh-TW/index.json';

const resources = {
  de: deDE,
  en: enUS,
  es: esES,
  fr: frFR,
  it: itIT,
  ja: jaJP,
  ko: koKR,
  pt: ptBR,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

i18next
  .use({
    name: 'markdown',
    process(value) {
      const file = unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(remarkRehype)
        .use(rehypeReact, { createElement, Fragment })
        .processSync(value);
      return file.result;
    },
    type: 'postProcessor',
  })
  .use(initReactI18next)
  .use(LanguageDetector)
  .on('languageChanged', async (lng) => {
    const abbr = lng.includes('zh') ? 'zh' : lng;
    const languages = await import(`i18n-iso-countries/langs/${abbr}.json`);
    countries.registerLocale(languages);
  })
  .init({
    // debug: true,
    defaultNS: 'common',
    detection: {
      caches: ['cookie'],
      order: ['querystring', 'cookie'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value: any, format: string, lng: string) => {
        if (value instanceof Date) {
          const locale = getLocaleObject(lng);
          switch (format) {
            case 'distance-in-words-to-now':
              return formatDistanceToNow(value, { locale });
            default:
              if (format === 'zzz') {
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';
                return timeZoneFormat(value, format, { timeZone, locale });
              }
              return dateFormat(value, format, { locale });
          }
        }

        if (format === 'country-code-to-name') return countries.getName(value, lng, { select: 'official' });
        if (format === 'abbreviated-number') return abbreviate(value, 1);
        if (format === 'currency')
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(value);
        if (format === 'duration') return formatDuration(value, lng);
        if (format === 'number') return formatNumber(value, lng);
        if (format === 'percent') return new Intl.NumberFormat(lng, { style: 'percent' }).format(value);
        if (format === 'percent-with-decimal')
          return new Intl.NumberFormat(lng, { maximumFractionDigits: 2, style: 'percent' }).format(value);

        return value;
      },
    },
    ns: 'common',
    react: { wait: true },
    resources,
    saveMissing: true,
    // eslint-disable-next-line no-unused-vars
    missingKeyHandler(lng, ns, key) {
      // Sentry event
    },
  });

i18next.addResourceBundle('de', 'const', deConst, true, false);
i18next.addResourceBundle('en', 'const', enConst, true, false);
i18next.addResourceBundle('es', 'const', esConst, true, false);
i18next.addResourceBundle('fr', 'const', frConst, true, false);
i18next.addResourceBundle('it', 'const', itConst, true, false);
i18next.addResourceBundle('ja', 'const', jaConst, true, false);
i18next.addResourceBundle('ko', 'const', koConst, true, false);
i18next.addResourceBundle('pt', 'const', ptConst, true, false);
i18next.addResourceBundle('zh-CN', 'const', zhCNConst, true, false);
i18next.addResourceBundle('zh-TW', 'const', zhTWConst, true, false);

export default i18next;

export function formatNumber(value: any, lng: string = 'en') {
  return new Intl.NumberFormat(lng, { style: 'decimal' }).format(value);
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function formatDuration(duration: ?Object, lng: string) {
  let display = '';

  if (duration) {
    if (lng === 'de') {
      if (duration.years) display = `${display} ${duration.years} jahre`;
      if (duration.months) display = `${display} ${duration.months} monate`;
      if (duration.days) display = `${display} ${duration.days} tage`;
      if (duration.hours) display = `${display} ${duration.hours} stunden`;
      if (duration.minutes) display = `${display} ${duration.minutes} minuten`;
      if (duration.seconds) display = `${display} ${duration.seconds} sekunden`;
    } else if (lng === 'es') {
      if (duration.years) display = `${display} ${duration.years} años`;
      if (duration.months) display = `${display} ${duration.months} meses`;
      if (duration.days) display = `${display} ${duration.days} días`;
      if (duration.hours) display = `${display} ${duration.hours} horas`;
      if (duration.minutes) display = `${display} ${duration.minutes} minutos`;
      if (duration.seconds) display = `${display} ${duration.seconds} segundos`;
    } else if (lng === 'fr') {
      if (duration.years) display = `${display} ${duration.years} ans`;
      if (duration.months) display = `${display} ${duration.months} mois`;
      if (duration.days) display = `${display} ${duration.days} jours`;
      if (duration.hours) display = `${display} ${duration.hours} heures`;
      if (duration.minutes) display = `${display} ${duration.minutes} minutes`;
      if (duration.seconds) display = `${display} ${duration.seconds} secondes`;
    } else if (lng === 'it') {
      if (duration.years) display = `${display} ${duration.years} anni`;
      if (duration.months) display = `${display} ${duration.months} mesi`;
      if (duration.days) display = `${display} ${duration.days} giorni`;
      if (duration.hours) display = `${display} ${duration.hours} ore`;
      if (duration.minutes) display = `${display} ${duration.minutes} minuti`;
      if (duration.seconds) display = `${display} ${duration.seconds} secondi`;
    } else if (lng === 'ja') {
      if (duration.years) display = `${display} ${duration.years}年`;
      if (duration.months) display = `${display} ${duration.months}ヶ月`;
      if (duration.days) display = `${display} ${duration.days}日`;
      if (duration.hours) display = `${display} ${duration.hours}時間`;
      if (duration.minutes) display = `${display} ${duration.minutes}分`;
      if (duration.seconds) display = `${display} ${duration.seconds}秒`;
    } else if (lng === 'ko') {
      if (duration.years) display = `${display} ${duration.years} 년`;
      if (duration.months) display = `${display} ${duration.months} 개월`;
      if (duration.days) display = `${display} ${duration.days} 일`;
      if (duration.hours) display = `${display} ${duration.hours} 시간`;
      if (duration.minutes) display = `${display} ${duration.minutes} 분`;
      if (duration.seconds) display = `${display} ${duration.seconds} 초`;
    } else if (lng === 'pt') {
      if (duration.years) display = `${display} ${duration.years} anos`;
      if (duration.months) display = `${display} ${duration.months} meses`;
      if (duration.days) display = `${display} ${duration.days} dias`;
      if (duration.hours) display = `${display} ${duration.hours} horas`;
      if (duration.minutes) display = `${display} ${duration.minutes} minutos`;
      if (duration.seconds) display = `${display} ${duration.seconds} segundos`;
    } else if (lng === 'zh-CN') {
      if (duration.years) display = `${display} ${duration.years} 年`;
      if (duration.months) display = `${display} ${duration.months} 个月`;
      if (duration.days) display = `${display} ${duration.days} 天`;
      if (duration.hours) display = `${display} ${duration.hours} 小时`;
      if (duration.minutes) display = `${display} ${duration.minutes} 分钟`;
      if (duration.seconds) display = `${display} ${duration.seconds} 秒`;
    } else if (lng === 'zh-TW') {
      if (duration.years) display = `${display} ${duration.years} 年`;
      if (duration.months) display = `${display} ${duration.months} 個月`;
      if (duration.days) display = `${display} ${duration.days} 天`;
      if (duration.hours) display = `${display} ${duration.hours} 小時`;
      if (duration.minutes) display = `${display} ${duration.minutes} 分鐘`;
      if (duration.seconds) display = `${display} ${duration.seconds} 秒`;
    } else {
      if (duration.years) display = `${display} ${duration.years} years`;
      if (duration.months) display = `${display} ${duration.months} months`;
      if (duration.days) display = `${display} ${duration.days} days`;
      if (duration.hours) display = `${display} ${duration.hours} hours`;
      if (duration.minutes) display = `${display} ${duration.minutes} mins`;
      if (duration.seconds) display = `${display} ${duration.seconds} secs`;
    }
  }

  return display.trim();
}

function getLocaleObject(lng: string) {
  const { de, es, fr, it, ja, ko, pt, zhCN, zhTW } = localeObj;

  switch (lng) {
    case 'de':
      return de;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'it':
      return it;
    case 'ja':
      return ja;
    case 'ko':
      return ko;
    case 'pt':
      return pt;
    case 'zh-CN':
      return zhCN;
    case 'zh-TW':
      return zhTW;
    default:
      return null;
  }
}
