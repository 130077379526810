// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Text = styled.text`
  fill: ${({ theme }) => theme.colors.independence};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(14)};
  text-transform: uppercase;

  ${({ number }) =>
    number &&
    css`
      fill: ${({ theme }) => theme.colors.black};
      font-family: ${({ theme }) => theme.fonts.din};
      font-size: ${rem(32)};
      font-weight: 500;
    `};
`;

export default Text;
