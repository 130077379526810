import { styled, Box, Button, Cluster, Stack } from '@a1s/ui';
import { gql, useQuery } from '@apollo/client';
import { useLocalStorage } from '@marshall/hooks';
import { compareDesc, isValid, parseJSON } from 'date-fns';
import { select } from 'hast-util-select';
import { toText } from 'hast-util-to-text';
// @ts-ignore
import Cookies from 'js-cookie';
import { find, uniq, uniqBy } from 'lodash';
import React, {
  createElement,
  useEffect,
  useState,
  Fragment,
  ImgHTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactEventHandler,
  ComponentProps,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch, Route } from 'react-router-dom';
import rehypeReact from 'rehype-react';
import remarkExtract from 'remark-extract-frontmatter';
import remarkFrontmatter from 'remark-frontmatter';
import remarkGfm from 'remark-gfm';
import remarkParse, { Options } from 'remark-parse';
import remarkRehype from 'remark-rehype';
import toml from 'toml';
import { unified } from 'unified';
import { remove } from 'unist-util-remove';
import voca from 'voca';

import { ReactComponent as BackSVG } from './back.svg';
import { ReactComponent as LogoSVG } from './logo.svg';

import { Dialog, Scrollable, Text } from 'ui-new';

//
// Configuration
// -------------------------------------------------------------------------------------------------

export const LOCAL_STORAGE_KEY = 'seen-announcements-ids';

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function Announcements() {
  const { t } = useTranslation('announcements');
  const history = useHistory();

  function handleClose(event: MouseEvent) {
    event?.stopPropagation();

    if (document.body.dataset.closePath) {
      history.push(document.body.dataset.closePath);
      delete document.body.dataset.closePath;
    } else {
      history.push(Cookies.get('searchLaunchedFrom') || '/home');
    }
  }
  return (
    <Dialog onClose={handleClose as ReactEventHandler} visible>
      <Box css={{ maxHeight: 608, minWidth: 670 }} p="5">
        <Stack gap="4">
          <Cluster align="center" nowrap gap="5">
            <Logo />
            <Divider />
            <Title>Area 1</Title>
          </Cluster>
          <Heading>{t('recentUpdates')}</Heading>
          <MessageView />
          <Footer onClose={handleClose} />
        </Stack>
      </Box>
    </Dialog>
  );
}

//
// Private component
// -------------------------------------------------------------------------------------------------

const FILL = { bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 };

function Back() {
  const history = useHistory();
  const location = useLocation();

  if (location.pathname.length <= 14) return null;

  function handlePress() {
    history.push('/announcements', location.state);
  }

  return (
    <BackButton onClick={handlePress}>
      <BackSVG />
    </BackButton>
  );
}

interface BlurbProps {
  announcement: AnnouncementData;
}

function Blurb({ announcement }: BlurbProps) {
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation('announcements');

  function handlePress() {
    history.push(`/announcements/${announcement.id}`, state);
  }

  return (
    <Box bg="$gray100" px="5" py r>
      <Stack align="start" gap>
        <Stack gap>
          <Stack>
            <Text as="p" color="orange" size="md" weight="bold">
              {announcement.title}
            </Text>

            <Text as="p" color="$gray200" size="sm" transform="uppercase">
              {announcement && t('common:dateFromNow', { date: announcement.publishedAt })}
            </Text>
          </Stack>

          <Text as="div" css={{ 'p + p': { marginTop: '$3' } }} color="$gray600" size="md">
            {announcement.blurb}
          </Text>
        </Stack>
        <Button onPress={handlePress}>{t('view')}</Button>
      </Stack>
    </Box>
  );
}

interface DetailProps {
  announcement?: AnnouncementData;
}

function Detail({ announcement }: DetailProps) {
  const { t } = useTranslation('announcements');

  if (!announcement) return null;

  return (
    <Box bg="$gray100" css={{ ...FILL, zIndex: 2 }} data-detail p r>
      <Scrollable css={{ margin: '$2' }} dark thin>
        <Box p>
          <Stack align="start" gap>
            <Stack gap>
              <Stack>
                <Text as="p" color="orange" size="md" weight="bold">
                  {announcement.title}
                </Text>

                <Text as="p" color="$gray200" size="sm" transform="uppercase">
                  {announcement && t('common:dateFromNow', { date: announcement.publishedAt })}
                </Text>
              </Stack>

              <Text as="div" css={{ 'p + p': { marginTop: '$3' } }} color="$gray600" size="md">
                {announcement.content}
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Scrollable>
    </Box>
  );
}

interface FooterProps {
  onClose: ComponentProps<typeof Button>['onPress'];
}

function Footer({ onClose }: FooterProps) {
  const { t } = useTranslation('announcements');

  return (
    <Cluster align="center" justify="space-between">
      <Back />
      <span />
      <Button onPress={onClose}>{t('close')}</Button>
    </Cluster>
  );
}

function Image({ alt, src, ...props }: ImgHTMLAttributes<HTMLImageElement>) {
  const url = `https://annooucements-worker.area1cloudflare-webapps.workers.dev/${src}`;
  return <EmbeddedImage alt={alt} src={url} {...props} />;
}

interface ListProps {
  announcements: AnnouncementData[];
}
function List({ announcements }: ListProps) {
  if (!announcements.length) return <NoAnnouncements />;

  return (
    <Scrollable css={{ ...FILL, zIndex: 1, '[data-detail] + &': { visibility: 'hidden' } }}>
      <Stack gap>
        {announcements.map((announcement) => (
          <Blurb announcement={announcement} key={announcement.id} />
        ))}
      </Stack>
    </Scrollable>
  );
}

function Loading() {
  const { t } = useTranslation('announcements');

  return (
    <Box css={{ height: 300 }}>
      <Text as="p" color="$gray200" size="sm">
        {t('checking')}
      </Text>
    </Box>
  );
}

function MessageView() {
  const { path } = useRouteMatch();
  const [announcementIds, setAnnouncementIds] = useLocalStorage(LOCAL_STORAGE_KEY, { encode: true });

  const { data, loading } = useRemoteData((ids) => {
    const uniqIds = uniq([...ids, ...(Array.isArray(announcementIds) ? announcementIds : [])]);
    if (setAnnouncementIds && uniqIds.length > 0) setAnnouncementIds(uniqIds);
  });

  if (loading) return <Loading />;

  return (
    <Box css={{ height: 300, position: 'relative' }}>
      <Route
        path={`${path}/:annoucementId`}
        render={({ match: { params } }) => {
          const annooucement = find(data, { id: params.annoucementId });
          return <Detail announcement={annooucement} />;
        }}
      />
      <Route path={`${path}`} render={() => <List announcements={data} />} />
    </Box>
  );
}

function NoAnnouncements() {
  const { t } = useTranslation('announcements');

  return (
    <Stack css={{ height: '100%' }} justify="stretch">
      <Stack align="center" justify="center">
        <Text as="p" color="$gray500" size="sm">
          {t('noAnnouncements')}
        </Text>
      </Stack>
    </Stack>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

async function parseMarkdown(text: string): Promise<[Record<string, unknown>, ReactElement]> {
  const processor = unified()
    .use(remarkParse, { fragment: true } as Options)
    .use(remarkGfm)
    .use(remarkFrontmatter, ['toml'])
    .use(remarkExtract, { remove: true, toml: toml.parse })
    .use(remarkRehype)
    .use((): any => (tree: any, file: any) => {
      const result = select('h1:first-child', tree) as any;
      // eslint-disable-next-line no-param-reassign
      file.data.title = toText(result);
      remove(tree, result);
    })
    .use((): any => (tree: any, file: any) => {
      const result = select('p:not(:has(img))', tree) as any;
      // eslint-disable-next-line no-param-reassign
      file.data.blurb = voca.prune(toText(result), 150, '…');
    })
    .use(rehypeReact, { components: { img: Image }, createElement, Fragment });

  const file = await processor.process(text);
  return [file.data, file.result];
}

function parseTime(text: string): Date {
  const parsed = parseJSON(text);
  if (isValid(parsed)) return parsed;
  return new Date();
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

interface AnnouncementData {
  blurb: string;
  content: ReactElement;
  id: string;
  roles: string[];
  publishedAt: Date;
  title: string;
}

const query = gql`
  query GetProductAnnouncements {
    productAnnouncements @rest(endpoint: "announcements", path: "/announcements", type: "ProductAnnouncement") {
      body
      id
      publishedAt
      title
      __typename
    }
  }
`;

// eslint-disable-next-line no-unused-vars
type CallbackFn = (ids: Array<AnnouncementData['id']>) => void;

function useRemoteData(callback: CallbackFn): { loading: boolean; data: AnnouncementData[] } {
  const { data, loading } = useQuery(query, {
    fetchPolicy: 'cache-first',
    onCompleted({ productAnnouncements }) {
      const ids: Array<AnnouncementData['id']> = uniq(productAnnouncements.map(({ id }: { id: string }) => id));
      callback(ids);
    },
  });
  const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);

  useEffect(() => {
    if (!data?.productAnnouncements) return;

    Promise.all(
      data.productAnnouncements.map(async ({ body, publishedAt, ...rest }: any): Promise<AnnouncementData> => {
        const [extracted, content] = await parseMarkdown(body);
        return { ...rest, ...extracted, content, publishedAt: parseTime(publishedAt) } as AnnouncementData;
      })
    ).then((responses) => {
      const sorted = responses.sort(({ publishedAt: a }, { publishedAt: b }) => compareDesc(a as Date, b as Date));
      setAnnouncements(uniqBy(sorted, 'id'));
    });
  }, [data]);

  return { data: announcements, loading };
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const BackButton = styled('button', {
  background: 'transparent',
  border: 0,
  cursor: 'pointer',

  '& svg': { width: 22 },
  '& svg path': { fill: '$blue400' },
  '&:hover svg path': { fill: '$gray600' },
});

const Divider = styled('div', {
  alignSelf: 'stretch',
  background:
    'linear-gradient(0deg, rgba(136,136,136,0) 0%, rgba(136,136,136,1) 20%, rgba(136,136,136,1) 80%, rgba(136,136,136,0) 100%);',
  minHeight: '100%',
  opacity: 0.5,
  width: 1,
});

const EmbeddedImage = styled('img', {
  height: 'auto',
  maxWidth: '100%',
});

const Heading = styled('h3', {
  background: 'linear-gradient(269.99deg, #1941A5 5.08%, #2196EA 108%)',
  borderRadius: '$2',
  color: '$white',
  fontFamily: '$roboto',
  fontSize: '$xl',
  fontWeight: 300,
  padding: '$4 $5',
});

const Logo = styled(LogoSVG, {
  width: 120,
});

const Title = styled('div', {
  color: '$gray500',
  fontFamily: '$lato',
  fontSize: '$2xl',
  fontWeight: 300,
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});
