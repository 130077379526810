import { toQueryString } from '@a1s/lib';
import { Box, Cluster, HorizontalBars, Loadable, SegmentChart, Stack } from '@a1s/ui';
import { endOfDay, formatISO, startOfDay, subDays } from 'date-fns';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { percentOf } from '../../lib';
import { LabeledValue } from '../../ui';

import useRemoteData, { TotalsData, TopFoldersData } from './useRemoteData';

import { ButtonLink, InfoTooltip, PanelContainer, Text } from 'ui-new';
import { useDuration, Duration } from 'utils/duration';

export function RetractionsPanel() {
  const duration = useDuration();
  const { data, loading } = useRemoteData({ duration });
  const { t } = useTranslation('dashboardHome');

  return (
    <PanelContainer title={t('retractions')} titleDecoration={<Tooltip />}>
      <Box p>
        <Cluster align="start" gap="3" justify="stretch">
          <Loadable loading={loading}>
            <Total total={data.totals.attempts} />
            <Graphs totals={data.totals} />
            <TopFolders data={data.topFolders} />
            <Controls duration={duration} />
          </Loadable>
        </Cluster>
      </Box>
    </PanelContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ControlsType {
  duration: Duration;
}

function Controls({ duration: daysBack }: ControlsType) {
  const { t } = useTranslation('dashboardHome');

  const dateRange = [startOfDay(subDays(new Date(), Number.parseInt(daysBack, 10))), endOfDay(new Date())];

  const searchUrlParams = {
    end: formatISO(dateRange[1]),
    enableRedress: true,
    finalDisposition: '',
    redress: 'RETRACTION',
    searchMode: 'fielded',
    searchType: 'all-mail',
    start: formatISO(dateRange[0]),
  };

  return (
    <Stack align="end" gap justify="end">
      <ButtonLink to={`/beta/search?${toQueryString(searchUrlParams)}`}>{t('viewDetails')}</ButtonLink>
    </Stack>
  );
}

interface GraphsType {
  totals: TotalsData;
}

function Graphs({ totals }: GraphsType) {
  const { t } = useTranslation('dashboardHome');

  const { attempts: total, auto, failed, manual, read, success, unread } = totals;

  return (
    <Box bg="$gray100" css={{ alignSelf: 'stretch' }} p r>
      <Cluster css={{ height: '100%' }} gap={3} justify="stretch">
        <SegmentChart.Loadable placeholderCount={2} width="15">
          <SegmentChart.Segment
            color={setBackgroundColor('teal650', percentOf(success, total))}
            value={percentOf(success, total)}
          >
            <LabeledValue childrenColor="$gray800" gap="2" value={`${percentOf(success, total)}%`} weight="medium">
              {t('success')}
            </LabeledValue>
          </SegmentChart.Segment>

          <SegmentChart.Segment
            color={setBackgroundColor('pink500', percentOf(failed, total))}
            value={percentOf(failed, total)}
          >
            <LabeledValue childrenColor="$gray800" gap="2" value={`${percentOf(failed, total)}%`} weight="medium">
              {t('fail')}
            </LabeledValue>
          </SegmentChart.Segment>
        </SegmentChart.Loadable>

        <SegmentChart.Loadable placeholderCount={2} width="15">
          <SegmentChart.Segment
            color={setBackgroundColor('teal650', percentOf(unread, total))}
            value={percentOf(unread, total)}
          >
            <LabeledValue childrenColor="$gray800" gap="2" value={`${percentOf(unread, total)}%`} weight="medium">
              {t('unread')}
            </LabeledValue>
          </SegmentChart.Segment>

          <SegmentChart.Segment
            color={setBackgroundColor('pink500', percentOf(read, total))}
            value={percentOf(read, total)}
          >
            <LabeledValue childrenColor="$gray800" gap="2" value={`${percentOf(read, total)}%`} weight="medium">
              {t('read')}
            </LabeledValue>
          </SegmentChart.Segment>
        </SegmentChart.Loadable>

        <SegmentChart.Loadable placeholderCount={2} width="15">
          <SegmentChart.Segment
            color={setBackgroundColor('teal650', percentOf(auto, total))}
            value={percentOf(auto, total)}
          >
            <LabeledValue childrenColor="$gray800" gap="2" value={`${percentOf(auto, total)}%`} weight="medium">
              {t('auto')}
            </LabeledValue>
          </SegmentChart.Segment>

          <SegmentChart.Segment
            color={setBackgroundColor('pink500', percentOf(manual, total))}
            value={percentOf(manual, total)}
          >
            <LabeledValue childrenColor="$gray800" gap="2" value={`${percentOf(manual, total)}%`} weight="medium">
              {t('manual')}
            </LabeledValue>
          </SegmentChart.Segment>
        </SegmentChart.Loadable>
      </Cluster>
    </Box>
  );
}

interface TopFoldersType {
  data: TopFoldersData[];
}

function TopFolders({ data }: TopFoldersType) {
  const { t } = useTranslation('dashboardHome');
  const total = data.reduce((m, { total: count }) => m + count, 0);

  return (
    <Stack css={{ maxWidth: 200 }} gap="1" justify="stretch">
      <Text color="$gray800" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="semibold">
        {t('topFolders')}
      </Text>
      <HandleNoData data={data}>
        <HorizontalBars gap="2">
          {data.map((folder) => (
            <HorizontalBars.Bar color="$gray100" key={folder.name} value={percentOf(folder.total, total)}>
              <LabeledValue childrenColor="$gray800" gap="2" value={folder.total} weight="medium">
                <Text transform="none">{t(folder.name)}</Text>
              </LabeledValue>
            </HorizontalBars.Bar>
          ))}
        </HorizontalBars>
      </HandleNoData>
    </Stack>
  );
}

interface HandleNoDataType {
  data: TopFoldersData[];
}

function HandleNoData({ children, data }: PropsWithChildren<HandleNoDataType>) {
  const { t } = useTranslation('dashboardHome');

  if (data?.length === 0) {
    return (
      <Text color="$gray400" size="sm" stretch="ultraCondensed">
        {t('noDataToDisplay')}
      </Text>
    );
  }

  return <>{children}</>;
}

function Tooltip() {
  const { t } = useTranslation('dashboardHome');
  return <InfoTooltip>{t('tooltipCopy.retractions', { postProcess: 'markdown' })}</InfoTooltip>;
}

interface TotalType {
  total: number;
}

function Total({ total }: TotalType) {
  const { t } = useTranslation('dashboardHome');

  return (
    <Stack css={{ maxWidth: 150 }} gap justify="stretch">
      <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="regular">
        {t('totalRetractions')}
      </Text>
      <Text as="div" color="$gray600" font="lato" lineHeight="1" size="4xl" stretch="expanded" weight="light">
        {total}
      </Text>
    </Stack>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function setBackgroundColor(color: string, percentValue = '0', fallbackColor = 'gray200') {
  if (Number(percentValue) > 0) return color;
  return fallbackColor;
}
