import { Box, Button, Cluster, Stack, Text } from '@a1s/ui';
import { ApolloError } from '@apollo/client';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorDialog } from './ErrorDialog';

import { useGetCfAccounts } from './hooks/useGetCfAccounts';
import { useLinkCfAccount } from './hooks/useLinkCfAccount';
import { LinkButton } from './LinkButton';
import { CfAccountType } from './shared/types';
import { P, Title } from './shared/typography';
import { SuccessDialog } from './SuccessDialog';

import { CloseButton, Dialog } from 'ui-new';

import { permissionTypes, PermissionsType } from 'utils/AccessControl';
import useAccessControl from 'utils/hooks/useAccessControl';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';

export default function LinkCfAccount() {
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();
  const { t } = useTranslation('linkCfAccount');
  const [selectedAccount, setSelectedAccount] = useState<CfAccountType | undefined>();
  const [showLinkModal, setShowLinkModal] = useState(true);
  const [linkSuccess, setLinkSuccess] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>();

  const { data, error: getCfAccountsError, loading: getCfAccountsLoading } = useGetCfAccounts();
  const [linkAccount, { called: linkCfCalled, error: linkCfAccountError, loading: linkCfAccountLoading }] =
    useLinkCfAccount();

  // Only super admins can view this
  const { ADMIN } = permissionTypes;
  const { loading: accessControlLoading, permissions } = useAccessControl(ADMIN as PermissionsType);
  const adminPermitted = permissions;

  useEffect(() => {
    if (!!getCfAccountsError || !!linkCfAccountError) setError(getCfAccountsError || linkCfAccountError);
  }, [getCfAccountsError, linkCfAccountError]);

  useEffect(() => {
    if (linkCfCalled && !linkCfAccountLoading && !linkCfAccountError) setLinkSuccess(true);
  }, [linkCfAccountError, linkCfAccountLoading, linkCfCalled]);

  const handleClose = (event: SyntheticEvent) => {
    event.preventDefault();
    setShowLinkModal(false);
  };

  const handleButtonPress = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!selectedAccount) return;

    /* eslint-disable camelcase */
    try {
      const { id: account_id } = selectedAccount;
      await linkAccount({ variables: { input: { account_id } } });
      // eslint-disable-next-line no-shadow
    } catch (error) {
      setError(error as ApolloError);
    }
    /* eslint-enable camelcase */
  };

  const handleRadioClick = (account: CfAccountType) => {
    setError(undefined);
    setSelectedAccount(account);
  };

  // Short-circuit component if any of the following conditions are met
  if (accessControlLoading || !adminPermitted) return null;
  if (currentUserLoading || getCfAccountsLoading) return null;
  if (currentUser?.cfAccountId || currentUser?.isParent) return null;

  return (
    <>
      <LinkButton accounts={data?.accounts} handleClick={() => setShowLinkModal(true)} />

      {data?.accounts?.length > 0 && (
        <>
          <ErrorDialog account={selectedAccount} error={error} />
          <SuccessDialog account={selectedAccount} success={linkSuccess} />

          <Dialog maxWidth onClose={handleClose} visible={!linkSuccess && showLinkModal}>
            <Box p>
              <Stack gap="4">
                <Cluster gap>
                  <Title>{t('linkAccountTitle')}</Title>
                  <CloseButton onPress={handleClose} />
                </Cluster>
                <Stack gap="3">
                  <P>{t('linkAccountDescription')}</P>
                  <AccountRadioButtons
                    accounts={data.accounts}
                    handleRadioClick={handleRadioClick}
                    selectedAccount={selectedAccount}
                  />
                </Stack>
                <Cluster justify="space-between">
                  <Button
                    appearance="primary"
                    disabled={linkCfAccountLoading || !selectedAccount}
                    onPress={handleButtonPress}
                  >
                    {t('linkAccountButton')}
                  </Button>
                  <Button onPress={handleClose}>{t('cancel')}</Button>
                </Cluster>
              </Stack>
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
}

const AccountRadioButtons = ({
  accounts,
  handleRadioClick,
  selectedAccount,
}: {
  accounts: CfAccountType[];
  // eslint-disable-next-line no-unused-vars
  handleRadioClick: (account: CfAccountType) => void;
  selectedAccount?: CfAccountType;
}) => {
  return (
    <Stack gap={2} justify="center">
      {accounts.map((account: { id: string; name: string }) => {
        return (
          <Cluster gap={2}>
            <input
              checked={account.id === selectedAccount?.id}
              id={account.id}
              name="accountId"
              onChange={() => handleRadioClick(account)}
              type="radio"
              value={account.id}
            />
            <P>
              <label htmlFor={account.id}>
                <Text weight="bold">{account.name}</Text> ({account.id})
              </label>
            </P>
          </Cluster>
        );
      })}
    </Stack>
  );
};
