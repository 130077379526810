"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fonts = void 0;
const fonts = {
  lato: 'Lato, apple-system, sans-serif',
  roboto: 'Roboto, apple-system, sans-serif',
  robotoMono: '"Roboto Mono", monospace',
  sans: '$roboto',
  monospace: '$robotoMono'
};
exports.fonts = fonts;