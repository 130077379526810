"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledButton = void 0;
var _styled = require("../../styled");
const StyledButton = (0, _styled.styled)('button', {
  $$color: '$colors$blue500',
  $$size: '$space$4',
  backgroundColor: '$white',
  borderColor: '$$color',
  borderRadius: '$full',
  borderStyle: '$solid',
  borderWidth: '$1',
  color: '$$color',
  fontFamily: '$sans',
  fontSize: '$xs',
  fontWeight: '450',
  height: '$$size',
  lineHeight: '$$size',
  textAlign: 'cener',
  textTransform: 'uppercase',
  width: '$$size',
  '&:hover': {
    $$color: '$colors$blue600',
    backgroundColor: '$gray50'
  },
  '&:active': {
    $$color: '$colors$gray600',
    backgroundColor: '$white'
  },
  '&:focus': {
    $$color: '$colors$blue300',
    backgroundColor: '$gray100'
  }
});
exports.StyledButton = StyledButton;