// @flow

import { rem, rgba } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(6)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  display: block;
  margin: ${({ theme, wide }) => rem(wide ? 0 : theme.spacing.lg)};
  margin-bottom: ${({ theme }) => rem(theme.spacing.md)};
  overflow: hidden;
  position: sticky;
  top: ${rem(80)};
  width: ${rem(194)};
`;

Container.displayName = 'Container';

export default Container;
