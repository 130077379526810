"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggle = void 0;
const toggle = {
  hide: {
    transition: 'visibility 0s linear 300ms, opacity 300ms $quick',
    opacity: 0,
    visibility: 'hidden'
  },
  show: {
    opacity: 1,
    transition: 'visibility 0s linear 0s, opacity 300ms $quick',
    visibility: 'visible'
  }
};
exports.toggle = toggle;