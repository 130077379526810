"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledSwitcher = void 0;
var _styled = require("../../styled");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledSwitcher = (0, _styled.styled)('div', {
  $$threshold: '$sizes$lg',
  display: 'flex',
  flexWrap: 'wrap',
  '& > *': {
    // The flex-basis value enters the (current) width of the container, expressed as 100%, into a
    // calculation with the designated `$$threshold` breakpoint. Depending on the parsed value of 100%,
    // this will return either a positive or negative value: positive if the container is narrower than
    // $$threshold, or negative if it is wider. This number is then multiplied by 999 to produce either
    // a very large positive number or a very large negative number:
    flexBasis: 'calc(($$threshold - 100%) * 999)',
    flexGrow: 1
  },
  variants: {
    /**
     * The amount of horizontal _and_ vertical space to insert between the child components.
     * @type $space
     */
    gap: {
      true: {
        gap: '$3'
      },
      none: {
        gap: '$none'
      },
      px: {
        gap: '$px'
      },
      0.5: {
        gap: '$0.5'
      },
      1: {
        gap: '$1'
      },
      1.5: {
        gap: '$1.5'
      },
      2: {
        gap: '$2'
      },
      2.5: {
        gap: '$2.5'
      },
      3: {
        gap: '$3'
      },
      3.5: {
        gap: '$3.5'
      },
      4: {
        gap: '$4'
      },
      5: {
        gap: '$5'
      },
      6: {
        gap: '$6'
      },
      7: {
        gap: '$7'
      },
      8: {
        gap: '$8'
      },
      9: {
        gap: '$9'
      },
      10: {
        gap: '$10'
      },
      11: {
        gap: '$11'
      },
      12: {
        gap: '$12'
      }
    }
  }
});
exports.StyledSwitcher = StyledSwitcher;