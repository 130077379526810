import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import createPersistedState from 'use-persisted-state';

import { Dispositions, FNReportParams, FPReportParams } from '../types';

//
// Report False-Negative Hook
// -------------------------------------------------------------------------------------------------
const FN_REPORT_MUTATION = gql`
  mutation ReportFalseNegative($input: ReportFalsePositiveParams!) {
    reportFalseNegative(input: $input)
      @rest(method: "POST", path: "/papillon/internal/phish-submission/report_fn", type: "ReportFalseNegativeResult") {
      clientName
      comment
      emlBase64
      expectedDisposition
      messageId
      postfixId
      recipientEmails
      requestingUser
      submissionMessageId
    }
  }
`;

interface ReportFalseNegativeMutationParams extends FNReportParams {
  emlBase64?: string;
  expectedDisposition: Dispositions;
  messageId: string;
  postfixId: string;
  recipientEmails: string[];
}

/**
 * The response from Papillion retuns back the data that was sent to it, along with an
 * id for the submission.
 */
interface ReportFalseNegativeMutationResult extends ReportFalseNegativeMutationParams {
  submissionMessageId: string;
}

const FN_DEFAULT_DISPOSITION = 'MALICIOUS';

export function useReportFalseNegativeMutation(params?: ReportFalseNegativeMutationParams) {
  const options: MutationHookOptions<any, { input: ReportFalseNegativeMutationParams }> = {};
  if (params) {
    const { expectedDisposition = FN_DEFAULT_DISPOSITION, messageId, postfixId, recipientEmails } = params;
    options.variables = {
      input: {
        expectedDisposition,
        messageId,
        postfixId,
        recipientEmails,
      },
    };
  }

  return useMutation<
    { reportFalseNegative: ReportFalseNegativeMutationResult },
    { input: ReportFalseNegativeMutationParams }
  >(FN_REPORT_MUTATION, options);
}

//
// Report False-Positive Hook
// -------------------------------------------------------------------------------------------------

const REPORT_MUTATION = gql`
  mutation ReportFalsePositive($input: ReportFalsePositiveParams!) {
    reportFalsePositive(input: $input)
      @rest(
        method: "POST"
        path: "/papillon/internal/phish-submission/misdetection"
        type: "ReportFalsePositiveResult"
      ) {
      alertId
      clientName
      clientUuid
      comment
      submissionMessageId
    }
  }
`;

interface ReportFalsePositiveMutationParams extends FPReportParams {
  comment?: string;
  expectedDisposition?: Dispositions | 'BENIGN';
}

/**
 * The response from Papillion retuns back the data that was sent to it, along with an
 * id for the submission.
 */
interface ReportFalsePositiveMutationResult extends ReportFalsePositiveMutationParams {
  submissionMessageId: string;
}

const FP_DEFAULT_DISPOSITION = 'BENIGN';

export function useReportFalsePositiveMutation(params?: ReportFalsePositiveMutationParams) {
  const options: MutationHookOptions<any, { input: ReportFalsePositiveMutationParams }> = {};
  if (params) {
    const { alertId, expectedDisposition = FP_DEFAULT_DISPOSITION } = params;
    options.variables = { input: { alertId, expectedDisposition } };
  }

  return useMutation<
    { reportFalsePositive: ReportFalsePositiveMutationResult },
    { input: ReportFalsePositiveMutationParams }
  >(REPORT_MUTATION, options);
}

//
// Reported Alert IDs Hook
// -------------------------------------------------------------------------------------------------

type AlertList = string[];

const useAlertIDs = createPersistedState<AlertList>('alert-ids');

// eslint-disable-next-line no-unused-vars
export function useReportedAlerts(): [AlertList, (id: string) => void] {
  const [alertIds, setAlertIds] = useAlertIDs([]);
  return [alertIds, (id: string) => setAlertIds((ids) => [...ids, id])];
}
