import { Cluster, ClusterProps, Separator, Text, TextProps } from '@a1s/ui';
import React, { ReactNode } from 'react';

//
// Main component
// -------------------------------------------------------------------------------------------------

export interface LabeledValueProps {
  children: ReactNode;
  childrenColor?: TextProps['color'];
  gap?: ClusterProps['gap'];
  transform?: TextProps['transform'];
  value: ReactNode;
  valueColor?: TextProps['color'];
  weight?: TextProps['weight'];
}

export function LabeledValue({
  children,
  childrenColor = '$gray600',
  gap = '3',
  transform = 'uppercase',
  value,
  valueColor = '$gray400',
  weight = 'normal',
}: LabeledValueProps) {
  const displayValue = typeof value === 'number' ? new Intl.NumberFormat(undefined).format(value) : value;

  return (
    <Cluster align="center" gap={gap} justify="start">
      <Text color={childrenColor} font="sans" size="sm" stretch="ultraCondensed" transform={transform} weight={weight}>
        {children}
      </Text>

      <Separator color="gray400" />

      <Text color={valueColor} font="sans" size="sm" stretch="ultraCondensed" transform={transform} weight="light">
        {displayValue}
      </Text>
    </Cluster>
  );
}
