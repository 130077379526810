// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import NoAccess from 'ui/molecules/NoAccess';
import useAccessControl, { permissionTypes, type PermissionsType } from 'utils/hooks/useAccessControl';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface Props {
  allowed?: PermissionsType | PermissionsType[];
  denied?: PermissionsType | PermissionsType[];
  canAccess?: (permissions: boolean & boolean[]) => boolean;
  children: React$Node;
  renderNoAccess?: any;
}

export default function AccessChecker({ allowed, denied, children, ...props }: Props) {
  const { canAccess = defaultCanAccess, renderNoAccess = () => <DefaultNoAccessComponent /> } = props;

  const { loading, permissions } = useAccessControl(allowed || denied || []);

  if (loading) return null;

  if (!!allowed && !canAccess(permissions)) return renderNoAccess && renderNoAccess();
  if (!!denied && canAccess(permissions)) return renderNoAccess && renderNoAccess();

  return children;
}

export { permissionTypes };

//
// Private components
// -------------------------------------------------------------------------------------------------

function DefaultNoAccessComponent() {
  const { t } = useTranslation('components');
  return <NoAccess>{t('NoAccess.needAccessFromAdmin')}</NoAccess>;
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function defaultCanAccess(permissions: boolean | boolean[]) {
  if (Array.isArray(permissions)) return !permissions.includes(false);
  return permissions;
}

export const canAccessOptions = {
  any: (permissions: boolean[]) => permissions.includes(true),
  negation: (permissions: boolean[]) => permissions.includes(false),
};
