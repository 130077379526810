// @flow

/**
 * EllipsisTextTooltip Component
 *
 * Displays a truncated version of text with a width specified
 * and shows a tooltip with full text on hover
 */

// $FlowFixMe
import React, { useCallback, useEffect, useRef, useState, type Node } from 'react';

import Container from './Container';

import Tooltip from 'ui/atoms/Tooltip';
import TruncateText from 'ui/atoms/TruncateText';
import Copy from 'ui/molecules/Copy';

type Props = {
  children: Node,
  dataTestId?: string,
  disableCopy?: boolean,
  tooltipPosition?: 'bottom' | 'left' | 'right' | 'top',
  width?: number,
};

export default function TextEllipsisTooltip({
  children,
  dataTestId = 'molecule-text-ellipsis-tooltip',
  disableCopy = false,
  tooltipPosition,
  width,
}: Props) {
  const [anchor, setAnchor] = useState(null);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const [tooltipShown, setTooltipShown] = useState(false);
  const tooltipRef = useRef(null);

  const hideTooltip = (event) => {
    event.preventDefault();
    const timeout = setTimeout(() => setTooltipShown(false), 1000);
    setCloseTimeout(timeout);
  };

  const handleClick = useCallback(
    (event) => {
      const { target } = event;
      if (target !== tooltipRef.current.ref) {
        clearTimeout(closeTimeout);
        setTooltipShown(false);
      }
    },
    [closeTimeout]
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [handleClick]);

  const showTooltip = (event) => {
    event.preventDefault();
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    setTooltipShown(true);
  };

  return (
    <Container data-testid={dataTestId}>
      <TruncateText
        ref={(ref) => setAnchor(ref)}
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onMouseOut={hideTooltip}
        onMouseOver={showTooltip}
        useEllipsis
        width={width}
      >
        {children}
      </TruncateText>
      <Tooltip
        anchor={anchor}
        position={tooltipPosition}
        onBlur={hideTooltip}
        onFocus={showTooltip}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        ref={tooltipRef}
        visible={tooltipShown}
      >
        {disableCopy ? <>{children}</> : <Copy delay={5000}>{children}</Copy>}
      </Tooltip>
    </Container>
  );
}
