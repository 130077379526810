import { parseDuration } from '@a1s/lib';
import { Text } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface UpdatesDisplayProps {
  /**
   * A string in [ISO 8601 duration format](https://en.wikipedia.org/wiki/ISO_8601#Durations).
   */
  duration: string;
}

/**
 * Used for displaying how often a panel is updated (i.e. "Updates every 5 minutes").
 */
export function UpdatesDisplay({ duration }: UpdatesDisplayProps) {
  const { t } = useTranslation('common');

  return (
    <Text.Lato color="$gray700" size="xs" tracking="wide" transform="uppercase">
      <time dateTime={duration}>{t('updatesEvery', { duration: parseDuration(duration) })}</time>
    </Text.Lato>
  );
}
