// @flow

import { rem, rgba } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.topaz, 0.3)};
  border-radius: ${rem(4)};
  display: inline-flex;
  grid-template-columns: ${rem(48)} 1fr;
  padding: ${({ theme }) => rem(theme.spacing.xs)};
`;

export default Container;
