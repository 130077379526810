// @flow

import { AxisBottom, AxisLeft } from '@vx/axis';

import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { BarStack } from '@vx/shape';
import { max } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { rgba } from 'polished';
import React, { PureComponent } from 'react';
import { Translation } from 'react-i18next';
import ResizeObserver from 'resize-observer-polyfill';
import { withTheme } from 'styled-components';

import colors from './colorSchemes';
import Container from './Container';
import Legend from './Legend';
import { HEIGHT, PADDING, X_OFFSET, Y_OFFSET, type Data } from './util';

import type { Theme } from 'config/theme';
import { Grid } from 'ui/atoms/SVGGrid';

type ColorSchemes = 'detections' | 'sea' | 'graphite' | 'lavender';

type Props = {
  colorScheme?: ColorSchemes,
  data: Data[],
  dataTestId?: string,
  placeholder?: boolean,
  placeholderText?: string,
  theme: Theme,
  withoutLegend: boolean,
};
type State = { width: number };

class StackedBarGraph extends PureComponent<Props, State> {
  static mungeData = (data: Data[]) =>
    data
      .sort((a, b) => a.date - b.date)
      .map((row) => ({
        date: row.date,
        ...row.keys.reduce((obj, key, i) => ({ ...obj, [key]: row.values[i] }), {}),
      }));

  container: ?HTMLDivElement = null;

  resizeObserver = null;

  state = { width: 400 };

  componentDidMount() {
    if (!this.container) return;

    this.setState({ width: this.container.offsetWidth });

    this.resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      entries.forEach((entry) => this.setState({ width: entry.contentRect.width }));
    });

    // $FlowFixMe
    this.resizeObserver.observe(this.container);
  }

  componentWillUnmount() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  assignRef = (ref: any) => {
    this.container = ref;
  };

  render() {
    const {
      colorScheme = 'sea',
      data,
      dataTestId = 'organism-stacked-bar-graph',
      placeholder = false,
      placeholderText = 'components:sampleData',
      theme,
      withoutLegend = false,
    } = this.props;
    const { width } = this.state;

    const formattedData = StackedBarGraph.mungeData(data);

    const keys = formattedData.length > 0 ? Object.keys(formattedData[0]).filter((d) => d !== 'date') : [];
    const format = timeFormat('%b %d');
    const formatDate = (date) => format(date);

    // tickValues
    const sortedDates = formattedData.sort((a, b) => a.date - b.date).map(({ date }) => date);
    const beginDate = sortedDates[0];
    const endDate = sortedDates[sortedDates.length - 1];
    let medianDate;
    let tickValues;
    if (sortedDates.length > 2) {
      medianDate = sortedDates[Math.floor(sortedDates.length / 2)];
      tickValues = [beginDate, medianDate, endDate];
    } else {
      tickValues = [beginDate, endDate];
    }

    // accessors
    const x = (d) => d.date;

    const totals = formattedData.reduce((ret: any, cur) => {
      const t = keys.reduce((dailyTotal: any, k) => {
        // eslint-disable-next-line no-param-reassign
        dailyTotal += +cur[k];
        return dailyTotal;
      }, 0);
      ret.push(t);
      return ret;
    }, []);

    // bounds
    const xMax = width;
    const yMax = HEIGHT - Y_OFFSET;

    // scales
    const xScale = scaleBand({
      rangeRound: [0, xMax],
      domain: formattedData.map(x),
      padding: 0.2,
      tickFormat: () => (val) => formatDate(val),
    });

    const yScale = scaleLinear({
      rangeRound: [yMax, 0],
      nice: true,
      domain: [0, max(totals)],
    });

    let zScale = scaleOrdinal({
      domain: keys,
      range: colors[colorScheme],
    });

    // This is to map our set disposition colors to the right stack
    if (colorScheme === 'detections') {
      const domainKeys = Object.keys(colors[colorScheme]);

      zScale = scaleOrdinal({
        domain: domainKeys,
        range: domainKeys.map((domainKey) => colors[colorScheme][domainKey]),
      });
    }

    /* eslint-disable no-shadow */
    return (
      <Translation ns="const">
        {(t) => (
          <div ref={this.assignRef}>
            <Container
              data-testid={dataTestId}
              placeholder={placeholder ? 'true' : undefined}
              placeholderText={t(placeholderText)}
            >
              <svg width={width} height={HEIGHT + PADDING}>
                <Group top={PADDING}>
                  <Group>
                    <Grid
                      left={X_OFFSET + 12}
                      numTicks={4}
                      scale={yScale}
                      stroke={theme.colors.tuscanBrown}
                      width={width - X_OFFSET}
                    />
                  </Group>
                  <Group left={X_OFFSET / 2}>
                    <BarStack
                      top={0}
                      data={formattedData}
                      keys={keys}
                      height={yMax}
                      left={5}
                      x={x}
                      xScale={xScale}
                      yScale={yScale}
                      zScale={zScale}
                    />
                    <AxisBottom
                      scale={scaleBand({
                        rangeRound: [0, xMax],
                        domain: formattedData.map(x),
                        padding: 0.2,
                        tickFormat: () => (val) =>
                          t('DatePicker.dateShortMonthShortDayNoYear', { date: new Date(val) }),
                      })}
                      top={yMax}
                      stroke={theme.colors.glitter}
                      tickStroke={theme.colors.glitter}
                      tickLabelProps={(_, i) => ({
                        dy: 3,
                        fill: rgba(theme.colors.raisinBlack, 0.5),
                        fontSize: 10,
                        fontFamily: theme.fonts.roboto,
                        fontWeight: 500,
                        textAnchor: textAnchorForIndex(i, Math.max(formattedData.length - 1)),
                      })}
                      tickValues={tickValues}
                    />
                  </Group>

                  <AxisLeft
                    left={X_OFFSET + 12}
                    hideAxisLine
                    hideTicks
                    hideZero
                    numTicks={4}
                    scale={yScale}
                    tickLabelProps={() => ({
                      dy: 3,
                      dx: 5,
                      fill: rgba(theme.colors.raisinBlack, 0.5),
                      fontSize: 10,
                      fontFamily: theme.fonts.roboto,
                      fontWeight: 500,
                      textAnchor: 'end',
                    })}
                  />
                </Group>
              </svg>
              {!withoutLegend && (
                <Legend
                  scale={scaleOrdinal({
                    domain: keys.map((el) => t(el)),
                    range: colors[colorScheme],
                  })}
                  top={Y_OFFSET}
                />
              )}
            </Container>
          </div>
        )}
      </Translation>
    );
  }
}

export default withTheme(StackedBarGraph);

function textAnchorForIndex(index, numItems) {
  if (numItems > 15) {
    if (index === 0) return 'start';
    if (index === 2) return 'end';
  }

  return 'middle';
}
