// @flow

import { camelCase, transformKeys } from '@a1s/lib';
import axios from 'axios';
// $FlowFixMe
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

import getPermissionLevel from 'utils/permissions';

const Context = createContext({});

export function useCurrentUser() {
  return useContext(Context);
}

interface CurrentUserProviderProps {
  children: ReactNode;
}

export function CurrentUserProvider({ children }: CurrentUserProviderProps) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(defaultRecord);

  useEffect(
    () => {
      async function fetchUser() {
        try {
          const { data } = await axios.get('/api/users/');
          setLoading(!data);
          if (data) {
            setUser({
              ...user,
              ...transformKeys(data, camelCase),
              isAuthenticated: true,
              requestComplete: true,
            });
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`💣 Error fetching user: ${error}`);
        }
      }
      fetchUser();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUser]
  );

  return <Context.Provider value={{ user, setUser, loading, setLoading }}>{children}</Context.Provider>;
}

/*
  This is USER shape
*/
export type UserType = {
  accountType: 'Advantage' | 'Enterprise' | 'Internal' | 'Parent' | 'Partner' | 'POV',
  apiEnabled: boolean,
  autoRetractEnabled: boolean,
  bettermailEnabled: boolean,
  bettermailOnly: boolean,
  blockSubdomains: boolean,
  botCode: string,
  brsEnabled: boolean,
  childPermission: Array<string> | string,
  delegatedAccountsEnabled: boolean,
  delegatedRolesEnabled: boolean,
  createdAt: number,
  customerCreatedAt: number,
  customerId: string,
  customerName: string,
  directoriesEnabled: boolean,
  email: string,
  firstName: string,
  lastModified: number,
  lastName: string,
  lockoutEnds: number,
  loginCount: number,
  maliciousBecEnabled: boolean,
  mailsearchEnabled: boolean,
  mailstreamEnabled: boolean,
  mailtraceEnabled: boolean,
  parentPermission: Array<string> | string,
  parentUserId: number,
  passwordLastModified: number,
  phishguardEnabled: boolean,
  role: string,
  searchEnabled: boolean,
  settingsReadOnly: boolean,
  showEula: boolean,
  showOnboarding: boolean,
  userId: number,
};

export type FetchUserResponse = {
  account_type: 'Advantage' | 'Enterprise' | 'Internal' | 'Parent' | 'Partner' | 'POV',
  api_enabled: boolean,
  auto_retract_enabled: boolean,
  bettermail_enabled: boolean,
  bettermail_only: boolean,
  block_subdomains: boolean,
  bot_code: string,
  brs_enabled: boolean,
  created_at: number,
  customer_created_at: number,
  customer_id: string,
  customer_name: string,
  customer_permissions: Array<string | mixed>,
  directories_enabled: boolean,
  dns_rpz_enabled: boolean,
  email: string,
  first_name: string,
  is_parent: boolean,
  is_phishguard_enabled: boolean,
  last_modified: number,
  last_name: string,
  lockout_ends: number,
  login_count: number,
  malicious_bec_enabled: boolean,
  mailsearch_enabled: boolean,
  mailstream_enabled: boolean,
  mailtrace_enabled: boolean,
  password_last_modified: number,
  parent_user_id: number,
  role: string,
  search_enabled: boolean,
  show_eula: boolean,
  show_onboarding: boolean,
  user_id: number,
  user_permissions: string,
};

const defaultRecord: UserType = {
  accountType: 'Advantage',
  apiEnabled: false,
  autoRetractEnabled: false,
  bettermailEnabled: false,
  bettermailOnly: false,
  blockSubdomains: false,
  botCode: '',
  brsEnabled: false,
  childPermission: [],
  delegatedAccountsEnabled: false,
  delegatedRolesEnabled: false,
  createdAt: new Date(),
  customerCreatedAt: new Date(),
  customerId: '',
  customerName: '',
  directoriesEnabled: false,
  email: '',
  firstName: '',
  inviteSent: false,
  isAuthenticated: false,
  phishguardEnabled: false,
  lastModified: new Date(),
  lastName: '',
  lockoutEnds: 0,
  loginCount: 0,
  maliciousBecEnabled: false,
  mailsearchEnabled: false,
  mailstreamEnabled: false,
  mailtraceEnabled: false,
  parentPermission: [],
  parentUserId: 0,
  passwordLastModified: new Date(),
  requestComplete: false,
  role: 'read only',
  searchEnabled: false,
  settingsReadOnly: false,
  showEula: false,
  showOnboarding: false,
  userId: 0,
};

export function transformData(data: FetchUserResponse): UserType {
  return {
    accountType: data.account_type || defaultRecord.accountType,
    apiEnabled: data.api_enabled || defaultRecord.apiEnabled,
    autoRetractEnabled: data.auto_retract_enabled || defaultRecord.autoRetractEnabled,
    bettermailEnabled: data.bettermail_enabled || defaultRecord.bettermailEnabled,
    bettermailOnly: data.bettermail_only || defaultRecord.bettermailOnly,
    blockSubdomains: data.block_subdomains || defaultRecord.blockSubdomains,
    botCode: data.bot_code || defaultRecord.botCode,
    brsEnabled: data.brs_enabled || defaultRecord.brsEnabled,
    createdAt: new Date(data.created_at) || new Date(defaultRecord.createdAt),
    delegatedAccountsEnabled:
      ((data.user_permissions.includes('child read only') || data.user_permissions.includes('child admin')) &&
        data.is_parent) ||
      defaultRecord.delegatedAccountsEnabled,
    delegatedRolesEnabled: data.is_parent || defaultRecord.delegatedRolesEnabled,
    customerCreatedAt: new Date(data.customer_created_at) || new Date(defaultRecord.customerCreatedAt),
    customerId: data.customer_id || defaultRecord.customerId,
    customerName: data.customer_name || defaultRecord.customerName,
    directoriesEnabled: data.directories_enabled || defaultRecord.directoriesEnabled,
    email: data.email || defaultRecord.email,
    firstName: data.first_name || defaultRecord.firstName,
    lastModified: data.last_modified || defaultRecord.lastModified,
    lastName: data.last_name || defaultRecord.lastName,
    lockoutEnds: data.lockout_ends || defaultRecord.lockoutEnds,
    loginCount: data.login_count || defaultRecord.loginCount,
    maliciousBecEnabled: data.malicious_bec_enabled || defaultRecord.maliciousBecEnabled,
    mailsearchEnabled: data.mailsearch_enabled || defaultRecord.mailsearchEnabled,
    mailstreamEnabled: data.mailstream_enabled || defaultRecord.mailstreamEnabled,
    mailtraceEnabled: data.mailtrace_enabled || defaultRecord.mailtraceEnabled,
    parentUserId: data.parent_user_id || defaultRecord.parentUserId,
    passwordLastModified: new Date(data.password_last_modified) || defaultRecord.passwordLastModified,
    phishguardEnabled: data.is_phishguard_enabled || defaultRecord.phishguardEnabled,
    role: data.role || defaultRecord.role,
    searchEnabled: data.search_enabled || defaultRecord.searchEnabled,
    settingsReadOnly: !!(data.parent_user_id && data.role === 'read only') || defaultRecord.settingsReadOnly,
    showEula: data.show_eula || defaultRecord.showEula,
    showOnboarding: data.show_onboarding || defaultRecord.showOnboarding,
    userId: data.user_id || defaultRecord.userId,
    childPermission: getPermissionLevel(data.user_permissions, 'child') || defaultRecord.childPermission,
    parentPermission: getPermissionLevel(data.customer_permissions, 'parent') || defaultRecord.parentPermission,
  };
}
