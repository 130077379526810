// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Sticky = styled.div`
  margin-right: ${({ theme }) => rem(-theme.spacing.lg)};
  position: sticky;
  top: ${() => rem(136)};
`;

export default Sticky;
