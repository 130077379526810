// @flow

import theme from 'config/theme';

export type Data = { name: string, value: number };

export const DEFAULT_LABEL_FILL = theme.colors.independence;
export const HEIGHT = 350;
export const WIDTH = 350;
export const THICKNESS = 64.5;
