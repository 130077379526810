// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Label = styled.th.attrs({ scope: 'row' })`
  font-size: ${rem(14)};
  font-weight: 400;
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  padding-top: ${({ theme }) => theme.spacing.sm};
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  max-width: ${rem(1)} !important;
`;

export default Label;
