import React, { ComponentProps } from 'react';

import { Button } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface CloseButtonProps extends Omit<ComponentProps<typeof Button>, 'onClick' | 'type'> {
  /**
   * Makes the close icon half size (10x10 pixels).
   */
  small?: boolean;

  /**
   * Callback function that's invoked when the close icon is pressed.
   */
  onPress?: ComponentProps<typeof Button>['onClick'];
}

/**
 * Display a button with a _close_ "X" icon on it.
 */

export function CloseButton({ onPress, small, ...props }: CloseButtonProps) {
  return (
    <Button onClick={onPress} type="button" {...props}>
      <svg
        height={small ? '10' : '20'}
        viewBox="0 0 14 14"
        width={small ? '10' : '20'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m1 1 12 12M1 13 13 1M1 1l12 12M1 13 13 1"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </Button>
  );
}
