// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Header = styled.div`
  color: ${({ theme }) => theme.colors.independence};
  font-size: ${rem(14)};
  padding-bottom: ${({ theme }) => rem(theme.spacing.sm)};
  text-transform: uppercase;

  ${({ width }) => {
    switch (typeof width) {
      case 'number':
        return css`
          width: ${rem(width)};
        `;
      case 'string':
        return css`
          width: ${width};
        `;
      default:
        return css`
          flex: 1;
        `;
    }
  }};
`;

export default Header;
