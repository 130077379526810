// @flow

import { subDays } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.fonts.roboto};
  font-size: 14px;
  font-weight: 400;
  padding-bottom: ${({ theme }) => rem(theme.spacing.lg)};
  text-transform: uppercase;
`;

type Props = { isComparing: boolean, daysBack: number, showTime: boolean, startDate: Date };

export default function Header({ daysBack = 6, isComparing, showTime = false, startDate }: Props) {
  const { t } = useTranslation('const');

  return (
    <Container>
      {t('DatePicker.dateShortMonthNoYear', { date: startDate })}
      {showTime && ` ${t('components:GlobalSearch.time', { date: startDate })}`}
      {isComparing && ` vs ${t('DatePicker.dateShortMonthNoYear', { date: subDays(startDate, daysBack) })}`}
    </Container>
  );
}
