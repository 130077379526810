import React, { ComponentProps } from 'react';

import { Container } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface SearchInputProps extends ComponentProps<typeof Container> {}

/**
 * Renders the search input for the Unified Search
 */
export function SearchInput(props: SearchInputProps) {
  return <Container {...props} type="search" />;
}
