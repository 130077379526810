// @flow

import hash from 'hash-sum';
import { rem } from 'polished';
import React from 'react';
import { Spring, config } from 'react-spring/renderprops';
import { withTheme } from 'styled-components';

import type { Theme } from 'config/theme';

type TooltipData = {| date: Date, value: number |};

type Props = {
  getPathYFromX: Function,
  lineTints: { [number]: string },
  onMouseLeave: SyntheticMouseEvent<any>,
  onMouseMove: (e: SyntheticMouseEvent<any>, Object) => void,
  series: mixed[],
  theme: Theme,
  tooltipOpen: boolean,
  tooltipData: TooltipData[],
  vertLineLeft: number,
  xMax: number,
  xScale: Function,
  yMax: number,
};

const Overlay = ({
  getPathYFromX,
  lineTints,
  onMouseLeave,
  onMouseMove,
  series,
  theme,
  tooltipOpen,
  tooltipData,
  vertLineLeft,
  xMax,
  xScale,
  yMax,
}: Props) => (
  <>
    <Spring config={config.default} from={{ opacity: 0, x: vertLineLeft }} to={{ x: vertLineLeft }}>
      {({ x }) =>
        tooltipData && (
          <g>
            {tooltipData.map((d, i) => {
              const y = getPathYFromX(i, x);

              return (
                <circle
                  cx={x}
                  cy={y}
                  fill={theme.tints[lineTints[i]].fill}
                  fillOpacity={tooltipOpen ? 1 : 0}
                  key={hash({ ...d, i })}
                  r={3}
                  stroke={theme.tints[lineTints[i]].fill}
                  strokeWidth={rem(1)}
                  strokeOpacity={tooltipOpen ? 1 : 0}
                />
              );
            })}
          </g>
        )
      }
    </Spring>
    <rect
      x="0"
      y="0"
      width={xMax}
      height={yMax}
      fill="transparent"
      onMouseLeave={onMouseLeave}
      onMouseMove={(event) => onMouseMove(event, { series, xMax, xScale, yMax })}
      onTouchMove={(event) => onMouseMove(event, { series, xMax, xScale, yMax })}
    />
  </>
);

export default withTheme(Overlay);
