// @flow

import { rgba, rem } from 'polished';
import styled from 'styled-components';

import TwoColumn from 'ui/templates/TwoColumn';

const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => rgba(theme.colors.black, 0.1)} 0 ${rem(4)} ${rem(16)};
  margin: ${({ theme }) => rem(theme.spacing.lg)};

  ${TwoColumn} > & {
    margin-top: 0;
  }
`;

export default Container;
