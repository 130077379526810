// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Change = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => rem(theme.spacing.sm)};
`;

export default Change;
