"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shadows = void 0;
const shadows = {
  1: '0 $space$1 $space$4 #00000045',
  text: '$space$1 $space$1 3px $colors$gray700'
};
exports.shadows = shadows;