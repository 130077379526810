// @flow

import { rgba, rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent } from './check-shield.svg';

//
// Styled Components
// -------------------------------------------------------------------------------------------------

const Icon = styled(ReactComponent)`
  height: ${rem(16)};
  width: ${rem(14)};

  path {
    transition: fill 150ms ${({ theme }) => theme.timing.easeOutCirc};
  }
`;

const Link = styled(NavLink)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${rem(32)};
  justify-content: center;
  width: ${rem(32)};
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.magicMint, 0.5)};
  border-radius: 50%;
  display: flex;
  height: ${rem(24)};
  justify-content: center;
  transition: background-color 250ms ${({ theme }) => theme.timing.easeOutCirc};
  width: ${rem(24)};

  a:hover & {
    background-color: ${({ theme }) => theme.colors.magicMint};
  }

  a[aria-current='page'] & {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  }

  a[aria-current='page'] & path:first-child {
    fill: ${({ theme }) => theme.colors.deepSkyBlue};
  }
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

export default function AccountabilityLink() {
  const { t } = useTranslation('common');

  return (
    <Link title={t('accountability')} to="/accountability">
      <Wrapper>
        <Icon />
      </Wrapper>
    </Link>
  );
}
