// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Text = styled.text`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${rem(14)};
`;

export default Text;
