// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

const Line = styled.span`
  border-radius: ${rem(4)};
  display: inline-block;
  height: ${rem(3)};
  margin-left: ${({ theme }) => rem(theme.spacing.sm)};

  ${({ color, dashed, theme }) =>
    !dashed &&
    css`
      background: linear-gradient(90deg, ${theme.tints[color].from}, ${theme.tints[color].to});
    `};

  ${({ color, dashed, theme }) =>
    dashed &&
    css`
      background-image: linear-gradient(
          90deg,
          transparent,
          transparent 50%,
          ${theme.colors.jet} 50%,
          ${theme.colors.jet} 100%
        ),
        linear-gradient(90deg, ${theme.tints[color].from}, ${theme.tints[color].to});
      background-size: 15px 3px, 100% 3px;
    `};
`;

const Number = styled.div`
  display: block;
  font: ${({ theme }) => theme.fonts.din};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: ${rem(-2.0)};
  margin-top: ${({ theme }) => rem(-theme.spacing.xs)};
  padding-bottom: ${({ theme }) => rem(theme.spacing.sm)};
`;

const TopRow = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: ${rem(10)};
  font-weight: 500;
  text-transform: uppercase;

  & > * {
    flex: 1;
  }
`;

type Props = { color: string, children: Node, dashedLine?: boolean, title?: string };

const Content = ({ color, children, dashedLine = false, title }: Props) => (
  <>
    <TopRow>
      {title}
      <Line color={color} dashed={dashedLine} />
    </TopRow>
    <Number>{children}</Number>
  </>
);

export default Content;
