"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colors = void 0;
const colors = {
  black: '#000000',
  // ✓
  white: '#ffffff',
  // ✓

  gray50: '#f9f9f9',
  gray100: '#ECF0F2',
  // ✓
  gray150: '#D6D8D9',
  gray200: '#bfbfbf',
  // ✓
  gray300: '#dddddd',
  // ✓
  gray400: '#a8a8a8',
  // ✓
  gray500: '#888888',
  // ✓
  gray600: '#6a6a6a',
  // ✓
  gray700: '#444444',
  // ✓
  gray800: '#313131',
  gray900: '#212121',
  pink50: '#F1F5F9',
  pink100: '#F0EBF6',
  pink200: '#f3cbe2',
  // ✓
  pink300: '#ebabd0',
  // ✓
  pink400: '#e58ec0',
  // ✓
  pink450: '#e07ab5',
  pink500: '#dc67ab',
  // ✓
  pink600: '#b850be',
  // ✓
  pink700: '#AE336B',
  pink800: '#932C5D',
  pink900: '#7F2753',
  blue50: '#E8F8FE',
  blue100: '#D5EDFE',
  blue200: '#D1E1F3',
  // ✓
  blue225: '#6C90A1',
  // ✓
  blue250: '#9CB0B9',
  // ✓
  blue300: '#67b7dc',
  // ✓
  blue400: '#6da7d8',
  // ✓
  blue500: '#2791ea',
  // ✓
  blue600: '#3F73EA',
  blue700: '#0E5598',
  // ✓
  blue800: '#3550B0',
  blue900: '#1941a5',
  // ✓

  teal50: '#EAFDFB',
  teal100: '#ddf1f1',
  teal200: '#d0e4e6',
  teal300: '#c2d7db',
  // ✓
  teal400: '#b5cbd1',
  // ✓
  teal500: '#94a8bb',
  // ✓
  teal600: '#6ea6bf',
  // ✓
  teal650: '#6fa6bf',
  teal700: '#4c8599',
  // ✓
  teal800: '#406169',
  teal900: '#375358',
  green50: '#E7FDF9',
  green100: '#C7FCE4',
  green200: '#AFF4D7',
  green300: '#80EBB8',
  green400: '#00cf97',
  // ✓
  green500: '#50BC80',
  green600: '#4B9671',
  green700: '#467864',
  green800: '#3F6154',
  green900: '#38514B',
  yellow50: '#fff7ec',
  yellow100: '#FFF4D3',
  // ✓
  yellow200: '#F8ED8B',
  yellow300: '#F6DE49',
  yellow400: '#ffc375',
  yellow500: '#FBB410',
  // ✓
  yellow600: '#D88429',
  yellow700: '#A96029',
  yellow800: '#904D23',
  yellow900: '#7C4120',
  orange50: '#F6FCF0',
  orange100: '#F6F2CF',
  orange200: '#F6E58A',
  orange300: '#F5D34D',
  orange400: '#c05d08',
  orange500: '#ee8031',
  // ✓
  orange600: '#E0732C',
  orange700: '#B65323',
  orange800: '#97411E',
  orange900: '#83381B',
  red50: '#F3F4F6',
  red100: '#F3E4E8',
  red200: '#F1CED1',
  red300: '#F0A7A9',
  red400: '#EB7672',
  red500: '#E24D47',
  red600: '#FF0000',
  // ✓
  red700: '#B12D29',
  red800: '#972527',
  red900: '#84262A',
  purple50: '#F1F7FE',
  purple100: '#EAECFE',
  purple200: '#DCDCFD',
  purple300: '#C5BFFC',
  purple400: '#AB95FB',
  purple500: '#a368dc',
  // ✓
  purple600: '#8550EA',
  purple700: '#7440CF',
  purple800: '#633AAB',
  purple900: '#572F8E'
};
exports.colors = colors;