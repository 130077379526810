// @flow

// $FlowFixMe
import { Box, HorizontalBars, Loadable, Text } from '@a1s/ui';
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderDataArray } from '../dataTypesAndUtils';
import { LabeledValue } from '../ui/LabeledValue';

import { insightsMaliciousRecipient } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';

export default function TopMaliciousRecipients() {
  const { t } = useTranslation('email');
  const { currentInterval } = useContext(DaysBackContext);

  const { data, error, loading } = useQuery(insightsMaliciousRecipient, {
    variables: setInsightsDateRangeFromDuration(currentInterval),
  });
  const recipients = get(data, 'insightsMaliciousRecipients.data', []);

  return (
    <ContentBlock
      dataTestId="top-targeted-recipients-component"
      top={
        <>
          <BlockHeader title={t('topMaliciousTargets')} />
          <ConditionalRender
            condition={!error && (loading || dataForGraphTable(recipients)?.length > 0)}
            fallback={
              <Box css={{ textAlign: 'center' }} pb>
                <Text color="$gray400" size="sm" stretch="ultraCondensed">
                  {t('components:NoData.noDataObserved')}
                </Text>
              </Box>
            }
          >
            <HorizontalBars gap="2">
              {(!loading ? dataForGraphTable(recipients) : placeholderDataArray(10)).map((row) => {
                return (
                  <HorizontalBars.Bar color="$gray100" key={row[0]} value={row[1]}>
                    <Loadable loading={loading}>
                      <LabeledValue
                        childrenColor="$gray600"
                        gap="5"
                        params={{
                          disposition: 'malicious',
                          target: row[0],
                        }}
                        value={row[1]}
                      >
                        {row[0]}
                      </LabeledValue>
                    </Loadable>
                  </HorizontalBars.Bar>
                );
              })}
            </HorizontalBars>
          </ConditionalRender>
        </>
      }
    />
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------
const dataForGraphTable = (data) => {
  if (!data) return [];
  return data.map((el) => [el.maliciousRecipients, el.value]);
};
