// @flow

import { rgba, rem } from 'polished';
import styled, { css } from 'styled-components';

const Graph = styled.td`
  background-image: ${({ colorScheme, percent }) => `
    linear-gradient(
      to right,
      ${rgba(colorScheme, percent)} ${percent * 100}%,
      transparent ${percent * 100}%,
      transparent
    );
  `};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${rem(14)};
  height: ${rem(40)};
  padding: ${({ theme }) => rem(theme.spacing.sm)};
  text-shadow: ${({ theme }) => rgba(theme.colors.jet, 0.1)} 0 1px 2px;
  vertical-align: middle;

  ${({ hideValue }) =>
    hideValue &&
    css`
      color: transparent;
    `};
`;

export default Graph;
