// @flow

import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent } from './settings.svg';

//
// Styled Components
// -------------------------------------------------------------------------------------------------

const Icon = styled(ReactComponent)`
  height: ${rem(16)};
  width: ${rem(14)};

  a:hover & path {
    fill: ${({ theme }) => theme.colors.grayBlue};
  }
`;

const Link = styled(NavLink)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${rem(32)};
  justify-content: center;
  width: ${rem(32)};
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  height: ${rem(24)};
  justify-content: center;
  transition: background-color 150ms ${({ theme }) => theme.timing.easeOutCirc};
  width: ${rem(24)};

  a[aria-current='page'] & {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  }

  a[aria-current='page'] & path {
    fill: ${({ theme }) => theme.colors.deepSkyBlue};
  }
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

export default function SettingsLink() {
  const { t } = useTranslation('common');

  return (
    <Link title={t('settings')} to="/settings">
      <Wrapper>
        <Icon />
      </Wrapper>
    </Link>
  );
}
