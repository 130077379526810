import { styled } from '@a1s/ui';

export const Container = styled('input', {
  appearance: 'textfield',
  backgroundColor: '$gray100',
  border: 'none',
  borderRadius: '$2',
  color: '$gray600',
  flexGrow: 1,
  fontFamily: '$roboto',
  fontSize: '$md',
  fontStretch: 'ultra-condensed',
  fontWeight: 500,
  px: '$4',
  py: '$2',

  '&:focus': {
    backgroundColor: '$gray50',
  },

  '&::placeholder': {
    color: '$gray400',
    textTransform: 'uppercase',
  },
});
