// @flow

import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

const Option = styled.div`
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  margin-left: ${({ theme }) => rem(theme.spacing.sm)} !important;
  padding: 0 !important;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    margin-left: ${rem(0)} !important;
  }

  &:hover {
    background-color: ${({ decorated, theme }) => decorated && rgba(theme.colors.azureishWhite, 0.5)};
  }

  ${({ decorated }) =>
    decorated &&
    css`
      border-radius: ${rem(8)};
      padding: ${({ theme }) => rem(theme.spacing.sm + theme.spacing.md)} !important;

      & + & {
        margin-left: ${({ theme }) => rem(theme.spacing.lg)};
      }
    `};

  ${({ selected }) =>
    selected &&
    css`
      &:active {
        background-color: transparent !important;
      }

      background-color: ${({ decorated, theme }) => decorated && rgba(theme.colors.azureishWhite, 0.5)};
    `};

  ${({ small }) =>
    small &&
    css`
      font-size: ${rem(12)};
      margin-left: 0;
      padding: ${({ theme }) => rem(theme.spacing.sm)} !important;

      & + & {
        margin-left: ${({ theme }) => rem(theme.spacing.md)};
      }
    `};
`;

export default Option;
