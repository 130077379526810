// @flow

import { random } from '@a1s/lib';

import React, { type Node, Component } from 'react';

import Container from './Container';

import Content from 'ui/molecules/Content';

type ArrowAlign = 'center' | 'end' | 'start';
type Position = 'bottom' | 'left' | 'right' | 'top';
type ViewportTracker = { bottom: boolean, left: boolean, right: boolean, top: boolean };

type Props = {
  anchor: ?HTMLElement,
  arrowAlign?: ArrowAlign,
  children?: Node,
  dataTestId?: string,
  onBlur?: Function,
  onFocus?: Function,
  onMouseOver?: Function,
  onMouseOut?: Function,
  position?: Position,
  wide?: boolean,
  visible?: boolean,
};
type State = { inViewport: ViewportTracker, left: number, top: number };

class Tooltip extends Component<Props, State> {
  htmlId: string = `id${random()}`;

  ref: ?HTMLSpanElement = null;

  static defaultProps = { arrowAlign: 'center', position: 'top', visible: false, wide: false };

  state = { inViewport: { bottom: true, left: true, right: true, top: true }, left: 0, top: 0 };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps: Props) {
    const { anchor } = this.props;
    if (prevProps.anchor === null && anchor !== null) {
      window.requestAnimationFrame(this.handleWindowResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  assignRef = (ref: any) => {
    this.ref = ref;
  };

  containerPositionStyles = () => {
    const { anchor } = this.props;
    let { position } = this.props;
    const { inViewport } = this.state;

    if (!anchor || !this.ref || !anchor.parentNode) return {};

    if (position === 'right' && !inViewport.right) position = 'left';
    if (position === 'left' && !inViewport.left) position = 'right';

    const anchorRect = anchor.getBoundingClientRect();
    // $FlowIssue I'm checking if parentNode is available on line #41 but Flow keeps complaining
    const parentRect = anchor.parentNode.getBoundingClientRect();
    // $FlowIssue I'm checking if ref is available on line #41 but Flow keeps complaining
    const tooltipRect = this.ref.getBoundingClientRect();

    const x = anchorRect.left - parentRect.left;
    const y = anchorRect.top - parentRect.top;

    switch (position) {
      case 'bottom':
        return { left: x - (tooltipRect.width / 2 + anchorRect.width / 2), top: y + anchorRect.height + 14 };
      case 'left':
        return {
          left: x - (tooltipRect.width + anchorRect.width + 14),
          top: y - tooltipRect.height / 2 + anchorRect.height / 2,
        };
      case 'right':
        return {
          left: x + 14,
          top: y - tooltipRect.height / 2 + anchorRect.height / 2,
        };
      case 'top':
        return { left: x - (tooltipRect.width / 2 + anchorRect.width / 2), top: y - tooltipRect.height - 14 };
      default:
        return {};
    }
  };

  handleWindowResize = () => {
    if (!this.ref) return;

    const bounding = this.ref.getBoundingClientRect();
    const { documentElement } = document;

    const { left, top } = this.containerPositionStyles();

    if (documentElement) {
      this.setState({
        inViewport: {
          top: bounding.top >= 0,
          right: bounding.right + 20 <= (window.innerWidth || documentElement.clientWidth),
          bottom: bounding.bottom + 20 <= (window.innerHeight || documentElement.clientHeight),
          left: bounding.left >= 0,
        },
        left,
        top,
      });
    }
  };

  warnIfAnchorParentIsNotRelative() {
    const { anchor } = this.props;
    if (!anchor || !anchor.parentNode) return;

    const position = window.getComputedStyle(anchor.parentNode, null).getPropertyValue('position');
    if (position !== 'relative') {
      // eslint-disable-next-line no-console
      console.warn(
        "anchor's parent must have `position: relative` in order for Tooltip's positioning to work properly"
      );
    }
  }

  render() {
    const {
      anchor,
      arrowAlign,
      children,
      dataTestId = 'atom-tooltip',
      onBlur,
      onFocus,
      onMouseOut,
      onMouseOver,
      position,
      wide,
      visible,
    } = this.props;
    const { left, top } = this.state;

    this.warnIfAnchorParentIsNotRelative();

    const hidden = !visible;

    // Attach the tooltip to the anchor ref to make the component accessible.
    // More info: https://www.sarasoueidan.com/blog/accessible-tooltips/
    if (anchor) {
      anchor.setAttribute('aria-describedby', 'tooltipInfo');
    }

    return (
      <Container
        aria-hidden={hidden.toString()}
        arrowAlign={arrowAlign}
        data-testid={dataTestId}
        left={left}
        id="tooltip"
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        position={position}
        ref={this.assignRef}
        top={top}
        wide={wide}
        visible={visible}
      >
        <Content small>{children}</Content>
      </Container>
    );
  }
}

export default Tooltip;
