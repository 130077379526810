import { Box, Cluster, Loadable, WorldMap } from '@a1s/ui';
import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React from 'react';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../lib';

import SourcesGraphs, { SourcesData } from './SourcesGraphs';

import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface MiddleProps {
  /**
   * Which tab should be shown
   */
  currentTab: Tabs;

  /**
   * The duration of time in which to display data for
   */
  duration: Duration;
}

export default function Middle({ currentTab, duration }: MiddleProps) {
  const { data, loading } = useRemoteData(duration);

  return (
    <Box pl pr testId="threat-origins-middle-box">
      <Cluster gap justify="stretch">
        <Loadable loading={loading}>
          <WorldMap.Loadable data={data?.byLocationCounts} height="280" testId="threat-origins-words-map" width="400" />
          <SourcesGraphs
            globalSources={currentTab === 'worldwide' ? data?.globalCounts : data?.globalCountsByIndustry}
            orgSources={data?.orgCounts}
            testId="threat-origins-sources-graphs"
          />
        </Loadable>
      </Cluster>
    </Box>
  );
}

//
// Data fetching
// -------------------------------------------------------------------------------------------------

/**
 * The types of tabs uses in this component.
 */
export type Tabs = 'sameIndustry' | 'worldwide';

interface LocationData {
  count: number;
  lat: number;
  lng: number;
}

export interface APIData {
  byLocationCounts: LocationData[];
  globalCounts: SourcesData;
  globalCountsByIndustry: SourcesData;
  orgCounts: SourcesData;
}

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: APIData;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

/**
 * Private hook that encapsulates loading the data for the `ThreatOriginsPanel`.
 * Poll the endpoint every 15 minutes.
 */
function useRemoteData(duration: Duration = '30'): HookResult {
  const { data, error, loading } = useQuery(query, {
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });

  if (loading) return { data: undefined, error: null, loading: true };
  if (error) return { data: undefined, error, loading: false };

  // Make sure there's data in the first element of the `data` array too.
  if (!data?.insightsThreatOriginsData?.data || !data?.insightsThreatOriginsData?.data[0]) {
    return { data: undefined, error: null, loading: false };
  }

  return { data: mungeAPIData(data.insightsThreatOriginsData.data), error: null, loading: false };
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

interface CountryCount {
  count: number;
  iso3166: string;
  name: string;
}

interface LocationCount {
  count: number;
  geohash: string;
  lat: number;
  lng: number;
}

interface MungeAPIData {
  byLocationCounts: LocationCount[];
  globalCountsByCountry: CountryCount[];
  globalCountsByCountryByIndustry: CountryCount[];
  orgCountsByCountry: CountryCount[];
}

/**
 * This function accepts data from either threat_origins endpoint and munges it ready to be consumed.
 */
function mungeAPIData(data: MungeAPIData[]) {
  const { byLocationCounts } = data[0];
  const globalCounts = mungeToCountGroup(data[0].globalCountsByCountry);
  const globalCountsByIndustry = mungeToCountGroup(data[0].globalCountsByCountryByIndustry);
  const orgCounts = mungeToCountGroup(data[0].orgCountsByCountry);

  return { byLocationCounts, globalCounts, globalCountsByIndustry, orgCounts };
}

/**
 * Specify how many of the top country to extract our of the data.
 */
const TOP_COUNTRY_COUNT = 4;

function mungeToCountGroup(data: Array<{ name: string; count: number; iso3166: string }>): SourcesData {
  const sorted = [...data].sort((a, b) => b.count - a.count);

  return {
    other: sorted.slice(TOP_COUNTRY_COUNT).reduce((acc, c) => acc + c.count, 0),
    top: sorted
      .slice(0, TOP_COUNTRY_COUNT)
      .map((c) => ({ country: c.name as string, count: c.count as number, isoName: c.iso3166 as string })),
  };
}
