// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type ThreatType } from './dataTypesAndUtils';
import TopThreatTypes from './TopThreatTypes';

import { DaysBackContext } from 'screens/App';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';

export const query = loader('./queries/InsightsThreatTypeQuery.graphql');

export default function MaliciousTargetsOverview() {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(query, { variables: setInsightsDateRangeFromDuration(currentInterval) });

  // Transform the insight data into data that `TopThreatTypes` will accept
  const threatTypes = get(data, 'maliciousThreatTypesData.data', []).map(({ threatType, value }): ThreatType => {
    return { count: value, threatType };
  });

  return (
    <>
      <Helmet title={`${t('targets')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <TopThreatTypes data={threatTypes} error={error} loading={loading} />
    </>
  );
}
