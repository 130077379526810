// @flow

import { rem } from 'polished';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

import Content from 'ui/molecules/Content';

const Text = styled(Content)`
  display: inline-block;
  max-width: ${({ width }) => rem(width)};
  overflow: hidden;
  white-space: nowrap;

  ${({ allowWrap }) =>
    allowWrap &&
    css`
      white-space: normal;
    `};

  ${({ useEllipsis }) =>
    useEllipsis &&
    css`
      text-overflow: ellipsis;
    `};
`;

type Props = { children: Node, width?: number, useEllipsis?: boolean };

const TruncateText = ({ children, width, useEllipsis = true, ...rest }: Props, ref) => (
  <Text ref={ref} useEllipsis={useEllipsis} width={width} {...rest}>
    {children}
  </Text>
);

// $FlowFixMe
export default React.forwardRef(TruncateText);
