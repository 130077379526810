// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Description = styled.p`
  flex-basis: 100%;
  font-size: ${rem(14)};
`;

export default Description;
