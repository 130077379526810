import { styled } from '@a1s/ui';

export const StyledTab = styled('div', {
  borderBottom: 'transparent solid 3px',
  color: '$gray500',
  cursor: 'pointer',
  fontFamily: '$sans',
  fontSize: '$md',
  fontStretch: 'ultra-condensed',
  fontWeight: '$light',
  pt: '$2',
  textTransform: 'uppercase',

  '&:hover': {
    color: '$gray600',
  },

  '&[aria-selected="true"]': {
    borderBottomColor: '$gray500',
    color: '$gray800',
    fontWeight: '$semibold',
  },
});

export const StyledTabBar = styled('div', {
  display: 'flex',
  gap: '$8',
  px: '$3',
});
