// @flow

// $FlowFixMe
import { Box, HorizontalBars, Loadable, Text } from '@a1s/ui';
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
// $FlowFixMe
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderDataArray } from '../dataTypesAndUtils';
import { LabeledValue } from '../ui/LabeledValue';

import { insightsExtensionsQuery, mungeProgressTableData } from './dataTypesAndUtils';

import { DaysBackContext } from 'screens/App';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';

export default function TopMaliciousFileTypes() {
  const { currentInterval } = useContext(DaysBackContext);
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(insightsExtensionsQuery, {
    variables: setInsightsDateRangeFromDuration(currentInterval),
  });
  const allExtensions = get(data, 'maliciousAttachmentsExtensions.data.extensions', []);

  const getTranslatedLabelName = (label) => {
    if (label === 'No name') {
      return t('noNameType');
    }

    if (label === 'other') {
      return t('other');
    }

    return label;
  };

  return (
    <ContentBlock
      dataTestId="top-malicious-file-types-component"
      top={
        <>
          <BlockHeader title={t('topMaliciousFileTypes')} />

          <ConditionalRender
            condition={!error && (loading || allExtensions.length > 0)}
            fallback={
              <Box css={{ textAlign: 'center' }} pb>
                <Text color="$gray400" size="sm" stretch="ultraCondensed">
                  {t('components:NoData.noDataObserved')}
                </Text>
              </Box>
            }
          >
            <HorizontalBars gap="2">
              {(loading ? placeholderDataArray(5) : mungeProgressTableData(allExtensions)).map((row) => {
                return (
                  <HorizontalBars.Bar color="$gray100" key={row[0]} value={row[1]}>
                    <Loadable loading={loading}>
                      <LabeledValue
                        childrenColor="$gray600"
                        gap="5"
                        params={
                          row[0] !== 'No name' && {
                            disposition: 'malicious',
                            metric: `attachment_disposition:${row[0]}`,
                          }
                        }
                        transform={row[0] === 'No name' ? 'none' : 'lowercase'}
                        value={row[1]}
                      >
                        {getTranslatedLabelName(row[0])}
                      </LabeledValue>
                    </Loadable>
                  </HorizontalBars.Bar>
                );
              })}
            </HorizontalBars>
          </ConditionalRender>
        </>
      }
    />
  );
}
