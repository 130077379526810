// @flow

import { get } from 'lodash';
// $FlowFixMe
import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import BlockedPatterns from './BlockedPatterns';
import BlockedRecipients from './BlockedRecipients';

type Props = {
  blockListData: Object,
  error: boolean,
  loading: boolean,
};
export default function BlockList({ blockListData, error, loading }: Props) {
  const { t } = useTranslation('email');
  const sectionOverviewData = get(blockListData, 'totals', {});
  const currentTimeline = get(blockListData, 'timeline.current', []);
  const graphData = currentTimeline.map((b) => ({
    date: b.day,
    keys: [t(b.totals[0].policyType)],
    values: [b.total],
  }));
  const sortedStackBrGraphData = graphData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <>
      <Helmet title={`${t('blockList')} :: ${t('common:email')} - ${t('const:names:area1security')}`} />
      <>
        <BlockedPatterns
          data={{
            sectionOverview: {
              current: sectionOverviewData.current,
              previous: sectionOverviewData.previous,
            },
            stackBarGraph: sortedStackBrGraphData,
          }}
          error={error}
          loading={loading}
        />
        <BlockedRecipients />
      </>
    </>
  );
}
