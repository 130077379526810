export const DISPOSITIONS = ['BULK', 'MALICIOUS', 'MALICIOUS-BEC', 'NONE', 'SPAM', 'SPOOF', 'SUSPICIOUS'] as const;
export const SUBMISSION_DISPOSITIONS = ['BULK', 'MALICIOUS', 'NONE', 'SPAM', 'SPOOF', 'SUSPICIOUS'] as const;
export type Dispositions = typeof DISPOSITIONS[number];

export function matchColorAndDisposition(disposition?: Dispositions) {
  switch (disposition) {
    case 'BULK':
      return 'teal300';
    case 'MALICIOUS':
      return 'pink500';
    case 'MALICIOUS-BEC':
      return 'pink800';
    case 'NONE':
      return 'gray300';
    case 'SPAM':
      return 'teal500';
    case 'SPOOF':
      return 'pink200';
    case 'SUSPICIOUS':
      return 'pink400';
    default:
      return 'gray500';
  }
}
