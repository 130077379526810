// @flow

export type DetectionsCountType = {|
  bulk: number,
  malicious: number,
  maliciousBec: number,
  none: number,
  spam: number,
  spoof: number,
  suspicious: number,
|};

export type EmailCountsRowTimeseriesType = {
  [date: string]: {|
    mailBec: number,
    mailBulk: number,
    mailMalicious: number,
    mailSpam: number,
    mailSpoof: number,
    mailSuspicious: number,
    mailTotal: number,
  |},
};

export type EmailCountsRowTotalsType = {|
  mailBec: number,
  mailBulk: number,
  mailMalicious: number,
  mailSpam: number,
  mailSpoof: number,
  mailSuspicious: number,
  mailTotal: number,
|};

export type EmailCountsMarshallTypeRow = {|
  timeseries: EmailCountsRowTimeseriesType,
  totals: EmailCountsRowTotalsType,
|};

export const defaultDetections = {
  bulk: 0,
  malicious: 0,
  maliciousBec: 0,
  none: 0,
  spam: 0,
  spoof: 0,
  suspicious: 0,
};

export const defaultDetectionsTimeline = {
  bulk: 0,
  malicious: 0,
  maliciousBec: 0,
  none: 0,
  spam: 0,
  spoof: 0,
  suspicious: 0,
  date: '',
};

export const defaultTotals = {
  mailBec: 0,
  mailBulk: 0,
  mailMalicious: 0,
  mailSpam: 0,
  mailSpoof: 0,
  mailSuspicious: 0,
  mailTotal: 0,
};

type ActionTypes = 'RESET_STATE' | 'SET_ERROR' | 'SET_LOADING' | 'SET_HEIGHT' | 'SET_WIDTH';

type DonutChartReturnType = {
  name: string,
  value: number,
};

type StackedBarGraphResult = {
  date: Date,
  keys: Array<string>,
  values: Array<number>,
};

type StateType = {
  error: Boolean,
  height: Number,
  loading: Boolean,
  width: Number,
};

type StateAndActionType = {
  type: ActionTypes,
  ...StateType,
};

export const initialFormState = {
  error: false,
  height: 0,
  loading: false,
  width: 0,
};

export const stateReducer = (state: StateType, action: StateAndActionType) => {
  switch (action.type) {
    case 'RESET_STATE': {
      return {
        error: false,
        height: 0,
        loading: false,
        width: 0,
      };
    }

    case 'SET_ERROR': {
      const { error } = action;
      return { ...state, error };
    }

    case 'SET_HEIGHT': {
      const { height } = action;
      return { ...state, height };
    }

    case 'SET_LOADING': {
      const { loading } = action;
      return { ...state, loading };
    }

    case 'SET_WIDTH': {
      const { width } = action;
      return { ...state, width };
    }

    default: {
      return state;
    }
  }
};

export function dataForStackedBarGraph(
  becEnabled: boolean,
  data: EmailCountsRowTimeseriesType
): Array<StackedBarGraphResult> {
  return Object.keys(data).map((key) => {
    const keys = [];
    const values = [];
    Object.keys(data[key]).forEach((disposition) => {
      if (disposition === 'mailTotal') return;

      if (!becEnabled) {
        if (disposition !== 'mailBec') {
          keys.push(disposition);
          values.push(data[key][disposition]);
        }
      } else {
        keys.push(disposition);
        values.push(data[key][disposition]);
      }
    });

    return {
      date: new Date(key),
      keys,
      values,
    };
  });
}

export function daysSorted(data: Array<EmailCountsRowTimeseriesType>): Array<EmailCountsRowTimeseriesType> {
  return data.sort((a, b) => {
    const aDateArr = Object.keys(a)[0].split('/');
    const bDateArr = Object.keys(b)[0].split('/');

    const date1 = new Date(parseInt(aDateArr[2]), parseInt(aDateArr[0]) - 1, parseInt(aDateArr[1]));
    const date2 = new Date(parseInt(bDateArr[2]), parseInt(bDateArr[0]) - 1, parseInt(bDateArr[1]));

    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  });
}

const checkBecEnabled = (bec, key) => {
  return bec ? key !== 'none' : key !== 'maliciousBec' && key !== 'none';
};

export const dataForDonutChart = (
  becEnabled: boolean,
  emailCount: DetectionsCountType,
  t: Function
): Array<DonutChartReturnType> =>
  Object.keys(emailCount)
    .filter((key) => checkBecEnabled(becEnabled, key))
    .map((key) => ({ name: t(key), value: emailCount[key] }));

export const alignHeading = (heading: string): 'center' | 'left' | 'right' => {
  if (heading === 'date') {
    return 'left';
  }
  return 'right';
};
