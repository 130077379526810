"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCenter = void 0;
var _styled = require("../../styled");
var _utils = require("../../utils");
//
// Styled components
// -------------------------------------------------------------------------------------------------

const StyledCenter = (0, _styled.styled)('div', {
  boxSizing: 'content-box',
  marginLeft: 'auto',
  marginRight: 'auto',
  variants: {
    /**
     * The amount of horizontal space to keep on the sides of the component.
     * @type $space
     */
    gutter: {
      true: {
        px: '$3'
      },
      none: {
        px: '$none'
      },
      px: {
        px: '$px'
      },
      0.5: {
        px: '$0.5'
      },
      1: {
        px: '$1'
      },
      1.5: {
        px: '$1.5'
      },
      2: {
        px: '$2'
      },
      2.5: {
        px: '$2.5'
      },
      3: {
        px: '$3'
      },
      3.5: {
        px: '$3.5'
      },
      4: {
        px: '$4'
      },
      5: {
        px: '$5'
      },
      6: {
        px: '$6'
      },
      7: {
        px: '$7'
      },
      8: {
        px: '$8'
      },
      9: {
        px: '$9'
      },
      10: {
        px: '$10'
      },
      11: {
        px: '$11'
      },
      12: {
        px: '$12'
      }
    },
    /**
     * The maximum width of the centered element
     * @type $size
     */
    max: (0, _utils.tokenVariant)('maxWidth', _styled.theme.sizes)
  }
});
exports.StyledCenter = StyledCenter;