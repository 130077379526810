// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.independence};
  font-size: ${rem(12)};
  font-weight: 400;
  letter-spacing: ${rem(0.5)};
  line-height: ${rem(16)};
  text-transform: uppercase;
  margin: 0 ${({ theme }) => rem(theme.spacing.lg)};
  text-align: center;
`;

export default Label;
