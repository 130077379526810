"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDebounce = useDebounce;
exports.useDebouncedCallback = useDebouncedCallback;
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _react = require("react");
var _usePrevious = require("../usePrevious");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * Debounces a value change throughout re-renders. The debounce will be triggered everytime the value changes (compared
 * using strict equality).
 * @param {*} value - The value to keep track of for debouncing rerenders.
 * @param {number} delay - How long to delay rerendering after the value changes.
 * @param {number} maxWait - The maximum amount of time to wait to rerender after the value changes.
 */
function useDebounce(value, delay = 0, maxWait) {
  const previousValue = (0, _usePrevious.usePrevious)(value);
  const [current, setCurrent] = (0, _react.useState)(value);
  const debouncedCallback = useDebouncedCallback(value => setCurrent(value), delay, {
    maxWait
  });
  (0, _react.useEffect)(() => {
    // Trigger the debounce timer initially so we have an initial value.
    if (value !== previousValue) {
      debouncedCallback(value);
      // Tell React to cancel the debounced function while cleaning up.
      return debouncedCallback.cancel;
    }
  }, [debouncedCallback, previousValue, value]);
  return current;
}

// This is how @types/lodash types out its generic function. However, it doesn't export this declaration so we need to
// redeclare here so we can except the same types of function as `debounce`.

/**
 *
 * @param {Function} callback - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay in calling the given callback.
 * @param {Object} options - Extra options to pass to `lodash.debounce`.
 */
function useDebouncedCallback(cb, delay = 0, options) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (0, _react.useCallback)((0, _debounce.default)(cb, delay, options), [cb, delay, options]);
}