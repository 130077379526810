// @flow

// $FlowFixMe
import React, { Suspense } from 'react';

import Loading from 'ui/atoms/Loading';

export default function WaitingComponent(Component: any) {
  return (props: any) => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
}
