// @flow

import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Container = styled.div`
  animation: ${gradient} 15s ease infinite;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
`;

export default Container;
