import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { FIFTEEN_MINUTE_POLL_INTERVAL } from '../../../Dashboard/lib/index';

import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
import { Duration } from 'utils/duration';

export type FilterType = 'all' | 'team' | 'user';

interface TimelineData {
  BULK: number;
  date: string;
  MALICIOUS: number;
  SPAM: number;
}

interface TotalsData {
  bulkPercent: number;
  currentTotal: number;
  maliciousPercent: number;
  previousTotal: number;
  spamPercent: number;
}

export interface PhishguardAPIData {
  timeline: TimelineData[];
  totals: TotalsData;
}

interface HookResult {
  /**
   * The data coming _out_ of the usePhishguardData hook.
   */
  data?: Record<FilterType, PhishguardAPIData>;

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: ApolloError | null;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

const query = loader('./load.graphql');

interface UsePhishguardData {
  duration: Duration;
}

export function usePhishguardData({ duration }: UsePhishguardData): HookResult {
  const { data, error, loading } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    pollInterval: FIFTEEN_MINUTE_POLL_INTERVAL,
    variables: setInsightsDateRangeFromDuration(duration),
  });

  if (loading) return { data: undefined, error: null, loading: true };
  if (error) return { data: undefined, error, loading: false };

  const filters: FilterType[] = ['all', 'team', 'user'];

  const phishguardData = filters.reduce((acc, filter) => {
    const timeline = mungeTimelineData(data, filter);
    const totals = mungeTotalsData(data, filter);
    acc[filter] = {
      timeline,
      totals,
    };
    return acc;
  }, {} as Record<FilterType, PhishguardAPIData>);

  return {
    data: phishguardData,
    error: null,
    loading: false,
  };
}

interface RawInsightsTimelineData {
  day: string;
  submittedDisposition: 'BULK' | 'MALICIOUS' | 'SPAM';
  total: number;
  type: 'TEAM' | 'USER';
}

interface RawInsightsCategoryData {
  bulk?: number;
  malicious?: number;
  spam?: number;
}

interface RawInsightsTotalsData {
  generic?: RawInsightsCategoryData;
  team?: RawInsightsCategoryData;
  user?: RawInsightsCategoryData;
}

// The shape of the data coming back from the API endpoint (Whisk/Insights)
// This data will be transformed into PhishguardAPIData
interface RawInsightsData {
  phishSubmissions: {
    data: {
      timeline: {
        current: RawInsightsTimelineData[];
        previous?: RawInsightsTimelineData[];
      };
      totals: {
        current: RawInsightsTotalsData;
        previous?: RawInsightsTotalsData;
      };
    };
  };
}

function mungeTimelineData(data: RawInsightsData, filter: FilterType = 'all'): TimelineData[] {
  const currentTimelineRaw = data?.phishSubmissions?.data?.timeline?.current || [];

  const currentTimelineByType = currentTimelineRaw?.filter((timelineRow) => {
    switch (filter) {
      case 'team':
        return timelineRow.type === 'TEAM';
      case 'user':
        return timelineRow.type === 'USER';
      default:
        return true;
    }
  });

  const currentTimelineDates = currentTimelineByType.map((timelineRow) => timelineRow.day);
  const currentTimelineDatesUnique = Array.from(new Set(currentTimelineDates));

  const currentTimelineMunged: TimelineData[] = currentTimelineDatesUnique.map((date) => {
    const timelineElementsForDate = currentTimelineByType.filter((timelineRow) => timelineRow.day === date);
    const BULK = timelineElementsForDate.find((timelineRow) => timelineRow.submittedDisposition === 'BULK')?.total || 0;
    const MALICIOUS =
      timelineElementsForDate.find((timelineRow) => timelineRow.submittedDisposition === 'MALICIOUS')?.total || 0;
    const SPAM = timelineElementsForDate.find((timelineRow) => timelineRow.submittedDisposition === 'SPAM')?.total || 0;

    return {
      date,
      BULK,
      MALICIOUS,
      SPAM,
    };
  });

  return currentTimelineMunged;
}

function mungeTotalsData(data: RawInsightsData, filter: FilterType = 'all') {
  // Current totals
  const currentTotals = data?.phishSubmissions?.data?.totals?.current;
  const { bulk = 0, malicious = 0, spam = 0 } = calculateTotalsByDisposition(currentTotals, filter);

  // Previous totals are only needed to calculate the percent changed
  const previousTotals = data?.phishSubmissions?.data?.totals?.previous;
  const {
    bulk: previousBulk = 0,
    malicious: previousMalicious = 0,
    spam: previousSpam = 0,
  } = calculateTotalsByDisposition(previousTotals, filter);

  const currentTotal = bulk + malicious + spam;
  const previousTotal = previousBulk + previousMalicious + previousSpam;

  const bulkPercent = Math.round(calculatePercent(bulk, currentTotal));
  const maliciousPercent = Math.round(calculatePercent(malicious, currentTotal));
  const spamPercent = Math.round(calculatePercent(spam, currentTotal));

  return {
    bulkPercent,
    currentTotal,
    maliciousPercent,
    previousTotal,
    spamPercent,
  };
}

function calculatePercent(current: number, total: number) {
  if (total === 0) return 0;
  return (current / total) * 100;
}

function calculateTotalsByDisposition(data?: RawInsightsTotalsData, filter?: FilterType) {
  const defaultTotals = {
    bulk: 0,
    malicious: 0,
    spam: 0,
  };

  if (!data) return defaultTotals;

  // The only 2 submission types we display
  const submissionTypes = ['team', 'user'] as const;
  // The only 3 dispositions we display here
  const submissionDispositions = ['bulk', 'malicious', 'spam'] as const;

  return submissionTypes
    .filter((submissionType) => {
      switch (filter) {
        case 'all':
          return ['team', 'user'].includes(submissionType);
        default:
          return submissionType === filter;
      }
    })
    .reduce((memo, submissionType) => {
      const record = data[submissionType];
      if (!record) return memo;

      const updatedMemo = { ...memo };

      submissionDispositions.forEach((submissionDisposition) => {
        if (Object.keys(record).includes(submissionDisposition)) {
          updatedMemo[submissionDisposition] += record[submissionDisposition] || 0;
        }
      });
      return updatedMemo;
    }, defaultTotals);
}
