// @flow

import React, { type ComponentType, type Node } from 'react';

import Error from 'ui/atoms/Error';
import Loading from 'ui/atoms/Loading';

type OutputProps = {
  data: any,
  isEmpty?: (any, isLoading?: boolean) => boolean,
  isError?: boolean,
  isLoading?: boolean,
};

type Args = { empty?: Node, error?: Node, loading?: Node };

const defaultEmpty = <p>Empty?</p>;
const defaultError = <Error />;
const defaultLoading = <Loading />;

export default function withDataHandler(args: Args = {}) {
  const { empty = defaultEmpty, error = defaultError, loading = defaultLoading } = args;
  return function withDataHandlerHOC<InputProps: {}>(
    Component: ComponentType<InputProps>
  ): ComponentType<InputProps & OutputProps> {
    return function WrappedComponent(props: InputProps & OutputProps) {
      const { data, isError, isEmpty = defaultIsEmpty, isLoading, ...rest } = props;

      if (isError) return error;
      if (isLoading && isEmpty(data, isLoading)) return loading;
      if (isEmpty(data)) return empty;

      return <Component data={data} {...rest} />;
    };
  };
}

// Private Functions

// eslint-disable-next-line no-unused-vars
function defaultIsEmpty(data: any, _?: boolean): boolean {
  if (Array.isArray(data)) return !data.length;
  return !data;
}
