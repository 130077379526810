import { styled, Text } from '@a1s/ui';
import { useQuery } from '@apollo/client';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { loader } from 'graphql.macro';
import { get } from 'lodash';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import ConditionalRender from 'ui/atoms/ConditionalRender';

import { useDuration } from 'utils/duration';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';

export const SimilarMessagesCountQuery = loader('./query.graphql');

//
// Main component
// -------------------------------------------------------------------------------------------------

interface SimilarMessagesProps {
  edfHash?: string;
  searchType: 'detection-only' | 'all-mail';
}

export function SimilarMessages({ edfHash, searchType }: SimilarMessagesProps) {
  const duration = useDuration();
  const { t } = useTranslation('dashboardHome');

  const [skipQuery, setSkipQuery] = useState<boolean>(true);

  const { data, error, loading } = useQuery(SimilarMessagesCountQuery, {
    fetchPolicy: 'network-only',
    skip: skipQuery,
    variables: {
      edfHash,
    },
  });

  let similarMessageCount = get(data, 'similarMessagesResults.count', 0);

  // Similar messages count includes the message currently being viewed so we subtract 1
  if (similarMessageCount > 0) {
    similarMessageCount -= 1;
  }

  const similarMessagesGlobalSearch = useGlobalSearch(
    searchType === 'detection-only' ? 'detectionSearch' : 'mailTrace'
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (similarMessageCount <= 0) return;

    const range = [startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), endOfDay(new Date())];
    similarMessagesGlobalSearch({ finalDisposition: '', dateRange: [range[0], range[1]], query: edfHash });
  };

  const handleEnter = () => {
    if (edfHash) {
      setSkipQuery(false);
    }
  };

  return (
    <>
      <Waypoint onEnter={handleEnter} bottomOffset="-500px" />
      <ConditionalRender condition={similarMessageCount <= 0 && !loading && !error}>
        <Text as="div" color="$gray200" font="sans" size="sm" stretch="ultraCondensed">
          {t('similarMessagesNotFound')}
        </Text>
      </ConditionalRender>
      <ConditionalRender condition={!!error}>
        <Text as="div" color="$gray200" font="sans" size="sm" stretch="ultraCondensed">
          {t('similarMessagesError')}
        </Text>
      </ConditionalRender>
      <ConditionalRender
        condition={similarMessageCount > 0 && !loading && !error}
        fallback={
          loading && (
            <Text as="div" color="$gray200" font="sans" size="sm" stretch="ultraCondensed">
              {t('similarMessagesLoading')}
            </Text>
          )
        }
      >
        <Text as="div" color="$gray600" font="sans" size="sm" stretch="ultraCondensed">
          {t('similarMessagesCount', { count: similarMessageCount })} (
          <StyledButton onClick={handleClick}>{t('similarMessagesShow')}</StyledButton>)
        </Text>
      </ConditionalRender>
    </>
  );
}

//
// Private component(s)
// -------------------------------------------------------------------------------------------------

const StyledButton = styled('button', {
  background: 'inherit',
  border: 'none',
  color: '$blue400',
  cursor: 'pointer',
  fontFamily: '$sans',
  fontSize: '$sm',
  fontStretch: 'ultra-condensed',
  textDecoration: 'underline',

  [`&:hover `]: {
    color: '$black',
  },
});
