// @flow

// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledCell } from '../dataTypesAndUtils';

import { mungeProgressTableData, type SenderMismatchList } from './dataTypesAndUtils';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import TruncateText from 'ui/atoms/TruncateText';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import ProgressTable from 'ui/molecules/ProgressTable';
import ProgressTablePlaceholder from 'ui/molecules/ProgressTablePlaceholder';
import TextEllipsisTooltip from 'ui/molecules/TextEllipsisTooltip';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

type Props = { data: Array<SenderMismatchList>, error: boolean, loading: boolean };

const ProgressTableWithDataHandler = withDataHandler({
  empty: <ProgressTablePlaceholder />,
})(ProgressTable);

export default function MostCommonMismatchedSenders({ data, error, loading }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader
            title={t('commonMismatchedSenders')}
            action={
              <Button
                disabled={data.length === 0}
                icon="hamburger"
                muted
                onClick={() => data.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
          <ProgressTableWithDataHandler
            /* eslint-disable no-shadow */
            // eslint-disable-next-line no-unused-vars
            calculatePercent={(data, [_0, _1, number]) =>
              Number(number) / data.reduce((a, b) => Number(a) + Number(b[2]), 0)
            }
            cellWidths={['45%', '45%', '10%']}
            data={mungeProgressTableData(data)}
            headings={[t('envelopeFrom'), t('messageFrom'), t('occurrences')]}
            isEmpty={() => loading || error || data.length === 0}
            isLoading={loading}
            renderHeader={(data, index) => {
              if (index === 0)
                return (
                  // $FlowFixMe
                  <TruncateText useEllipsis={false} width={400}>
                    {data}
                  </TruncateText>
                );
              if (index === 1)
                return (
                  // $FlowFixMe
                  <TruncateText useEllipsis={false} width={400}>
                    <StyledCell centered>{data}</StyledCell>
                  </TruncateText>
                );
              return data;
            }}
            renderCell={(data, _rowIndex, index) => {
              if (index === 0)
                return (
                  // $FlowFixMe
                  <TextEllipsisTooltip tooltipPosition="top" width={300}>
                    {data}
                  </TextEllipsisTooltip>
                );
              if (index === 1)
                return (
                  // $FlowFixMe
                  <TextEllipsisTooltip tooltipPosition="top" width={300}>
                    {data}
                  </TextEllipsisTooltip>
                );
              if (index === 2)
                return (
                  // $FlowFixMe
                  <TruncateText width={50}>{data}</TruncateText>
                );
              return data;
            }}
          />
        </>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>
          {openModal && <DetailsTable error={error} data={data} loading={loading} />}
        </Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
