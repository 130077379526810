// @flow

import { camelCase } from '@a1s/lib';
// $FlowFixMe
import { GraphTable, styled } from '@a1s/ui';
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { get } from 'lodash';

// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderData } from '../dataTypesAndUtils';

import { dataForGraphTable, mapSendersWithPatternType } from './dataTypesAndUtils';

// $FlowFixMe
import { filterAndSortData } from 'screens/Dashboard/panels/dataTypesAndUtils';
// $FlowFixMe
import { Searchable } from 'screens/shared/Searchable';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import Dropdown from 'ui/organisms/Dropdown';
import Split from 'ui/templates/Split';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';

export const insightsAllowListByTypeQuery = loader('./queries/InsightsAllowListByType.graphql');

const DetailsTable = lazy(() => import('./DetailsTable'));

const allowTypes = ['Allowed Recipients', 'Allowed Senders', 'Acceptable Senders'];

export default function AllowedRecipients() {
  const duration = useDuration();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState('Allowed Recipients');
  const { t } = useTranslation('email');

  const { data, error, loading } = useQuery(insightsAllowListByTypeQuery, {
    variables: {
      limit: 5,
      ...setInsightsDateRangeFromDuration(duration),
    },
  });
  const allowedTypesData = get(data, 'allowListByType.data', {});
  const allAllowedTypes = mapSendersWithPatternType(allowedTypesData);
  const sortedData = allAllowedTypes && filterAndSortData({ filterBy: 'pattern', sortBy: 'count' }, allAllowedTypes);
  const tableData =
    dataForGraphTable(sortedData, selected, t).length > 0
      ? dataForGraphTable(sortedData, selected, t)
      : placeholderData;
  const searchableParams = selected === 'Allowed Recipients' ? 'target' : 'sender';

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <Split>
            <Dropdown
              expanded={false}
              icon="caret"
              /* eslint-disable no-shadow */
              onOptionSelected={(selected) => setSelected(t(`${selected}`))}
              options={allowTypes}
              renderAsOption={(allowTypes) => t(camelCase(allowTypes))}
              size="large"
            >
              {t(camelCase(selected))}
            </Dropdown>
            <Button
              disabled={Object.keys(allowedTypesData).length === 0}
              icon="hamburger"
              muted
              onClick={() => Object.keys(allowedTypesData).length > 0 && setOpenModal(true)}
            >
              {t('viewDetails')}
            </Button>
          </Split>
        </>
      }
      bottom={
        <GraphTable.Loadable
          loading={loading || error}
          placeholder={{
            hasData: dataForGraphTable(sortedData, selected, t).length > 0,
            text: t('components:NoData.noDataObserved'),
          }}
        >
          {tableData.map(({ label, value }) => {
            // eslint-disable-next-line no-unused-vars
            const [first, ..._] = tableData;
            const percent = Math.max(value / first.value, 0.1);
            // color #375358 === teal900
            const linearColor = `#375358 ${percent * 100}%`;
            const transparency = `transparent ${percent * 100}%`;

            return (
              <GraphTable.Row
                css={{
                  backgroundImage: `linear-gradient(to right, ${linearColor}, ${transparency}, transparent)`,
                  opacity: `${percent}`,
                }}
                value={value}
              >
                <Searchable params={{ [searchableParams]: label }} searchType="mailTrace">
                  <GraphTable.Label label={label} />
                </Searchable>
              </GraphTable.Row>
            );
          })}
        </GraphTable.Loadable>
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>
          {openModal && <DetailsTable fetchData={openModal} filter={selected} />}
        </Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}

export const TrucatedText = styled(Text, {
  display: 'block',
  wordBreak: 'break-all',
});
