"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.letterSpacings = void 0;
const letterSpacings = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em'
};
exports.letterSpacings = letterSpacings;