import { gql, useQuery } from '@apollo/client';

const GET_CF_ACCOUNTS_QUERY = gql`
  query GetCfAccountsForUser {
    accounts @rest(path: "/cf-auth/accounts", type: "CfAccount") {
      id
      name
      __typename
    }
  }
`;

export function useGetCfAccounts() {
  return useQuery(GET_CF_ACCOUNTS_QUERY, { fetchPolicy: 'network-only' });
}
