import { styled, Separator as BaseSeparator } from '@a1s/ui';

import { ReactComponent as CheckMarkSVG } from './check-mark.svg';
import { ReactComponent as ProblemSVG } from './large-warning.svg';
import { ReactComponent as LiveModeSVG } from './live-mode.svg';

export const Cell = styled('td', {
  color: '$gray600',
  fontFamily: '$sans',
  fontSize: '$xs',
  fontStretch: '50%',
  fontWeight: 400,
  textTransform: 'uppercase',
});

export const ChartWrapper = styled('div', {
  width: '45%',
});

export const CheckMark = styled(CheckMarkSVG, {
  color: '$white',
  display: 'block',
  width: '2rem',
});

export const Header = styled('th', {
  color: '$gray700',
  fontFamily: '$sans',
  fontSize: '$xs',
  fontStretch: '50%',
  fontWeight: 500,
  textAlign: 'left',
  textTransform: 'uppercase',
  verticalAlign: 'baseline',

  svg: { transform: 'translateY(3.5px)' },

  variants: {
    error: { true: { color: '$red600' } },
  },
});

export const Link = styled('a', {
  color: '$blue300',

  '&:hover': {
    color: '$blue400',
    textDecorationColor: '$blue200',
  },
});

export const LiveMode = styled(LiveModeSVG, {
  alignSelf: 'center',
  fill: 'currentColor',
  display: 'block',
  width: '1rem',
});

export const Problem = styled(ProblemSVG, {
  color: '$gray300',
  display: 'block',
  width: '2rem',
});

export const Separator = styled(BaseSeparator, {
  minHeight: '$sizes$5xl !important',
});

export const Table = styled('table', {
  borderCollapse: 'collapse',
  marginTop: '$3',
});

export const TiltedDivider = styled('div', {
  alignSelf: 'stretch',
  height: 100,
  position: 'relative',
  width: '$xs',

  '&::after': {
    backgroundColor: '$gray300',
    bottom: 0,
    content: '',
    display: 'block',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: ' translate(-1.75rem, 1.25rem) rotate(38deg)',
    width: '1px',
  },
});
