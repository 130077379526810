import { random } from '@a1s/lib';
import { Box, Cluster, Stack, Text } from '@a1s/ui';
import React, { useRef, Component, ComponentProps, ErrorInfo, PropsWithChildren, ReactNode } from 'react';

import { Alert, BaseContainer, ContentContainer, ErrorDisplay, TitleContainer } from './styled';

//
// Main component
// -------------------------------------------------------------------------------------------------

export interface PanelContainerProps {
  /**
   * The title of the widget, which is displayed in the floating title box.
   */
  title: ComponentProps<typeof Title>['children'];

  /**
   * Content that is displayed to the right of the title
   */
  titleDecoration?: ComponentProps<typeof Title>['children'];
}

export function PanelContainer({ children, title, titleDecoration }: PropsWithChildren<PanelContainerProps>) {
  // We generate a unique, random id for each instance of Widget so the aria, accessibility attributes
  // work properly.
  const titleIdRef = useRef(`widget-${random(6)}`);

  return (
    <BaseContainer aria-labelledby={titleIdRef.current} role="region">
      <Title accessibilityId={titleIdRef.current}>
        {title}
        {titleDecoration}
      </Title>
      <Content>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Content>
    </BaseContainer>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface ContentProps {
  /**
   * The widget contents to be displayed
   */
  children?: ReactNode;
}

function Content({ children }: ContentProps) {
  return <ContentContainer>{children}</ContentContainer>;
}

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundryState {
  hasError: boolean;
}

/**
 * Captures any errors that are thrown within the children of this component.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundryState> {
  public state: ErrorBoundryState = { hasError: false };

  // eslint-disable-next-line no-unused-vars
  static getDerivedSateFromError(_: Error): ErrorBoundryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('[ERROR IN PANEL]', error, errorInfo);
    if (!window.Sentry) return;
    Sentry.captureException(error, { extra: errorInfo as unknown as Record<string, unknown> });
  }

  /* eslint-disable react/destructuring-assignment */
  public render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <ErrorDisplay>
        <Stack align="center" gap>
          <Alert />
          <Text color="$gray600" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="medium">
            An error has occured
          </Text>
        </Stack>
      </ErrorDisplay>
    );
  }
  /* eslint-enable react/destructuring-assignment */
}

interface TitleProps {
  /**
   * An instance-unique string to help identity the title of the widget for accessibility
   */
  accessibilityId: string;
}

function Title({ accessibilityId, children }: PropsWithChildren<TitleProps>) {
  return (
    <TitleContainer data-title>
      <Box px>
        <Cluster>
          <Box bg="$orange500" css={{ px: '6px' }} py="0.5" rt="1">
            <Text
              as="h1"
              color="$white"
              font="sans"
              id={accessibilityId}
              size="xs"
              stretch="semiCondensed"
              tracking="wide"
              transform="uppercase"
              weight="semibold"
            >
              {children}
            </Text>
          </Box>
        </Cluster>
      </Box>
    </TitleContainer>
  );
}
