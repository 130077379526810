// @flow

// $FlowFixMe
import { Box, HorizontalBars, Loadable, Text } from '@a1s/ui';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderDataArray } from '../dataTypesAndUtils';
import { LabeledValue } from '../ui/LabeledValue';

import { type SuspiciousTargetedRecipient } from './dataTypesAndUtils';

import ConditionalRender from 'ui/atoms/ConditionalRender';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';

type Props = {
  data: Array<SuspiciousTargetedRecipient>,
  error: boolean,
  loading: boolean,
};

export default function TopTargetedRecipients({ data, error, loading }: Props) {
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="top-targeted-recipients-component"
      top={
        <>
          <BlockHeader title={t('topSuspiciousTargets')} />
          <ConditionalRender
            condition={!error && (loading || dataForGraphTable(data).length > 0)}
            fallback={
              <Box css={{ textAlign: 'center' }} pb>
                <Text color="$gray400" size="sm" stretch="ultraCondensed">
                  {t('components:NoData.noDataObserved')}
                </Text>
              </Box>
            }
          >
            <HorizontalBars gap="2">
              {(loading ? placeholderDataArray(5) : dataForGraphTable(data)).map((row) => {
                return (
                  <HorizontalBars.Bar color="$gray100" key={row[0]} value={row[1]}>
                    <Loadable loading={loading}>
                      <LabeledValue
                        childrenColor="$gray600"
                        gap="5"
                        params={{
                          disposition: 'suspicious',
                          target: row[0],
                        }}
                        value={row[1]}
                      >
                        {row[0]}
                      </LabeledValue>
                    </Loadable>
                  </HorizontalBars.Bar>
                );
              })}
            </HorizontalBars>
          </ConditionalRender>
        </>
      }
    />
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------
const dataForGraphTable = (data) => {
  if (!data) return [];
  return data.map((row) => [row.address, row.count]);
};
