// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

// $FlowFixMe
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { placeholderData } from '../dataTypesAndUtils';

import { dataForGraphTable, insightsBlockListByTypeQuery } from './dataTypesAndUtils';

import Button from 'ui/atoms/Button';
import Loading from 'ui/atoms/Loading';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import Drawer from 'ui/molecules/Drawer';
import GraphTable from 'ui/molecules/GraphTable';
import PlaceholderGraphTable from 'ui/molecules/PlaceholderGraphTable';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';
import withDataHandler from 'utils/withDataHandler';

const DetailsTable = lazy(() => import('./DetailsTable'));

const GraphTableDataHandler = withDataHandler({
  empty: <PlaceholderGraphTable data={placeholderData} />,
})(GraphTable);

export default function BlockedRecipients() {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('email');
  const duration = useDuration();
  const { data, error, loading } = useQuery(insightsBlockListByTypeQuery, {
    variables: { limit: 5, ...setInsightsDateRangeFromDuration(duration) },
  });
  const blockedData = get(data, 'blockListByType.data.blockedSender', []);

  return (
    <ContentBlock
      zebraStripe={false}
      top={
        <>
          <BlockHeader
            title={t('topBlockedPatterns')}
            action={
              <Button
                disabled={blockedData.length === 0}
                icon="hamburger"
                muted
                onClick={() => blockedData.length > 0 && setOpenModal(true)}
              >
                {t('viewDetails')}
              </Button>
            }
          />
        </>
      }
      bottom={
        <GraphTableDataHandler
          colorScheme="sea"
          data={dataForGraphTable(blockedData)}
          isEmpty={() => loading || error || blockedData.length === 0}
          isLoading={loading}
        />
      }
    >
      <Drawer.Modal visible={openModal} onClose={() => setOpenModal(false)} size="large" paddedSides={false}>
        <Suspense fallback={<Loading />}>{openModal && <DetailsTable fetchData={openModal} />}</Suspense>
      </Drawer.Modal>
    </ContentBlock>
  );
}
