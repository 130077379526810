import { styled } from '@a1s/ui';

/**
 * The styled component makes sure the browser doesn't draw extra decorations
 * on the <abbr> element.
 */
export const StyledAbbr = styled('abbr', {
  borderBottom: 'none',
  cursor: 'inherit',
  outline: 'none',
  textDecoration: 'none',
});
