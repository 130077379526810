// @flow

import { rem } from 'polished';
// $FlowFixMe
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent } from './bell.svg';

//
// Styled Components
// -------------------------------------------------------------------------------------------------

const Icon = styled(ReactComponent)`
  height: ${rem(16)};
  width: ${rem(14)};

  path {
    fill: ${({ theme }) => theme.colors.pastelBlue};
    transition: fill 150ms ${({ theme }) => theme.timing.easeOutCirc};
  }
`;

const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: ${rem(32)};
  justify-content: center;
  width: ${rem(32)};
`;

const Unread = styled.div.attrs({ role: 'presentation' })`
  background-color: ${(p) => p.theme.colors.redOrange};
  border: ${(p) => p.theme.colors.white} solid ${rem(2)};
  border-radius: ${rem(8)};
  height: ${rem(12)};
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 250ms ${(p) => p.theme.timing.easeOutBack};
  width: ${rem(12)};

  &[aria-hidden='true'] {
    transform: scale(0);
  }
`;

const Wrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: ${rem(24)};
  justify-content: center;
  position: relative;
  transition: background-color 250ms ${({ theme }) => theme.timing.easeOutCirc};
  width: ${rem(24)};

  *:hover > & {
    path {
      fill: ${({ theme }) => theme.colors.grayBlue};
    }
  }

  a[aria-current='page'] & {
    background-color: ${({ theme }) => theme.colors.antiFlashWhite};
  }

  a[aria-current='page'] & path:first-child {
    fill: ${({ theme }) => theme.colors.deepSkyBlue};
  }
`;

//
// Main Component
// -------------------------------------------------------------------------------------------------

interface Props {
  unread?: boolean;
}

function NotificationsLink({ unread = false, ...props }: Props, ref: any) {
  const { t } = useTranslation('common');

  return (
    <Container aria-label={t('notifications')} ref={ref} role="button" {...props}>
      <Wrapper>
        <Icon />
        <Unread aria-hidden={!unread} />
      </Wrapper>
    </Container>
  );
}

export default forwardRef(NotificationsLink);
