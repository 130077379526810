// @flow

import { random } from '@a1s/lib';
import React, { type Node, Component } from 'react';

import Container from './Container';

type Props = { anchor: HTMLElement, children?: Node, scrollNode?: ?HTMLElement };
type State = { clientX: number, clientY: number, visible: boolean, scrollHeight: number };

class TooltipTrackingMouse extends Component<Props, State> {
  htmlId: string = random();

  ref: ?HTMLSpanElement = null;

  state = { clientX: 0, clientY: 0, visible: false, scrollHeight: 0 };

  componentDidMount() {
    const { anchor } = this.props;
    anchor.addEventListener('mouseenter', this.handleMouseEnter);
    anchor.addEventListener('mouseleave', this.handleMouseLeave);
  }

  componentWillUnmount() {
    const { anchor } = this.props;
    anchor.removeEventListener('mouseleave', this.handleMouseLeave);
    anchor.removeEventListener('mouseenter', this.handleMouseEnter);
    anchor.removeEventListener('mousemove', this.handleMouseMove);
    anchor.removeEventListener('mousewheel', this.handleMouseWheel);
  }

  getMousePosition() {
    const { anchor } = this.props;
    const { clientY, clientX, scrollHeight } = this.state;
    const top = clientY + scrollHeight - 40; // -40 to be sure the tooltip isn't on top of the mouse
    const node = anchor.parentNode ? anchor.parentNode : anchor;
    // $FlowIssue I'm checking if parentNode is available on line #41 but Flow keeps complaining
    const rect = node.getBoundingClientRect();
    let left = clientX - rect.left;

    if (this.ref) {
      const tooltipRect = this.ref.getBoundingClientRect();
      const { documentElement } = document;
      const documentWidth = documentElement !== null ? documentElement.clientWidth : 0;
      const windowWidth = window.innerWidth || documentWidth;
      const overflowRight = clientX + tooltipRect.width - windowWidth;
      const overflowLeft = tooltipRect.width - clientX;
      if (overflowLeft > 0 && overflowLeft > overflowRight) {
        left += overflowLeft;
      } else if (overflowRight > 0) {
        left -= overflowRight;
      }
    }

    return { top, left };
  }

  assignRef = (ref: any) => {
    this.ref = ref;
  };

  handleMouseWheel = () => {
    const { scrollNode } = this.props;
    if (scrollNode) {
      setTimeout(this.updateScrollHeight.bind(this), 250); // wait for scroll event to finish
    }
  };

  handleMouseMove = (event: MouseEvent) => {
    const { clientX, clientY } = event;
    const { scrollNode, anchor } = this.props;
    const { left, top, bottom, right } = anchor.getBoundingClientRect();
    let { scrollHeight } = this.state;
    let visible = true;
    if (clientY > bottom || clientY < top || clientX < left || clientX > right) {
      visible = false;
    }
    if (scrollNode) {
      scrollHeight = scrollNode.scrollTop || 0;
    }
    this.setState({ clientX, clientY, scrollHeight, visible });
  };

  handleMouseEnter = () => {
    const { anchor } = this.props;
    anchor.addEventListener('mousemove', this.handleMouseMove);
    anchor.addEventListener('mousewheel', this.handleMouseWheel);
    this.setState({ visible: true });
  };

  handleMouseLeave = () => {
    const { anchor } = this.props;
    anchor.removeEventListener('mousemove', this.handleMouseMove);
    anchor.removeEventListener('mousewheel', this.handleMouseWheel);
    this.setState({ visible: false });
  };

  updateScrollHeight = () => {
    // This is needed along with handleMouseWheel because the scrollTop isn't updated until after
    // the event is fired
    const { scrollNode } = this.props;
    let scrollHeight = 0;
    if (scrollNode) {
      scrollHeight = scrollNode.scrollTop;
    }
    this.setState({ scrollHeight });
  };

  render() {
    const { anchor, children } = this.props;
    const { visible } = this.state;
    const { top, left } = this.getMousePosition();

    if (!anchor) return null;

    // Attach the tooltip to the anchor ref to make the component accessible.
    // More info: https://www.sarasoueidan.com/blog/accessible-tooltips/
    anchor.setAttribute('aria-describedby', this.htmlId);

    return (
      <Container
        ref={this.assignRef}
        visible={visible}
        id={this.htmlId}
        style={{ top, left }}
        aria-hidden={!visible.toString()}
      >
        {children}
      </Container>
    );
  }
}

export default TooltipTrackingMouse;
