// @flow

import { AnimatePresence } from 'framer-motion';
import { rem } from 'polished';
import React, { PureComponent } from 'react';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as CloseSVG } from './close.svg';
import Container from './Container';
import Icon from './Icon';
import Input from './Input';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Close = styled(CloseSVG)`
  cursor: pointer;
  height: ${rem(12)};
  flex-shrink: 0;
  margin-left: ${({ theme }) => rem(theme.spacing.sm)};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  pointer-events: ${({ hidden }) => (hidden ? 'none' : 'auto')};
  transition: opacity 250ms ${({ theme }) => theme.timing.easeOutCirc};
  width: ${rem(12)};
  /* stylelint-disable no-descending-specificity */
  path {
    fill: ${({ theme }) => theme.colors.grayBlue};
    transition: fill 150ms ${({ theme }) => theme.timing.easeOutCirc};
  }
  /* stylelint-enable no-descending-specificity */

  &:hover path {
    fill: ${({ theme }) => theme.colors.jet};
  }
`;

type Props = {
  dataTestId?: string,
  expandAt?: number,
  expandTo?: number,
  location?: Object,
  onBlur?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onClear?: (event: SyntheticMouseEvent<any>) => void,
  onChange?: (target: any) => void,
  onFocus?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onKeyUp?: (target: any) => void,
  placeholder?: string,
  small?: boolean,
};

type State = { focused: boolean, small?: boolean, text: string };

class SearchInput extends PureComponent<Props, State> {
  ref: ?HTMLInputElement = null;

  // eslint-disable-next-line react/destructuring-assignment
  state = { focused: false, text: this.props.location ? getText(this.props.location) : '' };

  handleClearClick = (event: SyntheticMouseEvent<any>) => {
    const { onClear } = this.props;
    this.setState({ text: '' });
    if (onClear) onClear(event);
  };

  onBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ focused: false });
    const { onBlur } = this.props;
    if (onBlur) onBlur(event);
  };

  onChange = ({ target }: SyntheticInputEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(target.value.toLowerCase());
    }
    this.setState({
      text: target.value,
    });
  };

  onClick = () => {
    if (this.ref) this.ref.focus();
  };

  onFocus = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ focused: true });
    const { onFocus } = this.props;
    if (onFocus) onFocus(event);
  };

  render() {
    const {
      dataTestId = 'atom-search-input',
      expandAt = 20,
      expandTo = 350,
      placeholder,
      small,
      onClear,
      onKeyUp,
    } = this.props;
    const { focused, text } = this.state;

    return (
      <Translation ns="components">
        {(t) => (
          <AnimatePresence initial={false}>
            <Container
              animate={{ width: text.length > expandAt ? expandTo : '100%' }}
              data-testid={dataTestId}
              focused={focused}
              onClick={this.onClick}
              small={small}
            >
              <Icon focused={focused ? 'true' : undefined} />
              <Input
                onBlur={this.onBlur}
                onChange={this.onChange}
                onKeyUp={onKeyUp}
                onFocus={this.onFocus}
                placeholder={placeholder || t('SearchInput.placeholder')}
                ref={(ref) => {
                  this.ref = ref;
                }}
                value={text}
              />
              {typeof onClear === 'function' && <Close hidden={!text} onClick={this.handleClearClick} />}
            </Container>
          </AnimatePresence>
        )}
      </Translation>
    );
  }
}

// $FlowFixMe
const ForwardRefSearchInput = React.forwardRef((props, ref) => <SearchInput ref={ref} {...props} />);

export default ForwardRefSearchInput;

/*
  Private functions
*/

function getText(location) {
  if (location.state && location.state.searchTerm) return location.state.searchTerm;
  return '';
}
