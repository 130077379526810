// @flow

import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mungeLineGraphData, type MaliciousAttachmentCountsByDate } from './dataTypesAndUtils';

import PaddedContent from 'ui/atoms/PaddedContent';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import PlaceholderLineGraph from 'ui/molecules/PlaceholderLineGraph';
import RingChart from 'ui/molecules/RingChart';
import SearchLink from 'ui/molecules/SearchLink';
import DataView from 'ui/organisms/DataView';
import SectionOverview from 'ui/organisms/SectionOverview';
import Split from 'ui/templates/Split';
import useGlobalSearch from 'utils/hooks/useGlobalSearch';
import withDataHandler from 'utils/withDataHandler';

type Data = {
  lineGraph: {
    current: MaliciousAttachmentCountsByDate[],
    previous: MaliciousAttachmentCountsByDate[],
  },
  ringChart: number,
  sectionOverview: {
    current: number,
    previous: number,
  },
};

type Props = {
  data: Data,
  error: boolean,
  loading: boolean,
};

const DataViewWithDataHandler = withDataHandler({
  empty: <PlaceholderLineGraph />,
})(DataView);

export default function MaliciousAttachments({ data, error, loading }: Props) {
  const searchDetections = useGlobalSearch('detectionSearch');
  const { current, previous } = get(data, 'lineGraph', { current: [], previous: [] });
  const ringChart = get(data, 'ringChart', 0);
  const { current: sectionCurrent, previous: sectionPrevious } = get(data, 'sectionOverview', {
    current: 0,
    previous: 0,
  });
  const { t } = useTranslation('email');

  return (
    <ContentBlock
      dataTestId="malicious-attachments-component"
      top={
        <>
          <BlockHeader
            pullBottom
            title={
              <SearchLink handleSearch={() => searchDetections({ query: 'attachment contains malware' })}>
                {t('maliciousAttachments')}
              </SearchLink>
            }
          />
          <Split>
            <SectionOverview
              currentValue={sectionCurrent}
              dimmed={loading}
              dataTestId="malicious-attachments-statistic"
              formatValue="number"
              previousValue={sectionPrevious}
            />
            <RingChart formatValue="number" label={t('totalMaliciousEmails')} percent={ringChart} />
          </Split>
          <PaddedContent pushTop="xl">
            <DataViewWithDataHandler
              data={{
                '': {
                  current: mungeLineGraphData(current),
                  previous: mungeLineGraphData(previous),
                },
              }}
              isEmpty={() => loading || error || current.length === 0}
              isLoading={loading}
              previousData={mungeLineGraphData(previous)}
            />
          </PaddedContent>
        </>
      }
    />
  );
}
