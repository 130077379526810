// @flow

import { AxisBottom } from '@vx/axis';
import { scaleTime } from '@vx/scale';
import { extent } from 'd3-array';
import { rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { HEIGHT, X_OFFSET } from './util';

import type { Data } from './util';

import type { Theme } from 'config/theme';

type Props = { data: Data[], theme: Theme, width: number };
const XAxis = ({ data, theme, width }: Props) => {
  const { t } = useTranslation('const');

  const x = (d) => d.date;
  const xScale = scaleTime({ domain: extent(data, x), range: [0, width - X_OFFSET] });
  const sortedDates = data.sort((a, b) => b.date - a.date).map(({ date }) => date);
  const beginDate = new Date(sortedDates[0]);
  const endDate = new Date(sortedDates[sortedDates.length - 1]);
  let medianDate;
  let tickValues;
  if (sortedDates.length > 2) {
    medianDate = new Date((beginDate.getTime() + endDate.getTime()) / 2);
    tickValues = [beginDate, medianDate, endDate];
  } else {
    tickValues = [beginDate, endDate];
  }

  return (
    <AxisBottom
      hideAxisLine
      hideTicks
      scale={xScale}
      tickFormat={(val) => t('DatePicker.dateShortMonthShortDayNoYear', { date: val }).toUpperCase()}
      tickValues={tickValues}
      tickLabelProps={(_, i) => ({
        dy: -1,
        fill: rgba(theme.colors.raisinBlack, 0.5),
        fontFamily: theme.fonts.roboto,
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 1,
        textAnchor: textAnchorForIndex(i),
      })}
      top={HEIGHT - 18}
    />
  );
};

export default withTheme(XAxis);

function textAnchorForIndex(index) {
  if (index === 0) return 'end';
  if (index === 6) return 'start';

  return 'middle';
}
