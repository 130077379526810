import { Box, Stack } from '@a1s/ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MailviewData, SearchResultRow } from '../../../types';
import { BenignEmailPreview, EmailDetails } from '../../../ui';

import { DetectionEmailPreview, MailTrace, RawEmail, Scrollable, Tabs } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface EmailDataProps {
  emlPath?: string;
  clientRecipients?: string[];
  clientUuid?: string;
  detailData?: MailviewData;
  isBenign: boolean;
  isJournaled?: boolean;
  isQuarantined?: boolean;
  lookupKey?: string;
  mailviewLoading?: boolean;
  messageId?: string;
  searchInfo?: SearchResultRow;
  previewEnabled?: boolean;
}

export function EmailData({
  emlPath,
  clientRecipients,
  clientUuid,
  detailData,
  isBenign,
  isJournaled,
  isQuarantined,
  lookupKey,
  mailviewLoading,
  messageId,
  previewEnabled,
  searchInfo,
}: EmailDataProps) {
  const { t } = useTranslation('unisearch');
  type TabState = 'details' | 'downloads' | 'mailtrace' | 'preview' | 'raw';

  const [selected, setSelected] = useState<TabState>('details');
  const { screenshot } = detailData || {};

  const messagePreviewDisabled = clientRecipients?.length === 0;

  return (
    <Stack css={{ flexGrow: 2 }}>
      <Tabs>
        <Tabs.Tab onClick={() => setSelected('details')} selected={selected === 'details'}>
          {t('emailDetails')}
        </Tabs.Tab>
        <Tabs.Tab
          disabled={messagePreviewDisabled}
          onClick={() => setSelected('preview')}
          selected={selected === 'preview'}
          title={messagePreviewDisabled ? t('noPreview') : null}
        >
          {t('emailPreview')}
        </Tabs.Tab>
        <Tabs.Tab
          disabled={isBenign}
          onClick={() => setSelected('raw')}
          selected={selected === 'raw'}
          title={isBenign ? t('noRawEmail') : null}
        >
          {t('rawEmail')}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setSelected('mailtrace')} selected={selected === 'mailtrace'}>
          {t('mailTrace')}
        </Tabs.Tab>
      </Tabs>

      <Box bg="$white" css={{ flexGrow: 1, height: '100%' }} r p="2">
        <Box css={{ height: '100%', position: 'relative' }}>
          <Scrollable>
            <Box p="2">
              {selected === 'details' && (
                <EmailDetails
                  isBenign={isBenign}
                  loading={mailviewLoading}
                  mailviewHighlightData={detailData?.highlightData}
                  searchInfo={searchInfo}
                />
              )}
              {selected === 'preview' && (
                <GetMessagePreview
                  clientRecipients={clientRecipients}
                  clientUuid={clientUuid}
                  image={screenshot}
                  loading={mailviewLoading}
                  messageId={messageId}
                  previewEnabled={previewEnabled}
                />
              )}

              {selected === 'raw' && <RawEmail emlPath={emlPath} />}
              {selected === 'mailtrace' && (
                <MailTrace lookupKey={lookupKey} showOutbound={!isJournaled && !isQuarantined} />
              )}
            </Box>
          </Scrollable>
        </Box>
      </Box>
    </Stack>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface GetPreviewType {
  clientRecipients?: string[];
  clientUuid?: string;
  image?: string;
  isBenign?: boolean;
  loading?: boolean;
  messageId?: string;
  previewEnabled?: boolean;
}

export function GetMessagePreview({
  clientRecipients,
  clientUuid,
  image,
  loading,
  messageId,
  previewEnabled,
}: GetPreviewType) {
  /*
   If the message's 'stored_at' is null AND clientRecipients.length > 0
   we render from /previews otherwise from /mailview
   */
  if (previewEnabled)
    return <BenignEmailPreview clientRecipients={clientRecipients} clientUuid={clientUuid} messageId={messageId} />;
  return <DetectionEmailPreview image={image} loading={loading} />;
}
