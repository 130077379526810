// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
import LoginPage from './LoginPage';

import PageNotFound from 'ui/organisms/PageNotFound';
import LoginTemplate from 'ui/templates/Login';

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function LoginScreen() {
  return (
    <LoginTemplate
      main={
        <Switch>
          <Route component={ForgotPassword} path="/users/login/forgot-password" />
          <Route component={LoginPage} path="/users/login" />
          <Route component={PageNotFound} path="*" status={404} />
        </Switch>
      }
    />
  );
}
