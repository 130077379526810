"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transitions = void 0;
const transitions = {
  easeInOutBack: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
  quick: 'cubic-bezier(0.16, 1, 0.3, 1)'
};
exports.transitions = transitions;