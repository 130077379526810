// @flow
// $FlowFixMe
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

import { get } from 'lodash';
// $FlowFixMe
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OverviewPhishLineGraph from './OverviewPhishLineGraph';

import Button from 'ui/atoms/Button';
import Link from 'ui/atoms/Link';
import BlockHeader from 'ui/molecules/BlockHeader';
import ContentBlock from 'ui/molecules/ContentBlock';
import SectionOverview from 'ui/organisms/SectionOverview';
import TwoColumn from 'ui/templates/TwoColumn';
// $FlowFixMe
import { setInsightsDateRangeFromDuration } from 'utils/dateRangeFromDuration';
// $FlowFixMe
import { useDuration } from 'utils/duration';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';

export const systemStatsQuery = loader('./queries/InsightsSystemStatsQuery.graphql');

export default function Detections() {
  const duration = useDuration();

  const [selectedDetection, setSelectedDetection] = useState('');

  const { user } = useCurrentUser();
  const becEnabled = get(user, 'maliciousBecEnabled', false);

  const { data, error, loading } = useQuery(systemStatsQuery, {
    variables: setInsightsDateRangeFromDuration(duration),
  });

  const systemStatsData = get(data, 'insightsSystemStats.data', {
    chartByDay: [
      {
        attacksPrevented: 0,
        day: new Date(),
        emailsProcessed: 0,
      },
    ],
    totalAttacksPrevented: 0,
    totalAttacksPreventedPrevious: 0,
    totalEmailsProcessed: 0,
    totalEmailsProcessedPrevious: 0,
  });

  const total = get(systemStatsData, 'totalEmailsProcessed', 0);
  const totalPrev = get(systemStatsData, 'totalEmailsProcessedPrevious', 0);

  const totalPhish = get(systemStatsData, 'totalAttacksPrevented', 0);
  const totalPhishPrev = get(systemStatsData, 'totalAttacksPreventedPrevious', 0);

  const { t } = useTranslation('email');

  return (
    <ContentBlock
      top={
        <>
          <BlockHeader
            title={t('detections')}
            action={
              <Link to="/email/detections">
                <Button dataTestId="detections-view-details-button">{t('viewDetails')}</Button>
              </Link>
            }
          />
          <TwoColumn>
            <SectionOverview
              animate
              currentValue={totalPhish}
              dataTestId="detections-total-phish"
              dimmed={error || loading}
              formatValue="number"
              previousValue={totalPhishPrev}
              title={t('totalPhish')}
            />
            <SectionOverview
              animate
              currentValue={total}
              dataTestId="detections-total-emails-processed"
              dimmed={error || loading}
              formatValue="number"
              previousValue={totalPrev}
              title={t('totalEmailsProcessed')}
            />
          </TwoColumn>
        </>
      }
      bottom={
        <OverviewPhishLineGraph
          becEnabled={becEnabled}
          detectionLoading={loading}
          selectedDetection={selectedDetection}
          setSelectedDetection={setSelectedDetection}
        />
      }
      dataTestId="detections-card"
    />
  );
}
