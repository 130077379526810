// @flow

type Node = { id: string };
type Nodes = Array<Node>;
type Link = { source: string, target: string, value: number };
type Links = Array<Link>;
export type Data = { nodes: Nodes, links: Links };

export const HEIGHT: number = 1048;
export const NODE_PADDING: number = 45;
export const NODE_WIDTH: number = 380;
