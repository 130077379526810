// @flow

import { rem } from 'polished';
import styled from 'styled-components';

const Tooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.jet};
  border-radius: ${rem(4)};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  opacity: 0.7;
  padding: 12px;
  pointer-events: none;
  position: absolute;
  min-width: ${rem(200)};
`;

export default Tooltip;
