// @flow

import { toQueryString } from '@a1s/lib';
import { endOfDay, formatISO, startOfDay, subDays } from 'date-fns';
import Cookies from 'js-cookie';
import { isFunction } from 'lodash';
import { rem, rgba } from 'polished';

// $FlowFixMe
import React, { useCallback, useContext, useEffect, useState } from 'react';

// $FlowFixMe
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSpring } from 'react-spring';
import styled, { css } from 'styled-components';

import { type SearchParams, type SearchType } from '../../shared/dataTypeAndUtils';

import Announcements from './Announcements';

import { GlobalSearchContext } from 'screens/App';
// $FlowFixMe
import { INITIAL_STATE as INITIAL_SEARCH_STATE } from 'screens/Search';
// $FlowFixMe
import { DurationDropdown } from 'ui-new';
import AccountabilityLink from 'ui/atoms/AccountabilityLink';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Logo from 'ui/atoms/Logo';
import SearchPlaceholder from 'ui/atoms/SearchPlaceholder';
import SettingsLink from 'ui/atoms/SettingsLink';
import SupportLink from 'ui/atoms/SupportLink';
import UserLink from 'ui/atoms/UserLink';
import AccessChecker, { permissionTypes } from 'ui/molecules/AccessChecker';
import Alert from 'ui/molecules/Alert';
import HeaderOptions from 'ui/molecules/HeaderOptions';
import MainNavigation from 'ui/molecules/MainNavigation';

// $FlowFixMe
import { useDuration, useDurationContext } from 'utils/duration';

//
// Constants
// -------------------------------------------------------------------------------------------------

/* Permissions Constants */
const { ADMIN, CONFIG_ADMIN, DELEGATED_ROLES_ENABLED, MAILSEARCH_ENABLED, RESUME_PARENT_ENABLED, SETTINGS_READ_ONLY } =
  permissionTypes;

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 ${({ theme }) => rem(theme.spacing.md)};
`;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  min-height: ${rem(56)};
  position: relative;
  z-index: 11;

  ${(p) =>
    p.hasShadow &&
    css`
      box-shadow: ${rgba(p.theme.colors.jet, 0.1)} 0 ${rem(2)} ${rem(4)};
    `};
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  margin-left: ${({ theme }) => rem(theme.spacing.md)};
  position: relative;

  & > :nth-child(1) {
    flex-grow: 1;
  }

  & > :nth-child(2) {
    margin-right: ${rem(24)};
  }

  & > :nth-child(3) {
    width: ${rem(184)};
  }

  & > :nth-child(5) {
    margin-left: ${rem(24)};
    width: ${rem(144)};
  }
`;

const Search = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  height: ${rem(38)};
  margin-right: ${(p) => rem(p.theme.spacing.md)};
  margin-top: ${rem(1)};
  position: relative;
  transition: left 150ms ${(p) => p.theme.timing.easeOutcirc}, width 150ms ${(p) => p.theme.timing.easeOutcirc};
  white-space: nowrap;
  will-change: left, width;
  z-index: 100;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

export default function Header() {
  const duration = useDuration();
  const { setGlobalSearchConnector } = useContext(GlobalSearchContext);

  const history = useHistory();
  const location = useLocation();
  const dateRange = [startOfDay(subDays(new Date(), Number.parseInt(duration, 10))), endOfDay(new Date())];

  const [alertMessage, setAlertMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const headerStyle = useSpring({
    config: { tension: 1200, friction: 80 },
  });

  const onAlertDismiss = () => {
    setDialogOpen(false);
    setAlertMessage('');
  };

  const dateRangeDependency = JSON.stringify(dateRange);
  // This gets passed to the search context and then is fired whenever a component uses the `useGlobalSearch` hook.
  const triggerSearch = useCallback(
    (triggeredSearchType: SearchType, params: SearchParams) => {
      const searchType = triggeredSearchType === 'detectionSearch' ? 'detection-only' : 'all-mail';
      setClosePath(location.pathname);

      if (searchType === 'all-mail') {
        history.push(
          `/beta/search?${toQueryString({
            ...INITIAL_SEARCH_STATE,
            ...{
              alertId: params?.alertId,
              end: formatISO(params?.dateRange?.[1] || dateRange[1]),
              domain: params?.domain,
              messageId: params?.messageId,
              metric: params?.metric,
              recipient: params?.recipient,
              searchTerm: params?.query,
              searchType,
              sender: params?.sender,
              start: formatISO(params?.dateRange?.[0] || dateRange[0]),
              subject: params?.subject,
            },
          })}`
        );
      } else {
        history.push(
          `/beta/search?${toQueryString({
            ...INITIAL_SEARCH_STATE,
            end: formatISO(params?.dateRange?.[1] || dateRange[1]),
            finalDisposition: params?.finalDisposition || '',
            metric: params?.metric,
            sender: params?.sender,
            recipient: params?.recipient,
            searchTerm: params?.query,
            searchType,
            start: formatISO(params?.dateRange?.[0] || dateRange[0]),
          })}`
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRangeDependency, history, location]
  );

  useEffect(() => {
    if (isFunction(setGlobalSearchConnector)) setGlobalSearchConnector(() => triggerSearch);
  }, [setGlobalSearchConnector, triggerSearch]);

  function handleLogoClick() {
    history.push('/home');
  }

  function handleLogout() {
    window.location = '/logout';
  }

  const handlePlaceholderClick = (event) => {
    // $FlowFixMe
    event.stopPropagation();
    Cookies.set('searchLaunchedFrom', document.location.pathname);
    history.push(`/beta/search?${toQueryString(INITIAL_SEARCH_STATE)}`);
  };

  return (
    <>
      <Container hasShadow={location.pathname === '/user'}>
        <Center>
          <Logo onClick={handleLogoClick} />
          <Navigation>
            <MainNavigation style={headerStyle} />

            <DashboardDateRange />
            <AccessChecker allowed={MAILSEARCH_ENABLED} renderNoAccess={false}>
              <AccessChecker denied={DELEGATED_ROLES_ENABLED} renderNoAccess={false}>
                <Search data-testid="main-navigation-search">
                  <SearchPlaceholder onClick={handlePlaceholderClick} />
                </Search>
              </AccessChecker>
            </AccessChecker>

            <HeaderOptions style={headerStyle}>
              <AccessChecker denied={DELEGATED_ROLES_ENABLED} renderNoAccess={false}>
                <AccountabilityLink />
              </AccessChecker>
              <AccessChecker
                allowed={[ADMIN, CONFIG_ADMIN, SETTINGS_READ_ONLY, DELEGATED_ROLES_ENABLED]}
                canAccess={canAccessSettings}
                renderNoAccess={false}
              >
                <SettingsLink />
              </AccessChecker>
              <SupportLink />
              <Announcements />
              <AccessChecker denied={RESUME_PARENT_ENABLED} renderNoAccess={false}>
                <UserLink
                  onClickLogout={handleLogout}
                  onClickProfile={(e) => {
                    e.preventDefault();
                    history.push('/user');
                  }}
                />
              </AccessChecker>
            </HeaderOptions>
          </Navigation>
        </Center>
      </Container>

      <ConditionalRender condition={!!alertMessage.length && dialogOpen}>
        <Alert message={alertMessage} onDismiss={onAlertDismiss} open={dialogOpen} />
      </ConditionalRender>
    </>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

function DashboardDateRange() {
  const location = useLocation();
  const [duration, setDuration] = useDurationContext();

  if (['/home', '/submissions'].includes(location.pathname)) {
    return <DurationDropdown onChange={setDuration} value={duration} />;
  }

  // We need to return back a DOM element since some of the CSS is nth-child based.
  return <div />;
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function canAccessSettings(permissions: boolean[]): boolean {
  return permissions.includes(true); // At least one permission is true
}

function setClosePath(path) {
  // $FlowFixMe
  document.body.dataset.closePath = path;
}
