import { TFunction } from 'react-i18next';

import enUS from '../../config/i18n/en-US/index.json';

export function defang(url: string): string {
  return url.replace(/ftp/g, 'fxp').replace(/http/g, 'hxxp').replace(/\./g, '[.]');
}

// $FlowFixMe
export type DispositionType = '' | 'bulk' | 'malicious' | 'malicious-bec' | 'spam' | 'suspicious' | 'spoof';
export type SearchParams = {
  alertId?: string,
  dateRange?: [Date, Date],
  domain?: string,
  finalDisposition: DispositionType,
  messageId?: string,
  metric?: string,
  query?: string,
  recipient?: string,
  sender?: string,
  subject?: string,
};
export type SearchType = 'detectionSearch' | 'mailTrace';
export type SearchValue = null | string;

export const getUniqueRecipientsAsString = (...recipientsArray: Array<string | string[] | null>) => {
  const recipientSet = new Set();

  recipientsArray.forEach((recipients) => {
    if (Array.isArray(recipients)) {
      recipients.forEach((recipient: string) => {
        if (recipient) recipientSet.add(recipient);
      });
    }
    if (recipients && typeof recipients === 'string') {
      recipientSet.add(recipients);
    }
  });

  return [...recipientSet].join(', ');
};

export async function downloadEml(filename: string, text: string) {
  const u8arr = await encode(text);
  const url = window.URL.createObjectURL(new Blob([u8arr], { type: 'application/octet-stream' }));
  const element = document.createElement('a');

  element.setAttribute('href', url);
  element.setAttribute('download', `${filenameFromPath(filename)}.infected`);
  element.style.display = 'none';

  if (document.body !== null) {
    document.body.appendChild(element);
    element.click();
    // $FlowFixMe - already doing a document.body check above
    document.body.removeChild(element);
  }
}

export async function encode(text: string): any {
  if (typeof TextEncoder !== 'undefined') {
    // $FlowFixMe
    return new TextEncoder('utf-8').encode(text);
  }

  const { manualEncoder } = await import('./manualEncoder');
  return manualEncoder(text);
}

const filenameFromPath = (path: string): string => {
  return path.replace(/kubrick_(.*)_emails/, 'email');
};

// converts {value:em} to {{ value }} so we can inject and translate these variables
function parseFinding(reason: string): string {
  let openBracketIndex = 0;
  let closeBracketIndex = 0;
  let substringToChange = '';
  // $FlowFixMe property replaceAll is missing in String
  const reasonString = reason.replaceAll('}', ' }}').replaceAll('{', '{{ ');
  let parsedString = reasonString;

  while (closeBracketIndex < reasonString.length - 1 && openBracketIndex !== -1) {
    openBracketIndex = reasonString.indexOf('{{ ', closeBracketIndex);
    closeBracketIndex = reasonString.indexOf(' }}', openBracketIndex);
    substringToChange = reasonString.substring(openBracketIndex, closeBracketIndex);
    const partToCut = substringToChange.substring(substringToChange.indexOf(':'));
    parsedString = parsedString.replace(partToCut, '');
  }
  return parsedString;
}

type Dispositions = 'BULK' | 'MALICIOUS' | 'MALICIOUS-BEC' | 'NONE' | 'SPAM' | 'SPOOF' | 'SUSPICIOUS';

export interface FindingsType {
  action: 'PROMOTE' | 'SILENT';
  attachment?: string;
  detail?: string;
  detection: Dispositions;
  diagnostic: string;
  field?: string;
  name: string;
  portion?: string;
  reason?: string;
  score?: number;
  value?: string;
  version: string;
}

export // finds the reason and maches with the key in i18n file
function translateReason(reasonObj: FindingsType, t: TFunction): string {
  const { attachment, detail, detection, field, portion, reason, score, value } = reasonObj;

  const translatedReasonsValueArr = Object.values(enUS.adminQuarantine.reasons);
  const parsedReason = parseFinding(reason);
  // eslint-disable-next-line no-param-reassign
  const customerReasonWordsArr = parsedReason.split(' ');

  const reasonValue =
    translatedReasonsValueArr
      // Find if any reasons contain at least one target word
      // $FlowFixMe
      .filter((r) => customerReasonWordsArr.some((word) => r.includes(word)))
      // Sort the reasons in descending order of how many words matched
      .sort(
        (a, b) =>
          // $FlowFixMe
          customerReasonWordsArr.filter((word) => b.includes(word)).length -
          // $FlowFixMe
          customerReasonWordsArr.filter((word) => a.includes(word)).length
      )[0] || ''; // The first reason matched the most words, otherwise return ''

  const reasonKey =
    Object.keys(enUS.adminQuarantine.reasons).find((key) => enUS.adminQuarantine.reasons[key] === reasonValue) ||
    'DEFAULT';

  return t(`reasons.${reasonKey}`, { attachment, detail, detection, field, portion, score, value });
}
