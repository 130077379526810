// @flow

import { rem } from 'polished';
import styled, { css } from 'styled-components';

import type { DispositionType } from '.';

const Container = styled.div`
  background-color: transparent;
  border: 0;
  cursor: ${({ canLinkToDisposition }) => (canLinkToDisposition ? 'pointer' : 'default')};
  margin: 0;
  outline: 0;
  padding: 0;

  &:active {
    transform: translateY(${rem(1)});
  }

  ${({ disposition, theme }) =>
    disposition &&
    css`
      & > div:first-child {
        background-color: ${disposition === 'QUARANTINE_RELEASE'
          ? theme.colors.white
          : theme.colors[translateDisposition(disposition)]};
        border: ${rem(1)} solid ${theme.colors[translateDisposition(disposition)]};
        & > span:first-child {
          color: ${disposition === 'QUARANTINE_RELEASE'
            ? theme.colors[translateDisposition(disposition)]
            : theme.colors.white};
        }
      }
    `}
`;

export default Container;

//
// Private functions
// -------------------------------------------------------------------------------------------------

function translateDisposition(disposition: DispositionType): string {
  if (disposition.toLowerCase() === 'malicious-bec') return 'maliciousBec';
  if (disposition.toLowerCase() === 'none') return 'noDisposition';
  if (disposition === 'QUARANTINE_RELEASE') return 'quarantineRelease';

  return disposition.toLowerCase();
}
