// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import NavLink from 'ui/atoms/NavLink';
import AccessChecker, { canAccessOptions, permissionTypes } from 'ui/molecules/AccessChecker';

export default function HeaderLeft() {
  const { ADMIN, READ_WRITE } = permissionTypes;
  const { t } = useTranslation('email');

  return (
    <>
      <NavLink exact lined to="/email">
        {t('common:overview')}
      </NavLink>

      <NavLink lined to="/email/detections">
        {t('detections')}
      </NavLink>

      <AccessChecker allowed={[ADMIN, READ_WRITE]} canAccess={canAccessOptions.any} renderNoAccess={false}>
        <NavLink lined to="/email/admin-quarantine">
          {t('adminQuarantine')}
        </NavLink>
      </AccessChecker>

      <NavLink lined to="/email/user-activity">
        {t('userActivity')}
      </NavLink>

      <NavLink lined to="/email/detection-details">
        {t('detectionDetails')}
      </NavLink>
    </>
  );
}
