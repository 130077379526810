// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from './Container';
import Direction from './Direction';
import Number from './Number';

type Props = { boxed?: boolean, children: number, dataTestId?: string, shrunk?: boolean };

const PercentChange = ({ boxed = false, children, dataTestId = 'molecule-percent-change', shrunk = false }: Props) => {
  let size = shrunk ? 'medium' : 'large';
  if (boxed) size = shrunk ? 'tiny' : 'small';

  const { t } = useTranslation('components');

  return (
    <Container boxed={boxed} data-testid={dataTestId} negative={children < 0}>
      <Direction negative={children < 0} size={size} />
      <Number size={size}>{t('PercentChange.percent', { value: children })}</Number>
    </Container>
  );
};

export default PercentChange;
