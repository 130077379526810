// @flow

import { loader } from 'graphql.macro';
import { type TFunction } from 'react-i18next';

export const insightsAllowListByTypeQuery = loader('./queries/InsightsAllowListByType.graphql');

export type AllowListList = { count: number, pattern: string, patternType: string };

export type AllowListCountsByDate = { date?: number, keys?: string[], values?: number[] };

type GraphTableReturnType = {
  label: string,
  value: number,
};

type WhitelistTypes = 'acceptableSender' | 'allowedRecipient' | 'allowedSender' | 'clientWhitelisted';

type SendersData = {
  name: string,
  count: number,
};

type SendersListType = {
  [type: WhitelistTypes]: SendersData[],
};

export const PATTERN = {
  'Allowed Recipients': 'allowedRecipient',
  'Allowed Senders': 'allowedSender',
  'Acceptable Senders': 'acceptableSender',
};

export function dataForGraphTable(
  list: Array<AllowListList>,
  allowType: string,
  t: TFunction
): Array<GraphTableReturnType> {
  if (list) {
    return list
      .filter((row) => row.patternType === PATTERN[allowType])
      .map((row) => ({ label: t(row.pattern), value: row.count }));
  }
  return [];
}

export function mapSendersWithPatternType(sendersList: SendersListType) {
  let allAllowedTypes = [];

  Object.keys(sendersList).forEach((type) => {
    const getPatternType = type === 'clientWhitelisted' ? 'acceptableSender' : type;
    // eslint-disable-next-line
    allAllowedTypes = [
      ...allAllowedTypes,
      ...sendersList[type].map((entry) => ({
        ...{ count: entry.count, pattern: entry.name, patternType: getPatternType },
      })),
    ];
  });

  return allAllowedTypes;
}
